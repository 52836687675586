/* USERS */
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

/**
 * add USER
 */
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';

/**
 * Edit USER
 */
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

/**
 * Delete USER
 */
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

/**
 * GROUP-SUBGROUPS-DEPENDENCIES
 */

export const ADD_GROUP_SUBGROUPS_DEPENDENCIES = 'ADD_GROUP_SUBGROUPS_DEPENDENCIES';
export const ADD_GROUP_SUBGROUPS_DEPENDENCIES_SUCCESS = 'ADD_GROUP_SUBGROUPS_DEPENDENCIES_SUCCESS';
export const ADD_GROUP_SUBGROUPS_DEPENDENCIES_FAIL = 'ADD_GROUP_SUBGROUPS_DEPENDENCIES_FAIL';


/**
 * GET FILTERED USERS
 */
export const GET_FILTERED_USER = 'GET_FILTERED_USER';
export const GET_FILTERED_USER_SUCCESS = 'GET_FILTERED_USER_SUCCESS';
export const GET_FILTERED_USER_FAILURE = 'GET_FILTERED_USER_FAILURE';
export const RESET_ERROR_STATUS = 'RESET_ERROR_STATUS';

/* USERS DETAIL */
export const GET_USER_DETAIL = 'GET_USER_DETAIL';
export const GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS';
export const GET_USER_DETAIL_FAIL = 'GGET_USER_DETAIL_FAIL';

/** ADD ORDER */
export const ADD_USER_ORDER_SUCCESS = 'ADD_USER_ORDER_SUCCESS';

/* UPDATE ORDER  */
export const UPDATE_USER_ORDER_SUCCESS = 'UPDATE_USER_ORDER_SUCCESS';

/* UPDATE USER STATUS */
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';
export const UPDATE_USER_STATUS_SUCCESS = 'UPDATE_USER_STATUS_SUCCESS';
export const UPDATE_USER_STATUS_FAIL = 'UPDATE_USER_STATUS_FAIL';

/* SEARCH USER BY GROUP*/
export const GET_FILTERED_USER_BY_GROUP = 'GET_FILTERED_USER_BY_GROUP';
export const GET_FILTERED_USER_BY_GROUP_SUCCESS = 'GET_FILTERED_USER_BY_GROUP_SUCCESS';
export const GET_FILTERED_USER_BY_GROUP_FAIL = 'GET_FILTERED_USER_BY_GROUP_FAIL';

export const REMOVE_FILTERED_USER = 'REMOVE_FILTERED_USER';

/* DELETE USER IN GROUP */
export const DELETE_USER_ON_GROUP = 'DELETE_USER_ON_GROUP';
export const DELETE_USER_ON_GROUP_SUCCESS = 'DELETE_USER_ON_GROUP_SUCCESS';
export const DELETE_USER_ON_GROUP_FAIL = 'DELETE_USER_ON_GROUP_FAIL';

/** ADD FAVORITE PRODUCT */
export const ADD_USER_FAVORITE_PRODUCT = 'ADD_FAVORITE_PRODUCT';
export const ADD_USER_FAVORITE_PRODUCT_SUCCESS = 'ADD_FAVORITE_PRODUCT_SUCCESS';
export const ADD_USER_FAVORITE_PRODUCT_FAIL = 'ADD_FAVORITE_PRODUCT_FAIL';

export const EXPORT_USER_CSV = 'EXPORT_USER_CSV';
export const EXPORT_USER_CSV_SUCCESS = 'EXPORT_USER_CSV_SUCCESS';
export const EXPORT_USER_CSV_FAIL = 'EXPORT_USER_CSV_FAIL';
