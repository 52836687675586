/* CATEGORIES */
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';

export const GET_CATEGORIES_WITHOUT_SUBS = 'GET_CATEGORIES_WITHOUT_SUBS';
export const GET_CATEGORIES_WITHOUT_SUBS_SUCCESS = 'GET_CATEGORIES_WITHOUT_SUBS_SUCCESS';
export const GET_CATEGORIES_WITHOUT_SUBS_FAIL = 'GET_CATEGORIES_WITHOUT_SUBS_FAIL';

export const GET_CATEGORIES_WITHOUT_SUBS_BY_ID = 'GET_CATEGORIES_WITHOUT_SUBS_BY_ID';
export const GET_CATEGORIES_WITHOUT_SUBS_BY_ID_SUCCESS = 'GET_CATEGORIES_WITHOUT_SUBS_BY_ID_SUCCESS';
export const GET_CATEGORIES_WITHOUT_SUBS_BY_ID_FAIL = 'GET_CATEGORIES_WITHOUT_SUBS_BY_ID_FAIL';

/**
 * add CATEGORY
 */
export const ADD_NEW_CATEGORY = 'ADD_NEW_CATEGORY';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAIL = 'ADD_CATEGORY_FAIL';

/**
 * Edit CATEGORY
 */
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';

/**
 * Delete CATEGORY
 */
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';

/* SUB_CATEGORIES */
export const GET_SUB_CATEGORIES = 'GET_SUB_CATEGORIES';
export const GET_SUB_CATEGORIES_SUCCESS = 'GET_SUB_CATEGORIES_SUCCESS';
export const GET_SUB_CATEGORIES_FAIL = 'GET_SUB_CATEGORIES_FAIL';

export const ADD_SUB_CATEGORY_SUCCESS = 'ADD_SUB_CATEGORY_SUCCESS';

export const UPDATE_SUB_CATEGORY_SUCCESS = 'UPDATE_SUB_CATEGORY_SUCCESS';

export const DELETE_SUB_CATEGORY_SUCCESS = 'DELETE_SUB_CATEGORY_SUCCESS';

/**
 * RESET ERROR STATUS
 */
export const RESET_ERROR_STATUS = 'RESET_ERROR_STATUS';

/**
 * SWAP CATEGORY POSITION
 */
export const SWAP_CATEGORY_POSITION = 'SWAP_CATEGORY_POSITION';
export const SWAP_CATEGORY_POSITION_SUCCESS = 'SWAP_CATEGORY_POSITION_SUCCESS';
export const SWAP_CATEGORY_POSITION_FAILURE = 'SWAP_CATEGORY_POSITION_FAILURE';
export const SWAP_SUB_CATEGORY_POSITION_SUCCESS = 'SWAP_SUB_CATEGORY_POSITION_SUCCESS';
export const SWAP_SUB_CATEGORY_POSITION_FAILURE = 'SWAP_SUB_CATEGORY_POSITION_FAILURE';

 export const GET_GROUPS_FOR_CATEGORY = 'GET_GROUPS_FOR_CATEGORY';
 export const GET_GROUPS_FOR_CATEGORY_SUCCESS = 'GET_GROUPS_FOR_CATEGORY_SUCCESS';
 export const GET_GROUPS_FOR_CATEGORY_FAIL = 'GET_GROUPS_FOR_CATEGORY_FAIL';

 export const GET_PRODUCTS_FOR_CATEGORY = 'GET_PRODUCTS_FOR_CATEGORY';
 export const GET_PRODUCTS_FOR_CATEGORY_SUCCESS = 'GET_PRODUCTS_FOR_CATEGORY_SUCCESS';
 export const GET_PRODUCTS_FOR_CATEGORY_FAIL = 'GET_PRODUCTS_FOR_CATEGORY_FAIL';

 export const UPDATE_GROUPS_FOR_CATEGORY = 'UPDATE_GROUPS_FOR_CATEGORY';
 export const UPDATE_GROUPS_FOR_CATEGORY_SUCCESS = 'UPDATE_GROUPS_FOR_CATEGORY_SUCCESS';
 export const UPDATE_GROUPS_FOR_CATEGORY_FAIL = 'UPDATE_GROUPS_FOR_CATEGORY_FAIL';

 export const UPDATE_PRODUCTS_FOR_CATEGORY = 'UPDATE_PRODUCTS_FOR_CATEGORY';
 export const UPDATE_PRODUCTS_FOR_CATEGORY_SUCCESS = 'UPDATE_PRODUCTS_FOR_CATEGORY_SUCCESS';
 export const UPDATE_PRODUCTS_FOR_CATEGORY_FAIL = 'UPDATE_PRODUCTS_FOR_CATEGORY_FAIL';
