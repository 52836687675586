export const ORDER_HIGHER_ACTION_VALUE = {
  ADD_NEW_ORDER: 'ADD_NEW_ORDER',
  ADD_NEW_PRODUCT: 'ADD_NEW_PRODUCT',
  EXPORT_CSV: 'EXPORT_CSV'
};
  
export const ORDER_HIGHER_ACTION_LABELS = {
  ADD_NEW_ORDER: 'add new order',
  ADD_NEW_PRODUCT: 'add new product',
  EXPORT_CSV: 'export csv'
};

export const ORDER_HIGHER_ACTION = [
  {
    value: ORDER_HIGHER_ACTION_VALUE.ADD_NEW_ORDER,
    label: ORDER_HIGHER_ACTION_LABELS.ADD_NEW_ORDER
  },
  {
    value: ORDER_HIGHER_ACTION_VALUE.ADD_NEW_PRODUCT,
    label: ORDER_HIGHER_ACTION_LABELS.ADD_NEW_PRODUCT
  },
  {
    value: ORDER_HIGHER_ACTION_VALUE.EXPORT_CSV,
    label: ORDER_HIGHER_ACTION_LABELS.EXPORT_CSV
  }
];