import { CATCH_ERROR } from './actionsTypes';
import { get as _get } from 'lodash';

export const catchError = error => {
  return {
    type: CATCH_ERROR,
    payload: {
      message: _get(error, ['data', 'erros'], ''),
      status: _get(error, 'status', '')
    }
  };
};
