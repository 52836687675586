import { AvField, AvForm } from 'availity-reactstrap-validation';
import ActionsPopup from 'components/Common/ActionPopup';
import DeliveryMenForm from 'components/Common/DeliveryMenForm';
import { DELIVERY_ACTIONS } from 'components/Common/DeliveryMenForm/constants';
import { OrderForm } from 'components/Common/OrderForm';
import { AddProductForm } from 'components/Common/OrderForm/addProductForm';
import { EllipseSpan } from 'components/Common/StyledComponents/Span';
import { TooltipComponent } from 'components/Common/Tooltip';
import { SUPERIOR_ADMIN_ROLE } from 'constants/index';
import { showNotification } from 'constants/messages';
import { get as _get, isEmpty, isNull } from 'lodash';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Table } from 'react-super-responsive-table';
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import {
  addNewOrder,
  addSelfProduct,
  deleteSelfProduct,
  exportCsv,
  getDeliveryStaffs,
  getOrderById,
  getOrderHistory,
  resetErrorStatus,
  updateDeliveryMen,
  updateOrder,
  updateOrderStatus,
  updateSelfProduct,
  deleteOrder
} from 'store/orders/actions';
import styled from 'styled-components';
import { PERMISSIONS } from 'utils/permissions';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
  ORDER_ACTION,
  ORDER_HIGHER_ACTION,
  ORDER_HIGHER_ACTION_VALUE,
  SELECTIVE_TAB,
  SELECTIVE_TAB_VALUE
} from './constants';

import {
  ERROR_MESSAGE
} from 'constants/messages';

const Icon = styled.i`
  margin-left: 5px;
`;

const OrderDetail = props => {
  const history = useHistory();
  const orderId = _get(props, ['match', 'params', 'id'], '');
  const dispatch = useDispatch();
  const [isConfirming, setIsConfirming] = useState(false);
  const [isEditting, setIsEditting] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [action, setAction] = useState('');
  const [manipulatingProduct, setManipulatingProduct] = useState({});
  const [editingOrder, setEdittingOrder] = useState({});
  const [newProduct, setNewProduct] = useState({});
  const [isEdittingOrder, setIsEdittingOrder] = useState(false);
  const [isAddingNewOrder, setIsAddingNewOrder] = useState(false);
  const [isEdittingDelivery, setIsEdittingDelivery] = useState(false);
  const [isEditDeliveryMen, setIsEditDeliveryMen] = useState(false);
  const [isActionDropdownOpen, setIsActionDropdownOpen] = useState(false);
  const [isPushNotification, setIsPushNotification] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdateBillOrder, setIsUpdateBillOrder] = useState(false);
  const toggleActionDropdown = () => setIsActionDropdownOpen(prevState => !prevState);

  const { t: translator } = props;

  const toggleAddOrder = () => {
    setIsAddingNewOrder(!isAddingNewOrder);
  };

  const toggleEdittingModal = () => setIsEditting(!isEditting);

  const toggleDeliveryModal = () => setIsEdittingDelivery(!isEdittingDelivery);

  const toggleBillOrderModal = () => setIsUpdateBillOrder(!isUpdateBillOrder);

  const {
    orderHistory,
    orderDetail,
    isAddOrderSuccess,
    deliveryStaffs,
    myPermissions,
    role,
    errors,
    isSuccess
  } = useSelector(state => {
    return {
      orderDetail: _get(state, ['orders', 'orderDetail'], {}),
      orderHistory: _get(state, ['orders', 'orderHistory'], []),
      isAddOrderSuccess: _get(state, ['orders', 'isAddOrderSuccess'], false),
      deliveryStaffs: _get(state, ['orders', 'deliveryStaffs'], []),
      myPermissions: _get(state, ['permissions', 'myPermissions'], []),
      role: _get(state, ['Profile', 'profile', 'role'], undefined),
      errors: _get(state, ['orders', 'errors', 'errors'], ''),
      isSuccess: _get(state, ['orders', 'isSuccess'], '')
    };
  });

  const [isSuperior, setIsSuperior] = useState(false);
  useEffect(() => {
    setIsSuperior(role === SUPERIOR_ADMIN_ROLE);
  }, [role]);

  const [isAssignDeliveryPermission, setIsAssignDeliveryPermission] = useState(false);
  const [isPrivateCommentPermission, setIsPrivateCommentPermission] = useState(false);
  const [isModifyDeleteProductPermission, setIsModifyDeleteProductPermission] = useState(false);
  const [isAddOrderPermission, setIsAddOrderPermission] = useState(false);
  const [isAddProductPermission, setIsAddProductPermission] = useState(false);
  const [isChangeStatusOrderPermission, setIsChangeStatusOrderPermission] = useState(false);
  const [isExportCSVPermission, setIsExportCSVPermission] = useState(false);
  const [isViewHistoryPermission, setIsViewHistoryPermission] = useState(false);
  const [isModifyDeleteOrderPermission, setIsModifyDeleteOrderPermission] = useState(false);
  const [isMessagesNotificationPermission, setIsMessagesNotificationPermission] = useState(false);
  const [orderReverse, setOrderReverse] = useState(orderHistory);
  // const [currentAction, setCurrentAction] = useState('');

  useEffect(() => {
    if (isEmpty(myPermissions)) return;
    const arr = [];
    myPermissions.forEach(permission => {
      const permissionName = _get(permission, 'name', '');
      arr.push(permissionName);
    });
    setIsAssignDeliveryPermission(arr.includes(PERMISSIONS.ORDER_MANAGEMENT.ASSIGN_DELIVERY));
    setIsPrivateCommentPermission(arr.includes(PERMISSIONS.ORDER_MANAGEMENT.LEAVE_COMMENT));
    setIsModifyDeleteProductPermission(
      arr.includes(PERMISSIONS.ORDER_MANAGEMENT.MODIFY_DELETE_PRODUCT_ORDER)
    );
    setIsAddOrderPermission(arr.includes(PERMISSIONS.ORDER_MANAGEMENT.ADD_ORDER));
    setIsAddProductPermission(arr.includes(PERMISSIONS.ORDER_MANAGEMENT.ADD_PRODUCT_ORDER));
    setIsChangeStatusOrderPermission(
      arr.includes(PERMISSIONS.ORDER_MANAGEMENT.CHANGE_STATUS_ORDER)
    );
    setIsExportCSVPermission(arr.includes(PERMISSIONS.ORDER_MANAGEMENT.EXPORT_CSV));
    setIsViewHistoryPermission(arr.includes(PERMISSIONS.ORDER_MANAGEMENT.VIEW_HISTORY_ACTIONS));
    setIsModifyDeleteOrderPermission(
      arr.includes(PERMISSIONS.ORDER_MANAGEMENT.MODIFY_DELETE_ORDER)
    );
    setIsMessagesNotificationPermission(arr.includes(PERMISSIONS.MESSAGES_NOTIFICATIONS));
  }, [myPermissions]);

  useEffect(() => {
    dispatch(getOrderById(orderId));
    dispatch(getDeliveryStaffs());
    dispatch(getOrderHistory(orderId));
  }, []);

  useEffect(() => {
    if (isEmpty(orderHistory)) return;
    setOrderReverse(orderHistory.reverse());
  }, [orderHistory]);

  useEffect(() => {
    if (isAddOrderSuccess) history.push('/orders');
  }, [isAddOrderSuccess]);

  useEffect(() => {
    if (!isEmpty(errors)) showNotification(translator(ERROR_MESSAGE.COMMON));
    if (isSuccess) showNotification('');
    dispatch(resetErrorStatus());
  }, [errors, isSuccess]);  
  
  const orderStatus = _get(orderDetail, 'status', '');
  const products = _get(orderDetail, 'products', []);
  const user = _get(orderDetail, 'user', []);
  const deliveryMen = useMemo(() => {
    const result = _get(orderDetail, 'delivery_men', {});
    return isNull(result) ? {} : result;
  });
  const [edittingDeliveryMen, setEdittingDeliveryMen] = useState({});

  const handleClickPush = () => {
    setIsPushNotification(!isPushNotification);
  };

  const getImage = image => {
    return `${process.env.REACT_APP_API_URL}/${image}`;
  };

  const handleValidDate = date => {
    return moment(new Date(date)).format('DD/MM/YYYY');
  };

  const timeToFranceZone = date => {
    return moment(new Date(date)).format('DD/MM/YYYY h:mma ');
  };

  const translateAction = actions => {
    const text = actions.split(": ");
    if (text.includes("modification order status")) {
      const subText = text[1].split(" > ");
      return translator(text[0]) + translator(subText[0]) + " > " + translator(subText[1]);
    }
    if (text.includes("Modification of the product quantity")) {
      return translator(text[0]) + text[1] + ": " + text[2];
    }
    return text[1] ? translator(text[0]) + text[1] : translator(text[0]);
  };

  const handleAddOrder = order => {
    dispatch(
      addNewOrder({
        ...order,
        userId: _get(user, 'id', '')
      })
    );
    toggleAddOrder();
  };

  const printIframe = id => {
    const iframe = document.frames ? document.frames[id] : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };

  const printPdf = () => {
    printIframe('print-order');
  };

  const exportOrderCsv = () => {
    const order = {
      orderId,
      orderNumber: _get(orderDetail, 'code', ''),
      brandName: _get(user, 'brand_name', ''),
      orderDate: moment(new Date(_get(orderDetail, 'created_at', ''))).format('YYYY-MM-DD')
    };
    dispatch(exportCsv(order));
  };

  // eslint-disable-next-line no-unused-vars
  const deleteOrderDetail = id => {
    if (isConfirming) {
      dispatch(deleteOrder(id));
      history.push('/orders');
    }
  };

  const handleHigherActions = selectedAction => {
    // setCurrentAction(selectedAction);
    switch (selectedAction.value) {
      case ORDER_HIGHER_ACTION_VALUE.ADD_NEW_ORDER:
        toggleAddOrder();
        break;
      case ORDER_HIGHER_ACTION_VALUE.PRINT_PDF:
        printPdf();
        break;
      case ORDER_HIGHER_ACTION_VALUE.EXPORT_CSV:
        exportOrderCsv();
        break;
      case ORDER_HIGHER_ACTION_VALUE.DELETE_ORDER:
        setIsConfirming(true);
        setAction(ORDER_HIGHER_ACTION_VALUE.DELETE_ORDER);
        break;
      default:
        return;
    }
  };

  const handleUpdateProduct = (e, values) => {
    setIsConfirming(true);
    const infoID = _get(manipulatingProduct, 'info', {});
    setNewProduct({
      productId: manipulatingProduct.id,
      amount: values.quantity,
      isPushNotification,
      infoId: _get(infoID, 'id', 0),
    });
  };

  const handleUpdateOrder = values => {
    setIsConfirming(true);
    setEdittingOrder({
      ...values
    });
  };

  const handleUpdateBillOrder = values => {
    setEdittingOrder({
      ...values
    });
    setIsUpdateBillOrder(true);
    toggleBillOrderModal();
  };

  const handleDeleteOrder = product => {
    dispatch(
      deleteSelfProduct({
        id: orderId,
        product,
        isPushNotification,
      })
    );
  };

  const handleAddProduct = product => {
    setIsConfirming(true);
    setAction(ORDER_ACTION.ADDING_PRODUCT);
    setManipulatingProduct(product);
  };

  const getProductActions = product => [
    {
      label: translator('edit'),
      type: 'success',
      onClick: () => {
        setIsEditting(true);
        setAction(ORDER_ACTION.EDITTING_PRODUCT);
        setManipulatingProduct(product);
        toggleEdittingModal();
      }
    },
    {
      label: translator('delete'),
      type: 'danger',
      onClick: () => {
        setIsConfirming(true);
        setAction(ORDER_ACTION.DELETING_PRODUCT);
        setManipulatingProduct(product);
        setIsDeleting(true);
      }
    }
  ];

  const toggleAddProduct = () => {
    setIsAdding(!isAdding);
  };

  const toggleEdittingOrder = () => {
    setIsEdittingOrder(!isEdittingOrder);
  };

  // eslint-disable-next-line no-unused-vars
  const handleSubmit = () => {
    switch (action) {
      case ORDER_ACTION.UPDATE_ORDER:
        dispatch(updateOrder(editingOrder));
        break;
      case ORDER_ACTION.EDITTING_PRODUCT:
        dispatch(
          updateSelfProduct({
            id: orderId,
            product: newProduct
          })
        );
        setIsEditting(false);
        break;
      case ORDER_ACTION.DELETING_PRODUCT:
        handleDeleteOrder(manipulatingProduct);
        break;
      case ORDER_ACTION.UPDATE_ORDER_STATUS:
        dispatch(
          updateOrderStatus({
            order: {
              id: _get(orderDetail, 'id', ''),
              status: parseInt(_get(editingOrder, 'status', '')),
              isPushNotification
            },
          })
        );
        toggleEdittingOrder();
        break;
      case ORDER_ACTION.ADDING_PRODUCT:
        dispatch(
          addSelfProduct({
            id: orderId,
            product: manipulatingProduct
          })
        );
        toggleAddProduct();
        break;
      case ORDER_HIGHER_ACTION_VALUE.DELETE_ORDER:
        deleteOrderDetail(orderId);
        break;
      default:
        break;
    }
    setAction('');
    setIsConfirming(false);
    setIsPushNotification(false);
    dispatch(getOrderHistory(orderId));
  };

  // eslint-disable-next-line no-unused-vars
  const handleUpdateStatus = (e, values) => {
    const status = _get(values, 'stat', '').toString();
    const prevStatus = _get(user, 'status', '').toString();
    if (status === prevStatus) return toggle();
    setAction(ORDER_ACTION.UPDATE_ORDER_STATUS);
    setIsConfirming(true);
    setEdittingOrder({
      status
    });
  };

  const handleValidPrivateCommentSubmit = (e, values) => {
    setAction(ORDER_ACTION.UPDATE_ORDER);
    handleUpdateOrder({
      id: _get(orderDetail, 'id', ''),
      private_comment: values.comment
    });
  };

  const handleValidBillOrder = (e, values) => {
    const newBillOrder = {
      id: _get(orderDetail, 'id', ''),
      bill_number: values.billNumber
    };
    dispatch(updateOrder(newBillOrder));
    toggleBillOrderModal();
    dispatch(getOrderHistory(orderId));
  };

  const handleUpdateDeliveryMen = deliveryStaff => {
    const isDeliveryStaffEmpty = isEmpty(deliveryStaff) || isNull(deliveryStaff);
    setEdittingDeliveryMen({
      deliveryMenId: isDeliveryStaffEmpty ? '' : deliveryStaff.id,
      name: deliveryMen.name
    });
    setIsEditDeliveryMen(true);
    toggleDeliveryModal();
  };

  const onSubmitDelivery = (e, values) => {
    handleValidDeliveryMenSubmit(e, values);
  };

  const handleValidDeliveryMenSubmit = (e, values) => {
    const newDeliveryMen = {
      orderId: orderId,
      deliveryMenId: _get(values, ['deliveryMen', 'id'], ''),
      isOrderDetail: true
    };
    dispatch(updateDeliveryMen(newDeliveryMen));
    toggleDeliveryModal();
    dispatch(getOrderHistory(orderId));
  };

  const privateComment = useMemo(() => {
    const result = _get(orderDetail, 'private_comment', '');
    return result === null ? '' : result;
  }, [orderDetail]);

  const billNumber = useMemo(() => {
    const result = _get(orderDetail, 'bill_number', '');
    return result === null ? '' : result;
  }, [orderDetail]);

  const showProductName = (name, productId) => {
    const tooltipContent = <p className='mt-1 ms-2'>{name}</p>;
    return (
      <TooltipComponent id={`product_${productId.toString()}`} content={tooltipContent}>
        <EllipseSpan>{name}</EllipseSpan>
      </TooltipComponent>
    );
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <iframe
          id='print-order'
          src={`/print/${orderId}`}
          style={{ display: 'none' }}
          title={translator('print order')}
        />
        <MetaTags>
          <title>{`${translator('order detail')} | Le diamant du terroir`}</title>
        </MetaTags>
        {isConfirming && (
          <SweetAlert
            title={translator('are you sure?')}
            warning
            showCancel
            confirmBtnBsStyle='success'
            cancelBtnBsStyle='danger'
            onConfirm={() => {
              handleSubmit();
              setIsDeleting(false);
              setIsPushNotification(false);
            }}
            onCancel={() => {
              setIsConfirming(false);
            }}
          >
            {isDeleting && (isSuperior || isMessagesNotificationPermission) && (
              <>
                <Input
                  type='checkbox'
                  onChange={() => handleClickPush()}
                  title={translator('push notification')}
                  value={isPushNotification}
                />
                <Label className='pushNoti_checkbox'>{translator('push notification')} </Label>
              </>
            )}
          </SweetAlert>
        )}
        <Modal isOpen={isAddingNewOrder} toggle={toggleAddOrder} scrollable={true}>
          <ModalHeader toggle={toggleAddOrder} tag='h4'>
            {translator('new order')}
          </ModalHeader>
          <ModalBody>
            <OrderForm
              userId={_get(user, 'id', '').toString()}
              handleSubmit={handleAddOrder}
              deliveryStaffs={deliveryStaffs}
              isNotificationEnable={isAddingNewOrder}
            />
          </ModalBody>
        </Modal>
        <Modal isOpen={isEditting} toggle={toggleEdittingModal} scrollable={true}>
          <ModalHeader toggle={toggleEdittingModal} tag='h4'>
            {translator('edit')}
          </ModalHeader>
          <ModalBody>
            <AvForm onValidSubmit={handleUpdateProduct}>
              <Container>
                <Row>
                  <Col>
                    <AvField
                      name='quantity'
                      label={translator('quantity')}
                      type='number'
                      errorMessage={translator('invalid quantity')}
                      validate={{
                        required: { value: true }
                      }}
                      min='1'
                      value={manipulatingProduct.amount || ''}
                    />
                    {(isSuperior || isMessagesNotificationPermission) && (
                      <>
                        <br />
                        <Input
                          type='checkbox'
                          onChange={() => handleClickPush()}
                          title={translator('push notification')}
                          value={isPushNotification}
                        />
                        <Label className='pushNoti_checkbox'>
                          {translator('push notification')}{' '}
                        </Label>
                      </>
                    )}
                  </Col>
                </Row>
              </Container>
              <br />
              <Row>
                <Col>
                  <div className='text-end'>
                    <button type='submit' className='btn btn-success save-customer'>
                      {translator('submit')}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
        <Modal isOpen={isAdding} toggle={toggleAddProduct} scrollable={true}>
          <ModalHeader toggle={toggleAddProduct} tag='h4'>
            {translator('new product')}
          </ModalHeader>
          <ModalBody>
            <AddProductForm
              handleSubmit={values => {
                handleAddProduct(values);
              }}
              userId={parseInt(_get(user, 'id', ''))}
              isNotificationEnable={isAdding}
            />
          </ModalBody>
        </Modal>
        <Modal isOpen={isEdittingOrder} toggle={toggleEdittingOrder} scrollable={true}>
          <ModalHeader toggle={toggleEdittingOrder} tag='h4'>
            {translator('edit')}
          </ModalHeader>
          <ModalBody>
            <AvForm onValidSubmit={handleUpdateStatus}>
              <Row form>
                <Col className='col-14'>
                  <div className='mb-3'>
                    <AvField
                      type='select'
                      name='stat'
                      label={translator("order's status")}
                      helpMessage={translator('select a status')}
                      errorMessage={translator('invalid product')}
                      validate={{
                        required: { value: true }
                      }}
                      value={orderDetail.status || ''}
                    >
                      <option value={SELECTIVE_TAB_VALUE[SELECTIVE_TAB.NEW_ORDER]}>
                        {translator(SELECTIVE_TAB.NEW_ORDER)}
                      </option>
                      <option value={SELECTIVE_TAB_VALUE[SELECTIVE_TAB.PREPARATION]}>
                        {translator(SELECTIVE_TAB.PREPARATION)}
                      </option>
                      <option value={SELECTIVE_TAB_VALUE[SELECTIVE_TAB.DELAYED]}>
                        {translator(SELECTIVE_TAB.DELAYED)}
                      </option>
                      <option value={SELECTIVE_TAB_VALUE[SELECTIVE_TAB.PROCESSED]}>
                        {translator(SELECTIVE_TAB.PROCESSED)}
                      </option>
                    </AvField>
                    {(isSuperior || isMessagesNotificationPermission) && (
                      <>
                        <br />
                        <Input
                          type='checkbox'
                          onChange={() => handleClickPush()}
                          title={translator('push notification')}
                          value={isPushNotification}
                        />
                        <Label className='pushNoti_checkbox'>
                          {translator('push notification')}{' '}
                        </Label>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className='text-end'>
                    <button type='submit' className='btn btn-success save-customer'>
                      {translator('update')}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
        
        <Modal isOpen={isEdittingDelivery} toggle={toggleDeliveryModal} scrollable={true}>
          <ModalHeader toggle={toggleDeliveryModal} tag='h4'>
            {isEditDeliveryMen ? translator('edit delivery men') : translator('add delivery men')}
          </ModalHeader>
          <ModalBody>
            <DeliveryMenForm
              deliveryMen={edittingDeliveryMen}
              action={isEditDeliveryMen ? DELIVERY_ACTIONS.UPDATE : DELIVERY_ACTIONS.CREATE}
              handleSubmit={onSubmitDelivery}
              deliveryStaffs={deliveryStaffs}
            />
          </ModalBody>
        </Modal>
        <Modal isOpen={isUpdateBillOrder} toggle={toggleBillOrderModal} scrollable={true}>
          <ModalHeader toggle={toggleBillOrderModal} tag='h4'>
            {translator('edit bill')}
          </ModalHeader>
          <ModalBody>
            <AvForm onValidSubmit={handleValidBillOrder}>
              <Container>
                <Row>
                  <Col>
                    <AvField
                      name='billNumber'
                      label={translator('bill number')}
                      errorMessage={translator('invalid bill number')}
                      validate={{
                        required: { value: true }
                      }}
                      value={billNumber}
                    />
                  </Col>
                </Row>
              </Container>
              <br />
              <Row>
                <Col>
                  <div className='text-end'>
                    <button type='submit' className='btn btn-success save-customer'>
                      {translator('submit')}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
        <Container fluid>
          <Breadcrumbs title={translator('orders')} breadcrumbItem='orderdetail' />
          <Row>
            <Col xs='10'></Col>
          </Row>
          <br />
          <Row>
            <Col lx='8'>
              {(isSuperior || isAddProductPermission) && (
                <Button
                  type='button'
                  color='success'
                  className='btn-rounded  mb-2 me-2'
                  onClick={() => setIsAdding(true)}
                >
                  <i className='mdi mdi-plus me-1' />
                  {translator('new product')}
                </Button>
              )}
              <Card>
                <CardBody>
                  <div className='table-responsive'>
                    <Table className='table align-middle mb-0 table-nowrap'>
                      <thead className='table-light'>
                        <tr>
                          <th>{translator('product image')}</th>
                          <th>{translator('product name')}</th>
                          <th>{translator('gen code')}</th>
                          <th>{translator('item code')}</th>
                          <th>{translator('packaging')}</th>
                          <th>{translator('quantity')}</th>
                          {(isSuperior || isModifyDeleteProductPermission) && (
                            <th>{translator('action')}</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {products.map(product => {
                          const amount = _get(product, 'amount', '');
                          const productId = _get(product, 'id', '');
                          return (
                            <tr key={productId}>
                              <td>
                                {product.image && (
                                  <img src={getImage(product.image)} className='mb-0 avatar-md' />
                                )}
                              </td>
                              <td>{showProductName(_get(product, 'title', ''), productId)}</td>
                              <td>{_get(product, ['info', 'gen_code'], '')}</td>
                              <td>{_get(product, 'item_code', '')}</td>
                              <td>{_get(product, ['info', 'packaging'], '')}</td>
                              <td>{amount}</td>
                              {(isSuperior || isModifyDeleteProductPermission) && (
                                <td>
                                  <ActionsPopup actions={getProductActions(product)} />
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>

              {(isSuperior || isViewHistoryPermission) && (
                  <Card>
                    <CardBody>
                      <h5>Logs de sécurité</h5>
                      <div className='table-responsive'>
                        <Table className='table align-middle mb-0 table-nowrap'>
                          <thead className='table-light'>
                          <tr>
                            <th>{translator('date')}</th>
                            <th>Utilisateur</th>
                            <th>{translator('action')}</th>
                          </tr>
                          </thead>
                          <tbody>
                          {orderReverse.map(order => {
                            const admin = _get(order, 'admin', {});
                            const userOrder = _get(order, 'user', {});
                            return (
                              <tr key={_get(order, 'id', {})}>
                                <td>{timeToFranceZone(_get(order, 'created_at', ''))}</td>
                                <td>{_get(admin, 'name', '') || _get(userOrder, 'user_name', '')}</td>
                                <td style={{ whiteSpace: 'break-spaces' }}>{translateAction(_get(order, 'action', ''))}</td>
                              </tr>
                            );
                          })}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
              )}
            </Col>

            <Col xl='4'>
              <Row className='text-sm-start mb-2'>
                <Dropdown isOpen={isActionDropdownOpen} toggle={toggleActionDropdown}>
                  <DropdownToggle outline color='success' caret>
                    {translator('actions')} <Icon className='fa fa-angle-down'></Icon>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>{translator('actions')}</DropdownItem>
                    {ORDER_HIGHER_ACTION.map((act, index) => {
                      if (
                        _get(act, 'value', '') === 'ADD_NEW_ORDER' &&
                        !isAddOrderPermission &&
                        !isSuperior
                      )
                        return;
                      if (
                        _get(act, 'value', '') === 'EXPORT_CSV' &&
                        !isExportCSVPermission &&
                        !isSuperior
                      )
                        return;
                      if (
                        _get(act, 'value', '') === 'DELETE_ORDER' && 
                        !isModifyDeleteOrderPermission &&
                        !isSuperior
                      )
                        return;
                      return (
                        <DropdownItem key={`act-${index}`} onClick={() => {
                          handleHigherActions(act);
                        }}>
                          {translator(act.label)}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </Row>
              <Card>
                <CardBody>
                  <CardTitle className='mb-3'>{translator('order information')}</CardTitle>
                  <div className='table-responsive'>
                    <Table className='table mb-0'>
                      <tbody>
                        <tr>
                          <td>{translator('order number')}: </td>
                          <td>{_get(orderDetail, 'code', '')}</td>
                        </tr>
                        <tr>
                          <td>{translator('expected delivery date')}: </td>
                          <td>{handleValidDate(_get(orderDetail, 'date_of_delivery', ''))}</td>
                        </tr>
                        <tr>
                          <td>{translator('ordering date')}: </td>
                          <td>{handleValidDate(_get(orderDetail, 'created_at', ''))}</td>
                        </tr>
                        <tr>
                          <td>{translator('comment')}: </td>
                          <td>{_get(orderDetail, 'comment', '')}</td>
                        </tr>
                        <tr>
                          <td>{translator('status')}: </td>
                          <td>
                            {orderStatus === 1
                              ? translator(SELECTIVE_TAB.NEW_ORDER)
                              : orderStatus === 2
                              ? translator(SELECTIVE_TAB.PREPARATION)
                              : orderStatus === 3
                              ? translator(SELECTIVE_TAB.DELAYED)
                              : orderStatus === 4
                              ? translator(SELECTIVE_TAB.PROCESSED)
                              : ''}
                          </td>
                          {(isSuperior || isChangeStatusOrderPermission) && (
                            <td>
                              <i
                                className='fas fa-pencil-alt text-success me-1'
                                onClick={() => {
                                  setIsEdittingOrder(true);
                                }}
                              />
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td>{translator('delivery men')}: </td>
                          <td>{_get(deliveryMen, 'name', '')}</td>
                          {(isSuperior || isAssignDeliveryPermission) && (
                            <td>
                              <i
                                className='fas fa-pencil-alt text-success me-1'
                                onClick={() => handleUpdateDeliveryMen(deliveryMen)}
                              />
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td>{translator('bill number')}: </td>
                          <td>{_get(orderDetail, 'bill_number', '')}</td>
                          {(isSuperior || isModifyDeleteOrderPermission) && (
                            <td>
                              <i
                                className='fas fa-pencil-alt text-success me-1'
                                onClick={() => handleUpdateBillOrder(orderDetail)}
                              />
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className='mb-3'>{translator('customer information')}</CardTitle>
                  <div className='table-responsive'>
                    <Table className='table mb-0'>
                      <tbody>
                        <tr>
                          <td>{translator('name')}: </td>
                          <td>{_get(user, 'brand_name', '')}</td>
                        </tr>
                        <tr>
                          <td>{translator('code')}: </td>
                          <td>{_get(user, 'code', '')}</td>
                        </tr>
                        <tr>
                          <td>{translator('address')}: </td>
                          <td>{_get(user, 'address', '')}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
              {(isSuperior || isPrivateCommentPermission) && (
                <Card>
                  <CardBody>
                    <CardTitle className='mb-3'>{translator('private comment')}</CardTitle>
                    <CardSubtitle tag='h6' className='mb-2 text-muted'></CardSubtitle>
                    <AvForm onValidSubmit={handleValidPrivateCommentSubmit}>
                      <AvField
                        name='comment'
                        type='textarea'
                        rows='4'
                        raggable='false'
                        value={privateComment}
                      />
                      <br />
                      <Row>
                        <Col>
                          <div className='text-end'>
                            <button type='submit' className='btn btn-success save-customer'>
                              {translator('update comment')}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

OrderDetail.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(OrderDetail));
