/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { format } from 'date-fns';
import { get as _get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Col, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { getCategoriesWithoutSubsById } from 'store/categories/actions';
import { getAllGroups } from 'store/groups/actions';
import { getProductsById } from 'store/products/actions';
import { getFilteredUsers } from 'store/users/actions';
import styled from 'styled-components';
import { MessageClientsOptions, MessageTargetOptions } from './constants';
import { SIZEPERPAGE } from 'constants/utils';
import * as moment from "moment";

const StyledInput = styled(Input)`
  width: 150px;
`;

const Form = props => {
  const dispatch = useDispatch();
  const { users, groups, isLoadingUsers, productsByUserId, categoriesWithoutSubsByUserId } = useSelector(state => {
    return {
      isLoadingUsers: _get(state, ['users', 'isLoadingUsers'], false),
      users: _get(state, ['users', 'filteredUsers'], []),
      groups: _get(state, ['groups', 'groups'], []),
      productsByUserId: _get(state, ['products', 'productsByUserId'], []),
        categoriesWithoutSubsByUserId: _get(state, ['categories', 'categoriesWithoutSubsByUserId'], []),
    };
  });
  const { handleSubmit, type, t: translator } = props;

  const [clientType, setClientType] = useState('');
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedTargets, setSelectedTargets] = useState([]);
  const [targetType, setTargetType] = useState('');
  const [previousKeyword, setPreviousKeyword] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isUserModalOpen, setUserModalStatus] = useState(false);
  const [titleCounter, setTitleCounter] = useState(0);
  const [contentCounter, setContentCounter] = useState(0);

  const [pageOptions, setPageOptions] = useState({
    sizePerPage: SIZEPERPAGE,
    totalSize: users.length,
    hideSizePerPage: true
  });

  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  useEffect(() => {
    if (isEmpty(users)) {
      return;
    }
    setPageOptions({
      sizePerPage: SIZEPERPAGE,
      hideSizePerPage: true,
      totalSize: users.length
    });
  }, [users]);

  useEffect(() => {
    if (isEmpty(groups) && clientType.value === '2') {
      dispatch(getAllGroups());
    }
  }, [clientType]);

  const handleSelectedMessageTarget = targetOption => {
    const data = clientType.value === '1' 
      ? {
          user_id: _get(selectedClients, 'id', '')
        } 
      : {
          group_id: selectedClients.map(selectedClient => _get(selectedClient, 'value', ''))
        };

    targetOption.value === '1' ? dispatch(getProductsById(data)) : dispatch(getCategoriesWithoutSubsById(data));
  };

  const getSourceOptions = source =>
    source.map(item => ({
      value: _get(item, 'id', ''),
      label: _get(item, 'name', ''),
      isSub: item.type ? true : false
    }));

  const onSubmit = (e, values) => {
    let clientVariableName;
    let clientVariables;
    const targetVariableName = targetType.value === '1' ? 'productId' : 'categoryId';
    if (clientType.value === '1') {
      (clientVariableName = 'userId'), (clientVariables = _get(selectedClients, 'id', ''));
    } else {
      clientVariableName = 'groupId';
      clientVariables = selectedClients.map(selectedClient => _get(selectedClient, 'value', ''));
    }
    let startDateSelected = format(selectedStartDate, 'yyyyMMdd');
    let endDateSelected = format(selectedEndDate, 'yyyyMMdd');

    const newMessage = clientType.value !== '3' ? {
      title: values.title,
      content: values.content,
      [clientVariableName]: clientVariables,
      [targetVariableName]: _get(selectedTargets,'value',''),
      startDate: !isEmpty(startDateSelected) ? startDateSelected : '',
      endDate: !isEmpty(endDateSelected) ? endDateSelected : ''
    } : {
      title: values.title,
      content: values.content,
      [targetVariableName]: _get(selectedTargets,'value',''),
      startDate: !isEmpty(startDateSelected) ? startDateSelected : '',
      endDate: !isEmpty(endDateSelected) ? endDateSelected : ''
    };

    handleSubmit(newMessage);
  };

  const toggle = () => {
    setUserModalStatus(!isUserModalOpen);
  };

  const handleSelectClients = options => {
    setSelectedClients(options);
    setTargetType('');
  };

  const handleSelectTargets = options => {
    setSelectedTargets(options);
  };

  const handleSelectedStartDate = targetDate => {
    setSelectedStartDate(targetDate);
  };

  const handleSelecteEnddDate = targetDate => {
    setSelectedEndDate(targetDate);
  };

  const onSearchSubmit = () => {
    toggle();
    if (isEmpty(searchKeyword) || previousKeyword === searchKeyword || isLoadingUsers) return;
    dispatch(getFilteredUsers(searchKeyword));
    setPreviousKeyword(searchKeyword);
  };

  const UsersColumn = [
    {
      dataField: 'user_name',
      text: translator('username'),
      sort: true
    },
    {
      dataField: 'code',
      text: translator('code'),
      sort: true
    },
    {
      dataField: 'brand_name',
      text: translator('brand name'),
      sort: false
    }
  ];

  const defaultSorted = [
    {
      dataField: 'name',
      order: 'desc'
    }
  ];

  const tableRowEvents = {
    onClick: (e, row) => {
      const userName = _get(row, 'name', '');
      setSearchKeyword(userName);
      handleSelectClients(row);
      toggle();
    }
  };

  const customStyles = {
    option: (provided, state) => {
      return {
        ...provided,
        paddingLeft: state.data.isSub ? 30 : 15
      };
    }
  };

  const getTargetOptions = () => {
    switch (targetType.value) {
      case '1':
        return productsByUserId.map(product => ({
          label: _get(product, 'name', ''),
          options: _get(product, 'products', []).map(option => ({
            label: _get(option, 'title', ''),
            value: _get(option, 'id', ''),
            info: _get(option, 'info', [])
          }))
        }));
      case '2':
        return getSourceOptions(categoriesWithoutSubsByUserId);
      default:
        return [];
    }
  };

  const CustomInputDateTime = forwardRef(({ value, onClick }, ref) => (
    <StyledInput onClick={onClick} ref={ref} defaultValue={moment(new Date(value)).format('DD/MM/YYYY')} />
  ));

  return (
    <AvForm onValidSubmit={onSubmit}>
      <Row>
        <div className='modal-header'>
          <h4 className='modal-title mt-0' id='myModalLabel'>
            {translator('you will send a push message to the selected client')}
          </h4>
        </div>
        <div className='modal-header'>
          <h5 className='modal-title mt-0' id='myModalLabel'>
            {translator('select client')}
          </h5>
        </div>
        <Col className='col-3'>
          <div className='mb-3'>
            <Select
              value={clientType}
              isMulti={false}
              onChange={value => {
                setClientType(value);
                setSelectedClients([]);
              }}
              options={MessageClientsOptions.map(option => ({
                ...option,
                label: translator(option.label)
              }))}
              classNamePrefix='select2-selection'
              placeholder={translator('select')}
            />
          </div>
        </Col>
        <Col className='col-6'>
          {clientType.value === '2' && (
            <div className='mb-3'>
              <Select
                styles={customStyles}
                value={selectedClients}
                isMulti={true}
                onChange={value => {
                  handleSelectClients(value);
                }}
                options={getSourceOptions(groups)}
                classNamePrefix='select2-selection'
                placeholder={translator('select')}
              />
            </div>
          )}
          {clientType.value === '1' && (
            <>
              <Row>
                <Col className='col-9'>
                  <Input
                    value={searchKeyword}
                    type='text'
                    onChange={e => {
                      setSearchKeyword(_get(e, ['target', 'value'], ''));
                    }}
                  />
                </Col>
                <Col className='col-3'>
                  <Button onClick={onSearchSubmit}>{translator('search')}</Button>
                </Col>
              </Row>
              <Modal isOpen={isUserModalOpen} toggle={toggle} scrollable={true}>
                <ModalHeader toggle={toggle} tag='h4'>
                  {translator('user list')}
                </ModalHeader>
                <ModalBody>
                  <div className='table-responsive'>
                    <BootstrapTable
                      keyField='id'
                      responsive
                      bordered={false}
                      striped={false}
                      defaultSorted={defaultSorted}
                      classes={'table align-middle table-nowrap table-check'}
                      headerWrapperClasses={'table-light'}
                      pagination={paginationFactory(pageOptions)}
                      data={users}
                      columns={UsersColumn}
                      rowEvents={tableRowEvents}
                    />
                  </div>
                </ModalBody>
              </Modal>
            </>
          )}
        </Col>
      </Row>
      <Row form>
        <Col className='col-12'>
          <Row>
            <div className='modal-header'>
              <h5 className='modal-title mt-0' id='myModalLabel'>
                {translator('select message target')}
              </h5>
            </div>
            <Col className='col-3'>
              <div className='mb-3'>
                <Select
                  value={targetType}
                  isMulti={false}
                  onChange={value => {
                    setTargetType(value);
                    setSelectedTargets([]);
                    handleSelectedMessageTarget(value);
                  }}
                  options={MessageTargetOptions.map(option => ({
                    ...option,
                    label: translator(option.label)
                  }))}
                  classNamePrefix='select2-selection'
                  placeholder={translator('select')}
                />
              </div>
            </Col>
            <Col className='col-6'>
              {targetType && targetType.value && (
                <div className='mb-3'>
                  <Select
                    styles={customStyles}
                    value={selectedTargets}
                    isMulti={false}
                    onChange={value => {
                      handleSelectTargets(value);
                    }}
                    options={getTargetOptions()}
                    classNamePrefix='select2-selection'
                    placeholder={translator('select')}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col className='col-6'>
              <h6>{translator('start date')}: </h6>
              <DatePicker
                selected={selectedStartDate}
                onChange={values => {
                  handleSelectedStartDate(values);
                }}
                customInput={<CustomInputDateTime />}
              />
            </Col>
            <Col className='col-6'>
              <h6>{translator('end date')}: </h6>
              <DatePicker
                selected={selectedEndDate}
                onChange={values => {
                  handleSelecteEnddDate(values);
                }}
                customInput={<CustomInputDateTime />}
              />
            </Col>
          </Row>
          <div className='mb-3'>
            <AvField
              name='title'
              label={translator('title')}
              type='text'
              errorMessage={translator('invalid title')}
              onChange={e => {
                setTitleCounter(e.target.value.length);
              }}
              validate={{
                required: { value: true },
                maxLength: {
                  value: 50,
                  errorMessage: translator('title must be less than 50 characters')
                }
              }}
              helpMessage={`${titleCounter}/50`}
            />
          </div>
          <div className='mb-3'>
            <div className='mt-4 mt-md-0'>
              <AvField
                name='content'
                label={translator('content')}
                type='textarea'
                errorMessage={translator('invalid content')}
                onChange={e => {
                  setContentCounter(e.target.value.length);
                }}
                validate={{
                  required: { value: true },
                  maxLength: {
                    value: 100,
                    errorMessage: translator('content must be less than 100 characters')
                  }
                }}
                helpMessage={`${contentCounter}/100`}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='text-end'>
            <button className='btn btn-success save-customer' type='submit'>
              {translator('submit')}
            </button>
          </div>
        </Col>
      </Row>
    </AvForm>
  );
};

export const PushMessageForm = withTranslation()(Form);

Form.propTypes = {
  handleSubmit: PropTypes.func,
  type: PropTypes.string,
  t: PropTypes.any
};
