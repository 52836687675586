import { get as _get, isEmpty } from 'lodash';
import {
  ADD_NEW_ORDER,
  ADD_ORDER_FAIL,
  ADD_ORDER_SELF_PRODUCTS,
  ADD_ORDER_SELF_PRODUCTS_FAIL,
  ADD_ORDER_SELF_PRODUCTS_SUCCESS,
  ADD_ORDER_SUCCESS,
  DELETE_ORDER,
  DELETE_ORDER_FAIL,
  DELETE_ORDER_SELF_PRODUCTS,
  DELETE_ORDER_SELF_PRODUCTS_FAIL,
  DELETE_ORDER_SELF_PRODUCTS_SUCCESS,
  DELETE_ORDER_SUCCESS,
  GET_ALL_ORDERS_BY_STATUS,
  GET_ALL_ORDERS_BY_STATUS_FAIL,
  GET_ALL_ORDERS_BY_STATUS_SUCCESS,
  GET_DELIVERY_STAFFS,
  GET_DELIVERY_STAFFS_FAIL,
  GET_DELIVERY_STAFFS_SUCCESS,
  GET_ORDERS_BY_STATUS,
  GET_ORDER_BY_DATE,
  GET_ORDER_BY_DATE_FAIL,
  GET_ORDER_BY_DATE_SUCCESS,
  GET_ORDER_DETAIL,
  GET_ORDER_DETAIL_FAIL,
  GET_ORDER_DETAIL_SUCCESS,
  GET_ORDER_HISTORY,
  GET_ORDER_HISTORY_FAIL,
  GET_ORDER_HISTORY_SUCCESS,
  UPDATE_DELIVERY_MEN,
  UPDATE_DELIVERY_MEN_FAIL,
  UPDATE_DELIVERY_MEN_SUCCESS,
  UPDATE_ORDER,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_SELF_PRODUCTS,
  UPDATE_ORDER_SELF_PRODUCTS_FAIL,
  UPDATE_ORDER_SELF_PRODUCTS_SUCCESS,
  UPDATE_ORDER_STATUS,
  UPDATE_ORDER_STATUS_FAIL,
  UPDATE_ORDER_STATUS_SUCCESS,
  UPDATE_ORDER_SUCCESS,
  EXPORT_CSV,
  EXPORT_CSV_SUCCESS,
  EXPORT_CSV_FAIL,
  RESET_ERROR_STATUS,
  SWITCH_TABLE,
  GET_ORDER_BY_DATERANGE,
  GET_ORDER_BY_DATERANGE_SUCCESS,
  GET_ORDER_BY_DATERANGE_FAIL,
} from './actionTypes';

const INIT_STATE = {
  errors: '',
  success: '',
};

const OrdersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_NEW_ORDER:
    case UPDATE_ORDER:
    case DELETE_ORDER:
    case UPDATE_ORDER_STATUS:
    case GET_ORDER_DETAIL:
    case GET_ORDER_BY_DATE:
    case GET_ORDER_HISTORY:
    case GET_DELIVERY_STAFFS:
    case EXPORT_CSV:
    case UPDATE_DELIVERY_MEN:
      return {
        ...state,
        isLoadingOrders: true,
        isAddOrderSuccess: false,
        errors: undefined,
      };
    case GET_ALL_ORDERS_BY_STATUS:
      return {
        ...state,
        isLoadingOrders: true,
        isAddOrderSuccess: false,
        errors: undefined,
        isFilteringByDateRange: false
      };
    case GET_ORDER_BY_DATERANGE:
      return {
        ...state,
        isLoadingOrders: true,
        isAddOrderSuccess: false,
        errors: undefined,
        isFilteringByDateRange: true
      };
    case SWITCH_TABLE:
      return {
        ...state,
        orders: [],
      };
    case UPDATE_ORDER_SELF_PRODUCTS:
    case ADD_ORDER_SELF_PRODUCTS:
    case DELETE_ORDER_SELF_PRODUCTS:
      return {
        ...state,
        isLoadingOrders: false,
        errors: undefined,
      };
    case ADD_ORDER_SELF_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoadingOrders: false,
        errors: undefined,
        isSuccess: true,
        orderDetail: _get(action, 'payload', {}),
      };
    case DELETE_ORDER_SELF_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoadingOrders: false,
        errors: undefined,
        isSuccess: true,
        orderDetail: _get(action, 'payload', {}),
      };
    case UPDATE_ORDER_SELF_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoadingOrders: false,
        errors: undefined,
        isSuccess: true,
        orderDetail: _get(action, 'payload', {}),
      };
    case ADD_ORDER_SELF_PRODUCTS_FAIL:
      return {
        ...state,
        isLoadingOrders: false,
        isSuccess: false,
        errors: _get(action, 'payload', {}),
      };
    case UPDATE_ORDER_SELF_PRODUCTS_FAIL:
      return {
        ...state,
        isLoadingOrders: false,
        isSuccess: false,
        errors: action.payload,
      };
    case DELETE_ORDER_SELF_PRODUCTS_FAIL:
      return {
        ...state,
        isLoadingOrders: false,
        errors: _get(action, 'payload', {}),
        isSuccess: false,
      };

    case GET_ORDERS_BY_STATUS:
      return {
        ...state,
        isLoadingOrders: false,
      };
    case GET_ALL_ORDERS_BY_STATUS_SUCCESS:
      return {
        ...state,
        isLoadingOrders: false,
        orders: _get(action, ['payload', 'orders'], []),
        totalOrders: parseInt(_get(action, ['payload', 'total'], '1')),
        currentPage: parseInt(_get(action, ['payload', 'page']) || '1'),
      };
    case GET_ORDER_BY_DATERANGE_SUCCESS:
      return {
        ...state,
        isLoadingOrders: false,
        orders: _get(action, ['payload', 'orders'], []),
        totalOrders: parseInt(_get(action, ['payload', 'total'], '1')),
        currentPage: parseInt(_get(action, ['payload', 'page']) || '1'),
        range: _get(action, ['payload', 'range'], {}),
      };
    case GET_ORDER_BY_DATERANGE_FAIL:
      return {
        ...state,
        isLoadingOrders: false,
        orders: [],
        errors: action.payload,
        range: {},
      };
    case GET_ALL_ORDERS_BY_STATUS_FAIL:
      return {
        ...state,
        isLoadingOrders: false,
        orders: [],
        errors: action.payload,
      };
    case ADD_ORDER_SUCCESS:
      return {
        ...state,
        isLoadingOrders: false,
        isAddOrderSuccess: true,
        isSuccess: true,
      };
    case ADD_ORDER_FAIL:
      return {
        ...state,
        isLoadingOrders: false,
        errors: action.payload,
        isAddOrderSuccess: false,
        isSuccess: false,
      };
    case UPDATE_ORDER_STATUS_SUCCESS: {
      const orderDetail = _get(state, 'orderDetail', {});
      return {
        ...state,
        isLoadingOrders: false,
        orderDetail: isEmpty(orderDetail)
          ? {}
          : {
              ...orderDetail,
              status: action.payload.status,
            },
        orders: action.payload.orders,
        isSuccess: true,
      };
    }
    case UPDATE_ORDER_STATUS_FAIL:
      return {
        ...state,
        isLoadingOrders: false,
        errors: _get(action, ['payload', 'errors'], {}),
        isSuccess: false,
      };
    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        orderDetail: {
          ...state.orderDetail,
          ...action.payload,
        },
        orders: !isEmpty(state.orders)
          ? state.orders.map(order =>
              order.id.toString() === action.payload.id.toString()
                ? { order, ...action.payload }
                : order
            )
          : [],
      };
    case UPDATE_ORDER_FAIL:
      return {
        ...state,
        isLoadingOrders: false,
        errors: _get(action, ['payload', 'errors'], {}),
      };
    case DELETE_ORDER_SUCCESS:
      return {
        ...state,
        isLoadingOrders: false,
        orders: state.orders.filter(order => order.id.toString() !== action.payload.toString()),
      };
    case DELETE_ORDER_FAIL:
      return {
        ...state,
        isLoadingOrders: false,
        errors: _get(action, ['payload', 'errors'], {}),
      };
    case GET_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        isLoadingOrders: false,
        orderDetail: action.payload,
      };
    case GET_ORDER_DETAIL_FAIL:
      return {
        ...state,
        isLoadingOrders: false,
        errors: _get(action, ['payload', 'errors'], {}),
      };
    case GET_ORDER_BY_DATE_SUCCESS:
      return {
        ...state,
        isLoadingOrders: false,
        currentDate: action.payload.currentDate,
        orders: action.payload.result.orders,
        maxProduct: action.payload.result.max_product,
        products: action.payload.result.products,
        sumProducts: action.payload.result.sum_products,
      };
    case GET_ORDER_BY_DATE_FAIL:
      return {
        ...state,
        isLoadingOrders: false,
        errors: _get(action, ['payload', 'errors'], {}),
      };
    case GET_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        isLoadingOrders: false,
        orderHistory: action.payload,
      };
    case GET_ORDER_HISTORY_FAIL:
      return {
        ...state,
        isLoadingOrders: false,
        errors: _get(action, ['payload', 'errors'], {}),
      };
    case GET_DELIVERY_STAFFS_SUCCESS:
      return {
        ...state,
        isLoadingOrders: false,
        deliveryStaffs: action.payload,
      };
    case GET_DELIVERY_STAFFS_FAIL:
      return {
        ...state,
        isLoadingOrders: false,
        errors: _get(action, ['payload', 'errors'], {}),
      };

    case UPDATE_DELIVERY_MEN_SUCCESS: {
      const results = action.payload.results;
      const isOrderDetail = action.payload.isOrderDetail;
      if (isOrderDetail) {
        return {
          ...state,
          isLoadingOrders: false,
          orderDetail: results,
        };
      }
      const newOrders = state.orders.map(order =>
        order.id.toString() === results.id.toString()
          ? { ...order, delivery_men: results.delivery_men }
          : order
      );
      return {
        ...state,
        isLoadingOrders: false,
        orders: newOrders,
      };
    }
    case UPDATE_DELIVERY_MEN_FAIL:
      return {
        ...state,
        isLoadingOrders: false,
        errors: _get(action, 'payload', {}),
      };
    case EXPORT_CSV_SUCCESS: {
      return {
        ...state,
      };
    }
    case EXPORT_CSV_FAIL:
      return {
        ...state,
        errors: _get(action, ['payload', 'errors'], {}),
      };
    case RESET_ERROR_STATUS:
      return {
        ...state,
        errors: undefined,
        isSuccess: false,
      };
    default:
      return state;
  }
};

export default OrdersReducer;
