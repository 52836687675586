/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { capitalize } from 'utils/string';

export const TooltipComponent = props => {
  const { children, placement, content = '', id, tag, tagContent } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const handleMouseEnter = () => {
    setTooltipOpen(true);
  };

  const handleMouseLeave = () => {
    setTooltipOpen(false);
  };
  return (
    <>
      <div
        id={`Tooltip-${tag}-${id}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      <Popover
        target={`Tooltip-${tag}-${id}`}
        placement={placement || 'top-start'}
        isOpen={tooltipOpen}
        toggle={toggle}
        popperClassName='custom-popover-wrapper'
        innerClassName='custom-popover-inner'
      >
        <PopoverHeader>{capitalize(tagContent)}</PopoverHeader>
        <PopoverBody>{content}</PopoverBody>
      </Popover>
    </>
  );
};

TooltipComponent.propTypes = {
  children: PropTypes.node,
  placement: PropTypes.string,
  content: PropTypes.any,
  tag: PropTypes.string,
  id: PropTypes.string,
  tagContent: PropTypes.string
};
