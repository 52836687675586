import { get as _get, isEmpty } from 'lodash';

export const handleUpdateSubGroupState = (state, action) => {
  let newState = { ...state };
  const subGroups = _get(newState, 'subGroups', []);
  if (!isEmpty(subGroups)) {
    newState = {
      ...newState,
      subGroups: subGroups.map(subGroup =>
        _get(subGroup, 'id', '').toString() ===
        _get(action, ['payload', 'subGroup', 'id'], '').toString()
          ? { ...subGroup, ..._get(action, ['payload', 'subGroup'], {}) }
          : subGroup
      )
    };
  }
  const groups = _get(newState, 'groups', []);
  if (!isEmpty(groups)) {
    newState = {
      ...newState,
      groups: groups.map(group => {
        if (
          _get(group, 'id', '').toString() !== _get(action, ['payload', 'groupId'], '').toString()
        )
          return group;
        return {
          ...group,
          sub: _get(group, 'sub', []).map(subGroup =>
            _get(subGroup, 'id', '') !== _get(action, ['payload', 'subGroup', 'id'], '')
              ? subGroup
              : {
                  ...subGroup,
                  ..._get(action, ['payload', 'subGroup'], '')
                }
          )
        };
      })
    };
  }
  return newState;
};

export const handleDeleteSubGroupState = (state, { payload }) => {
  let newState = { ...state };
  const subGroups = _get(newState, 'subGroups', []);
  if (!isEmpty(subGroups)) {
    newState = {
      ...newState,
      subGroups: subGroups.filter(
        subGroup => subGroup.id.toString() !== _get(payload, 'subGroupId', '').toString()
      )
    };
  }
  const groups = _get(newState, 'groups', []);
  if (!isEmpty(groups)) {
    newState = {
      ...newState,
      groups: groups.map(group => {
        if (group.id.toString() !== _get(payload, 'groupId', '').toString()) return group;
        return {
          ...group,
          sub: _get(group, 'sub', []).filter(
            subGroup => subGroup.id.toString() !== _get(payload, 'subGroupId', '').toString()
          )
        };
      })
    };
  }
  return newState;
};

export const handleUpdateTotalUserGroup = (groupsState, groupId, countUpdate) =>
  groupsState.map(group =>
    _get(group, 'id', '').toString() === groupId.toString()
      ? { ...group, total_user: _get(group, 'total_user', 0) + countUpdate }
      : group
  );

export const handleUpdateUserGroup = (groupsState, parentGroupId, groupId, countUpdate) =>
  groupsState.map(group => {
    if (_get(group, 'id', '').toString() !== parentGroupId.toString()) return group;
    const subGroups = Object.values(_get(group, 'sub', []));
    const newSubGroups = handleUpdateTotalUserGroup(subGroups, groupId, countUpdate);
    return {
      ...group,
      sub: newSubGroups
    };
  });
