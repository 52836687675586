export const ADD_NEW_ORDER = 'ADD_NEW_ORDER';
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const ADD_ORDER_FAIL = 'ADD_ORDER_FAIL';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL';
export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS';
export const UPDATE_ORDER_STATUS_SUCCESS = 'UPDATE_ORDER_STATUS_SUCCESS';
export const UPDATE_ORDER_STATUS_FAIL = 'UPDATE_ORDER_STATUS_FAIL';
export const DELETE_ORDER = 'DELETE_ORDER';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAIL = 'DELETE_ORDER_FAIL';
export const GET_ORDERS_BY_STATUS = 'GET_ORDERS_BY_STATUS';
export const GET_ORDERS_BY_STATUS_FAIL = 'GET_ORDERS_FAIL_BY_STATUS';
export const GET_ORDERS_BY_STATUS_SUCCESS = 'GET_ORDERS_SUCCESS_BY_STATUS';
export const GET_ALL_ORDERS_BY_STATUS = 'GET_ALL_ORDERS_BY_STATUS';
export const GET_ALL_ORDERS_BY_STATUS_FAIL = 'GET_ALL_ORDERS_FAIL_BY_STATUS';
export const GET_ALL_ORDERS_BY_STATUS_SUCCESS = 'GET_ALL_ORDERS_SUCCESS_BY_STATUS';
export const GET_ORDER_DETAIL = 'GET_ORDER_DETAIL';
export const GET_ORDER_DETAIL_SUCCESS = 'GET_ORDER_DETAIL_SUCCESS';
export const GET_ORDER_DETAIL_FAIL = 'GET_ORDER_DETAIL_FAIL';
export const UPDATE_ORDER_SELF_PRODUCTS = 'UPDATE_ORDER_SELF_PRODUCTS';
export const UPDATE_ORDER_SELF_PRODUCTS_SUCCESS = 'UPDATE_ORDER_SELF_PRODUCTS_SUCCESS';
export const UPDATE_ORDER_SELF_PRODUCTS_FAIL = 'UPDATE_ORDER_SELF_PRODUCTS_FAIL';
export const DELETE_ORDER_SELF_PRODUCTS = 'DELETE_ORDER_SELF_PRODUCTS';
export const DELETE_ORDER_SELF_PRODUCTS_SUCCESS = 'DELETE_ORDER_SELF_PRODUCTS_SUCCESS';
export const DELETE_ORDER_SELF_PRODUCTS_FAIL = 'DELETE_ORDER_SELF_PRODUCTS_FAIL';
export const ADD_ORDER_SELF_PRODUCTS = 'ADD_ORDER_SELF_PRODUCTS';
export const ADD_ORDER_SELF_PRODUCTS_SUCCESS = 'ADD_ORDER_SELF_PRODUCTS_SUCCESS';
export const ADD_ORDER_SELF_PRODUCTS_FAIL = 'ADD_ORDER_SELF_PRODUCTS_FAIL';
export const GET_ORDER_BY_DATE = 'GET_ORDER_BY_DATE';
export const GET_ORDER_BY_DATE_SUCCESS = 'GET_ORDER_BY_DATE_SUCCESS';
export const GET_ORDER_BY_DATE_FAIL = 'GET_ORDER_BY_DATE_FAIL';
export const GET_ORDER_BY_DATERANGE = 'GET_ORDER_BY_DATERANGE';
export const GET_ORDER_BY_DATERANGE_SUCCESS = 'GET_ORDER_BY_DATERANGE_SUCCESS';
export const GET_ORDER_BY_DATERANGE_FAIL = 'GET_ORDER_BY_DATERANGE_FAIL';
export const GET_ORDER_HISTORY = 'GET_ORDER_HISTORY';
export const GET_ORDER_HISTORY_SUCCESS = 'GET_ORDER_HISTORY_SUCCESS';
export const GET_ORDER_HISTORY_FAIL = 'GET_ORDER_HISTORY_FAIL';
export const GET_DELIVERY_STAFFS = 'GET_DELIVERY_STAFFS';
export const GET_DELIVERY_STAFFS_SUCCESS = 'GET_DELIVERY_STAFFS_SUCCESS';
export const GET_DELIVERY_STAFFS_FAIL = 'GET_DELIVERY_STAFFS_FAIL';
export const UPDATE_DELIVERY_MEN = 'UPDATE_DELIVERY_MEN';
export const UPDATE_DELIVERY_MEN_SUCCESS = 'UPDATE_DELIVERY_MEN_SUCCESS';
export const UPDATE_DELIVERY_MEN_FAIL = 'UPDATE_DELIVERY_MEN_FAIL';
export const EXPORT_CSV = 'EXPORT_CSV';
export const EXPORT_CSV_SUCCESS = 'EXPORT_CSV_SUCCESS';
export const EXPORT_CSV_FAIL = 'EXPORT_CSV_FAIL';
export const RESET_ERROR_STATUS = 'RESET_ERROR_STATUS';
export const EXPORT_MULTIPLE_ORDERS_CSV = 'EXPORT_MULTIPLE_ORDERS_CSV';
export const EXPORT_MULTIPLE_ORDERS_CSV_SUCCESS = 'EXPORT_MULTIPLE_ORDERS_CSV_SUCCESS';
export const EXPORT_MULTIPLE_ORDERS_CSV_FAIL = 'EXPORT_MULTIPLE_ORDERS_CSV_FAIL';

export const SWITCH_TABLE = 'SWITCH_ORDER_TABLES';
