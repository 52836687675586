/* eslint-disable react/display-name */
import { Spin } from 'antd';
import Breadcrumbs from 'components/Common/Breadcrumb';
import StaffGroupForm from 'components/Common/StaffGroupForm';
import { STAFF_GROUP_ACTIONS } from 'components/Common/StaffGroupForm/constants';
import { EllipseSpan } from 'components/Common/StyledComponents/Span';
import { TooltipComponent } from 'components/Common/Tooltip';
import { showNotification } from 'constants/messages';
import { get as _get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown
} from 'reactstrap';
import { getPermissions } from 'store/permission/actions';
import {
  addNewStaffGroup,
  deleteStaffGroup,
  getStaffGroups,
  resetErrorStatus,
  updateStaffGroup
} from 'store/staffGroups/actions';
import EmpyDataForm from '../../components/Common/EmptyDataForm';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SIZEPERPAGE } from 'constants/utils';

const onClickAction = 'onclick-action';

const StaffGroupList = props => {
  const dispatch = useDispatch();
  const { t: translator } = props;

  const { permissions, staffGroups, errors, isSuccess, isLoadingStaffGroups } = useSelector(state => {
    return {
      isLoadingStaffGroups: _get(state, ['staffGroups', 'isLoadingStaffGroups'], false),
      staffGroups: _get(state, ['staffGroups', 'staffGroups'], []),
      permissions: _get(state, ['permissions', 'permissions'], []),
      errors: _get(state, ['staffGroups', 'errors', 'errors'], ''),
      isSuccess: _get(state, ['staffGroups', 'isSuccess'], ''),
    };
  });
  const [confirmation, setConfirmation] = useState(false);
  const [staffGroupDelete, setStaffGroupDelete] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [edittingStaffGroup, setEdittingStaffGroup] = useState({});
  const [pageOptions, setPageOptions] = useState({
    sizePerPage: SIZEPERPAGE,
    totalSize: staffGroups.length,
    hideSizePerPage: true
  });
  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc'
    }
  ];

  const StaffGroupsColumns = [
    {
      dataField: 'name',
      text: translator('name'),
      sort: true
    },
    {
      dataField: 'description',
      text: translator('description'),
      sort: true
    },
    {
      dataField: 'permission',
      text: translator('permissions'),
      sort: true,
      formatter: (cellContent, row) => {
        const permissionRow = _get(row, 'permission', []);
        const permission = permissionRow.reduce((accumulator, cur) => {
          return accumulator + ', ' + cur.name;
        }, '');
        const tooltipContent = (
          <ul>
            {permissionRow.map(per => (
              <li key={`permission-${_get(per, 'name', '')}`}>{translator(_get(per, 'name', '').toLowerCase())}</li>
            ))}
          </ul>
        );
        return (
          <TooltipComponent
            id={_get(row, 'id', '').toString()}
            tag={'permissions'}
            tagContent={translator('permissions')}
            content={tooltipContent}
          >
            <EllipseSpan>{permission.substring(1)}</EllipseSpan>
          </TooltipComponent>
        );
      }
    },
    {
      dataField: 'menu',
      isDummyField: true,
      text: translator('action'),
      formatter: (cellContent, staffGroup) => (
        <UncontrolledDropdown direction='left'>
          <DropdownToggle href='#' className='card-drop' tag='i'>
            <i className='mdi mdi-dots-horizontal font-size-18' action={onClickAction} />
          </DropdownToggle>
          <DropdownMenu className='dropdown-menu-end'>
            <DropdownItem onClick={() => handleEditStaffGroup(staffGroup)} action={onClickAction}>
              <i className='fas fa-pencil-alt text-success me-1' />
              {translator('edit')}
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                setConfirmation(true);
                setStaffGroupDelete(staffGroup);
              }}
              action={onClickAction}
            >
              <i className='fas fa-trash-alt text-danger me-1' />
              {translator('delete')}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    }
  ];

  const handleEditStaffGroup = staffGroup => {
    setEdittingStaffGroup({
      id: staffGroup.id,
      name: staffGroup.name,
      description: staffGroup.description,
      permissionsStaffGroup: staffGroup.permission
    });
    setIsEdit(true);
    toggle();
  };

  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    if (isEmpty(staffGroups)) {
      return;
    }
    setPageOptions({
      sizePerPage: SIZEPERPAGE,
      hideSizePerPage: true
    });
  }, [staffGroups]);

  useEffect(() => {
    if (!isEmpty(errors)) showNotification(errors);
    if (isSuccess) showNotification('');
    dispatch(resetErrorStatus());
  }, [errors, isSuccess]);

  useEffect(() => {
    dispatch(getStaffGroups());
    dispatch(getPermissions());
  }, []);

  const handleDeleteStaffGroup = staffGroup => {
    dispatch(deleteStaffGroup(staffGroup));
  };

  const handleAddNewStaffGroup = () => {
    setIsEdit(false);
    toggle();
  };

  const onSubmit = (e, values) => {
    handleValidStaffGroupSubmit(e, values);
  };

  const handleValidStaffGroupSubmit = (e, values) => {
    const newStaffGroup = {
      id: edittingStaffGroup.id,
      name: values.name,
      description: values.description,
      permissions: values.permissionIds
    };
    isEdit ? dispatch(updateStaffGroup(newStaffGroup)) : dispatch(addNewStaffGroup(newStaffGroup));
    toggle();
  };

  return (
    <Spin spinning={isLoadingStaffGroups} tip={translator('loading') + '...'}>
    <React.Fragment>
      <div className='page-content'>
        <MetaTags>
          <title>{translator('user groups')} | Le diamant du terroir</title>
        </MetaTags>
        {confirmation && (
          <SweetAlert
            title={translator('are you sure?')}
            warning
            showCancel
            confirmBtnBsStyle='success'
            cancelBtnBsStyle='danger'
            onConfirm={() => {
              handleDeleteStaffGroup(staffGroupDelete);
              setConfirmation(false);
            }}
            onCancel={() => {
              setConfirmation(false);
            }}
          />
        )}
        <Container fluid>
          <Breadcrumbs title={translator('manage')} breadcrumbItem={translator('usergroups')} />
          <Row>
            <Col xs='12'>
              <Card>
                <CardBody>
                  <React.Fragment>
                    <Row className='mb-2'>
                      <Col sm='4'>
                        <div className='search-box ms-2 mb-2 d-inline-block'>
                          <div className='position-relative' />
                        </div>
                      </Col>
                      <Col sm='8'>
                        <div className='text-sm-end'>
                          <Button
                            type='button'
                            color='success'
                            className='btn-rounded  mb-2 me-2'
                            onClick={handleAddNewStaffGroup}
                          >
                            <i className='mdi mdi-plus me-1' />
                            {translator('new user group')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    {!isEmpty(staffGroups) ? (
                      <Row>
                        <Col xl='12'>
                          <div className='table-responsive'>
                            <BootstrapTable
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              classes={'table align-middle table-nowrap table-check'}
                              headerWrapperClasses={'table-light'}
                              keyField='id'
                              // rowEvents={tableRowEvents}
                              pagination={paginationFactory(pageOptions)}
                              data={staffGroups}
                              columns={StaffGroupsColumns}
                            />
                          </div>
                          <Modal isOpen={modal} toggle={toggle} scrollable={true} size='xl'>
                            <ModalHeader toggle={toggle} tag='h4'>
                              {isEdit ? translator('edit') : translator('add')}
                            </ModalHeader>
                            <ModalBody>
                              <StaffGroupForm
                                staffGroup={isEdit ? edittingStaffGroup : {}}
                                action={
                                  isEdit ? STAFF_GROUP_ACTIONS.UPDATE : STAFF_GROUP_ACTIONS.CREATE
                                }
                                permissions={permissions}
                                handleSubmit={onSubmit}
                              />
                            </ModalBody>
                          </Modal>
                        </Col>
                      </Row>
                    ) : (
                      <EmpyDataForm/>
                    )}
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
    </Spin>
  );
};

StaffGroupList.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(StaffGroupList);
