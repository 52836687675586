import { call, put, takeEvery } from "redux-saga/effects";
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess } from "./actions";
import { authenticationService } from '../../../services';
import { getProfileSuccess } from 'store/auth/profile/actions';
import { get as _get } from 'lodash';
import { catchError } from 'store/errors/actions';

function* login({ payload: { user } }) {
  try {
    const response = yield call(authenticationService.login, user);
    const token = _get(response, ['results','token']);
    const info = _get(response, ['results','info']);
    localStorage.setItem("diament", token);
    localStorage.setItem("id", info.id);
    yield put(getProfileSuccess(info));
    yield put(loginSuccess(response));
  } catch (error) {
    yield put(catchError(error));
    yield put(apiError(error));
  }
};

function* logout({ payload: { history } }) {
  try {
    localStorage.removeItem("diament");
    localStorage.removeItem("id");
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
};

function* authSaga() {
  yield takeEvery(LOGIN_USER, login);
  yield takeEvery(LOGOUT_USER, logout);
};

export default authSaga;
