import { call, put, takeEvery } from 'redux-saga/effects';
import { productService } from 'services';
import { get as _get } from 'lodash';
import { catchError } from 'store/errors/actions';
import {
  GET_PRODUCTS,
  GET_PRODUCTS_BY_ID,
  ADD_NEW_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  UPDATE_CATEGORIES_FOR_PRODUCT,
  GET_FILTERED_PRODUCTS_BY_USER_ID,
  GET_FILTERED_PRODUCTS
} from './actionTypes';
import {
  getProductsSuccess,
  getProductsByIdSuccess,
  addProductSuccess,
  addProductFail,
  updateProductSuccess,
  updateProductFail,
  deleteProductSuccess,
  deleteProductFail,
  updateCategoriesForProductSuccess,
  updateCategoriesForProductFail,
  getFilteredProductByUserIdSuccess,
  getFilteredProductByUserIdFail,
  getFilteredProductSuccess,
  getFilteredProductFail
} from './actions';

function* fetchProductsFromBE() {
  try {
    const res = yield call(productService.list);
    yield put(getProductsSuccess(_get(res, 'results', [])));
  } catch (error) {
    yield put(catchError(error));
  }
}

function* fetchProductsByUserIdFromBE({ payload }) {
  try {
    const res = yield call(productService.listProductsByUserId, payload);
    yield put(getProductsByIdSuccess(_get(res, 'results', [])));
  } catch (error) {
    yield put(catchError(error));
  }
}


function* addNewProduct({ payload }) {
  try {
    const image = _get(payload, 'image', null);
    let obj = {
      item_code: _get(payload, 'item_code', ''),
      title: _get(payload, 'title', ''),
      description: _get(payload, 'description', ''),
      unit_weight: _get(payload, 'unit_weight', ''),
      packaging: _get(payload, 'packaging', ''),
      dlc: _get(payload, 'dlc', ''),
      gen_code: _get(payload, 'gen_code', '')
    };

    obj = !(Object.keys(image).length === 0 && image.constructor === Object)
      ? {
          ...obj,
          image
        }
      : obj;

    const res = yield call(productService.create, obj);
    yield put(addProductSuccess(_get(res, 'results', {})));
  } catch (error) {
    yield put(catchError(error));
    yield put(addProductFail(error));
  }
}

function* updateProduct({ payload }) {
  try {
    const image = _get(payload, 'image', null);
    let obj = {
      item_code: _get(payload, 'item_code', ''),
      title: _get(payload, 'title', ''),
      description: _get(payload, 'description', ''),
      info: _get(payload, 'info', ''),
      dlc: _get(payload, 'dlc', ''),
    };

    obj = !(Object.keys(image).length === 0 && image.constructor === Object)
      ? {
          ...obj,
          image
        }
      : obj;

    const res = yield call(productService.update, {
      id: _get(payload, 'id', ''),
      product: obj
    });
    yield put(updateProductSuccess(_get(res, 'results', {})));
  } catch (error) {
    yield put(catchError(error));
    yield put(updateProductFail(error));
  }
}

function* deleteProduct({ payload }) {
  try {
    const id = _get(payload, 'id', '');
    yield call(productService.archive, {
      id
    });
    yield put(deleteProductSuccess(id));
  } catch (error) {
    yield put(catchError(error));
    yield put(deleteProductFail(error));
  }
}

function* updateCategories({ payload }) {
  try {
    const subCategoryIds = Object.values(payload.category).map(category =>
      _get(category, 'id', [])
    );
    const res = yield call(productService.updateCategories, {
      productId: payload.productId,
      categoryIds: subCategoryIds
    });
    yield put(updateCategoriesForProductSuccess(_get(res, ['results', 1], {})));
  } catch (error) {
    yield put(catchError(error));
    yield put(updateCategoriesForProductFail(error));
  }
}

function* getFilterProductsById({ payload }) {
  try {
    const res = yield call(productService.listByUserId, payload);
    yield put(getFilteredProductByUserIdSuccess(_get(res, 'results', [])));
  } catch (error) {
    yield put(catchError(error));
    yield put(getFilteredProductByUserIdFail(error));
  }
}

function* getFilterProducts({ payload }) {
  try {
    const res = yield call(productService.getFilterProducts, {
      query: _get(payload, 'query', ''),
      categoryId: _get(payload, 'categoryId', '')
    });
    yield put(getFilteredProductSuccess(_get(res, 'results', [])));
  } catch (error) {
    yield put(catchError(error));
    yield put(getFilteredProductFail(error));
  }
}

function* productsSaga() {
  yield takeEvery(GET_FILTERED_PRODUCTS_BY_USER_ID, getFilterProductsById);
  yield takeEvery(GET_PRODUCTS, fetchProductsFromBE);
  yield takeEvery(GET_PRODUCTS_BY_ID, fetchProductsByUserIdFromBE);
  yield takeEvery(ADD_NEW_PRODUCT, addNewProduct);
  yield takeEvery(UPDATE_PRODUCT, updateProduct);
  yield takeEvery(DELETE_PRODUCT, deleteProduct);
  yield takeEvery(UPDATE_CATEGORIES_FOR_PRODUCT, updateCategories);
  yield takeEvery(GET_FILTERED_PRODUCTS, getFilterProducts);
}

export default productsSaga;
