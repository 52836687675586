export const USER_ACTIONS = {
  UPDATE: 'UPDATE_USER',
  CREATE: 'CREATE_USER',
};

export const ACTION_LABELS = {
  [USER_ACTIONS.UPDATE]: 'update',
  [USER_ACTIONS.CREATE]: 'create',
};

