import { CATCH_ERROR } from './actionsTypes';
import { get as _get } from 'lodash';

const ErrorReducer = (state = {
    status: '',
    message: '',
}, { type, payload}) => {
    switch (type) {
        case CATCH_ERROR:
            return {
                ...state,
                status: _get(payload,'status',''),
                message:  _get(payload,'message',''),
            };
        default:
            return {
                ...state,
                status: '',
                message: '',
            };
    }
};

export default ErrorReducer;