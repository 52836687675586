import { AvField, AvForm } from 'availity-reactstrap-validation';
import { ERROR_MESSAGE, REGEX } from 'constants/regex';
import { get as _get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import Select from 'react-select';
import { Col, Container, Row, Input, Label } from 'reactstrap';
import { ACTION_LABELS, ADMIN_ACTIONS } from './constants';
import { withTranslation } from 'react-i18next';
import { customStyleSelect } from '../../../constants/styles';

const Form = props => {
  const { handleSubmit, admin = {}, staffGroups, action, t: translator } = props;

  const requiredErrorMessage = useMemo(() => `${translator('this field is required')}!`, []);
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const optionStatus = useMemo(
    () => [
      {
        options: [
          {
            label: translator('active'),
            value: 'active'
          },
          {
            label: translator('inactive'),
            value: 'inactive'
          }
        ]
      }
    ],
    []
  );

  const togglePasswordVisiblity = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const [selectedStatus, setSelectedStatus] = useState(optionStatus);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [optionTeams, setOptionTeams] = useState([]);

  useEffect(() => {
    const status = _get(admin, 'status', '');
    if (!isEmpty(status)) {
      setSelectedStatus({ label: translator(status), value: status });
    }

    if (!isEmpty(staffGroups)) {
      const objTeams = staffGroups.map(team => {
        return { id: team.id, label: team.name, value: team.name };
      });
      setOptionTeams([{ options: objTeams }]);
    }

    const teams = _get(admin, 'teams', []);
    if (!isEmpty(teams)) {
      const objTeams = teams.map(team => {
        return { id: team.id, label: team.name, name: team.name };
      });
      setSelectedTeams(objTeams);
    }
  }, []);
  const handleSelectStatus = targetStatus => {
    setSelectedStatus(targetStatus);
  };

  const handleSelectTeams = targetTeams => {
    setSelectedTeams(targetTeams);
  };

  const onSubmit = (e, values) => {
    const teamIds = selectedTeams.map(item => item.id);
    handleSubmit(e, {
      ...values,
      teamIds,
      status: selectedStatus.value || ''
    });
  };

  return (
    <AvForm onValidSubmit={onSubmit}>
      <Container>
        <Row>
          <Col xs='6' sm='6'>
            <div className='mb-3'>
              <AvField
                name='name'
                label={translator('name')}
                type='text'
                errorMessage={translator('invalid name')}
                validate={{
                  required: { value: true }
                }}
                value={admin.name || ''}
              />
            </div>
            <div className='mb-3'>
              <AvField
                name='first_name'
                label={translator('first name')}
                type='text'
                value={admin.firstName || ''}
              />
            </div>
            <div className='mb-3'>
              <AvField
                name='last_name'
                label={translator('last name')}
                type='text'
                value={admin.lastName || ''}
              />
            </div>
            <div className='mb-3'>
              <AvField
                name='password'
                label={translator('password')}
                type= {isPasswordShown ? 'text' : 'password'}
                validate={
                  action === ADMIN_ACTIONS.CREATE
                    ? {
                        pattern: {
                          value: REGEX.PASSWORD,
                          errorMessage: ERROR_MESSAGE.PASSWORD
                        },
                        required: {
                          value: true,
                          errorMessage: requiredErrorMessage
                        }
                      }
                    : {
                        pattern: {
                          value: REGEX.PASSWORD,
                          errorMessage: ERROR_MESSAGE.PASSWORD
                        }
                      }
                }
                value={admin.password || ''}
              />
              <div className='password-input'>
                <Input
                  type='checkbox'
                  onChange={() => togglePasswordVisiblity()}
                />
                <Label className='pushNoti_checkbox'>
                  {translator('show password')}{' '}
                </Label>
                </div>
            </div>
          </Col>
          <Col xs='6' sm='6'>
            <div className='mb-3'>
              <AvField
                name='phone_number'
                label={translator('phone')}
                type='text'
                value={admin.phoneNumber || ''}
              />
            </div>
            <div className='mb-3'>
              <AvField
                name='email'
                label={translator('email')}
                type='email'
                validate={{
                  pattern: {
                    value: REGEX.EMAIL,
                    errorMessage: ERROR_MESSAGE.EMAIL
                  }
                }}
                value={admin.email || ''}
              />
            </div>
            <div className='mb-3'>
              <label className='control-label'>{translator('status')}</label>
              <Select
                styles={customStyleSelect}
                value={selectedStatus || ''}
                onChange={values => {
                  handleSelectStatus(values);
                }}
                validate={{
                  required: { value: true }
                }}
                options={optionStatus}
                classNamePrefix='select2-selection'
                placeholder={translator('select')}
              />
            </div>
          </Col>
          <div className='mb-3'>
            <label className='control-label'>{translator('staff groups')}</label>
            <Select
              styles={customStyleSelect}
              // value={selectedTeams}
              value={_get(optionTeams[0], 'options', []).filter(option =>
                selectedTeams
                  .map(team => _get(team, 'label', ''))
                  .includes(_get(option, 'label', ''))
              )}
              isMulti={true}
              onChange={values => {
                handleSelectTeams(values);
              }}
              options={optionTeams}
              classNamePrefix='select2-selection'
              placeholder={translator('select')}
            />
          </div>
        </Row>
      </Container>
      <Row>
        <Col>
          <div className='text-end'>
            <button type='submit' className='btn btn-success save-customer'>
              {translator(ACTION_LABELS[action])}
            </button>
          </div>
        </Col>
      </Row>
    </AvForm>
  );
};

export const AdminForm = withTranslation()(Form);

Form.propTypes = {
  handleSubmit: PropTypes.func,
  admin: PropTypes.object,
  action: PropTypes.string,
  staffGroups: PropTypes.array,
  t: PropTypes.any
};
