import {
  GET_STAFF_GROUPS,
  GET_STAFF_GROUPS_FAIL,
  GET_STAFF_GROUPS_SUCCESS,
  ADD_NEW_STAFF_GROUP,
  ADD_STAFF_GROUP_FAIL,
  ADD_STAFF_GROUP_SUCCESS,
  UPDATE_STAFF_GROUP,
  UPDATE_STAFF_GROUP_FAIL,
  UPDATE_STAFF_GROUP_SUCCESS,
  DELETE_STAFF_GROUP,
  DELETE_STAFF_GROUP_FAIL,
  DELETE_STAFF_GROUP_SUCCESS,
  RESET_ERROR_STATUS,
} from './actionTypes';
import { get as _get } from 'lodash';

const INIT_STATE = {
  error: '',
  success: ''
};

const StaffGroupsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_NEW_STAFF_GROUP:
    case UPDATE_STAFF_GROUP:
    case GET_STAFF_GROUPS:
    case DELETE_STAFF_GROUP:
      return {
        ...state,
        isLoadingStaffGroups: true,
        isSuccess: false,
        errors: ''
      };
    case GET_STAFF_GROUPS_SUCCESS:
      return {
        ...state,
        isLoadingStaffGroups: false,
        staffGroups: action.payload
      };
    case GET_STAFF_GROUPS_FAIL:
      return {
        ...state,
        isLoadingStaffGroups: false,
        errors: action.payload
      };
    case ADD_STAFF_GROUP_SUCCESS:
      return {
        ...state,
        isLoadingStaffGroups: false,
        staffGroups: [action.payload, ...state.staffGroups],
        isSuccess: true
      };
    case ADD_STAFF_GROUP_FAIL:
      return {
        ...state,
        isLoadingStaffGroups: false,
        errors: action.payload,
        isSuccess: false,
      };
    case UPDATE_STAFF_GROUP_SUCCESS:
      return {
        ...state,
        isLoadingStaffGroups: false,
        staffGroups: state.staffGroups.map(staffGroup =>
          staffGroup.id.toString() === action.payload.id.toString()
            ? { staffGroup, ...action.payload }
            : staffGroup
        ),
        isSuccess: true
      };
    case UPDATE_STAFF_GROUP_FAIL:
      return {
        ...state,
        isLoadingStaffGroups: false,
        errors: action.payload,
        isSuccess: false,
      };
    case DELETE_STAFF_GROUP_SUCCESS:
      return {
        ...state,
        isLoadingStaffGroups: false,
        staffGroups: [..._get(state,'staffGroups',[])].filter(staffGroup => staffGroup.id.toString() !== action.payload.toString()),
        isSuccess: true
      };
    case DELETE_STAFF_GROUP_FAIL:
      return {
        ...state,
        isLoadingStaffGroups: false,
        errors: action.payload,
        isSuccess: false
      };
    case RESET_ERROR_STATUS:
      return {
        ...state,
        errors: undefined,
        isSuccess: false,
      };
    default:
      return state;
  }
};

export default StaffGroupsReducer;
