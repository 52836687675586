/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import { Checkbox } from 'antd';
import { AvCheckboxGroup, AvField, AvForm } from 'availity-reactstrap-validation';
import { get as _get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { CardTitle, Col, Row } from 'reactstrap';
import { ACTION_LABELS } from '../StaffGroupForm/constants';

const StaffGroupForm = props => {
  const { handleSubmit, staffGroup = {}, permissions, action, t: translator } = props;
  const [permissionCheckboxes, setPermissionCheckboxes] = React.useState({});

  const dependentFullModeId = [8, 9, 10, 11, 12, 13, 14, 15, 16];
  const handlePermissionCheckbox = e => {
    let newCheckboxesValue = {
      ...permissionCheckboxes,
      [e.target.name]: e.target.checked,
    };
    if (e.target.name === 22 && _get(newCheckboxesValue, [e.target.name], false))
      newCheckboxesValue = {
        ...newCheckboxesValue,
        18: true,
      };
    if (e.target.name === 18 && !_get(newCheckboxesValue, [e.target.name], false))
      newCheckboxesValue = {
        ...newCheckboxesValue,
        22: false,
      };
    if (e.target.name === 28 && _get(newCheckboxesValue, [e.target.name], false))
      newCheckboxesValue = {
        ...newCheckboxesValue,
        6: true,
      };
    if (e.target.name === 29 && _get(newCheckboxesValue, [e.target.name], false))
      newCheckboxesValue = {
        ...newCheckboxesValue,
        6: true,
      };
    if (e.target.name === 6 && !_get(newCheckboxesValue, [e.target.name], false))
      newCheckboxesValue = {
        ...newCheckboxesValue,
        28: false,
        29: false,
      };
    if (e.target.name === 6 && _get(newCheckboxesValue, [e.target.name], false))
      newCheckboxesValue = {
        ...newCheckboxesValue
      };
    if (
      dependentFullModeId.includes(e.target.name) &&
      _get(newCheckboxesValue, [e.target.name], false)
    )
      newCheckboxesValue = {
        ...newCheckboxesValue,
        7: true
      };
    if (e.target.name === 7 && !_get(newCheckboxesValue, [e.target.name], false))
      newCheckboxesValue = {
        ...newCheckboxesValue,
        8: false,
        9: false,
        10: false,
        11: false,
        12: false,
        13: false,
        14: false,
        15: false,
        16: false,
      };
    if (e.target.name === 7 && _get(newCheckboxesValue, [e.target.name], false))
      newCheckboxesValue = {
        ...newCheckboxesValue,
      };
    setPermissionCheckboxes(newCheckboxesValue);
  };
  const onSubmit = (e, values) => {
    const ids = Object.keys(permissionCheckboxes)
      .filter(id => permissionCheckboxes[id])
      .map(id => parseInt(id));
    handleSubmit(e, {
      ...values,
      permissionIds: ids,
    });
  };

  const permissionStaffGroupIds = _get(staffGroup, 'permissionsStaffGroup', []).map(per => per.id);
  const defaultValues = { permissionIds: permissionStaffGroupIds };

  const moveArrayItemToNewIndex = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  const filteredPermissionId29 = permissions[0].child.find(per => per.id == 29);
  const indexPermissionId29 = permissions[0].child.indexOf(filteredPermissionId29);
  permissions.map(permission => {
    if (_get(permission, 'id', '') !== 1) return permission;
    return moveArrayItemToNewIndex(permission.child, indexPermissionId29, 1);
  });

  const filteredPermissionId28 = permissions[0].child.find(per => per.id == 28);
  const indexPermissionId28 = permissions[0].child.indexOf(filteredPermissionId28);
  permissions.map(permission => {
    if (_get(permission, 'id', '') !== 1) return permission;
    return moveArrayItemToNewIndex(permission.child, indexPermissionId28, 1);
  });
  useState(() => {
    setPermissionCheckboxes(
      permissionStaffGroupIds.reduce((acc, curr) => ((acc[curr] = true), acc), {})
    );
  }, [permissionStaffGroupIds]);

  return (
    <AvForm onValidSubmit={onSubmit} model={defaultValues}>
      <Row>
        <Row>
          <Col className='col-6'>
            <AvField
              name='name'
              label={translator('name')}
              type='text'
              errorMessage={translator('invalid name')}
              validate={{
                required: { value: true },
              }}
              value={_get(staffGroup, 'name', '')}
              className='mb-3'
            />
          </Col>
          <Col className='col-6'>
            <AvField
              name='description'
              label={translator('description')}
              type='text'
              value={_get(staffGroup, 'description', '')}
            />
          </Col>
        </Row>
      </Row>
      <Row>
        <Col className='col-12'>
          <CardTitle className='mb-3 font-size-16'> {translator('permissions')}: </CardTitle>
          <AvCheckboxGroup name='permissionIds'>
            <Row>
              {permissions.map((permission, i) => (
                <Col key={'title' + i} className='col-6'>
                  {_get(permission, 'parent', 1) !== 0 && (
                    <div className='form-check font-size-14 mb-3 fw-bold'>
                      {translator(_get(permission, 'name', '').toLowerCase())}
                    </div>
                  )}
                  {_get(permission, 'child', []).map((data, ind) => (
                    <div
                      className='form-check font-size-13 mb-3 fw-normal'
                      key={'description' + ind}
                    >
                      <Checkbox
                        className='fw-normal'
                        checked={_get(permissionCheckboxes, [_get(data, 'id', '')], false)}
                        name={_get(data, 'id', '')}
                        onChange={handlePermissionCheckbox}
                      >
                        {translator(_get(data, 'name', '').toLowerCase())}
                      </Checkbox>
                    </div>
                  ))}
                  {_get(permission, 'id', 1) === 4 && (
                    <div className='form-check mb-3 label--fw-bold' key={'description' + i}>
                      <br />
                      <br />
                      <br />
                      <Checkbox
                        name={_get(
                          permissions.find(item => _get(item, 'id', 1) === 5),
                          'id',
                          ''
                        )}
                        checked={_get(permissionCheckboxes, [5], false)}
                        onChange={handlePermissionCheckbox}
                      >
                        {translator(
                          _get(
                            permissions.find(item => _get(item, 'id', 1) === 5),
                            'name',
                            ''
                          ).toLowerCase()
                        ).toUpperCase()}
                      </Checkbox>
                    </div>
                  )}
                </Col>
              ))}
            </Row>
          </AvCheckboxGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='text-end'>
            <button type='submit' className='btn btn-success save-customer'>
              {translator(ACTION_LABELS[action])}
            </button>
          </div>
        </Col>
      </Row>
    </AvForm>
  );
};

StaffGroupForm.propTypes = {
  handleSubmit: PropTypes.func,
  staffGroup: PropTypes.object,
  permissions: PropTypes.array,
  action: PropTypes.string,
  t: PropTypes.any,
};

export default withTranslation()(StaffGroupForm);
