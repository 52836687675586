import { call, put, takeEvery } from 'redux-saga/effects';
import { staffGroupService } from 'services';
import { get as _get } from 'lodash';
import { catchError } from 'store/errors/actions';
import {
  GET_STAFF_GROUPS,
  UPDATE_STAFF_GROUP,
  DELETE_STAFF_GROUP,
  ADD_NEW_STAFF_GROUP
} from './actionTypes';
import {
  getStaffGroupsFail,
  getStaffGroupsSuccess,
  addStaffGroupFail,
  addStaffGroupSuccess,
  updateStaffGroupFail,
  updateStaffGroupSuccess,
  deleteStaffGroupFail,
  deleteStaffGroupSuccess
} from './actions';
import { getMyPermissions } from 'store/permission/actions';

function* fetchStaffGroupsFromBE() {
  try {
    const res = _get(yield call(staffGroupService.list), 'results', {});
    yield put(getStaffGroupsSuccess(res));
  } catch (error) {
    yield put(catchError(error));
    yield put(getStaffGroupsFail(error));
  }
}

function* addNewStaffGroup({ payload }) {
  try {
    const res = yield call(staffGroupService.create, {
      name: _get(payload, 'name', ''),
      description: _get(payload, 'description', ''),
      permission: _get(payload, 'permissions', [])
    });
    yield put(addStaffGroupSuccess(_get(res, 'results', {})));
  } catch (error) {
    yield put(catchError(error));
    yield put(addStaffGroupFail(error));
  }
}

function* updateStaffGroup({ payload }) {
  try {
    const res = yield call(staffGroupService.update, {
      id: _get(payload, 'id', ''),
      staffGroup: {
        name: _get(payload, 'name', ''),
        description: _get(payload, 'description', ''),
        permission: _get(payload, 'permissions', [])
      }
    });
    yield put(updateStaffGroupSuccess(_get(res, 'results', {})));
    yield put(getMyPermissions());
  } catch (error) {
    yield put(catchError(error));
    yield put(updateStaffGroupFail(error));
  }
}

function* deleteStaffGroup({ payload }) {
  try {
    const id = _get(payload, 'id', '');
    yield call(staffGroupService.archive, id);
    yield put(deleteStaffGroupSuccess(id));
  } catch (error) {
    yield put(catchError(error));
    yield put(deleteStaffGroupFail(error));
  }
}

function* staffGroupsSaga() {
  yield takeEvery(GET_STAFF_GROUPS, fetchStaffGroupsFromBE);
  yield takeEvery(ADD_NEW_STAFF_GROUP, addNewStaffGroup);
  yield takeEvery(UPDATE_STAFF_GROUP, updateStaffGroup);
  yield takeEvery(DELETE_STAFF_GROUP, deleteStaffGroup);
}
export default staffGroupsSaga;
