import { post, get, put } from '../helpers/apiHelper';
import { API_URL } from '../constants';
import { get as _get, isEmpty } from 'lodash';

const list = async () => {
  return await get({
    url: API_URL.messages.list,
  });
};

const create = async message => {
    const groupId = _get(message,'groupId',[]);
    const userId = _get(message,'userId','').toString();
    const productId = _get(message,'productId','');
    const categoryId = _get(message,'categoryId','');
    let data = {
      title: _get(message,'title',''),
      body: _get(message,'content',''),
      start_date : _get(message,'startDate',''),
      end_date : _get(message,'endDate',''),
    };
    data = isEmpty(userId) ? data : {
      ...data,
      user_id: userId,
    };
    data = isEmpty(groupId) ? data : {
      ...data,
      group_id: groupId,
    };
    data = productId === '' ? data : {
      ...data,
      product_id: productId,
    };
    data = categoryId === '' ? data : {
      ...data,
      category_id: categoryId,
    }; 
    return await post({
      url: API_URL.messages.create,
      data,
    });
  };

  const listUserForMessages = async (id) => {
    return await get({
      url: API_URL.messages.listUserForMessages(id),
    });
  };

  const update = async (newMessage) => {
    return await put({
      url: API_URL.messages.update(_get(newMessage, 'id')),
      data: {
        title: _get(newMessage, 'title'),
        content: _get(newMessage, 'content'),
        start_date: _get(newMessage, 'startDate'),
        end_date: _get(newMessage, 'endDate'),
        category_id: _get(newMessage, 'categoryId', null),
        product_id: _get(newMessage, 'productId', null),
      }
    });
  };
  
  export const messageService = {
    list,
    create,
    listUserForMessages,
    update
  };
  