import {
  GET_PROFILE_ERROR,
  GET_PROFILEE_SUCCESS,
  GET_PROFILE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  RESET_ERROR_STATUS
} from './actionTypes';
import { get as _get } from 'lodash';

const initialState = {
  error: '',
  success: ''
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE:
    case UPDATE_PROFILE:
      return {
        ...state,
        isLoadingProfiles: true,
        isUpdateProfileSuccess: false
      };
    case GET_PROFILEE_SUCCESS:
      return {
        ...state,
        isLoadingProfiles: false,
        profile: action.payload
      };
    case GET_PROFILE_ERROR:
      return {
        ...state,
        isLoadingProfiles: false,
        errors: _get(action, 'payload', {})
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isLoadingProfiles: false,
        profile: action.payload,
        isSuccess: true,
        isUpdateProfileSucess: true,
      };
    case UPDATE_PROFILE_FAIL:
      return {
        ...state,
        isLoadingProfiles: false,
        errors: _get(action, 'payload', {}),
        isSuccess: false,
        isUpdateProfileSucess: false
      };
    case RESET_ERROR_STATUS:
      return {
        ...state,
        errors: undefined,
        isSuccess: false
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
