/* eslint-disable react/display-name */
import React from 'react';
import { Redirect } from 'react-router-dom';

import UserProfile from '../pages/Profiles';

import Admins from '../pages/Admins/index';
import Users from '../pages/Users/index';
import Categories from '../pages/Categories/index';
import Products from '../pages/Products/index';

import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';

import Dashboard from '../pages/Dashboard/index';

import Notification from '../pages/Notifications';
import Message from '../pages/Messages';
import PagesMaintenance from '../pages/Utility/pages-maintenance';
import PagesComingsoon from '../pages/Utility/pages-comingsoon';
import Pages404 from '../pages/Utility/pages-404';
import Pages500 from '../pages/Utility/pages-500';

import Groups from '../pages/Groups/index';
// import SubGroupList from '../pages/Groups/sub-group';

import Orders from '../pages/Orders/index';
import OrderDetail from '../pages/Orders/order-detail';
import PrintOrderDetail from '../pages/Orders/PrintOrderDetail';
import PrintOrders from '../pages/Orders/PrintOrders';
import UserDetail from '../pages/Users/user-detail';

import StaffGroupList from '../pages/StaffGroup';

const superAdminProtectedRoutes = [{ path: '/admins', component: Admins }];

const defaultRoute = [{ path: '/', exact: true, component: () => <Redirect to='/profile' /> }];

const authProtectedRoutes = [
  { path: '/', component: UserProfile },
  { path: '/dashboard', component: Dashboard },
  { path: '/users', component: Users },
  { path: '/user/:id', component: UserDetail },
  { path: '/staff-groups', component: StaffGroupList },

  { path: '/orders', component: Orders },
  { path: '/order/:id', component: OrderDetail },

  //categories
  { path: '/categories', component: Categories },

  //products
  { path: '/products', component: Products },

  //profile
  { path: '/profile', component: UserProfile },

  //groups
  { path: '/groups', component: Groups },

  //notification
  { path: '/notifications', component: Notification },

  //message
  { path: '/messages', component: Message },

  { path: '/logout', component: Logout },

  ...defaultRoute,
];

const publicRoutes = [
  { path: '/login', component: Login },
  { path: '/pages-maintenance', component: PagesMaintenance },
  { path: '/pages-comingsoon', component: PagesComingsoon },
  { path: '/pages-404', component: Pages404 },
  { path: '/pages-500', component: Pages500 },
  ...defaultRoute,
];

const excludedRoutes = [
  //print
  { path: '/print/:id', component: PrintOrderDetail },
  { path: '/print/by-date/:date', component: PrintOrders },
];

export { authProtectedRoutes, publicRoutes, superAdminProtectedRoutes, excludedRoutes };
