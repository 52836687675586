import { 
  GET_PROFILE_ERROR, 
  GET_PROFILEE_SUCCESS, 
  EDIT_PROFILE, 
  RESET_PROFILE_FLAG, 
  GET_PROFILE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  RESET_ERROR_STATUS 
} from "./actionTypes";

export const  getProfile= user => {
  return{
    type: GET_PROFILE,
    payload: { user },
  };
};

export const getProfileSuccess = profile => {
  return {
    type: GET_PROFILEE_SUCCESS,
    payload: profile,
  };
};

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  };
};

export const getProfileError = error => {
  return {
    type: GET_PROFILE_ERROR,
    payload: error,
  };
};

export const resetProfileFlag = () => {
  return {
    type: RESET_PROFILE_FLAG,
  };
};

export const updateProfile = profile => ({
  type: UPDATE_PROFILE,
  payload: profile,
});

export const updateProfileSuccess = profile => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: profile,
});

export const updateProfileFail = error => ({
  type: UPDATE_PROFILE_FAIL,
  payload: error,
});

export const resetErrorStatus = () => ({
  type: RESET_ERROR_STATUS,
});
