import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

//i18n
import { withTranslation } from 'react-i18next';
// Redux
import {connect, useSelector} from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {get as _get} from 'lodash';

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call 'menu'
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      if (
        process.env.REACT_APP_DEFAULTAUTH === 'fake' ||
        process.env.REACT_APP_DEFAULTAUTH === 'jwt'
      ) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        setusername(obj.username);
      }
    }
  }, [props.success]);

  const { profile } = useSelector(state => ({
    profile: _get(state,['Profile','profile'],{})
  }));

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className='d-inline-block'
      >
        <DropdownToggle
          className='btn header-item '
          id='page-header-user-dropdown'
          tag='button'
        >
          <span className='d-none d-xl-inline-block ms-2 me-1'>{profile.name}</span>
          <i className='mdi mdi-chevron-down d-none d-xl-inline-block'/>
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-end'>
          <Link to='/profile'>
            <DropdownItem>
                <i className='bx bx-user font-size-16 align-middle me-1'/>
                {props.t('profile')}
            </DropdownItem>
          </Link>
          <div className='dropdown-divider'/>
          <Link to='/logout' className='dropdown-item'>
            <i className='bx bx-power-off font-size-16 align-middle me-1 text-danger'/>
            <span>{props.t('logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};


export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
