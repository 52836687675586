import {
  GET_MY_PERMISSIONS,
  GET_MY_PERMISSIONS_FAIL,
  GET_MY_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS,
  GET_PERMISSIONS_FAIL,
  GET_PERMISSIONS_SUCCESS,
  RESET_ERROR_STATUS
} from './actionTypes';

export const getPermissions = () => ({
  type: GET_PERMISSIONS
});

export const getPermissionsSuccess = permissions => ({
  type: GET_PERMISSIONS_SUCCESS,
  payload: permissions
});

export const getPermissionsFail = error => ({
  type: GET_PERMISSIONS_FAIL,
  payload: error
});

export const resetErrorStatus = () => ({
  type: RESET_ERROR_STATUS
});

export const getMyPermissions = () => ({
  type: GET_MY_PERMISSIONS
});

export const getMyPermissionsSuccess = permissions => ({
  type: GET_MY_PERMISSIONS_SUCCESS,
  payload: permissions
});

export const getMyPermissionsFail = error => ({
  type: GET_MY_PERMISSIONS_FAIL,
  payload: error
});
