import {
  PUSH_NOTIFICATION,
  PUSH_NOTIFICATION_SUCCESS,
  PUSH_NOTIFICATION_FAILURE,
  GET_NOTIFICATION_HISTORY,
  GET_NOTIFICATION_HISTORY_SUCCESS,
  GET_NOTIFICATION_HISTORY_FAILURE,
} from './actionTypes';

export const pushNotification = notification => ({
  type: PUSH_NOTIFICATION,
  payload: notification,
});

export const pushNotificationSuccess = notification => ({
  type: PUSH_NOTIFICATION_SUCCESS,
  payload: notification,
});

export const pushNotificationFailure = errors => ({
  type: PUSH_NOTIFICATION_FAILURE,
  payload: errors,
});

export const getNotificationHistory = () => ({
  type: GET_NOTIFICATION_HISTORY
});

export const getNotificationHistorySuccess = history => ({
  type: GET_NOTIFICATION_HISTORY_SUCCESS,
  payload: history,
});

export const getNotificationHistoryFailure = error => ({
  type: GET_NOTIFICATION_HISTORY_FAILURE,
  payload: error,
});
