import {
  GET_PRODUCTS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_BY_ID,
  GET_PRODUCTS_BY_ID_SUCCESS,
  GET_PRODUCTS_BY_ID_FAIL,
  ADD_NEW_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  UPDATE_CATEGORIES_FOR_PRODUCT,
  UPDATE_CATEGORIES_FOR_PRODUCT_SUCCESS,
  UPDATE_CATEGORIES_FOR_PRODUCT_FAIL,
  RESET_ERROR_STATUS,
  GET_FILTERED_PRODUCTS_BY_USER_ID,
  GET_FILTERED_PRODUCTS_BY_USER_ID_SUCCESS,
  GET_FILTERED_PRODUCTS_BY_USER_ID_FAIL,
  GET_FILTERED_PRODUCTS,
  GET_FILTERED_PRODUCTS_FAIL,
  GET_FILTERED_PRODUCTS_SUCCESS
} from './actionTypes';

const INIT_STATE = {
  error: '',
  success: ''
};

const ProductsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_NEW_PRODUCT:
    case UPDATE_PRODUCT:
    case GET_PRODUCTS:
    case GET_PRODUCTS_BY_ID:
    case DELETE_PRODUCT:
    case UPDATE_CATEGORIES_FOR_PRODUCT:
    case GET_FILTERED_PRODUCTS_BY_USER_ID:
    case GET_FILTERED_PRODUCTS:
      return {
        ...state,
        filteredProductById: [],
        isLoadingProducts: true,
        isSuccess: false,
        errors: ''
      };
    case GET_FILTERED_PRODUCTS_BY_USER_ID_SUCCESS:
      return {
        ...state,
        filteredProductById: action.payload,
        isSuccess: true,
        isLoadingProducts: false
      };
    case GET_FILTERED_PRODUCTS_BY_USER_ID_FAIL:
      return {
        ...state,
        filteredProductById: [],
        isSuccess: false,
        isLoadingProducts: false,
        errors: action.payload
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoadingProducts: false,
        products: action.payload
      };
    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        isLoadingProductsByUserId: false,
        errors: action.payload
      };
      case GET_PRODUCTS_BY_ID_SUCCESS:
        return {
          ...state,
          isLoadingProductsByUserId: false,
          productsByUserId: action.payload
        };
      case GET_PRODUCTS_BY_ID_FAIL:
        return {
          ...state,
          isLoadingProductsByUserId: false,
          errors: action.payload
        };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoadingProducts: false,
        products: [action.payload, ...state.products],
        isSuccess: true
      };
    case ADD_PRODUCT_FAIL:
      return {
        ...state,
        isLoadingProducts: false,
        errors: action.payload,
        isSuccess: false
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.map(product =>
          product.id.toString() === action.payload.id.toString()
            ? { product, ...action.payload }
            : product
        ),
        isLoadingProducts: false,
        isSuccess: true
      };
    case UPDATE_PRODUCT_FAIL:
      return {
        ...state,
        errors: action.payload,
        isLoadingProducts: false,
        isSuccess: false
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter(
          product => product.id.toString() !== action.payload.toString()
        ),
        isLoadingProducts: false,
        isSuccess: true
      };
    case DELETE_PRODUCT_FAIL:
      return {
        ...state,
        isLoadingProducts: false,
        errors: action.payload,
        isSuccess: false
      };
    case UPDATE_CATEGORIES_FOR_PRODUCT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoadingProducts: false,
        products: state.products.map(product =>
          product.id.toString() === action.payload.id.toString()
            ? { product, ...action.payload }
            : product
        )
      };
    case UPDATE_CATEGORIES_FOR_PRODUCT_FAIL:
      return {
        ...state,
        isLoadingProducts: false,
        errors: action.payload,
        isSuccess: false
      };
    case RESET_ERROR_STATUS:
      return {
        ...state,
        errors: undefined,
        isSuccess: false
      };
    case GET_FILTERED_PRODUCTS_SUCCESS:
      return {
        ...state,
        filteredProduct: action.payload,
        isSuccess: false,
        isLoadingProducts: false
      };
    case GET_FILTERED_PRODUCTS_FAIL:
      return {
        ...state,
        filteredProduct: [],
        isSuccess: false,
        isLoadingProducts: false,
        errors: action.payload
      };
    default:
      return state;
  }
};

export default ProductsReducer;
