/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import { Spin } from 'antd';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { EllipseSpan } from 'components/Common/StyledComponents/Span';
import { TooltipComponent } from 'components/Common/Tooltip';
import { UserForm } from 'components/Common/UserForm';
import { USER_ACTIONS } from 'components/Common/UserForm/constant';
import { SUPERIOR_ADMIN_ROLE, ACTIVE, INACTIVE } from 'constants/index';
import { showNotification } from 'constants/messages';
import { get as _get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import {
  addNewUser,
  deleteUser,
  getUsers,
  updateUser,
  resetErrorStatus,
  updateUserStatus,
  getFilteredUsers,
} from 'store/actions';
import { PERMISSIONS } from 'utils/permissions';
import EmpyDataForm from '../../components/Common/EmptyDataForm';
import { withTranslation } from 'react-i18next';
import { SIZEPERPAGE } from 'constants/utils';

const onClickAction = 'onclick-action';
let arrUsers = [];

const UserList = props => {
  const history = useHistory();
  const { t: translator } = props;
  const dispatch = useDispatch();
  const { users, errors, isSuccess, isLoadingUsers, filteredUsers, myPermissions, role } =
    useSelector(state => {
      return {
        isLoadingUsers: _get(state, ['users', 'isLoadingUsers'], false),
        users: _get(state, ['users', 'users'], []),
        filteredUsers: _get(state, ['users', 'filteredUsers'], []),
        errors: _get(state, ['users', 'errors', 'errors'], ''),
        isSuccess: _get(state, ['users', 'isSuccess'], ''),
        myPermissions: _get(state, ['permissions', 'myPermissions'], []),
        role: _get(state, ['Profile', 'profile', 'role'], undefined),
      };
    });

  const isModifyUserAllowed = useMemo(
    () => role === SUPERIOR_ADMIN_ROLE || myPermissions.map(permission => permission.name).includes(PERMISSIONS.CUSTOMER_MANAGEMENT.MODIFY_DELETE_CUSTOMER),
    [myPermissions]
  );

  const [isSuperior, setIsSuperior] = useState(false);
  useEffect(() => {
    setIsSuperior(role === SUPERIOR_ADMIN_ROLE);
  }, [role]);

  const [confirm_both, setconfirm_both] = useState(false);
  const [userDelete, setUserDelete] = useState([]);
  const [edittingUser, setEdittingUser] = useState({});
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [previousKeyword, setPreviousKeyword] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isSearched, setIsSearched] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [isUpdateStatus, setIsUpdateStatus] = useState(false);

  const [isCreateCustomerPermission, setIsCreateCustomerPermission] = useState(false);
  const [isModifyDeletePermission, setIsModifyDeletePermission] = useState(false);
  const [isChangeStatusPermission, setIsChangeStatusPermission] = useState(false);
  useEffect(() => {
    if (isEmpty(myPermissions)) return;
    const arr = [];
    myPermissions.forEach(permission => {
      const permissionName = _get(permission, 'name', '');
      arr.push(permissionName);
    });
    setIsCreateCustomerPermission(arr.includes(PERMISSIONS.CUSTOMER_MANAGEMENT.CREATE_CUSTOMER));
    setIsModifyDeletePermission(
      arr.includes(PERMISSIONS.CUSTOMER_MANAGEMENT.MODIFY_DELETE_CUSTOMER)
    );
    setIsChangeStatusPermission(
      arr.includes(PERMISSIONS.CUSTOMER_MANAGEMENT.CHANGE_STATUS_CUSTOMER)
    );
  }, [myPermissions]);

  const [pageOptions, setPageOptions] = useState({
    sizePerPage: SIZEPERPAGE,
    hideSizePerPage: true
  });

  useEffect(() => {
    if (!isEmpty(users))
      setPageOptions({
        sizePerPage: SIZEPERPAGE,
        hideSizePerPage: true,
      });
  }, [users]);

  const isUserCheckedUnitary = checkedUsers => {
    if (isEmpty(checkedUsers)) return false;
    if (checkedUsers.length === 1) return true;
    let initRes = '';
    let res = true;
    checkedUsers.forEach((user, index) => {
      if (index === 0) {
        initRes = user.userStatus;
        return;
      }
      if (user.userStatus !== initRes) res = false;
    });
    return res;
  };

  const handleClickChecked = user => {
    const isUserChecked = arrUsers.some(
      newUser => _get(newUser, 'userId', '').toString() === user.id.toString()
    );
    if (!isUserChecked) {
      arrUsers = [...arrUsers, { userId: user.id, userStatus: user.status }];
    } else {
      arrUsers = arrUsers.filter(
        newUser => _get(newUser, 'userId', '').toString() !== user.id.toString()
      );
    }
    setIsDisable(!isUserCheckedUnitary(arrUsers));
  };

  const handleUpdate = () => {
    const firstUser = _get(arrUsers, '0', {});
    const status = _get(firstUser, 'userStatus', '');
    const payload = {
      userIds: arrUsers.map(user => _get(user, 'userId', '')),
      userStatus: status === 'active' ? 'inactive' : 'active',
    };
    dispatch(updateUserStatus(payload));
    clearCheckedUser();
    setIsDisable(true);
  };

  const clearCheckedUser = () => {
    arrUsers = [];
    const inputs = document.getElementsByClassName('form-check-input');
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].checked = false;
    }
  };

  const UsersColumns =
    isSuperior || isModifyDeletePermission
      ? [
          {
            dataField: 'checkedbox',
            text: '',
            isDummyField: true,
            formatter: (cellContent, user) => (
              <Input
                type='checkbox'
                key={user.id}
                onChange={() => handleClickChecked(user)}
                action={onClickAction}
              ></Input>
            ),
          },
          {
            dataField: 'code',
            text: translator('code'),
            sort: true,
          },
          {
            dataField: 'user_name',
            text: translator('username'),
            sort: true,
          },
          {
            dataField: 'brand_name',
            text: translator('brand name'),
            sort: true,
          },
          {
            dataField: 'address',
            text: translator('address'),
            sort: true,
          },
          {
            dataField: 'status',
            text: translator('status'),
            sort: true,
            formatter: (cellContent, row) => (
              <>
                <p className='mb-0'>
                  {row.status == 'active' ? translator(ACTIVE) : translator(INACTIVE)}
                </p>
              </>
            ),
          },
          {
            dataField: 'groups',
            text: translator('groups'),
            sort: true,
            formatter: (cellContent, row) => {
              const groups = _get(row, ['groups'], []);
              const res = groups.reduce((accumulator, currentValue) => {
                return accumulator + ', ' + currentValue.name;
              }, '');
              const tooltipContent = (
                <ul>
                  {groups.map(subGroup => (
                    <li key={`sub-group-${_get(subGroup, 'name', '')}`}>
                      {_get(subGroup, 'name', '')}
                    </li>
                  ))}
                </ul>
              );
              return (
                <TooltipComponent
                  id={_get(row, 'id', '').toString()}
                  tag='group'
                  content={tooltipContent}
                >
                  <EllipseSpan>{res.substring(1)}</EllipseSpan>
                </TooltipComponent>
              );
            },
          },
          {
            dataField: 'menu',
            isDummyField: true,
            text: translator('action'),
            formatter: (cellContent, user) => (
              <UncontrolledDropdown direction='left'>
                <DropdownToggle href='#' className='card-drop' tag='i'>
                  <i className='mdi mdi-dots-horizontal font-size-18' action={onClickAction} />
                </DropdownToggle>
                <DropdownMenu className='dropdown-menu-end'>
                  <DropdownItem onClick={() => handleEditUser(user)} action={onClickAction}>
                    <i className='fas fa-pencil-alt text-success me-1' />
                    {translator('edit')}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setconfirm_both(true);
                      setUserDelete(user);
                      setIsUpdateStatus(false);
                    }}
                    action={onClickAction}
                  >
                    <i className='fas fa-trash-alt text-danger me-1' />
                    {translator('delete')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            ),
          },
        ]
      : [
          {
            dataField: 'checkedbox',
            text: '',
            isDummyField: true,
            formatter: (cellContent, user) => (
              <Input
                type='checkbox'
                key={user.id}
                onChange={() => handleClickChecked(user)}
                action={onClickAction}
              ></Input>
            ),
          },
          {
            dataField: 'code',
            text: translator('code'),
            sort: true,
          },
          {
            dataField: 'user_name',
            text: translator('username'),
            sort: true,
          },
          {
            dataField: 'brand_name',
            text: translator('brand name'),
            sort: true,
          },
          {
            dataField: 'address',
            text: translator('address'),
            sort: true,
          },
          {
            dataField: 'status',
            text: translator('status'),
            sort: true,
            formatter: (cellContent, row) => (
              <>
                <p className='mb-0'>
                  {row.status === 'active' ? translator(ACTIVE) : translator(INACTIVE)}
                </p>
              </>
            ),
          },
          {
            dataField: 'groups',
            text: translator('groups'),
            sort: true,
            formatter: (cellContent, row) => {
              const groups = _get(row, ['groups'], []);
              const res = groups.reduce((accumulator, currentValue) => {
                return accumulator + ', ' + currentValue.name;
              }, '');
              const tooltipContent = (
                <ul>
                  {groups.map(subGroup => (
                    <li key={`sub-group-${_get(subGroup, 'name', '')}`}>
                      {_get(subGroup, 'name', '')}
                    </li>
                  ))}
                </ul>
              );
              return (
                <TooltipComponent
                  id={_get(row, 'id', '').toString()}
                  tag='group'
                  content={tooltipContent}
                >
                  <EllipseSpan>{res.substring(1)}</EllipseSpan>
                </TooltipComponent>
              );
            },
          },
        ];

  const toggle = () => {
    setModal(!modal);
  };

  const handleEditUser = user => {
    setEdittingUser({
      code: user.code,
      id: user.id,
      userName: user.user_name,
      email: user.email,
      password: user.password,
      phoneNumber: user.phone_number,
      brandName: user.brand_name,
      status: user.status,
      address: user.address,
      isChangeStatusPermission: isChangeStatusPermission
    });
    setIsEdit(true);
    toggle();
  };

  const handleDeleteUser = user => {
    dispatch(deleteUser(user));
  };

  const handleValidUserSubmit = (e, values) => {
    const newUser = {
      code: values.code,
      id: edittingUser.id,
      userName: values.user_name,
      password: values.password,
      email: values.email,
      phoneNumber: values.phone_number,
      brandName: values.brand_name,
      status: values.status,
      address: values.address,
    };
    isEdit ? dispatch(updateUser(newUser)) : dispatch(addNewUser(newUser));
    toggle();
  };

  const onSubmit = (e, values) => {
    handleValidUserSubmit(e, values);
  };

  useEffect(() => {
    if (!isEmpty(errors)) showNotification(errors);
    if (isSuccess) showNotification('');
    dispatch(resetErrorStatus());
  }, [errors, isSuccess]);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const handleAddNewUser = () => {
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc',
    },
  ];

  const tableRowEvents = {
    onClick: (e, row) => {
      const action = e.target.getAttribute('action');
      if (action !== onClickAction && isModifyUserAllowed) {
        history.push(`/user/${row.id}`);
      }
    },
  };

  const onSearchSubmit = () => {
    if (previousKeyword === searchKeyword || isLoadingUsers) return;
    dispatch(getFilteredUsers(searchKeyword));
    setPreviousKeyword(searchKeyword);
    setIsSearched(true);
  };

  return (
    <Spin spinning={isLoadingUsers} tip={translator('loading...')}>
      <React.Fragment>
        <div className='page-content'>
          <MetaTags>
            <title> {translator('customers')} | Le diamant du terroir</title>
          </MetaTags>
          {confirm_both && (
            <SweetAlert
              title={translator('are you sure?')}
              warning
              showCancel
              confirmBtnBsStyle='success'
              cancelBtnBsStyle='danger'
              onConfirm={() => {
                isUpdateStatus ? handleUpdate() : handleDeleteUser(userDelete);
                setconfirm_both(false);
              }}
              onCancel={() => {
                setconfirm_both(false);
              }}
            />
          )}
          <Container fluid>
            <Breadcrumbs title={translator('manage')} breadcrumbItem={translator('customers')} />
            <Row>
              <Col xs='12'>
                <Card>
                  <CardBody>
                    <React.Fragment>
                      <Row className='mb-2'>
                        <Col sm='8'>
                          <Row>
                            <Col className='col-9'>
                              <Input
                                value={searchKeyword}
                                type='text'
                                onChange={e => {
                                  setSearchKeyword(_get(e, ['target', 'value'], ''));
                                }}
                              />
                            </Col>
                            <Col className='col-3'>
                              <Button onClick={onSearchSubmit}>{translator('search')}</Button>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm='4'>
                          <div className='text-sm-end'>
                            {(isSuperior || isChangeStatusPermission) && (
                              <Button
                                type='button'
                                color='success'
                                className='btn-rounded  mb-2 me-2'
                                onClick={() => {
                                  setconfirm_both(true);
                                  setIsUpdateStatus(true);
                                }}
                                disabled={isDisable}
                              >
                                {translator('update status')}
                              </Button>
                            )}
                            {(isSuperior || isCreateCustomerPermission) && (
                              <Button
                                type='button'
                                color='success'
                                className='btn-rounded  mb-2 me-2'
                                onClick={handleAddNewUser}
                              >
                                <i className='mdi mdi-plus me-1' />
                                {translator('new user')}
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                      {!isEmpty(users) ? (
                        <Row>
                          <Col xl='12'>
                            <div className='table-responsive'>
                              <BootstrapTable
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={'table align-middle table-nowrap table-check'}
                                headerWrapperClasses={'table-light'}
                                keyField='id'
                                pagination={paginationFactory(pageOptions)}
                                data={isSearched ? filteredUsers : users}
                                columns={UsersColumns}
                                rowEvents={tableRowEvents}
                              />
                            </div>
                            <Modal scrollable={true} isOpen={modal} toggle={toggle}>
                              <ModalHeader toggle={toggle} tag='h4'>
                                {isEdit ? translator('edit') : translator('add')}
                              </ModalHeader>
                              <ModalBody>
                                <UserForm
                                  user={isEdit ? edittingUser : {}}
                                  action={isEdit ? USER_ACTIONS.UPDATE : USER_ACTIONS.CREATE}
                                  handleSubmit={onSubmit}
                                />
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                      ) : (
                        <EmpyDataForm />
                      )}
                    </React.Fragment>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </Spin>
  );
};

UserList.propTypes = {
  Users: PropTypes.array,
  onGetUsers: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
  t: PropTypes.any,
};

export default withTranslation()(UserList);
