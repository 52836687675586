import { get as _get, isEmpty } from 'lodash';
import {
  ADD_CATEGORY_FAIL,
  ADD_CATEGORY_SUCCESS,
  ADD_NEW_CATEGORY,
  ADD_SUB_CATEGORY_SUCCESS,
  DELETE_CATEGORY,
  DELETE_CATEGORY_FAIL,
  DELETE_CATEGORY_SUCCESS,
  DELETE_SUB_CATEGORY_SUCCESS,
  GET_CATEGORIES,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_WITHOUT_SUBS,
  GET_CATEGORIES_WITHOUT_SUBS_FAIL,
  GET_CATEGORIES_WITHOUT_SUBS_SUCCESS,
  GET_CATEGORIES_WITHOUT_SUBS_BY_ID,
  GET_CATEGORIES_WITHOUT_SUBS_BY_ID_FAIL,
  GET_CATEGORIES_WITHOUT_SUBS_BY_ID_SUCCESS,
  GET_GROUPS_FOR_CATEGORY,
  GET_GROUPS_FOR_CATEGORY_FAIL,
  GET_GROUPS_FOR_CATEGORY_SUCCESS,
  GET_PRODUCTS_FOR_CATEGORY,
  GET_PRODUCTS_FOR_CATEGORY_FAIL,
  GET_PRODUCTS_FOR_CATEGORY_SUCCESS,
  GET_SUB_CATEGORIES,
  GET_SUB_CATEGORIES_FAIL,
  GET_SUB_CATEGORIES_SUCCESS,
  RESET_ERROR_STATUS,
  SWAP_CATEGORY_POSITION,
  SWAP_CATEGORY_POSITION_FAILURE,
  SWAP_CATEGORY_POSITION_SUCCESS,
  SWAP_SUB_CATEGORY_POSITION_SUCCESS,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_FAIL,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_GROUPS_FOR_CATEGORY,
  UPDATE_GROUPS_FOR_CATEGORY_FAIL,
  UPDATE_GROUPS_FOR_CATEGORY_SUCCESS,
  UPDATE_PRODUCTS_FOR_CATEGORY,
  UPDATE_PRODUCTS_FOR_CATEGORY_FAIL,
  UPDATE_PRODUCTS_FOR_CATEGORY_SUCCESS,
  UPDATE_SUB_CATEGORY_SUCCESS,
} from './actionTypes';
import {
  handleAddCategory,
  handleAddSubCategory,
  handleDeleteCategory,
  updatePosition,
  updateAmountProductForCategory,
  handleUpdateCategory,
  handleUpdateSubCategory,
} from './utils';

const INIT_STATE = {
  error: '',
  success: '',
};

const categoriesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_NEW_CATEGORY:
    case UPDATE_CATEGORY:
    case GET_CATEGORIES:
    case GET_SUB_CATEGORIES:
    case DELETE_CATEGORY:
    case GET_CATEGORIES_WITHOUT_SUBS:
    case GET_CATEGORIES_WITHOUT_SUBS_BY_ID:
    case SWAP_CATEGORY_POSITION:
    case UPDATE_GROUPS_FOR_CATEGORY:
    case UPDATE_PRODUCTS_FOR_CATEGORY:
      return {
        ...state,
        isLoadingCategories: true,
        isSuccess: false,
        errors: ''
      };
    case SWAP_CATEGORY_POSITION_SUCCESS:
      return {
        ...state,
        isLoadingCategories: false,
        isSuccess: true,
        categories: updatePosition(_get(state, 'categories', []), _get(action, 'payload', {}))
      };
    case SWAP_SUB_CATEGORY_POSITION_SUCCESS:
      return {
        ...state,
        isLoadingCategories: false,
        isSuccess: true,
        categories: _get(state, 'categories', []).map(category => {
          if (_get(category, 'id', '') === _get(action, 'type', '')) return { ...category };
          const subCats = Object.values(_get(category, 'sub', []));
          const resultSubCats = updatePosition(subCats, _get(action, 'payload', {}));
          return { ...category, sub: Object.assign({}, resultSubCats) };
        })
      };
    case SWAP_CATEGORY_POSITION_FAILURE:
      return {
        ...state,
        isLoadingCategories: false,
        errors: _get(action, 'payload', {})
      };
    case GET_PRODUCTS_FOR_CATEGORY:
      return {
        ...state,
        isLoadingCategories: false,
        productsForCategory: []
      };
    case GET_GROUPS_FOR_CATEGORY:
      return {
        ...state,
        isLoadingCategories: false,
        groupsForCategory: []
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoadingCategories: false,
        categories: action.payload
      };
    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        isLoadingCategories: false,
        errors: action.payload
      };
    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoadingCategories: false,
        categories: handleAddCategory(_get(state, 'categories', []), _get(action, 'payload', {})),
        isSuccess: true,
      };
    case ADD_CATEGORY_FAIL:
      return {
        ...state,
        isLoadingCategories: false,
        errors: action.payload,
        isSuccess: false
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoadingCategories: false,
        categories: handleUpdateCategory(
          _get(state, 'categories', []),
          _get(action, 'payload', {})
        ),
        isSuccess: true
      };
    case UPDATE_CATEGORY_FAIL:
      return {
        ...state,
        isLoadingCategories: false,
        errors: action.payload,
        isSuccess: false
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoadingCategories: false,
        categories: handleDeleteCategory(
          _get(state, 'categories', []),
          _get(action, ['payload', 'position'], -1),
          _get(action, ['payload', 'id'], {})
        ),
        isSuccess: true
      };
    case DELETE_CATEGORY_FAIL:
      return {
        ...state,
        isLoadingCategories: false,
        errors: action.payload,
        isSuccess: false
      };
    case GET_SUB_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoadingCategories: false,
        subCategories: action.payload
      };
    case GET_SUB_CATEGORIES_FAIL:
      return {
        subCategories: [],
        isLoadingCategories: false,
        errors: action.payload
      };
    case ADD_SUB_CATEGORY_SUCCESS: {
      const subCategories = _get(state, 'subCategories', []);
      return {
        ...state,
        isLoadingCategories: false,
        subCategories: isEmpty(subCategories) ? [] : [...subCategories, action.payload],
        isSuccess: true,
        categories: handleAddSubCategory(
          _get(state, 'categories', []),
          _get(action, 'payload', {})
        )
      };
    }
    case UPDATE_SUB_CATEGORY_SUCCESS: {
      const subCategories = _get(state, 'subCategories', []);
      const categories = _get(state, 'categories', []);
      const results = _get(action, 'payload', {});
      return {
        ...state,
        isLoadingCategories: false,
        categories: handleUpdateSubCategory(
          categories,
          results
        ),
        subCategories: isEmpty(subCategories) ? [] : handleUpdateCategory(subCategories, results),
        isSuccess: true
      };
    }
    case DELETE_SUB_CATEGORY_SUCCESS: {
      const { id, categoryId } = action.payload;
      const subCategories = _get(state, 'subCategories', []);
      const categories = _get(state, 'categories', []);
      return {
        ...state,
        isLoadingCategories: false,
        categories: isEmpty(categories)
          ? []
          : categories.map(category => {
              if (category.id.toString() !== categoryId.toString()) return category;
              const subCats = Object.values(_get(category, 'sub', []));
              const subCatsRes = handleDeleteCategory(
                subCats,
                _get(action, ['payload', 'position'], -1),
                id
              );
              return {
                ...category,
                sub: Object.assign({}, subCatsRes),
              };
            }),
        subCategories: isEmpty(subCategories)
          ? []
          : handleDeleteCategory(
              _get(state, 'categories', []),
              _get(action, ['payload', 'position'], -1),
              _get(action, ['payload', 'id'], {})
            ),   
        isSuccess: true
      };
    }
    case GET_CATEGORIES_WITHOUT_SUBS_SUCCESS:
      return {
        ...state,
        isLoadingCategories: false,
        categoriesWithoutSubs: action.payload
      };
    case GET_CATEGORIES_WITHOUT_SUBS_FAIL:
      return {
        ...state,
        isLoadingCategories: false,
        errors: action.payload,
        isSuccess: false,
      };
      case GET_CATEGORIES_WITHOUT_SUBS_BY_ID_SUCCESS:
        return {
          ...state,
          isLoadingCategoriesByUserId: false,
          categoriesWithoutSubsByUserId: action.payload
        };
      case GET_CATEGORIES_WITHOUT_SUBS_BY_ID_FAIL:
        return {
          ...state,
          isLoadingCategoriesByUserId: false,
          errors: action.payload,
          isSuccess: false,
        };
    case GET_GROUPS_FOR_CATEGORY_SUCCESS: {
      return {
        ...state,
        isLoadingCategories: false,
        groupsForCategory: action.payload
      };
    }
    case GET_GROUPS_FOR_CATEGORY_FAIL:
      return {
        ...state,
        isLoadingCategories: false,
        errors: action.payload,
        isSuccess: false
      };
    case GET_PRODUCTS_FOR_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoadingCategories: false,
        productsForCategory: action.payload
      };
    case GET_PRODUCTS_FOR_CATEGORY_FAIL:
      return {
        ...state,
        isLoadingCategories: false,
        errors: action.payload,
        isSuccess: false
      };
    case RESET_ERROR_STATUS:
      return {
        ...state,
        errors: undefined,
        isSuccess: false
      };
    case UPDATE_GROUPS_FOR_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoadingCategories: false,
        groupsForCategory: action.payload,
        isSuccess: true
      };
    case UPDATE_GROUPS_FOR_CATEGORY_FAIL:
      return {
        ...state,
        isLoadingCategories: false,
        errors: action.payload,
        isSuccess: false
      };
    case UPDATE_PRODUCTS_FOR_CATEGORY_SUCCESS: {
      const payload = _get(action, 'payload', {});
      return {
        ...state,
        isLoadingCategories: false,
        productsForCategory: _get(payload, ['result', 'products'], []),
        categories: updateAmountProductForCategory(_get(state, 'categories', []), payload),
        isSuccess: true
      };
    }
    case UPDATE_PRODUCTS_FOR_CATEGORY_FAIL:
      return {
        ...state,
        isLoadingCategories: false,
        errors: action.payload,
        isSuccess: false
      };
    default:
      return state;
  }
};

export default categoriesReducer;
