/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { get as _get } from 'lodash';
import { Table } from 'antd';
import { sortListByPosition } from './utils';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const CategoriesExpandableRow = props => {
  const {
    handleEditSubCategory,
    handleDeleteSubCategory,
    setEdittingCategory,
    rowDatas,
    setHandlingRowType,
    isEditDeletePermission
  } = props;
  const { sub: subCategories = [], id } = rowDatas;
  const { t: translator } = props;

  const subCategoriesData = useMemo(() => Object.values(subCategories), [subCategories]);

  const categoriesData = useMemo(
    () => sortListByPosition(subCategoriesData),
    [subCategories]
  );

  const columnNoAction = [
    {
      dataIndex: 'name',
      title: translator('name'),
      width: '50%',
      render: (cellContent, row) => <div className='column-content'>{row.name}</div>,
    },
    {
      dataIndex: 'productNumber',
      title: translator('products amount'),
      width: '40%',
      render: (cellContent, row) => <p>{_get(row, 'total_product', '')}</p>,
    }
  ];

  const columnFormat = isEditDeletePermission
    ? [
        ...columnNoAction,
        {
          dataIndex: 'menu',
          isDummyField: true,
          title: translator('action'),
          width: '10.5%',
          render: (cellContent, category) => (
            <UncontrolledDropdown direction='left'>
              <DropdownToggle href='#' className='card-drop' tag='i'>
                <i className='mdi mdi-dots-horizontal font-size-18' />
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu-end'>
                <DropdownItem onClick={() => handleEditSubCategory({ id, category })}>
                  <i className='fas fa-pencil-alt title-success me-1' />
                  {translator('edit')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    handleDeleteSubCategory({ id, category });
                  }}
                >
                  <i className='fas fa-trash-alt title-danger me-1' />
                  {translator('delete')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
      ]
    : columnNoAction;
  return (
    <Table
      onRow={() => {
        return {
          onMouseDown: () => {
            setHandlingRowType('sub');
            setEdittingCategory({ id });
          },
          onMouseUp: () => {
            setHandlingRowType('');
            setEdittingCategory({});
          }
        };
      }}
      showHeader={false}
      rowKey='id'
      dataSource={categoriesData}
      columns={columnFormat}
      pagination={false}
    ></Table>
  );
};

CategoriesExpandableRow.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(CategoriesExpandableRow);