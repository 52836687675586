import MetaTags from 'react-meta-tags';
import React, { useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Media } from 'reactstrap';
import { get as _get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Breadcrumb from 'components/Common/Breadcrumb';
import { resetErrorStatus } from 'store/auth/profile/actions';
import { withTranslation } from 'react-i18next';
import { showNotification } from 'constants/messages';
import { SimpleTable } from '../../components/Common/TableForm/SimpleTable';

const UserProfile = props => {
  const dispatch = useDispatch();
  const { t: translator } = props;

  const { profile, isSuccess, errors } = useSelector(state => ({
    profile: _get(state, ['Profile', 'profile'], {}),
    isSuccess: _get(state, ['Profile', 'isSuccess'], false),
    errors: _get(state, ['Profile', 'errors'], ''),
    isUpdateProfileSuccess: _get(state, ['Profile', 'isUpdateProfileSuccess'], false)
  }));

  useEffect(() => {
    if (!isEmpty(errors)) showNotification(errors);
    if (isSuccess) showNotification('');
    dispatch(resetErrorStatus());
  }, [errors, isSuccess]);

  const teams = profile.teams ? profile.teams.map(team => team.name) : [];

  return (
    <React.Fragment>
      <div className='page-content'>
        <MetaTags>
          <title> {translator('profile')} | Le diamant du terroir</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb breadcrumbItem={translator('profile')} />

          <Row>
            <Col lg='12'>
              <Card>
                <CardBody>
                  <Media body className='align-self-center'>
                    <div className='text-info'>
                      ID: <h5 className='text-detail'>{_get(profile, 'id', '')}</h5>
                    </div>
                    <div className='text-info'>
                      Emal: <h5 className='text-detail'>{_get(profile, 'email', '')}</h5>
                    </div>
                    <div className='text-info'>
                      Name: <h5 className='text-detail'>{_get(profile, 'name', '')}</h5>
                    </div>
                    <div className='text-info'>
                      First name: <h5 className='text-detail'>{_get(profile, 'first_name', '')}</h5>
                    </div>
                    <div className='text-info'>
                      Last name: <h5 className='text-detail'>{_get(profile, 'last_name', '') + ' ' + _get(profile, 'last_name', '')}</h5>
                    </div>
                    <div className='text-info'>
                      Phone number: <h5 className='text-detail'>{_get(profile, 'phone_number', '')}</h5>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {teams.includes("Delivery") && (
              <SimpleTable />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

UserProfile.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(UserProfile));
