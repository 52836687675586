import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication
import Login from './auth/login/reducer';
import Account from './auth/register/reducer';
import ForgetPassword from './auth/forgetpwd/reducer';
import Profile from './auth/profile/reducer';

//Dashboard
import Dashboard from './dashboard/reducer';

//Admins
import admins from './admins/reducer';

//Groups
import groups from './groups/reducer';

//Users
import users from './users/reducer';

//Categories
import categories from './categories/reducer';

//Products
import products from './products/reducer';

//Errors
import errors from './errors/reducer';

//Orders
import orders from './orders/reducer';

//Staff Groups
import staffGroups from './staffGroups/reducer';

//Permissions
import permissions from './permission/reducer';

//Notifications
import notifications from './pushNotifications/reducer';

//Messages
import messages from './pushMessages/reducer';

const rootReducer = combineReducers({
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  admins,
  users,
  orders,
  categories,
  products,
  Dashboard,
  errors,
  groups,
  notifications,
  staffGroups,
  permissions,
  messages
});

export default rootReducer;
