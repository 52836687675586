import { call, put, takeEvery } from 'redux-saga/effects';
import { messageService } from 'services/Messages.services';
import { get as _get } from 'lodash';
import { catchError } from 'store/errors/actions';
import { PUSH_MESSAGE, GET_MESSAGE_HISTORY, GET_USER_FOR_MESSAGE, UPDATE_MESSAGE } from './actionTypes';
import {
  pushMessageSuccess,
  pushMessageFailure,
  getMessageHistorySuccess,
  getMessageHistoryFailure,
  getUserForMessageSuccess,
  getUserForMessageFailure,
  updateMessageSuccess,
  updateMessageFailure
} from './actions';

function* pushMessage({ payload }) {
  try {
    const res = yield call(messageService.create, payload);
    yield put(pushMessageSuccess(_get(res, 'results', [])));
  } catch (error) {
    yield put(catchError(error));
    yield put(pushMessageFailure(error));
  }
}

function* getListMessages() {
  try {
    const res = yield call(messageService.list);
    yield put(getMessageHistorySuccess(_get(res, 'results', [])));
  } catch (error) {
    yield put(catchError(error));
    yield put(getMessageHistoryFailure(error));
  }
}

function* getUserMessages({ payload }) {
  try {
    const res = yield call(messageService.listUserForMessages, payload);
    yield put(getUserForMessageSuccess(_get(res, 'results', [])));
  } catch (error) {
    yield put(catchError(error));
    yield put(getUserForMessageFailure(error));
  }
}

function* updateMessage({ payload }) {
  try {
    const res = yield call(messageService.update, { ...payload });
    yield put(updateMessageSuccess(_get(res, 'results', [])));
  } catch (error) {
    yield put(catchError(error));
    yield put(updateMessageFailure(error));
  }
}

function* messageSaga() {
  yield takeEvery(PUSH_MESSAGE, pushMessage);
  yield takeEvery(GET_MESSAGE_HISTORY, getListMessages);
  yield takeEvery(GET_USER_FOR_MESSAGE, getUserMessages);
  yield takeEvery(UPDATE_MESSAGE, updateMessage);
}

export default messageSaga;
