/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import { Table } from 'antd';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { SUPERIOR_ADMIN_ROLE } from 'constants/index';
import { get as _get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Col, Input, Row } from 'reactstrap';
import { resetErrorStatus } from 'store/actions';
import {
  getCategoriesWithoutSubs,
  getGroupsForCategory,
  getProductsForCategory,
  getSubCategories,
  updateGroupsForCategory,
  updateProductsForCategory
} from 'store/categories/actions';
import { getFilteredProduct } from 'store/products/actions';
import { PERMISSIONS } from 'utils/permissions';
import { customStyleSelect } from '../../../constants/styles';
import { ACTION_LABELS, CATEGORY_ACTIONS } from './constants';
import GroupsForCategoryRow from './ExpandableRow';
import { SIZEPERPAGE } from 'constants/utils';

let arrGroupIds = [];
let arrProductIds = [];

const Form = props => {
  const dispatch = useDispatch();
  const { handleSubmit, category = {}, action, isSub, t: translator } = props;
  const {
    isLoadingProducts,
    isSuccess,
    errors,
    categoriesWithoutSubs,
    groupsForCategory,
    productsForCategory,
    filteredProduct,
    categories,
    subCategories,
    myPermissions,
    role
  } = useSelector(state => {
    return {
      categoriesWithoutSubs: _get(state, ['categories', 'categoriesWithoutSubs'], []),
      categories: _get(state, ['categories', 'categories'], []),
      errors: _get(state, ['users', 'errors', 'errors'], ''),
      isSuccess: _get(state, ['users', 'isSuccess'], ''),
      groupsForCategory: _get(state, ['categories', 'groupsForCategory'], []),
      productsForCategory: _get(state, ['categories', 'productsForCategory'], []),
      isLoadingProducts: _get(state, ['products', 'isLoadingProducts'], false),
      filteredProduct: _get(state, ['products', 'filteredProduct'], []),
      subCategories: _get(state, ['categories', 'subCategories'], []),
      myPermissions: _get(state, ['permissions', 'myPermissions'], []),
      role: _get(state, ['Profile', 'profile', 'role'], undefined)
    };
  });
  const arrProductsForCategory = Object.values(productsForCategory);

  const [isSuperior, setIsSuperior] = useState(false);
  useEffect(() => {
    setIsSuperior(role === SUPERIOR_ADMIN_ROLE);
  }, [role]);

  const typeSubCategory = _get(category, 'type', undefined);
  const categoryId = typeSubCategory ? typeSubCategory : _get(category, 'id', '');

  const subCategoryId = _get(category, 'id', '');

  const [searchKeywordProduct, setSearchKeywordProduct] = useState('');
  const [previousKeyword, setPreviousKeyword] = useState('');
  const [isSearched, setIsSearched] = useState(false);

  const [pageOptionsGroups, setPageOptionsGroups] = useState({
    sizePerPage: SIZEPERPAGE,
    totalSize: groupsForCategory.length,
    hideSizePerPage: true
  });

  const [isEditDeleteProductPermission, setIsEditDeleteProductPermission] = useState(false);
  useEffect(() => {
    if (isEmpty(myPermissions)) return;
    const arr = [];
    myPermissions.forEach(permission => {
      const permissionName = _get(permission, 'name', '');
      arr.push(permissionName);
    });
    setIsEditDeleteProductPermission(
      arr.includes(PERMISSIONS.PRODUCT_MANAGEMENT.EDIT_DELETE_CATEGORIES)
    );
  }, [myPermissions]);

  useEffect(() => {
    setPageOptionsGroups({
      sizePerPage: SIZEPERPAGE,
      hideSizePerPage: true
    });
  }, []);

  const [pageOptionsProducts, setPageOptionsProducts] = useState({
    sizePerPage: SIZEPERPAGE,
    totalSize: arrProductsForCategory.length,
    hideSizePerPage: true
  });

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc'
    }
  ];

  useEffect(() => {
    arrGroupIds = [];
    arrProductIds = [];
    if (action === CATEGORY_ACTIONS.UPDATE) {
      dispatch(getGroupsForCategory(isSub ? subCategoryId : categoryId));
      dispatch(getProductsForCategory(isSub ? subCategoryId : categoryId));
    } else dispatch(getCategoriesWithoutSubs());
    if (isSub) dispatch(getSubCategories(categoryId));
  }, []);

  useEffect(() => {
    if (!isSub || isEmpty(subCategories)) return;
    const mapSubCategories = subCategories.filter(cat => _get(cat, 'id', '') !== subCategoryId);
    const opSubCategories = {
      options: mapSubCategories.map(cat => ({
        id: _get(cat, 'id', ''),
        label: _get(cat, 'name', ''),
        value: _get(cat, 'position', 0)
      }))
    };
    setOptionSubCategories([opSubCategories]);
  }, [subCategories]);

  useEffect(() => {
    if (isEmpty(categoriesWithoutSubs)) return;
    const mapCategories = categoriesWithoutSubs.map(cate => {
      const nameCate = _get(cate, 'name', '');
      return {
        id: _get(cate, 'id', ''),
        label: nameCate,
        value: nameCate
      };
    }, []);
    const optionCategories = { options: mapCategories };
    setOptionPermissionCategory([optionCategories]);
  }, [categoriesWithoutSubs]);

  useEffect(() => {
    if (isSub || isEmpty(categories)) return;
    const mapCategories = categories.filter(cat => _get(cat, 'id', '') !== categoryId);
    const opCategories = {
      options: mapCategories.map(cat => ({
        id: _get(cat, 'id', ''),
        label: _get(cat, 'name', ''),
        value: _get(cat, 'position', 0)
      }))
    };
    setOptionCategories([opCategories]);
  }, [categories]);

  useEffect(() => {
    if (!isEmpty(errors)) showNotification(errors);
    if (isSuccess) showNotification('');
    dispatch(resetErrorStatus());
  }, [errors, isSuccess]);

  const [selectedPermissionCategory, setSelectedPermissionCategory] = useState({});
  const [selectedCategory, setSelectedCategory] = useState({});
  const [optionPermissionCategory, setOptionPermissionCategory] = useState([]);
  const [optionCategories, setOptionCategories] = useState([]);
  const [optionSubCategories, setOptionSubCategories] = useState([]);

  const onSubmit = (e, values) => {
    handleSubmit(e, {
      ...values,
      permissionCategory: _get(selectedPermissionCategory, 'id', ''),
      position: _get(selectedCategory, 'value', 0)
    });
  };

  const handleSelectPermissonCategory = targetCategory => {
    setSelectedPermissionCategory(targetCategory);
  };

  const handleSelectCategory = targetCategory => {
    setSelectedCategory(targetCategory);
  };

  const pushArrGroupIds = group => {
    const permission = _get(group, 'permission', false);
    const groupId = _get(group, 'id', '');
    const isGroupChecked = arrGroupIds.some(
      newGroupId => newGroupId.toString() === groupId.toString()
    );

    permission && !isGroupChecked && arrGroupIds.push(groupId);
  };

  const GroupsForCategoryColumns = [
    {
      dataIndex: 'name',
      title: translator('name'),
      width: '50%',
      render: (cellContent, row) => <div className='column-content'>{row.name}</div>
    },
    {
      dataIndex: 'checkedbox',
      title: translator('seen and select categories'),
      isDummyField: true,
      render: (cellContent, row) => {
        if (!isCheckingPermission) {
          pushArrGroupIds(row);
          _get(row, 'subs', []).forEach(subGroup => {
            pushArrGroupIds(subGroup);
          });
        }
        return (
          <Input
            type='checkbox'
            key={_get(row, 'id', '')}
            onChange={() => handleClickChecked(row)}
            defaultChecked={_get(row, 'permission', false)}
          />
        );
      }
    }
  ];

  const ProductsForCategoryColumns = [
    {
      dataField: 'title',
      text: translator('product'),
      sort: true
    },
    {
      dataField: 'checkedbox',
      text: translator('seen and select products'),
      isDummyField: true,
      formatter: (cellContent, row) => {
        const permission = _get(row, 'permission', false);
        const productId = _get(row, 'id', '');
        const isProductChecked = arrProductIds.some(
          newProductId => newProductId.toString() === productId.toString()
        );
        permission && !isProductChecked && arrProductIds.push(productId);
        return (
          <Input
            type='checkbox'
            key={productId}
            onChange={() => handleClickCheckedProduct(row)}
            defaultChecked={permission}
            disabled={!isSuperior && !isEditDeleteProductPermission}
          />
        );
      }
    }
  ];

  const [arrGroupIdsCopy, setArrGroupIdsCopy] = useState([]);
  const [isCheckingPermission, setIsCheckingPermission] = useState(false);

  const handleClickChecked = group => {
    const groupId = _get(group, 'id', '');
    const isGroupChecked = arrGroupIds.some(
      newGroupId => newGroupId.toString() === groupId.toString()
    );
    if (!isGroupChecked) {
      arrGroupIds = [...arrGroupIds, groupId];
    } else {
      arrGroupIds = arrGroupIds.filter(newGroupId => newGroupId.toString() !== groupId.toString());
    }
    setArrGroupIdsCopy(arrGroupIds);
    setIsCheckingPermission(true);
  };

  const handleClickCheckedProduct = product => {
    const productId = _get(product, 'id', '');
    const isProductChecked = arrProductIds.some(
      newProductId => newProductId.toString() === productId.toString()
    );
    if (!isProductChecked) {
      arrProductIds = [...arrProductIds, productId];
    } else {
      arrProductIds = arrProductIds.filter(
        newProductId => newProductId.toString() !== productId.toString()
      );
    }
  };

  const onUpdatePermission = () => {
    dispatch(
      updateGroupsForCategory({
        groupIds: arrGroupIds,
        categoryId: isSub ? subCategoryId : categoryId
      })
    );
  };

  const onUpdatePermissionProduct = () => {
    dispatch(
      updateProductsForCategory({ productIds: arrProductIds, categoryId, isSub, subCategoryId })
    );
  };

  const onSearchProducts = () => {
    if (previousKeyword === searchKeywordProduct || isLoadingProducts) return;
    dispatch(getFilteredProduct({ query: searchKeywordProduct, categoryId: categoryId }));
    setPreviousKeyword(searchKeywordProduct);
    setIsSearched(true);
  };

  const [isCategoryForm, setIsCategoryForm] = useState(false);
  const [isPermissionForm, setIsPermissionForm] = useState(true);
  const [isProductForm, setIsProductForm] = useState(true);

  const onClickCategoryForm = () => {
    setIsCategoryForm(false);
    setIsPermissionForm(true);
    setIsProductForm(true);
  };

  const onClickPermissionForm = () => {
    setIsCategoryForm(true);
    setIsPermissionForm(false);
    setIsProductForm(true);
  };

  const onClickProductForm = () => {
    setIsCategoryForm(true);
    setIsPermissionForm(true);
    setIsProductForm(false);
  };

  const opacityCategory = { opacity: isCategoryForm ? 0.25 : 1 };
  const opacityPermission = { opacity: isPermissionForm ? 0.25 : 1 };
  const opacityProduct = { opacity: isProductForm ? 0.25 : 1 };

  return (
    <AvForm onValidSubmit={onSubmit}>
      <Row form>
        <Row onClick={onClickCategoryForm} style={opacityCategory}>
          <Col className='col-12'>
            <div className='mb-3'>
              <AvField
                name='name'
                label={translator('name')}
                type='text'
                errorMessage={translator('invalid category')}
                validate={{
                  required: { value: true }
                }}
                value={category.name || ''}
              />
            </div>
            <div className='mb-3'>
              <AvField
                name='description'
                label={translator('description')}
                type='textarea'
                value={category.description || ''}
              />
            </div>
            <div className='mb-3'>
              <label className='control-label'>{translator('position')}</label>
              <Select
                styles={customStyleSelect}
                value={selectedCategory}
                onChange={values => {
                  handleSelectCategory(values);
                }}
                options={isSub ? optionSubCategories : optionCategories}
                classNamePrefix='select2-selection'
                placeholder={translator('select')}
              />
            </div>
            {action !== CATEGORY_ACTIONS['UPDATE'] && (
              <div className='mb-3'>
                <label className='control-label'>{translator('copy permission category')}</label>
                <Select
                  styles={customStyleSelect}
                  value={selectedPermissionCategory}
                  onChange={values => {
                    handleSelectPermissonCategory(values);
                  }}
                  options={optionPermissionCategory}
                  classNamePrefix='select2-selection'
                  placeholder={translator('select')}
                />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='text-end'>
              <button
                className='btn btn-success save-customer'
                type='submit'
                disabled={isCategoryForm}
              >
                {translator(ACTION_LABELS[action])}
              </button>
            </div>
          </Col>
        </Row>

        {action === CATEGORY_ACTIONS.UPDATE && (
          <>
            <div className='mb-3'>
              <Row>
                <div className='modal-header pl-0'>
                  <h4 className='modal-title mt-0' id='myModalLabel'>
                    {translator('permissions')}
                  </h4>
                </div>
              </Row>
            </div>
            <Row onClick={onClickPermissionForm} style={opacityPermission}>
              <div className='mb-3'>
                <Row>
                  <Col xl='12'>
                    <Table
                      rowKey='id'
                      pagination={{ pageSize: SIZEPERPAGE }}
                      dataSource={groupsForCategory}
                      columns={GroupsForCategoryColumns}
                      sticky={true}
                      expandable={{
                        expandedRowRender: row => (
                          <GroupsForCategoryRow rowDatas={row} onChecked={handleClickChecked} />
                        )
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className='text-end'>
                      <Button
                        className='btn btn-success'
                        onClick={onUpdatePermission}
                        disabled={isPermissionForm}
                      >
                        {translator('update')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>
            <div className='mb-3'>
              <Row>
                <div className='modal-header pl-0'>
                  <h4 className='modal-title mt-0' id='myModalLabel'>
                    {translator('products')}
                  </h4>
                </div>
              </Row>
            </div>
            <Row onClick={onClickProductForm} style={opacityProduct}>
              <div className='mb-3'>
                <Row>
                  <label className='control-label'>{translator('search products')}: </label>
                  <Col className='col-8'>
                    <Input
                      value={searchKeywordProduct}
                      type='text'
                      onChange={e => {
                        setSearchKeywordProduct(_get(e, ['target', 'value'], ''));
                      }}
                      placeholder={translator('name product')}
                    />
                  </Col>
                  <Col className='col-4'>
                    <Button onClick={onSearchProducts} disabled={isProductForm}>
                      {translator('search')}
                    </Button>
                  </Col>
                </Row>
              </div>
              <div className='mb-3'>
                <Row>
                  <Col xl='12'>
                    <div className='table-responsive'>
                      <BootstrapTable
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        classes={'table align-middle table-nowrap table-check'}
                        headerWrapperClasses={'table-light'}
                        keyField='id'
                        pagination={paginationFactory(pageOptionsProducts)}
                        data={isSearched ? filteredProduct : arrProductsForCategory}
                        columns={ProductsForCategoryColumns}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {(isSuperior || isEditDeleteProductPermission) && (
                      <div className='text-end'>
                        <Button
                          className='btn btn-success'
                          onClick={onUpdatePermissionProduct}
                          disabled={isProductForm}
                        >
                          {translator('update')}
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Row>
          </>
        )}
      </Row>
    </AvForm>
  );
};

export const CategoryForm = withTranslation()(Form);

Form.propTypes = {
  handleSubmit: PropTypes.func,
  category: PropTypes.object,
  action: PropTypes.string,
  isSub: PropTypes.bool,
  t: PropTypes.any
};
