import { get as _get } from 'lodash';
import { call, put, takeEvery } from 'redux-saga/effects';
import { userService } from 'services/User.service';
import { catchError } from 'store/errors/actions';
import { addUserGroupSuccess, deleteUsersGroupSuccess } from 'store/groups/actions';
import {
  addUserFail,
  addUserIntoGroupFail,
  addUserIntoGroupSuccess,
  addUserSuccess,
  deleteUserFail,
  deleteUserSuccess,
  getFilteredUsersFailure,
  getFilteredUsersSuccess,
  getUserDetailFail,
  getUserDetailSuccess,
  getUserFail,
  getUserSuccess,
  updateUserFail,
  updateUserSuccess,
  deleteUserOnGroupFail,
  deleteUserOnGroupSuccess,
  getFilteredUsersByGroupFail,
  getFilteredUsersByGroupSuccess,
  updateUserStatusFail,
  updateUserStatusSuccess,
  addUserFavoriteProductSuccess,
  addUserFavoriteProductFail,
  exportUserCsvFail,
} from './actions';
import {
  ADD_GROUP_SUBGROUPS_DEPENDENCIES,
  ADD_NEW_USER,
  DELETE_USER,
  DELETE_USER_ON_GROUP,
  GET_FILTERED_USER,
  GET_FILTERED_USER_BY_GROUP,
  GET_USERS,
  GET_USER_DETAIL,
  UPDATE_USER,
  UPDATE_USER_STATUS,
  ADD_USER_FAVORITE_PRODUCT,
  EXPORT_USER_CSV,
} from './actionTypes';
import { formatDate } from 'utils/date';
import { saveAs } from 'file-saver';

function* getFilteredUsers({ payload }) {
  try {
    const res = yield call(userService.search, payload);
    const data = _get(res, 'results', []);
    yield put(
      getFilteredUsersSuccess(
        data.map(item => ({
          ...item,
          name: _get(item, 'user_name', []),
          brandName: _get(item, 'brand_name', []),
          phoneNumber: _get(item, 'phone_number', []),
        }))
      )
    );
  } catch (error) {
    yield put(catchError(error));
    yield put(getFilteredUsersFailure(error));
  }
}

function* fetchUsersFromBE() {
  try {
    const res = yield call(userService.list);
    yield put(getUserSuccess(_get(res, 'results', [])));
  } catch (error) {
    yield put(catchError(error));
    yield put(getUserFail(error));
  }
}

function* addNewUser({ payload }) {
  try {
    const res = yield call(userService.create, {
      email: _get(payload, 'email', ''),
      code: _get(payload, 'code', ''),
      brand_name: _get(payload, 'brandName', ''),
      address: _get(payload, 'address', ''),
      status: _get(payload, 'status', ''),
      user_name: _get(payload, 'userName', ''),
      phone_number: _get(payload, 'phoneNumber', ''),
      password: _get(payload, 'password', ''),
    });
    yield put(addUserSuccess(_get(res, 'results', {})));
  } catch (error) {
    yield put(catchError(error));
    yield put(addUserFail(error));
  }
}

function* updateUser({ payload }) {
  try {
    const password = _get(payload, 'password', '');
    let obj = {
      email: _get(payload, 'email', ''),
      code: _get(payload, 'code', ''),
      brand_name: _get(payload, 'brandName', ''),
      address: _get(payload, 'address', ''),
      status: _get(payload, 'status', ''),
      user_name: _get(payload, 'userName', ''),
      phone_number: _get(payload, 'phoneNumber', ''),
    };
    if (password !== '') {
      obj = {
        ...obj,
        password,
      };
    }
    const res = yield call(userService.update, {
      id: _get(payload, 'id', ''),
      user: obj,
    });
    yield put(updateUserSuccess(_get(res, 'results', {})));
  } catch (error) {
    yield put(catchError(error));
    yield put(updateUserFail(error));
  }
}

function* deleteUser({ payload }) {
  try {
    const id = _get(payload, 'id', '');
    yield call(userService.archive, {
      id,
    });
    yield put(deleteUserSuccess(id));
  } catch (error) {
    yield put(catchError(error));
    yield put(deleteUserFail(error));
  }
}

function* addUserIntoGroup({ payload }) {
  try {
    const res = _get(yield call(userService.addUserIntoGroup, payload), 'results', {});
    const userRes = _get(res, 'user', {});
    yield put(addUserIntoGroupSuccess(userRes));
    yield put(
      addUserGroupSuccess({
        groupId: _get(payload, 'groupId', ''),
        parentGroupId: _get(payload, 'parentGroupId', undefined),
      })
    );
  } catch (error) {
    yield put(catchError(error));
    yield put(addUserIntoGroupFail(error));
  }
}

function* fetchUserDetail({ payload }) {
  try {
    const res = yield call(userService.getById, payload);
    yield put(getUserDetailSuccess(_get(res, 'results', {})));
  } catch (error) {
    yield put(catchError(error));
    yield put(getUserDetailFail(error));
  }
}

function* updateUserStatus({ payload }) {
  try {
    const res = _get(yield call(userService.updateUserStatus, payload), 'results', {});
    yield put(updateUserStatusSuccess({ res, userIds: _get(payload, 'userIds', []) }));
  } catch (error) {
    yield put(catchError(error));
    yield put(updateUserStatusFail(error));
  }
}

function* getFilteredUsersByGroup({ payload }) {
  try {
    const res = yield call(userService.searchByGroup, {
      query: _get(payload, 'query', ''),
      groupId: _get(payload, 'groupId', ''),
    });
    yield put(
      getFilteredUsersByGroupSuccess({
        results: _get(res, 'results', []),
        isGetAllUser: _get(payload, 'isGetAllUser', false),
      })
    );
  } catch (error) {
    yield put(catchError(error));
    yield put(getFilteredUsersByGroupFail(error));
  }
}

function* deleteUserOnGroup({ payload }) {
  try {
    const userIds = _get(payload, 'userIds', []);
    const groupId = _get(payload, 'groupId', '');
    yield call(userService.deleteUserOnGroup, {
      userIds,
      groupId,
    });
    yield put(deleteUserOnGroupSuccess(userIds));
    yield put(
      deleteUsersGroupSuccess({
        groupId,
        totalUserDeleted: userIds.length,
        parentGroupId: _get(payload, 'parentGroupId', undefined),
      })
    );
  } catch (error) {
    yield put(catchError(error));
    yield put(deleteUserOnGroupFail(error));
  }
}

function* addFavoriteProduct({ payload }) {
  try {
    const res = yield call(userService.addUserFavoriteProduct, payload);
    yield put(addUserFavoriteProductSuccess(_get(res, 'results', {})));
  } catch (error) {
    yield put(catchError(error));
    yield put(addUserFavoriteProductFail(error));
  }
}

function* exportUserCsv({ payload }) {
  try {
    const { range, user } = payload;
    const startDate = formatDate(range?.start, '-');
    const endDate = formatDate(range?.end, '-');
    const servicePayload = {
      range: {
        start: startDate,
        end: endDate,
      },
      userId: user?.id,
    };
    const result = yield call(userService.exportUserCsv, servicePayload);
    if (_get(result, 'status', '') !== 'NG')
      yield saveAs(result, `${user?.brand_name}_${user?.code}_${startDate}_${endDate}.csv`);
  } catch (error) {
    yield put(catchError(error));
    yield put(exportUserCsvFail(error));
  }
}

function* usersSaga() {
  yield takeEvery(GET_FILTERED_USER, getFilteredUsers);
  yield takeEvery(GET_USERS, fetchUsersFromBE);
  yield takeEvery(ADD_NEW_USER, addNewUser);
  yield takeEvery(UPDATE_USER, updateUser);
  yield takeEvery(DELETE_USER, deleteUser);
  yield takeEvery(ADD_GROUP_SUBGROUPS_DEPENDENCIES, addUserIntoGroup);
  yield takeEvery(GET_USER_DETAIL, fetchUserDetail);
  yield takeEvery(UPDATE_USER_STATUS, updateUserStatus);
  yield takeEvery(GET_FILTERED_USER_BY_GROUP, getFilteredUsersByGroup);
  yield takeEvery(DELETE_USER_ON_GROUP, deleteUserOnGroup);
  yield takeEvery(ADD_USER_FAVORITE_PRODUCT, addFavoriteProduct);
  yield takeEvery(EXPORT_USER_CSV, exportUserCsv);
}

export default usersSaga;
