import {
  GET_CATEGORIES,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  ADD_CATEGORY_SUCCESS,
  ADD_NEW_CATEGORY,
  ADD_CATEGORY_FAIL,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  GET_SUB_CATEGORIES,
  GET_SUB_CATEGORIES_SUCCESS,
  GET_SUB_CATEGORIES_FAIL,
  ADD_SUB_CATEGORY_SUCCESS,
  UPDATE_SUB_CATEGORY_SUCCESS,
  DELETE_SUB_CATEGORY_SUCCESS,
  GET_CATEGORIES_WITHOUT_SUBS,
  GET_CATEGORIES_WITHOUT_SUBS_FAIL,
  GET_CATEGORIES_WITHOUT_SUBS_SUCCESS,
  RESET_ERROR_STATUS,
  SWAP_CATEGORY_POSITION,
  SWAP_CATEGORY_POSITION_SUCCESS,
  SWAP_CATEGORY_POSITION_FAILURE,
  SWAP_SUB_CATEGORY_POSITION_SUCCESS,
  SWAP_SUB_CATEGORY_POSITION_FAILURE,
  GET_GROUPS_FOR_CATEGORY,
  GET_GROUPS_FOR_CATEGORY_SUCCESS,
  GET_GROUPS_FOR_CATEGORY_FAIL,
  GET_PRODUCTS_FOR_CATEGORY,
  GET_PRODUCTS_FOR_CATEGORY_SUCCESS,
  GET_PRODUCTS_FOR_CATEGORY_FAIL,
  UPDATE_PRODUCTS_FOR_CATEGORY,
  UPDATE_PRODUCTS_FOR_CATEGORY_SUCCESS,
  UPDATE_PRODUCTS_FOR_CATEGORY_FAIL,
  UPDATE_GROUPS_FOR_CATEGORY,
  UPDATE_GROUPS_FOR_CATEGORY_FAIL,
  UPDATE_GROUPS_FOR_CATEGORY_SUCCESS,
  GET_CATEGORIES_WITHOUT_SUBS_BY_ID,
  GET_CATEGORIES_WITHOUT_SUBS_BY_ID_FAIL,
  GET_CATEGORIES_WITHOUT_SUBS_BY_ID_SUCCESS,
} from './actionTypes';

export const getCategories = () => ({
  type: GET_CATEGORIES
});

export const getCategoriesSuccess = categories => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: categories
});

export const getCategoriesFail = error => ({
  type: GET_CATEGORIES_FAIL,
  payload: error
});

export const addNewCategory = category => ({
  type: ADD_NEW_CATEGORY,
  payload: category
});

export const addCategorySuccess = category => ({
  type: ADD_CATEGORY_SUCCESS,
  payload: category
});

export const addSubCategorySuccess = subCategory => ({
  type: ADD_SUB_CATEGORY_SUCCESS,
  payload: subCategory
});

export const addCategoryFail = error => ({
  type: ADD_CATEGORY_FAIL,
  payload: error
});

export const updateCategory = category => ({
  type: UPDATE_CATEGORY,
  payload: category
});

export const updateCategorySuccess = category => ({
  type: UPDATE_CATEGORY_SUCCESS,
  payload: category
});

export const updateSubCategorySuccess = subCategory => ({
  type: UPDATE_SUB_CATEGORY_SUCCESS,
  payload: subCategory
});

export const updateCategoryFail = error => ({
  type: UPDATE_CATEGORY_FAIL,
  payload: error
});

export const deleteCategory = category => {
  return {
    type: DELETE_CATEGORY,
    payload: category
  };
};

export const deleteCategorySuccess = category => ({
  type: DELETE_CATEGORY_SUCCESS,
  payload: category
});

export const deleteSubCategorySuccess = subCategory => ({
  type: DELETE_SUB_CATEGORY_SUCCESS,
  payload: subCategory
});

export const deleteCategoryFail = error => ({
  type: DELETE_CATEGORY_FAIL,
  payload: error
});

export const getSubCategories = categoryId => ({
  type: GET_SUB_CATEGORIES,
  payload: categoryId
});

export const getSubCategoriesSuccess = subCategories => ({
  type: GET_SUB_CATEGORIES_SUCCESS,
  payload: subCategories
});

export const getSubCategoriesFail = error => ({
  type: GET_SUB_CATEGORIES_FAIL,
  payload: error
});

export const getCategoriesWithoutSubs = () => ({
  type: GET_CATEGORIES_WITHOUT_SUBS
});

export const getCategoriesWithoutSubsSuccess = categoriesWithoutSubs => ({
  type: GET_CATEGORIES_WITHOUT_SUBS_SUCCESS,
  payload: categoriesWithoutSubs
});

export const getCategoriesWithoutSubsFail = error => ({
  type: GET_CATEGORIES_WITHOUT_SUBS_FAIL,
  payload: error
});

export const getCategoriesWithoutSubsById = userId => ({
  type: GET_CATEGORIES_WITHOUT_SUBS_BY_ID,
  payload: userId
});

export const getCategoriesWithoutSubsByIdSuccess = categoriesWithoutSubsById => ({
  type: GET_CATEGORIES_WITHOUT_SUBS_BY_ID_SUCCESS,
  payload: categoriesWithoutSubsById
});

export const getCategoriesWithoutSubsByIdFail = error => ({
  type: GET_CATEGORIES_WITHOUT_SUBS_BY_ID_FAIL,
  payload: error
});

export const resetErrorStatus = () => ({
  type: RESET_ERROR_STATUS
});

export const getGroupsForCategory = categoryId => ({
  type: GET_GROUPS_FOR_CATEGORY,
  payload: categoryId
});

export const getGroupsForCategorySuccess = groups => ({
  type: GET_GROUPS_FOR_CATEGORY_SUCCESS,
  payload: groups
});

export const getGroupsForCategoryFail = error => ({
  type: GET_GROUPS_FOR_CATEGORY_FAIL,
  payload: error
});

export const updateGroupsForCategory = payload => ({
  type: UPDATE_GROUPS_FOR_CATEGORY,
  payload
});

export const updateGroupsForCategorySuccess = groups => ({
  type: UPDATE_GROUPS_FOR_CATEGORY_SUCCESS,
  payload: groups
});

export const updateGroupsForCategoryFail = error => ({
  type: UPDATE_GROUPS_FOR_CATEGORY_FAIL,
  payload: error
});


export const getProductsForCategory = categoryId => ({
  type: GET_PRODUCTS_FOR_CATEGORY,
  payload: categoryId
});

export const getProductsForCategorySuccess = products => ({
  type: GET_PRODUCTS_FOR_CATEGORY_SUCCESS,
  payload: products
});

export const swapCategoryPosition = payload => ({
  type: SWAP_CATEGORY_POSITION,
  payload
});

export const swapCategoryPositionSuccess = payload => ({
  type: SWAP_CATEGORY_POSITION_SUCCESS,
  payload
});

export const swapCategoryPositionFailure = errors => ({
  type: SWAP_CATEGORY_POSITION_FAILURE,
  payload: errors
});

export const swapSubCategoryPositionSuccess = payload => ({
  type: SWAP_SUB_CATEGORY_POSITION_SUCCESS,
  payload
});

export const swapSubCategoryPositionFailure = errors => ({
  type: SWAP_SUB_CATEGORY_POSITION_FAILURE,
  payload: errors
});
export const getProductsForCategoryFail = error => ({
  type: GET_PRODUCTS_FOR_CATEGORY_FAIL,
  payload: error
});

export const updateProductsForCategory = payload => ({
  type: UPDATE_PRODUCTS_FOR_CATEGORY,
  payload
});

export const updateProductsForCategorySuccess = products => ({
  type: UPDATE_PRODUCTS_FOR_CATEGORY_SUCCESS,
  payload: products
});

export const updateProductsForCategoryFail = error => ({
  type: UPDATE_PRODUCTS_FOR_CATEGORY_FAIL,
  payload: error
});
