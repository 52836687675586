import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";

import classNames from "classnames";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

const Dashboard = props => {
  const [modal, setmodal] = useState(false);

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));

  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState("yearly");

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

  const onChangeChartPeriod = pType => {
    setPeriodType(pType);
    dispatch(onGetChartsData(pType));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetChartsData("yearly"));
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className='page-content'>
        <MetaTags>
          <title>Dashboard | Le diamant du terroir</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("dashboards")}
            breadcrumbItem={props.t("dashboard")}
          />

          <Row>
            <Col xl='4'>
              <WelcomeComp />
              <MonthlyEarning />
            </Col>
            <Col xl='8'>
              <Row>
                {/* Reports Render */}
                {/* {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </Media>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                ))} */}
              </Row>

              <Card>
                <CardBody>
                  <div className='d-sm-flex flex-wrap'>
                    <h4 className='card-title mb-4'>Email Sent</h4>
                    <div className='ms-auto'>
                      <ul className='nav nav-pills'>
                        <li className='nav-item'>
                          <Link
                            to='#'
                            className={classNames(
                              { active: periodType === "weekly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("weekly");
                            }}
                            id='one_month'
                          >
                            Week
                          </Link>{" "}
                        </li>
                        <li className='nav-item'>
                          <Link
                            to='#'
                            className={classNames(
                              { active: periodType === "monthly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("monthly");
                            }}
                            id='one_month'
                          >
                            Month
                          </Link>
                        </li>
                        <li className='nav-item'>
                          <Link
                            to='#'
                            className={classNames(
                              { active: periodType === "yearly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("yearly");
                            }}
                            id='one_month'
                          >
                            Year
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="clearfix"></div> */}
                  <StackedColumnChart periodData={periodData} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl='4'>
              <SocialSource />
            </Col>
            <Col xl='4'>
              <ActivityComp />
            </Col>

            <Col xl='4'>
              <TopCities />
            </Col>
          </Row>

        </Container>
      </div>

      <Modal
        isOpen={modal}
        role='dialog'
        autoFocus={true}
        centered={true}
        className='exampleModal'
        tabIndex='-1'
        toggle={() => {
          setmodal(!modal);
        }}
        scrollable={true}
      >
        <div className='modal-content'>
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className='mb-2'>
              Product id: <span className='text-primary'>#SK2540</span>
            </p>
            <p className='mb-4'>
              Billing Name: <span className='text-primary'>Neal Matthews</span>
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              type='button'
              color='secondary'
              onClick={() => {
                setmodal(!modal);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
