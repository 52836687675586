/* eslint-disable no-unused-vars */
import { Spin } from 'antd';
import { SUPERIOR_ADMIN_ROLE } from 'constants/index';
import { OrderContext } from 'contexts/orderContext';
import { get as _get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PERMISSIONS } from 'utils/permissions';
import FullModeBoard from './FullBoard';
import SimpleModeBoard from './SimpleBoard';

const OrderBoard = props => {
  const { isSimpleDisplayMode, setDisplayMode } = useContext(OrderContext);
  const { myPermissions, isLoadingOrders, role, isFilteringByDateRange } = useSelector(state => {
    return {
      isLoadingOrders: _get(state, ['orders', 'isLoadingOrders'], false),
      myPermissions: _get(state, ['permissions', 'myPermissions'], []),
      role: _get(state, ['Profile', 'profile', 'role'], undefined),
      isFilteringByDateRange: _get(state, ['orders', 'isFilteringByDateRange'], false)
    };
  });

  useEffect(() => {
    // setDisplayMode(!isFilteringByDateRange);
  },[isFilteringByDateRange]);

  const { t: translator } = props;

  const [isSuperior, setIsSuperior] = useState(false);
  useEffect(() => {
    setIsSuperior(role === SUPERIOR_ADMIN_ROLE);
  }, [role]);

  const [isFullModePermission, setIsFullModePermission] = useState(false);
  const [isSimpModePermission, setIsSimpModePermission] = useState(false);

  useEffect(() => {
    if (isEmpty(myPermissions)) return;
    const arr = [];
    myPermissions.forEach(permission => {
      const permissionName = _get(permission, 'name', '');
      arr.push(permissionName);
    });
    setIsFullModePermission(arr.includes(PERMISSIONS.ORDER_MANAGEMENT.ACCESS_ORDER_FULL));
    setIsSimpModePermission(arr.includes(PERMISSIONS.ORDER_MANAGEMENT.ACCESS_ORDER_SIMP));
  }, [myPermissions]);

  return (
      <React.Fragment>
        <div className='page-content'>
          <MetaTags>
            <title>{`${translator('orders')} | Le diamant du terroir`}</title>
          </MetaTags>
          {isSimpleDisplayMode && (isSuperior || isSimpModePermission) ? (
              <SimpleModeBoard />
          ) : isSuperior || isFullModePermission ? (
              <FullModeBoard />
          ) : (
              ''
          )}
        </div>
      </React.Fragment>
  );
};

OrderBoard.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(OrderBoard));
