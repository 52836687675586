import {
  ADD_GROUP_CATEGORY_DEPENDENCIES,
  ADD_GROUP_CATEGORY_DEPENDENCIES_FAIL,
  ADD_GROUP_CATEGORY_DEPENDENCIES_SUCCESS,
  ADD_GROUP_FAIL,
  ADD_GROUP_SUCCESS,
  ADD_NEW_GROUP,
  ADD_SUB_GROUP_SUCCESS,
  ADD_USER_GROUP_SUCCESS,
  DELETE_GROUP,
  DELETE_GROUP_FAIL,
  DELETE_GROUP_SUCCESS,
  DELETE_SUB_GROUP_SUCCESS,
  DELETE_USERS_GROUP_SUCCRESS,
  GET_GROUPS,
  GET_GROUPS_FAIL,
  GET_GROUPS_SUCCESS,
  GET_ALL_GROUPS,
  GET_ALL_GROUPS_FAIL,
  GET_ALL_GROUPS_SUCCESS,
  GET_SUB_GROUPS,
  GET_SUB_GROUPS_FAIL,
  GET_SUB_GROUPS_SUCCESS,
  RESET_ERROR_STATUS,
  UPDATE_GROUP,
  UPDATE_GROUP_FAIL,
  UPDATE_GROUP_SUCCESS,
  UPDATE_SUB_GROUP_SUCCESS
} from './actionTypes';

export const addGroupCategoryDependencies = payload => ({
  type: ADD_GROUP_CATEGORY_DEPENDENCIES,
  payload
});

export const addGroupCategoryDependenciesSuccess = payload => ({
  type: ADD_GROUP_CATEGORY_DEPENDENCIES_SUCCESS,
  payload
});

export const addGroupCategoryDependenciesFail = errors => ({
  type: ADD_GROUP_CATEGORY_DEPENDENCIES_FAIL,
  errors
});

export const getGroups = () => ({
  type: GET_GROUPS
});

export const getGroupsSuccess = groups => ({
  type: GET_GROUPS_SUCCESS,
  payload: groups
});

export const getGroupsFail = error => ({
  type: GET_GROUPS_FAIL,
  payload: error
});

export const getAllGroups = () => ({
  type: GET_ALL_GROUPS
});

export const getAllGroupsSuccess = groups => ({
  type: GET_ALL_GROUPS_SUCCESS,
  payload: groups
});

export const getAllGroupsFail = error => ({
  type: GET_ALL_GROUPS_FAIL,
  payload: error
});

export const addNewGroup = group => ({
  type: ADD_NEW_GROUP,
  payload: group
});

export const addGroupSuccess = group => ({
  type: ADD_GROUP_SUCCESS,
  payload: group
});

export const addGroupFail = error => ({
  type: ADD_GROUP_FAIL,
  payload: error
});

export const addSubGroupSuccess = subGroup => ({
  type: ADD_SUB_GROUP_SUCCESS,
  payload: subGroup
});

export const updateGroup = group => ({
  type: UPDATE_GROUP,
  payload: group
});

export const updateGroupSuccess = group => ({
  type: UPDATE_GROUP_SUCCESS,
  payload: group
});

export const updateGroupFail = error => ({
  type: UPDATE_GROUP_FAIL,
  payload: error
});

export const updateSubGroupSuccess = payload => ({
  type: UPDATE_SUB_GROUP_SUCCESS,
  payload
});

export const deleteGroup = group => {
  return {
    type: DELETE_GROUP,
    payload: group
  };
};

export const deleteGroupSuccess = group => ({
  type: DELETE_GROUP_SUCCESS,
  payload: group
});

export const deleteGroupFail = error => ({
  type: DELETE_GROUP_FAIL,
  payload: error
});

export const deleteSubGroupSuccess = payload => ({
  type: DELETE_SUB_GROUP_SUCCESS,
  payload: payload
});

export const getSubGroups = subGroupId => ({
  type: GET_SUB_GROUPS,
  payload: subGroupId
});

export const getSubGroupsSuccess = subGroups => ({
  type: GET_SUB_GROUPS_SUCCESS,
  payload: subGroups
});

export const getSubGroupsFail = error => ({
  type: GET_SUB_GROUPS_FAIL,
  payload: error
});

export const resetErrorStatus = () => ({
  type: RESET_ERROR_STATUS
});

export const addUserGroupSuccess = users => ({
  type: ADD_USER_GROUP_SUCCESS,
  payload: users
});

export const deleteUsersGroupSuccess = users => ({
  type: DELETE_USERS_GROUP_SUCCRESS,
  payload: users
});
