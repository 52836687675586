import {
  ADD_GROUP_SUBGROUPS_DEPENDENCIES,
  ADD_GROUP_SUBGROUPS_DEPENDENCIES_FAIL,
  ADD_GROUP_SUBGROUPS_DEPENDENCIES_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_FAIL,
  ADD_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_ON_GROUP,
  DELETE_USER_ON_GROUP_FAIL,
  DELETE_USER_ON_GROUP_SUCCESS,
  DELETE_USER_SUCCESS,
  GET_FILTERED_USER,
  GET_FILTERED_USER_BY_GROUP,
  GET_FILTERED_USER_BY_GROUP_FAIL,
  GET_FILTERED_USER_BY_GROUP_SUCCESS,
  GET_FILTERED_USER_FAILURE,
  GET_FILTERED_USER_SUCCESS,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USER_DETAIL,
  GET_USER_DETAIL_FAIL,
  GET_USER_DETAIL_SUCCESS,
  REMOVE_FILTERED_USER,
  RESET_ERROR_STATUS,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_ORDER_SUCCESS,
  UPDATE_USER_STATUS,
  UPDATE_USER_STATUS_FAIL,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_SUCCESS,
  ADD_USER_FAVORITE_PRODUCT,
  ADD_USER_FAVORITE_PRODUCT_SUCCESS,
  ADD_USER_FAVORITE_PRODUCT_FAIL,
  ADD_USER_ORDER_SUCCESS
} from './actionTypes';
import { get as _get } from 'lodash';

const INIT_STATE = {
  error: '',
  success: ''
};
const UsersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_NEW_USER:
    case UPDATE_USER:
    case GET_USERS:
    case DELETE_USER:
    case ADD_GROUP_SUBGROUPS_DEPENDENCIES:
    case GET_FILTERED_USER:
    case GET_USER_DETAIL:
    case UPDATE_USER_STATUS:
    case GET_FILTERED_USER_BY_GROUP:
    case DELETE_USER_ON_GROUP:
    case ADD_USER_FAVORITE_PRODUCT:
      return {
        ...state,
        isLoadingUsers: true,
        isSuccess: false,
        errors: ''
      };
    case GET_FILTERED_USER_SUCCESS:
      return {
        ...state,
        filteredUsers: action.payload,
        isLoadingUsers: false
      };
    case GET_FILTERED_USER_FAILURE:
      return {
        ...state,
        filteredUsers: [],
        errors: action.payload,
        isLoadingUsers: false
      };
    case ADD_GROUP_SUBGROUPS_DEPENDENCIES_SUCCESS:
      return {
        ...state,
        isLoadingUsers: false,
        isSuccess: true,
        filteredUsersByGroup: [..._get(state, 'filteredUsersByGroup', []), action.payload]
      };
    case ADD_GROUP_SUBGROUPS_DEPENDENCIES_FAIL:
      return {
        ...state,
        isLoadingUsers: false,
        errors: action.payload,
        isSuccess: false
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        isLoadingUsers: false,
        users: action.payload
      };
    case GET_USERS_FAIL:
      return {
        ...state,
        isLoadingUsers: false,
        errors: action.payload
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        isLoadingUsers: false,
        users: [action.payload, ...state.users],
        isSuccess: true
      };
    case ADD_USER_FAIL:
      return {
        ...state,
        isLoadingUsers: false,
        errors: action.payload,
        isSuccess: false
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoadingUsers: false,
        users: state.users.map(user =>
          user.id.toString() === action.payload.id.toString() ? { user, ...action.payload } : user
        ),
        filteredUsers: state.filteredUsers.map(filteredUser =>
          filteredUser.id.toString() === action.payload.id.toString()
            ? { filteredUser, ...action.payload }
            : filteredUser
        ),
        isSuccess: true
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        isLoadingUsers: false,
        errors: action.payload,
        isSuccess: false
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        isLoadingUsers: false,
        users: state.users.filter(user => user.id.toString() !== action.payload.toString()),
        isSuccess: true
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        isLoadingUsers: false,
        errors: action.payload,
        isSuccess: false
      };
    case RESET_ERROR_STATUS:
      return {
        ...state,
        errors: undefined,
        isSuccess: false
      };

    case GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        isLoadingUsers: false,
        userDetail: action.payload
      };
    case GET_USER_DETAIL_FAIL:
      return {
        ...state,
        isLoadingUsers: false,
        errors: action.payload
      };
    case UPDATE_USER_ORDER_SUCCESS: {
      const newOrders = state.userDetail.orders.map(order =>
        order.id.toString() === action.payload.id.toString()
          ? { ...order, status: parseInt(action.payload.status) }
          : order
      );
      return {
        ...state,
        isLoadingUsers: false,
        userDetail: {
          ...state.userDetail,
          orders: newOrders
        }
      };
    }
    case ADD_USER_ORDER_SUCCESS: {
      return {
        ...state,
        isLoadingUsers: false,
        userDetail: {
          ...state.userDetail,
          orders: [...state.userDetail.orders, action.payload]
        }
      };
    }
    case UPDATE_USER_STATUS_SUCCESS: {
      const filterUserIds = _get(state, 'filteredUsers', []).map(filterUser =>
        _get(filterUser, 'id', '')
      );
      const userRes = _get(action, ['payload', 'res'], {});
      return {
        ...state,
        isLoadingUsers: false,
        users: userRes,
        filteredUsers: userRes.filter(user => filterUserIds.includes(_get(user, 'id', ''))),
        isSuccess: true
      };
    }

    case UPDATE_USER_STATUS_FAIL:
      return {
        ...state,
        isLoadingUsers: false,
        errors: action.payload,
        isSuccess: false
      };
    case GET_FILTERED_USER_BY_GROUP_SUCCESS:
      return {
        ...state,
        filteredUsersByGroup: action.payload.results,
        isLoadingUsers: false
      };
    case GET_FILTERED_USER_BY_GROUP_FAIL:
      return {
        ...state,
        filteredUsersByGroup: [],
        errors: action.payload,
        isLoadingUsers: false
      };
    case REMOVE_FILTERED_USER:
      return {
        ...state,
        isLoadingUsers: false,
        filteredUsers: []
      };
    case DELETE_USER_ON_GROUP_FAIL:
      return {
        ...state,
        isLoadingUsers: false,
        errors: action.payload,
        isSuccess: false
      };
    case DELETE_USER_ON_GROUP_SUCCESS:
      return {
        ...state,
        isLoadingUsers: false,
        filteredUsersByGroup: state.filteredUsersByGroup.filter(
          user => !action.payload.includes(user.id)
        ),
        isSuccess: true
      };
    case ADD_USER_FAVORITE_PRODUCT_SUCCESS: {
      return {
        ...state,
        isLoadingUsers: false,
        isSuccess: true,
        userDetail: {
          ...state.userDetail,
          favorite: action.payload
        }
      };
    }
    case ADD_USER_FAVORITE_PRODUCT_FAIL:
      return {
        ...state,
        isSuccess: false,
        isLoadingUsers: false,
        errors: action.payload
      };
    default:
      return state;
  }
};

export default UsersReducer;
