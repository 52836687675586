/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import {AvField, AvForm} from 'availity-reactstrap-validation';
import { SUPERIOR_ADMIN_ROLE } from 'constants/index';
import { OrderContext } from 'contexts/orderContext';
import { format } from 'date-fns';
import { get as _get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, {forwardRef, useContext, useEffect, useState, useRef, useMemo} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {Button, Card, Col, Container, Modal, ModalBody, ModalHeader, Row} from 'reactstrap';
import {getDeliveryStaffs, getOrderByDate, updateDeliveryMen, updateOrder} from 'store/orders/actions';
import { PERMISSIONS } from 'utils/permissions';
import DeliveryMenForm from '../DeliveryMenForm';
import { DELIVERY_ACTIONS } from '../DeliveryMenForm/constants';
import EmpyDataForm from '../EmptyDataForm';
import { EllipseSpan } from '../StyledComponents/Span';
import { EllipseSpanCustom } from '../StyledComponents/SpanCustom';
import { TooltipComponent } from '../Tooltip';
import { REFRESH_DURATION } from 'pages/Orders/constants';
import { usePrevious } from 'hooks/usePrevous';
import * as moment from 'moment';
import { useLocation } from 'react-router-dom';

let simpleTimer = null;

const Table = props => {
  const dispatch = useDispatch();
  const { t: translator } = props;

  const location = useLocation();
  const pathName = location.pathname;

  const { toggleDisplayMode } = useContext(OrderContext);
  const { orders, productsSum, maxProductsRow, deliveryStaffs, myPermissions, role, maxProduct, name } =
    useSelector(state => {
      return {
        orders: _get(state, ['orders', 'orders'], []),
        productsSum: _get(state, ['orders', 'products'], []),
        maxProduct: _get(state, ['orders', 'maxProduct'], []),
        maxProductsRow: _get(state, ['orders', 'sumProducts'], []),
        deliveryStaffs: _get(state, ['orders', 'deliveryStaffs'], []),
        myPermissions: _get(state, ['permissions', 'myPermissions'], []),
        role: _get(state, ['Profile', 'profile', 'role'], undefined),
        name: _get(state, ['Profile', 'profile', 'name'], undefined),
      };
    });

  const [isSuperior, setIsSuperior] = useState(false);
  useEffect(() => {
    setIsSuperior(role === SUPERIOR_ADMIN_ROLE);
  }, [role]);

  const [isFullModePermission, setIsFullModePermission] = useState(false);
  const [isAssignDeliveryPermission, setIsAssignDeliveryPermission] = useState(false);
  const [isPrivateComment, setIsPrivateComment] = useState(false);
  useEffect(() => {
    if (isEmpty(myPermissions)) return;
    const arr = [];
    myPermissions.forEach(permission => {
      const permissionName = _get(permission, 'name', '');
      arr.push(permissionName);
    });
    setIsFullModePermission(arr.includes(PERMISSIONS.ORDER_MANAGEMENT.ACCESS_ORDER_FULL));
    setIsAssignDeliveryPermission(arr.includes(PERMISSIONS.ORDER_MANAGEMENT.ASSIGN_DELIVERY_SIMP));
    setIsPrivateComment(arr.includes(PERMISSIONS.ORDER_MANAGEMENT.LEAVE_COMMENT_SIMP));
  }, [myPermissions]);

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [edittingDeliveryMen, setEdittingDeliveryMen] = useState({});

  const [modalComment, setModalComment] = useState(false);
  const [edittingComment, setEdittingComment] = useState({});

  const toggle = () => {
    setModal(!modal);
  };

  const toggleComment = () => {
    setModalComment(!modalComment);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());
  const selectedNextDate = moment(selectedDate).add(1, 'day')._d; //the next date
  const currentTime = moment(selectedDate, 'h:mma');
  const eightAM = moment('8:00am', 'h:mma');
  const nextDate = moment(selectedDate).add(1, 'day').format('yyyyMMDD');
  const date = format(selectedDate, 'yyyyMMdd');
  const prev = usePrevious(date);
  useEffect(() => {
    if(prev !== date) {
      clearInterval(simpleTimer);
      simpleTimer = setInterval(() => {
        dispatch(getOrderByDate({
          date: date,
          isCheckDelivery: isCheckDelivery
        }));
      }, REFRESH_DURATION);
    }
    return function () {
      clearInterval(simpleTimer);
    };
  }, [date]);

  const categoriesLabel = [];

  for (const [key] of Object.entries(productsSum)) {
    categoriesLabel.push(key);
  }

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc',
    },
  ];

  const handleSelectedDate = targetDate => {
    setSelectedDate(targetDate);
  };

  const isCheckDelivery = pathName === '/profile';

  const handleCalendarClose = () => {
    dispatch(getOrderByDate({
      date: date,
      isCheckDelivery: isCheckDelivery
    }));
  };

  useEffect(() => {
    if (currentTime.isBefore(eightAM)){
      dispatch(getOrderByDate({
        date: date,
        isCheckDelivery: isCheckDelivery
      }));
    } else {
      setSelectedDate(selectedNextDate);
      dispatch(getOrderByDate({
        date: nextDate,
        isCheckDelivery: isCheckDelivery
      }));
    }
    dispatch(getDeliveryStaffs());
  }, []);

  const [pageOptionsOrders, setPageOptionsOrders] = useState(
    {
      sizePerPage: 50,
      totalSize: orders !== undefined ? orders.length : 0,
      hideSizePerPage: true,
    },
    [orders]
  );

  const [newOrders, setNewOrders] = useState([]);

  useEffect(() => {
    if (!isEmpty(orders)) {
      const newOrds = orders.map(order => {
        if (_get(order, 'delivery_man', {})) return order;
        return { ...order, delivery_man: {} };
      });
      setNewOrders(newOrds);
      setPageOptionsOrders({
        sizePerPage: 50,
        hideSizePerPage: true,
      });
    }
  }, [orders]);

  const handleUpdateComment = (orderId, privateComment) => {
    setEdittingComment({
      orderId: orderId,
      privateComment: privateComment
    });
    toggleComment();
  };

  const handleValidComment = (e, values) => {
    const updatedComment = {
      id: _get(edittingComment, 'orderId', ''),
      private_comment: _get(values, 'comment', ''),
    };
    dispatch(updateOrder(updatedComment));
    toggleComment();
  };

  const handleUpdateDeliveryMen = (orderId, deliveryMen) => {
    setEdittingDeliveryMen({
      orderId: orderId,
      deliveryMenId: deliveryMen.id,
      name: deliveryMen.name,
    });
    setIsEdit(true);
    toggle();
  };

  const addDeliveryMen = orderId => {
    setEdittingDeliveryMen({
      orderId: orderId,
    });
    setIsEdit(false);
    toggle();
  };

  const customProduct = prod => {
    if (!prod) return '';
    const unitWeight = isEmpty(_get(prod, ['info', 'unit_weight'], ''))
      ? ''
      : _get(prod, ['info', 'unit_weight'], '');
    return `${_get(prod, 'amount', '')} ${_get(prod, 'title', '')}  ${unitWeight}`;
  };

  const customProductCode = prod => {
    if (!prod) return '';
    const unitWeight = isEmpty(_get(prod, ['info', 'unit_weight'], ''))
        ? ''
        : _get(prod, ['info', 'unit_weight'], '');
    return `${_get(prod, 'amount', '')} ${_get(prod, 'item_code', '')}  ${unitWeight}`;
  };

  const ordersColumns = [
    {
      dataField: 'brand_name',
      text: translator(`clients`),
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className='mb-0'>{_get(row, ['user', 'brand_name'], '')}</p>
        </>
      ),
    },
    {
      dataField: 'products',
      text: translator('orders'),
      sort: false,
      classes: 'customTd',
      formatter: (cellContent, row) => {
        const products = _get(row, 'products', []);
        const arrProducts = Object.values(products);
        const countArrProducts = arrProducts.length;
        if (countArrProducts < maxProduct) {
          for (let i = 0; i < maxProduct - countArrProducts; i++) {
            arrProducts.push({
              id: 'prod' + i,
            });
          }
        }

        const arrCustomCodeProducts = [];
        const arrCustomProducts = [];
        arrProducts.map((arr) => {
          arrCustomCodeProducts.push(customProductCode(arr));
          arrCustomProducts.push(customProduct(arr));
        });

        return (
          <div>
            {
              <TooltipComponent
                id={`product-column-${_get(row, 'id', '')}-${_get(
                    cellContent,
                    'id',
                    ''
                ).toString()}`}
                content={<p className='mt-1 ms-2 break-line'>{arrCustomProducts.join(' / ').replace(/^[/\s]+|[/\s]+$/g, '')}</p>}
              >
                <EllipseSpanCustom>{arrCustomCodeProducts.join(' / ').replace(/^[/\s]+|[/\s]+$/g, '')}</EllipseSpanCustom>
              </TooltipComponent>
            }
          </div>
        );
      },
    },
    {
      dataField: 'comment',
      text: translator('comments'),
      sort: false,
      classes: 'customTd',
      formatter: (cellContent, row) => {
        const privateComment = _get(row, 'private_comment', '');
        const orderId = _get(row, 'id', '');
        return (
          <>
            <div className='simple-mode'>
              <div className='comment-simple' key={`comment-${_get(row, 'id', '')}`}>
                {
                  <TooltipComponent
                      id={`comment-column-${_get(row, 'id', '')}-${_get(
                          cellContent,
                          'id',
                          ''
                      ).toString()}`}
                      content={<p className='mt-1 ms-2'>{_get(row, 'comment', '')}</p>}
                  >
                    <EllipseSpan>{_get(row, 'comment', '')}</EllipseSpan>
                  </TooltipComponent>
                }
              </div>
              <div className='comment-simple ms-2 d-flex justify-content-between align-items-center' key={`privateComment-${_get(row, 'id', '')}`}>
                {
                  <TooltipComponent
                    id={`privateComment-column-${_get(row, 'id', '')}-${_get(
                      cellContent,
                      'id',
                      ''
                    ).toString()}`}
                    content={<p className='mt-1 ms-2'>{_get(row, 'private_comment', '')}</p>}
                  >
                    <EllipseSpan>{_get(row, 'private_comment', '')}</EllipseSpan>
                  </TooltipComponent>
                }
                {(isSuperior || isPrivateComment) &&
                  <i
                    className='fas fa-pencil-alt text-success me-1'
                    onClick={() => handleUpdateComment(orderId, privateComment)}
                  />
                }
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: 'delivery_men',
      text: translator('delivery men'),
      sort: false,
      classes: 'customColumn',
      formatter: (cellContent, row) => {
        const deliveryMenName = _get(row, ['delivery_men', 'name'], '');
        const deliveryMen = _get(row, ['delivery_men'], '');
        const orderId = _get(row, 'id', '');
        return (
          <>
            <div className='delivery'>
              <p className='mb-0'>{deliveryMenName}</p>
              {(isSuperior || isAssignDeliveryPermission) &&
                (isEmpty(deliveryMenName) ? (
                  <i
                    className='fas fa-plus text-success me-1 fa-lg'
                    onClick={() => addDeliveryMen(orderId)}
                  />
                ) : (
                  <i
                    className='fas fa-pencil-alt text-success me-1'
                    onClick={() => handleUpdateDeliveryMen(orderId, deliveryMen)}
                  />
                ))}
            </div>
          </>
        );
      },
    },
  ];

  let res = [];

  Object.entries(productsSum).forEach(item => {
    const pros = item[1];
    const arrCustomPros = [];
    pros.map((arr) => {
      arrCustomPros.push(customProductCode(arr));
    });
    const obj = {
      'category': item[0],
      'products': arrCustomPros.join(' / ').replace(/^[/\s]+|[/\s]+$/g, '')
    };
    res.push(obj);
  });

  const productsColumn = [
    {
      dataField: 'category',
      text: 'Catégorie'
    },
    {
      dataField: 'products',
      text: 'Des produits',
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className='mb-0 break-line'>{_get(row, 'products', '')}</p>
        </>
      ),
    }
  ];

  // eslint-disable-next-line react/prop-types
  const CustomInputDate = forwardRef(({ value, onClick }, ref) => (
    <a className='datePicker' onClick={onClick} ref={ref}>
      {moment(new Date(value)).format('DD/MM/YYYY')}
    </a>
  ));

  const onSubmit = (e, values) => {
    handleValidDeliveryMenSubmit(e, values);
  };

  const handleValidDeliveryMenSubmit = (e, values) => {
    const newDeliveryMen = {
      orderId: _get(edittingDeliveryMen, 'orderId', ''),
      deliveryMenId: _get(values, ['deliveryMen', 'id'], ''),
    };
    dispatch(updateDeliveryMen(newDeliveryMen));
    toggle();
  };

  const printIframe = id => {
    const iframe = document.frames ? document.frames[id] : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };

  const printOrderRef = useRef(null);

  const printPdf = () => {
    document.title = `commandes_app_diamant_${date.replace(/(\d{4})(\d{2})(\d{2})/g, '$3/$2/$1')}`;
    printIframe('print-orders');
    document.title = `${translator('orders')} | Le diamant du terroir`;
  };

  const loadData = () => {
    if (!printOrderRef.current) return;
    printOrderRef.current.contentWindow.postMessage({
      productsSum,
      maxProduct,
      newOrders,
      maxProductsRow,
      date,
      pathName,
      name
    });
  };

  useEffect(() => {
    loadData();
  }, [orders, newOrders]);

  return (
    <>
      <iframe
        id='print-orders'
        ref={printOrderRef}
        onLoad={loadData}
        src={`/print/by-date/${date}`}
        style={{ display: 'none' }}
        title={translator('print order')}
      />
      <Modal isOpen={modal} toggle={toggle} scrollable={true}>
        <ModalHeader toggle={toggle} tag='h4'>
          {translator(isEdit ? 'edit' : 'add') + ' ' + translator('delivery men')}
        </ModalHeader>
        <ModalBody>
          <DeliveryMenForm
            deliveryMen={isEdit ? edittingDeliveryMen : {}}
            action={isEdit ? DELIVERY_ACTIONS.UPDATE : DELIVERY_ACTIONS.CREATE}
            handleSubmit={onSubmit}
            deliveryStaffs={deliveryStaffs}
          />
        </ModalBody>
      </Modal>
      <Modal isOpen={modalComment} toggle={toggleComment} scrollable={true}>
        <ModalHeader toggle={toggleComment} tag='h4'>
          {translator('edit bill')}
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidComment}>
            <Container>
              <Row>
                <Col>
                  <AvField
                    name='comment'
                    label={translator('comment')}
                    value={edittingComment.privateComment || ''}
                  />
                </Col>
              </Row>
            </Container>
            <br />
            <Row>
              <Col>
                <div className='text-end'>
                  <button type='submit' className='btn btn-success save-customer'>
                    {translator('submit')}
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
      <AvForm>
        <div className='container-form'>
          <div className='delivery-date'>
            <p className='text-title'>{translator('delivery date')}: </p>
            <DatePicker
              selected={selectedDate}
              onChange={values => {
                handleSelectedDate(values);
              }}
              onCalendarClose={handleCalendarClose}
              customInput={<CustomInputDate />}
            />
          </div>
          <div>
            <p className='status-title'>
              {translator('status')}: {translator('new order')}
            </p>
          </div>
          <div>
            <Button
              color='info'
              outline
              className='me-2'
              disabled={isEmpty(orders)}
              onClick={printPdf}
            >
              {translator('print pdf')}
            </Button>
            {((isSuperior || isFullModePermission) && pathName === '/orders') && (
              <Button
                color='info'
                outline
                onClick={() => {
                  clearInterval(simpleTimer);
                  toggleDisplayMode();
                }}
              >
                {translator('switch to full mode')}
              </Button>
            )}
          </div>
        </div>
        {!isEmpty(orders) ? (
          <Row>
            <Col className='col-12'>
              <Card>
                <div className='table-responsive'>
                  <BootstrapTable
                    responsive
                    bordered={true}
                    striped={false}
                    defaultSorted={defaultSorted}
                    classes={'table align-middle table-nowrap table-check'}
                    headerWrapperClasses={'table-light'}
                    keyField='id'
                    pagination={paginationFactory(pageOptionsOrders)}
                    data={newOrders || []}
                    bar
                    columns={ordersColumns}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        ) : (
          <EmpyDataForm />
        )}
        {!isEmpty(categoriesLabel) && (
          <Row>
            <Col className='col-12'>
              <Card>
                <div className='table-responsive'>
                  <BootstrapTable
                    responsive
                    bordered={true}
                    striped={false}
                    classes={'table align-middle table-nowrap table-check'}
                    headerWrapperClasses={'table-light'}
                    keyField='id'
                    data={res || []}
                    columns={productsColumn}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        )}
      </AvForm>
    </>
  );
};

export const SimpleTable = withTranslation()(Table);

Table.propTypes = {
  handleSubmit: PropTypes.func,
  type: PropTypes.string,
  t: PropTypes.any,
};
