export const CATEGORY_ACTIONS = {
  UPDATE: 'UPDATE_CATEGORY',
  CREATE: 'CREATE_CATEGORY',
  CREATE_SUB: 'CREATE_SUB_CATEGORY'
};

export const ACTION_LABELS = {
  [CATEGORY_ACTIONS.UPDATE]: 'update',
  [CATEGORY_ACTIONS.CREATE]: 'create',
  [CATEGORY_ACTIONS.CREATE_SUB]: 'create',
};
