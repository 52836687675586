export const PERMISSIONS = {
  //ORDER MANAGEMENT
  ORDER_MANAGEMENT: {
    ACCESS_ORDER_SIMP: 'Access to order management (simple mode)',
    ASSIGN_DELIVERY_SIMP: 'Designate a delivery person to the order',
    LEAVE_COMMENT_SIMP: 'Leave a comment on an order simp',
    ACCESS_ORDER_FULL: 'Access to order management (full mode)',
    ASSIGN_DELIVERY: 'Assign a delivery person to the order',
    LEAVE_COMMENT: 'Leave a comment on an order',
    MODIFY_DELETE_ORDER: 'Modify / Delete an order',
    MODIFY_DELETE_PRODUCT_ORDER: 'Modify / Delete the products of an order',
    ADD_ORDER: 'Add a new order',
    ADD_PRODUCT_ORDER: 'Add a product to an order',
    CHANGE_STATUS_ORDER: 'Change the status of an order',
    EXPORT_CSV: 'Export orders (CSV format)',
    VIEW_HISTORY_ACTIONS: 'View the history of actions'
  },

  //CUSTOMER MANAGEMENT
  CUSTOMER_MANAGEMENT: {
    CREATE_CUSTOMER: 'Create a new customer account',
    MODIFY_DELETE_CUSTOMER: 'Modify / delete an existing customer account',
    CHANGE_STATUS_CUSTOMER: 'Change the status of a customer account',
    CREATE_CUSTOMER_GROUP: 'Create a new customer group',
    MODIFY_DELETE_CUSTOMER_GROUP: 'Modify / delete an existing customer group',
    ADD_REMOVE_FAVOURITES_CUSTOMER: 'Add / remove favorites to the customer'
  },

  //PRODUCT MANAGEMENT
  PRODUCT_MANAGEMENT: {
    CREATE_PRODUCT_CATEGORIES: 'Create product categories',
    EDIT_DELETE_CATEGORIES: 'Edit / delete product categories',
    CREATE_PRODUCTS: 'Create products',
    EDIT_DELETE_PRODUCTS: 'Edit / delete products'
  },

  //MANAGEMENT OF MESSAGES AND NOTIFICATIONS
  MESSAGES_NOTIFICATIONS: 'Send messages / push notifications to the customer',

  //ACCESS TO THE MANAGEMENT INTERFACE
  ACCESS_MANAGEMENT_INTERFACE: 'ACCESS TO THE MANAGEMENT INTERFACE',
};


