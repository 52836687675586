/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import { Spin } from 'antd';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { PushMessageForm } from 'components/Common/PushMessagesForm';
import { MessagesForm } from 'components/Common/PushMessagesForm/editMessageForm';
import { EllipseSpan } from 'components/Common/StyledComponents/Span';
import { TooltipComponent } from 'components/Common/Tooltip';
import { showNotification } from 'constants/messages';
import { get as _get, isEmpty } from 'lodash';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { getMessageHistory, pushMessage, updateMessage } from 'store/pushMessages/actions';
import { SIZEPERPAGE } from 'constants/utils';

const MessagesList = props => {
  const dispatch = useDispatch();

  const [confirmation, setConfirmation] = useState(false);
  const [message, setMessage] = useState({});
  const [edittingMessage, setEdittingMessage] = useState({});
  const [isCreatingMessage, setIsCreatingMessage] = useState(true);
  const [isEditting, setIsEditting] = useState(false);

  const { t: translator } = props;

  const { errors, isSuccess, isProcessing, messageHistory } = useSelector(state => {
    return {
      errors: _get(state, ['messages', 'errors'], ''),
      isSuccess: _get(state, ['messages', 'isSuccess'], ''),
      isProcessing: _get(state, ['messages', 'isProcessing'], false),
      messageHistory: _get(state, ['messages', 'messageHistory'], []),
    };
  });

  const handleValidDate = date => {
    return moment(new Date(date)).format('DD/MM/YYYY');
  };

  useEffect(() => {
    dispatch(getMessageHistory());
  }, []);

  const compareDate = useCallback((prev, next, keyField) => {
    const prevTime = new Date(_get(prev, keyField, ''));
    const nextTime = new Date(_get(next, keyField, ''));
    return +prevTime - +nextTime;
  }, []);

  const ListMessagesColumns = [
    {
      dataField: 'start_date',
      text: translator('start date'),
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className='mb-0'>{handleValidDate(_get(row, 'start_date', ''))}</p>
        </>
      ),
    },
    {
      dataField: 'end_date',
      text: translator('end date'),
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className='mb-0'>{handleValidDate(_get(row, 'end_date', ''))}</p>
        </>
      ),
    },
    {
      dataField: 'admin_name',
      text: translator('sender'),
      sort: true,
    },
    {
      dataField: 'title',
      text: translator('title'),
      sort: true,
    },
    {
      dataField: 'content',
      text: translator('content'),
      formatter: (cellContent, row) => {
        const contentMessage = _get(row, 'content', '');
        return (
          <TooltipComponent
            id={_get(row, 'id', '').toString()}
            tag={translator('content')}
            content={contentMessage}
          >
            <EllipseSpan>{contentMessage}</EllipseSpan>
          </TooltipComponent>
        );
      },
    },
    {
      dataField: 'images',
      text: translator('Recipients'),
      formatter: (cellContent, row) => {
        const recipients = row.group_ids ?
          _get(row, 'group_names', '').join(', ') :
          _get(row, 'user_names', '').join(', ');
        return (
          <TooltipComponent
            id={_get(row, 'id', '').toString()}
            tag={translator('Recipients')}
            content={recipients}
          >
            <EllipseSpan>{recipients}</EllipseSpan>
          </TooltipComponent>
        );
      }
    },
    {
      dataField: 'receiver',
      text: translator('customers'),
      sort: true,
      formatter: (cellContent, row) => {
        const userReceivers = _get(row, 'user_ids', []);
        return <p className='mb-0'>{userReceivers.length}</p>;
      },
    },
    {
      dataField: 'menu',
      text: translator('action'),
      isDummyField: true,
      formatter: (text, record) => (
        <UncontrolledDropdown direction='left'>
          <DropdownToggle href='#' className='card-drop' tag='i'>
            <i className='mdi mdi-dots-horizontal font-size-18' />
          </DropdownToggle>
          <DropdownMenu className='dropdown-menu-end'>
            <DropdownItem onClick={() => {
              setEdittingMessage(record);
              setIsEditting(true);
            }}>
              <i className='fas fa-pencil-alt text-success me-1' />
              {translator('edit')}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const [pageOptionsListMessages, setpageOptionsListMessages] = useState({
    sizePerPage: SIZEPERPAGE,
    totalSize: !isEmpty(messageHistory) ? messageHistory.length : 0,
    hideSizePerPage: true,
  });

  useEffect(() => {
    if (!isEmpty(messageHistory))
      setpageOptionsListMessages({
        sizePerPage: SIZEPERPAGE,
        hideSizePerPage: true,
      });
  }, [messageHistory]);

  const handleValidMessageSubmit = value => {
    setIsCreatingMessage(true);
    setMessage(value);
    setConfirmation(true);
  };

  const handleSubmit = () => {
    setConfirmation(false);
    return !isProcessing && isCreatingMessage ? dispatch(pushMessage(message)) : dispatch(updateMessage(edittingMessage));
  };
  
  const toggle = () => {
    setIsEditting(!isEditting);
  };

  const handleValidUpdateMessage = value => {
    setIsCreatingMessage(false);
    setIsEditting(false);
    setEdittingMessage(value);
    setConfirmation(true);
  };

  useEffect(() => {
    if (!isEmpty(errors)) showNotification(errors);
    if (isSuccess) showNotification('');
  }, [errors, isSuccess]);

  useEffect(() => {
    // dispatch(getMessagesHistoty());
  }, []);

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc',
    },
  ];

  return (
    <Spin tip={translator('loading') + '...'} spinning={isProcessing}>
      <React.Fragment>
        <div className='page-content'>
          <MetaTags>
            <title>Messages | Le diamant du terroir</title>
          </MetaTags>
          {confirmation && (
            <SweetAlert
              title='Are you sure?'
              warning
              showCancel
              confirmBtnBsStyle='success'
              cancelBtnBsStyle='danger'
              onConfirm={() => {
                handleSubmit();
              }}
              onCancel={() => {
                setConfirmation(false);
              }}
            />
          )}
          <Container fluid>
            <Breadcrumbs title='manage' breadcrumbItem='messages' />
            <Row>
              <Col xs='12'>
                <Card>
                  <CardBody>
                    <React.Fragment>
                      <Row className='mb-2'>
                        <Col sm='4'>
                          <div className='search-box ms-2 mb-2 d-inline-block'>
                            <div className='position-relative'></div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl='12'>
                          <PushMessageForm handleSubmit={handleValidMessageSubmit} />
                        </Col>
                      </Row>
                    </React.Fragment>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {!isEmpty(messageHistory) && (
              <Row>
                <Card>
                  <CardBody>
                    <CardTitle tag='h5'>List Messages</CardTitle>
                    <div className='table-responsive'>
                      <BootstrapTable
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        classes={'table align-middle table-nowrap table-check'}
                        headerWrapperClasses={'table-light'}
                        keyField='id'
                        pagination={paginationFactory(pageOptionsListMessages)}
                        data={messageHistory}
                        columns={ListMessagesColumns}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Row>
            )}
          </Container>
          <Modal style={{maxWidth: '50%', width: '100%'}} isOpen={isEditting} toggle={toggle} scrollable={true}>
            <ModalHeader toggle={toggle} tag='h4'>
              {props.t('edit')}
            </ModalHeader>
            <ModalBody>
              <MessagesForm
                message={edittingMessage}
                handleSubmit={handleValidUpdateMessage}
              />
            </ModalBody>
          </Modal>
        </div>
      </React.Fragment>
    </Spin>
  );
};

MessagesList.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(MessagesList);
