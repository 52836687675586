export const GROUP_ACTIONS = {
  UPDATE: 'UPDATE_GROUP',
  CREATE: 'CREATE_GROUP',
  SELECT_CATEGORIES: 'SELECT_CATEGORIES',
  CREATE_SUB: 'CREATE_SUB_GROUP'
};

export const ACTION_LABELS = {
  [GROUP_ACTIONS.UPDATE]: 'update',
  [GROUP_ACTIONS.CREATE]: 'create',
  [GROUP_ACTIONS.CREATE_SUB]: 'create',
};
