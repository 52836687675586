import { post } from '../helpers/apiHelper';
import { API_URL } from '../constants/api';

const login = async (credential) => {
  const { username , password } = credential;

  return await post({
    url: API_URL.authentication.login,
    data: {},
    config:
    {
      params: {
        username,
        password
      }
    }
  });
};

export const authenticationService = {
  login,
};
