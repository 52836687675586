/* eslint-disable react/display-name */
import { Table } from 'antd';
import { EllipseSpan } from 'components/Common/StyledComponents/Span';
import { TooltipComponent } from 'components/Common/Tooltip';
import { SUPERIOR_ADMIN_ROLE } from 'constants/index';
import { OrderContext } from 'contexts/orderContext';
import { get as _get, isEmpty, intersection, difference, isEqual } from 'lodash';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import {
  getAllOrdersByStatus,
  exportMultipleOrdersCsv,
  getOrderByDateRange,
} from 'store/orders/actions';
import { PERMISSIONS } from 'utils/permissions';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import EmpyDataForm from '../../components/Common/EmptyDataForm';
import DatePicker from 'react-datepicker';
import { SELECTIVE_TAB, STATUS, REFRESH_DURATION } from './constants';
import toastr from 'toastr';

const onClickAction = 'onclick-action';
let fullboardTimer = null;

const FullModeOrderBoard = props => {
  const { toggleDisplayMode } = useContext(OrderContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const { t: translator } = props;

  const { orders, myPermissions, role, totalOrders, currentPage, isFilteringByDateRange, range } =
    useSelector(state => {
      return {
        orders: _get(state, ['orders', 'orders'], []),
        myPermissions: _get(state, ['permissions', 'myPermissions'], []),
        role: _get(state, ['Profile', 'profile', 'role'], undefined),
        totalOrders: _get(state, ['orders', 'totalOrders'], 1),
        currentPage: _get(state, ['orders', 'currentPage'], 1),
        isFilteringByDateRange: _get(state, ['orders', 'isFilteringByDateRange'], false),
        range: _get(state, ['orders', 'range'], false),
      };
    });

  const [isFiltering, setIsFiltering] = useState(isFilteringByDateRange);

  const clearTimerInterval = useCallback(() => {
    clearInterval(fullboardTimer);
  }, []);

  const setupTimerInterval = useCallback(() => {
    dispatch(getAllOrdersByStatus(1));
    fullboardTimer = setInterval(() => {
      dispatch(getAllOrdersByStatus(currentPage));
    }, REFRESH_DURATION);
  }, []);

  useEffect(() => {
    clearTimerInterval();
    if (isFilteringByDateRange) {
      dispatch(getOrderByDateRange({ ...range, currentPage }));
      setStartFilterDate(range.start);
      setEndFilterDate(range.end);
    } else {
      dispatch(getAllOrdersByStatus(1));
      fullboardTimer = setInterval(() => {
        dispatch(getAllOrdersByStatus(currentPage));
      }, REFRESH_DURATION);
    }
    return function () {
      clearTimerInterval();
    };
  }, []);
  const pagination = useMemo(
    () => ({
      current: currentPage,
      defaultCurrent: 0,
      pageSize: 30,
      total: totalOrders,
      onChange: page => {
        dispatch(getAllOrdersByStatus(page));
      },
    }),
    [orders, totalOrders, currentPage]
  );

  const [isSuperior, setIsSuperior] = useState(false);
  useEffect(() => {
    setIsSuperior(role === SUPERIOR_ADMIN_ROLE);
  }, [role]);

  const [isSimpModePermission, setIsSimpModePermission] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [startFilterDate, setStartFilterDate] = useState(new Date());
  const [endFilterDate, setEndFilterDate] = useState(null);

  useEffect(() => {
    if (isEmpty(myPermissions)) return;
    const arr = [];
    myPermissions.forEach(permission => {
      const permissionName = _get(permission, 'name', '');
      arr.push(permissionName);
    });
    setIsSimpModePermission(arr.includes(PERMISSIONS.ORDER_MANAGEMENT.ACCESS_ORDER_SIMP));
  }, [myPermissions]);

  const compareString = useCallback((prev, next, selector) => {
    return _get(prev, [...selector], '')
      .toString()
      .toLowerCase()
      .localeCompare(
        _get(next, [...selector], '')
          .toString()
          .toLowerCase()
      );
  }, []);

  const compareDate = useCallback((prev, next, keyField) => {
    const prevTime = new Date(_get(prev, keyField, ''));
    const nextTime = new Date(_get(next, keyField, ''));
    return +prevTime - +nextTime;
  }, []);

  const compareStatus = useCallback((prev, next) => {
    const prevStat = translator(STATUS[_get(prev, 'status', -1)]);
    const nextStat = translator(STATUS[_get(next, 'status', -1)]);
    return prevStat.toLowerCase().localeCompare(nextStat.toLowerCase());
  }, []);

  const ordersColumns = [
    {
      dataIndex: 'code',
      title: translator('order number'),
      sorter: (prev, next) => compareString(prev, next, ['code']),
    },
    {
      dataIndex: 'user_name',
      title: translator(`customer's name`),
      sorter: (prev, next) => compareString(prev, next, ['user', 'user_name']),
      render: (cellContent, row) => (
        <>
          <p className='mb-0'>{_get(row, ['user', 'brand_name'], '')}</p>
        </>
      ),
    },
    {
      dataIndex: 'deliveryDate',
      title: translator('delivery date'),
      sorter: (prev, next) => compareDate(prev, next, 'date_of_delivery'),
      render: (cellContent, row) => (
        <>
          <p className='mb-0'>{handleValidDate(_get(row, 'date_of_delivery', ''))}</p>
        </>
      ),
    },
    {
      dataIndex: 'createdDate',
      title: translator('created date'),
      sorter: (prev, next) => compareDate(prev, next, 'created_at'),
      render: (cellContent, row) => (
        <>
          <p className='mb-0'>{handleValidDate(_get(row, 'created_at', ''))}</p>
        </>
      ),
    },
    {
      dataIndex: 'products',
      title: translator('product'),
      render: (cellContent, row) => {
        const products = _get(row, 'products', []);
        const arrProducts = Object.values(products);
        const product = arrProducts.reduce((accumulator, pro) => {
          const unitWeight = _get(pro.info, 'unit_weight', '');
          return accumulator + ', ' + pro.amount + ' ' + pro.title + ' ' + unitWeight;
        }, '');
        const tooltipContent = (
          <ul>
            {arrProducts.map(arr => (
              <li key={`order_product` + _get(arr, 'id', '')}>
                {_get(arr, 'amount', '') +
                  ' ' +
                  _get(arr, 'title', '') +
                  ' ' +
                  _get(arr, ['info', 'unit_weight'], '')}
              </li>
            ))}
          </ul>
        );
        return (
          <TooltipComponent
            id={_get(row, 'id', '').toString()}
            tag={translator('order')}
            content={tooltipContent}
          >
            <EllipseSpan>{product.substring(1)}</EllipseSpan>
          </TooltipComponent>
        );
      },
    },
    {
      dataIndex: 'status',
      title: translator('status'),
      sorter: compareStatus,
      render: (cellContent, row) => (
        <>
          <p className='mb-0'>
            {row.status === 1
              ? translator(SELECTIVE_TAB.NEW_ORDER)
              : row.status === 2
              ? translator(SELECTIVE_TAB.PREPARATION)
              : row.status === 3
              ? translator(SELECTIVE_TAB.DELAYED)
              : row.status === 4
              ? translator(SELECTIVE_TAB.PROCESSED)
              : ''}
          </p>
        </>
      ),
    },
  ];

  const currentIds = useMemo(() => {
    return orders.reduce((acc, order) => {
      return [...acc, order.id];
    }, []);
  }, [orders]);

  useEffect(() => {
    return isSelectedAll ? setSelectedRecords([...selectedRecords, ...currentIds]) : '';
  }, [currentIds]);

  const onSelectedRowKeysChange = selectedRowKeys => {
    const excludedIds = difference(selectedRecords, currentIds);
    setSelectedRecords([...excludedIds, ...selectedRowKeys]);
    setIsSelectedAll(isEqual(selectedRowKeys, currentIds));
  };

  const rowSelection = {
    selectedRowKeys: intersection(selectedRecords, currentIds),
    onChange: onSelectedRowKeysChange,
  };

  const handleValidDate = date => {
    return moment(new Date(date)).format('DD/MM/YYYY');
  };

  const onFilterDateChange = dates => {
    clearTimerInterval();
    const [start, end] = dates;
    setStartFilterDate(start);
    setEndFilterDate(end);
    setIsFiltering(true);
    dispatch(getOrderByDateRange({ start, end }));
  };

  const onViewAllHandler = useCallback(() => {
    if (isFiltering) return;
    setIsFiltering(false);
    setupTimerInterval();
    setStartFilterDate(new Date());
    setEndFilterDate(null);
    setSelectedRecords([]);
  }, []);

  return (
    <Container fluid>
      <Breadcrumbs title={translator('orders')} breadcrumbItem='full mode' />
      <div className='checkout-tabs'>
        <Row>
          <Col xl='12' sm='9'>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Row className='mb-2'>
                    <Col>
                      <Row>
                        <Col className='col-auto'>
                          <Button
                            onClick={onViewAllHandler}
                          >
                            {translator('view all')}
                          </Button>
                        </Col>
                        <Col className='col-auto zIndex--medium'>
                          <DatePicker
                            selected={startFilterDate}
                            onChange={onFilterDateChange}
                            startDate={startFilterDate}
                            endDate={endFilterDate}
                            selectsRange
                            customInput={<Button>{translator('view by date')}</Button>}
                          />
                        </Col>
                        <Col className='col-auto'>
                          <Button
                            onClick={() => {
                              if(isEmpty(selectedRecords)) return toastr.error('Aucune commande sélectionnée !', 'Error');
                              dispatch(
                                exportMultipleOrdersCsv({
                                  selectedRecords,
                                  isFilteringByDateRange,
                                  range: {
                                    start: startFilterDate,
                                    end: endFilterDate,
                                  },
                                })
                              );
                            }}
                          >
                            {translator('export csv')}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    {(isSuperior || isSimpModePermission) && (
                      <Col className='col-8 text-end'>
                        <Button
                          color='info'
                          outline
                          onClick={() => {
                            clearInterval(fullboardTimer);
                            toggleDisplayMode();
                          }}
                        >
                          {translator('switch to simple mode')}
                        </Button>
                      </Col>
                    )}
                  </Row>
                  {!isEmpty(orders) ? (
                    <Row>
                      <Col xl='12'>
                        <div className='table-responsive'>
                          <Table
                            rowSelection={rowSelection}
                            sticky={true}
                            rowKey='id'
                            pagination={pagination}
                            dataSource={orders || []}
                            columns={ordersColumns}
                            onRow={record => ({
                              onClick: e => {
                                const action = e.target.getAttribute('action');
                                if (action !== onClickAction) {
                                  history.push(`/order/${record.id}`);
                                }
                              },
                            })}
                          />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <EmpyDataForm />
                  )}
                </React.Fragment>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

FullModeOrderBoard.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(FullModeOrderBoard));
