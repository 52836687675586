import {
  ADD_NEW_ORDER,
  ADD_ORDER_FAIL,
  ADD_ORDER_SELF_PRODUCTS,
  ADD_ORDER_SELF_PRODUCTS_FAIL,
  ADD_ORDER_SELF_PRODUCTS_SUCCESS,
  ADD_ORDER_SUCCESS,
  DELETE_ORDER,
  DELETE_ORDER_FAIL,
  DELETE_ORDER_SELF_PRODUCTS,
  DELETE_ORDER_SELF_PRODUCTS_FAIL,
  DELETE_ORDER_SELF_PRODUCTS_SUCCESS,
  DELETE_ORDER_SUCCESS,
  GET_ALL_ORDERS_BY_STATUS,
  GET_ALL_ORDERS_BY_STATUS_FAIL,
  GET_ALL_ORDERS_BY_STATUS_SUCCESS,
  GET_DELIVERY_STAFFS,
  GET_ORDERS_BY_STATUS,
  GET_ORDERS_BY_STATUS_FAIL,
  GET_ORDERS_BY_STATUS_SUCCESS,
  GET_ORDER_BY_DATE,
  GET_ORDER_BY_DATE_FAIL,
  GET_ORDER_BY_DATE_SUCCESS,
  GET_ORDER_BY_DATERANGE,
  GET_ORDER_BY_DATERANGE_FAIL,
  GET_ORDER_BY_DATERANGE_SUCCESS,
  GET_ORDER_DETAIL,
  GET_ORDER_DETAIL_FAIL,
  GET_ORDER_DETAIL_SUCCESS,
  GET_ORDER_HISTORY,
  GET_ORDER_HISTORY_FAIL,
  GET_ORDER_HISTORY_SUCCESS,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_SELF_PRODUCTS,
  UPDATE_ORDER_SELF_PRODUCTS_FAIL,
  UPDATE_ORDER_SELF_PRODUCTS_SUCCESS,
  UPDATE_ORDER_STATUS,
  UPDATE_ORDER_STATUS_FAIL,
  UPDATE_ORDER_STATUS_SUCCESS,
  GET_DELIVERY_STAFFS_FAIL,
  GET_DELIVERY_STAFFS_SUCCESS,
  UPDATE_DELIVERY_MEN,
  UPDATE_DELIVERY_MEN_FAIL,
  UPDATE_DELIVERY_MEN_SUCCESS,
  EXPORT_CSV,
  EXPORT_CSV_SUCCESS,
  EXPORT_CSV_FAIL,
  EXPORT_MULTIPLE_ORDERS_CSV_SUCCESS,
  EXPORT_MULTIPLE_ORDERS_CSV_FAIL,
  EXPORT_MULTIPLE_ORDERS_CSV,
  RESET_ERROR_STATUS,
  SWITCH_TABLE
} from './actionTypes';

export const switchTable = () => ({
  type: SWITCH_TABLE,
});

export const addSelfProduct = product => ({
  type: ADD_ORDER_SELF_PRODUCTS,
  payload: product
});

export const addSelfProductSuccess = order => ({
  type: ADD_ORDER_SELF_PRODUCTS_SUCCESS,
  payload: order
});

export const addSelfProductFail = errors => ({
  type: ADD_ORDER_SELF_PRODUCTS_FAIL,
  payload: errors
});

export const updateSelfProduct = order => ({
  type: UPDATE_ORDER_SELF_PRODUCTS,
  payload: order
});

export const updateSelfProductSuccess = order => ({
  type: UPDATE_ORDER_SELF_PRODUCTS_SUCCESS,
  payload: order
});

export const updateSelfProductFail = errors => ({
  type: UPDATE_ORDER_SELF_PRODUCTS_FAIL,
  payload: errors
});

export const deleteSelfProduct = order => ({
  type: DELETE_ORDER_SELF_PRODUCTS,
  payload: order
});

export const deleteSelfProductSuccess = order => ({
  type: DELETE_ORDER_SELF_PRODUCTS_SUCCESS,
  payload: order
});

export const deleteSelfProductFail = errors => ({
  type: DELETE_ORDER_SELF_PRODUCTS_FAIL,
  payload: errors
});

export const addNewOrder = order => ({
  type: ADD_NEW_ORDER,
  payload: order
});

export const addOrderSuccess = order => ({
  type: ADD_ORDER_SUCCESS,
  payload: order
});

export const addOrderFail = error => ({
  type: ADD_ORDER_FAIL,
  payload: error
});

export const updateOrder = order => ({
  type: UPDATE_ORDER,
  payload: order
});

export const updateOrderStatus = payload => ({
  type: UPDATE_ORDER_STATUS,
  payload
});

export const updateOrderSuccess = order => ({
  type: UPDATE_ORDER_SUCCESS,
  payload: order
});

export const updateOrderFail = error => ({
  type: UPDATE_ORDER_FAIL,
  payload: error
});

export const updateOrderStatusSuccess = order => ({
  type: UPDATE_ORDER_STATUS_SUCCESS,
  payload: order
});

export const updateOrderStatusFail = error => ({
  type: UPDATE_ORDER_STATUS_FAIL,
  payload: error
});

export const deleteOrder = order => {
  return {
    type: DELETE_ORDER,
    payload: order
  };
};

export const deleteOrderSuccess = id => ({
  type: DELETE_ORDER_SUCCESS,
  payload: id
});

export const deleteOrderFail = error => ({
  type: DELETE_ORDER_FAIL,
  payload: error
});

export const getOrdersByStatus = status => ({
  type: GET_ORDERS_BY_STATUS,
  payload: status
});

export const getOrdersByStatusSuccess = orders => ({
  type: GET_ORDERS_BY_STATUS_SUCCESS,
  payload: orders
});

export const getOrdersByStatusFail = error => ({
  type: GET_ORDERS_BY_STATUS_FAIL,
  payload: error
});

export const getAllOrdersByStatus = page => ({
  type: GET_ALL_ORDERS_BY_STATUS,
  payload: page
});

export const getAllOrdersByStatusSuccess = orders => ({
  type: GET_ALL_ORDERS_BY_STATUS_SUCCESS,
  payload: orders
});

export const getAllOrdersByStatusFail = error => ({
  type: GET_ALL_ORDERS_BY_STATUS_FAIL,
  payload: error
});

export const getOrderById = orderId => ({
  type: GET_ORDER_DETAIL,
  payload: orderId
});

export const getOrderByIdSuccess = order => ({
  type: GET_ORDER_DETAIL_SUCCESS,
  payload: order
});

export const getOrderByIdFail = error => ({
  type: GET_ORDER_DETAIL_FAIL,
  payload: error
});

export const getOrderByDate = orders => ({
  type: GET_ORDER_BY_DATE,
  payload: orders
});

export const getOderByDateSuccess = orders => ({
  type: GET_ORDER_BY_DATE_SUCCESS,
  payload: orders
});

export const getOrderByDateFail = error => ({
  type: GET_ORDER_BY_DATE_FAIL,
  payload: error
});

export const getOrderByDateRange = range => ({
  type: GET_ORDER_BY_DATERANGE,
  payload: range
});

export const getOrderByDateRangeSuccess = orders => ({
  type: GET_ORDER_BY_DATERANGE_SUCCESS,
  payload: orders
});

export const getOrderByDateRangeFail = error => ({
  type: GET_ORDER_BY_DATERANGE_FAIL,
  payload: error
});

export const getOrderHistory = order => ({
  type: GET_ORDER_HISTORY,
  payload: order
});

export const getOrderHistorySuccess = order => ({
  type: GET_ORDER_HISTORY_SUCCESS,
  payload: order
});

export const getOrderHistoryFail = error => ({
  type: GET_ORDER_HISTORY_FAIL,
  payload: error
});

export const getDeliveryStaffs = deliveryStaffs => ({
  type: GET_DELIVERY_STAFFS,
  payload: deliveryStaffs
});

export const getDeliveryStaffsSuccess = deliveryStaffs => ({
  type: GET_DELIVERY_STAFFS_SUCCESS,
  payload: deliveryStaffs
});

export const getDeliveryStaffsFail = error => ({
  type: GET_DELIVERY_STAFFS_FAIL,
  payload: error
});

export const updateDeliveryMen = payload => ({
  type: UPDATE_DELIVERY_MEN,
  payload
});

export const updateDeliveryMenSuccess = order => ({
  type: UPDATE_DELIVERY_MEN_SUCCESS,
  payload: order
});

export const updateDeliveryMenFail = error => ({
  type: UPDATE_DELIVERY_MEN_FAIL,
  payload: error
});

export const exportCsv = orderId => ({
  type: EXPORT_CSV,
  payload: orderId
});

export const exportCsvSuccess = payload => ({
  type: EXPORT_CSV_SUCCESS,
  payload
});

export const exportCsvFail = error => ({
  type: EXPORT_CSV_FAIL,
  payload: error
});

export const resetErrorStatus = () => ({
  type: RESET_ERROR_STATUS
});

export const exportMultipleOrdersCsv = ordersId => ({
  type: EXPORT_MULTIPLE_ORDERS_CSV,
  payload: ordersId
});

export const exportMultipleOrdersCsvSuccess = payload => ({
  type: EXPORT_MULTIPLE_ORDERS_CSV_SUCCESS,
  payload
});

export const exportMultipleOrdersCsvFail = error => ({
  type: EXPORT_MULTIPLE_ORDERS_CSV_FAIL,
  payload: error
});
