/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { get as _get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect, forwardRef } from 'react';
import { Col, Container, Row, Input, Card, CardBody, CardTitle } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import Select from 'react-select';
import { getUserForMessage } from 'store/pushMessages/actions';
import { getProducts } from 'store/products/actions';
import { getCategories, getCategoriesWithoutSubs } from 'store/categories/actions';
import { MessageTargetOptions } from './constants';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { Table } from 'antd';
import * as moment from "moment";

const StyledInput = styled(Input)`
  width: 150px;
`;

const Form = props => {
  const { handleSubmit, message, t: translator } = props;

  const dispatch = useDispatch();

  //   const requiredErrorMessage = useMemo(() => `${translator('this field is required')}!`, []);

  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [titleCounter, setTitleCounter] = useState(0);
  const [contentCounter, setContentCounter] = useState(0);
  const [targetType, setTargetType] = useState({});
  const [selectedTargets, setSelectedTargets] = useState([]);
  const [isInit, setIsInit] = useState(true);

  const { products, categoriesWithoutSubs, userByMessages } = useSelector(state => {
    return {
      products: _get(state, ['products', 'products'], []),
      categoriesWithoutSubs: _get(state, ['categories', 'categoriesWithoutSubs'], []),
      userByMessages: _get(state,['messages','userByMessages'],[])
    };
  });

  useEffect(() => {
    dispatch(getUserForMessage(_get(message,'id','')));
  },[]);

  useEffect(() => {
    if (isEmpty(categoriesWithoutSubs)) {
      dispatch(getCategoriesWithoutSubs());
    }
    if (isEmpty(products)) {
      dispatch(getProducts());
    }
  }, []);

  useEffect(() => {
    const { product_id } = message;
    if (isInit && product_id !== null && !isEmpty(products)) {
      const prod = products.find(product => _get(product, 'id', '') === product_id) || {};
      setTargetType({ value: '1', label: 'product' });
      setSelectedTargets([{
        value: product_id,
        label: _get(prod, 'title', '')
      }]);
      setIsInit(false);
    }
  }, [products]);

  useEffect(() => {
    const { category_id } = message;
    if (isInit && category_id !== null && !isEmpty(categoriesWithoutSubs)) {
      const cat = categoriesWithoutSubs.find(category => _get(category, 'id', '') === category_id) || {};
      setTargetType({ value: '2', label: 'category' });
      setSelectedTargets([{ value: category_id, label: _get(cat, 'name', '') }]);
      setIsInit(false);
    }
  }, [categoriesWithoutSubs]);

  useEffect(() => {
    const { start_date, end_date, title, content } = message;
    setSelectedStartDate(new Date(start_date));
    setSelectedEndDate(new Date(end_date));
    setTitleCounter(title.length);
    setContentCounter(content.length);
  }, []);

  const getTargetOptions = () => {
    switch (targetType.value) {
      case '1':
        return products.map(item => ({
          value: _get(item, 'id', ''),
          label: _get(item, 'title', '')
        }));
      case '2':
        return categoriesWithoutSubs.map(item => ({
          value: _get(item, 'id', ''),
          label: _get(item, 'name', ''),
          isSub: item.type ? true : false
        }));
      default:
        return [];
    }
  };

  const onSubmit = (e, values) => {
    const targetVariableName = targetType.value === '1' ? 'productId' : 'categoryId';

    const startDate = format(selectedStartDate, 'yyyyMMdd');
    const endDate = format(selectedEndDate, 'yyyyMMdd');

    handleSubmit({
      ...values,
      id: _get(message, 'id', ''),
      startDate: !isEmpty(startDate) ? startDate : '',
      endDate: !isEmpty(endDate) ? endDate : '',
      [targetVariableName]: _get(selectedTargets, ['0', 'value'], '')
    });
  };

  const customStyleSelect = {
    menu: provided => ({
      ...provided,
      zIndex: 1,
      position: 'relative'
    }),
    option: (provided, state) => {
      return {
        ...provided,
        paddingLeft: state.data.isSub ? 30 : 15
      };
    }
  };

  // eslint-disable-next-line react/prop-types
  const CustomInputDateTime = forwardRef(({ value, onClick }, ref) => (
    <StyledInput onClick={onClick} ref={ref} defaultValue={moment(new Date(value)).format('DD/MM/YYYY')} />
  ));

  const UsersTableColumns = [
    {
      dataIndex: 'user_name',
      title: translator('username'),
      render: (cellContent, row) => <div className='column-content'>{row.user_name}</div>,
    },
    {
      dataIndex: 'brand_name',
      title: translator('brand name'),
      render: (cellContent, row) => <div className='column-content'>{row.brand_name}</div>,
    },
  ];

  return (
    <AvForm onValidSubmit={onSubmit}>
      <Container>
        <Row>
          <Col xs='12' sm='12'>
            <div className='mb-3'>
              <AvField
                value={message.title}
                name='title'
                label={translator('title')}
                type='text'
                errorMessage={translator('invalid title')}
                onChange={e => {
                  setTitleCounter(e.target.value.length);
                }}
                validate={{
                  required: { value: true },
                  maxLength: {
                    value: 50,
                    errorMessage: translator('title must be less than 50 characters')
                  }
                }}
                helpMessage={`${titleCounter}/50`}
              />
            </div>
            <Row>
              <Col className='col-6'>
                <h6>{translator('start date')}: </h6>
                <DatePicker
                  selected={selectedStartDate}
                  onChange={values => {
                    setSelectedStartDate(values);
                  }}
                  customInput={<CustomInputDateTime />}
                />
              </Col>
              <Col className='col-6'>
                <h6>{translator('end date')}: </h6>
                <DatePicker
                  selected={selectedEndDate}
                  onChange={values => {
                    setSelectedEndDate(values);
                  }}
                  customInput={<CustomInputDateTime />}
                />
              </Col>
            </Row>
            <div className='mb-3'>
              <AvField
                value={message.content}
                name='content'
                label={translator('content')}
                type='textarea'
                rows='4'
                errorMessage={translator('invalid content')}
                onChange={e => {
                  setContentCounter(e.target.value.length);
                }}
                validate={{
                  required: { value: true },
                  maxLength: {
                    value: 100,
                    errorMessage: translator('content must be less than 100 characters')
                  }
                }}
                helpMessage={`${contentCounter}/100`}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <div className='modal-header'>
            <h5 className='modal-title mt-0' id='myModalLabel'>
              {translator('select message target')}
            </h5>
          </div>
          <Col className='col-3'>
            <div className='mb-3'>
              <Select
                styles={customStyleSelect}
                value={targetType}
                isMulti={false}
                onChange={value => {
                  setTargetType(value);
                  setSelectedTargets([]);
                }}
                options={MessageTargetOptions.map(option => ({
                  ...option,
                  label: translator(option.label)
                }))}
                classNamePrefix='select2-selection'
                placeholder={translator('select')}
              />
            </div>
          </Col>
          <Col className='col-9'>
            {targetType && targetType.value && (
              <div className='mb-3'>
                <Select
                  styles={customStyleSelect}
                  value={selectedTargets}
                  isMulti={false}
                  onChange={value => {
                    setSelectedTargets([value]);
                  }}
                  options={getTargetOptions()}
                  classNamePrefix='select2-selection'
                  placeholder={translator('select')}
                />
              </div>
            )}
          </Col>
        </Row>
        {!isEmpty(userByMessages) && (
          <Row>
            <Card>
              <CardBody>
                <CardTitle tag='h5'>List Users</CardTitle>
                <div className='table-responsive'>
                  <Table
                    rowKey='id'
                    dataSource={userByMessages}
                    columns={UsersTableColumns}
                    pagination={false}
                  ></Table>
                </div>
              </CardBody>
            </Card>
          </Row>
        )}
      </Container>
      <Row>
        <Col>
          <div className='text-end'>
            <button type='submit' className='btn btn-success save-customer'>
              {translator('update')}
            </button>
          </div>
        </Col>
      </Row>
    </AvForm>
  );
};

export const MessagesForm = withTranslation()(Form);

Form.propTypes = {
  handleSubmit: PropTypes.func,
  message: PropTypes.object,
  action: PropTypes.string,
  staffGroups: PropTypes.array,
  t: PropTypes.any
};
