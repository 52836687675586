import {
  GET_MY_PERMISSIONS,
  GET_MY_PERMISSIONS_FAIL,
  GET_MY_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS,
  GET_PERMISSIONS_FAIL,
  GET_PERMISSIONS_SUCCESS,
  RESET_ERROR_STATUS,
} from './actionTypes';
import { LOGOUT_USER } from 'store/auth/login/actionTypes';

const INIT_STATE = {
  error: '',
  success: '',
};

const PermissionsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PERMISSIONS:
    case GET_MY_PERMISSIONS:
      return {
        ...state,
        isLoadingPermissions: true,
        isSuccess: false,
        errors: '',
      };
    case GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isLoadingPermissions: false,
        permissions: action.payload,
      };
    case GET_PERMISSIONS_FAIL:
      return {
        ...state,
        isLoadingPermissions: false,
        errors: action.payload,
      };
    case GET_MY_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isLoadingPermissions: false,
        myPermissions: action.payload,
      };
    case GET_MY_PERMISSIONS_FAIL:
      return {
        ...state,
        isLoadingPermissions: false,
        errors: action.payload,
      };
    case RESET_ERROR_STATUS:
      return {
        ...state,
        errors: undefined,
        isSuccess: false,
      };
    case LOGOUT_USER:
      return {
        ...INIT_STATE,
      };
    default:
      return state;
  }
};

export default PermissionsReducer;
