import { post, get, put, del } from '../helpers/apiHelper';
import { API_URL } from '../constants';

const list = async () => {
  return await get({
    url: API_URL.staffGroup.list
  });
};

const update = async ({id, staffGroup}) => {
  return await put({
    url: `${API_URL.staffGroup.update(id)}`,
    data: staffGroup
  });
};

const create = async staffGroup => {
  return await post({
    url: API_URL.staffGroup.create,
    data: staffGroup
  });
};

const archive = async id => {
  return await del({
    url: `${API_URL.staffGroup.archive}${id}`
  });
};

export const staffGroupService = {
  list,
  update,
  create,
  archive
};
