/* GROUPS */
export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAIL = 'GET_GROUPS_FAIL';

/* ALL GROUPS */
export const GET_ALL_GROUPS = 'GET_ALL_GROUPS';
export const GET_ALL_GROUPS_SUCCESS = 'GET_ALL_GROUPS_SUCCESS';
export const GET_ALL_GROUPS_FAIL = 'GET_ALL_GROUPS_FAIL';

/**
 * add GROUP
 */
export const ADD_NEW_GROUP = 'ADD_NEW_GROUP';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const ADD_GROUP_FAIL = 'ADD_GROUP_FAIL';

/**
 * Edit GROUP
 */
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAIL = 'UPDATE_GROUP_FAIL';

/**
 * Delete GROUP
 */
export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAIL = 'DELETE_GROUP_FAIL';

/**
 *  SUB-GROUP
 */
 export const GET_SUB_GROUPS = 'GET_SUB_GROUPS';
 export const GET_SUB_GROUPS_SUCCESS = 'GET_SUB_GROUPS_SUCCESS';
 export const GET_SUB_GROUPS_FAIL = 'GET_SUB_GROUPS_FAIL';
 export const ADD_SUB_GROUP_SUCCESS = 'ADD_SUB_GROUP_SUCCESS';
 export const UPDATE_SUB_GROUP_SUCCESS = 'UPDATE_SUB_GROUP_SUCCESS';
 export const DELETE_SUB_GROUP_SUCCESS = 'DELETE_SUB_GROUP_SUCCESS';

/**
 *  GROUP-CATEGORY-DEPENDENCIES
 */
 export const ADD_GROUP_CATEGORY_DEPENDENCIES = 'ADD_GROUP_CATEGORY_DEPENDENCIES';
 export const ADD_GROUP_CATEGORY_DEPENDENCIES_SUCCESS = 'ADD_GROUP_CATEGORY_DEPENDENCIES_SUCCESS';
 export const ADD_GROUP_CATEGORY_DEPENDENCIES_FAIL = 'ADD_GROUP_CATEGORY_DEPENDENCIES_FAIL';

 /**
 * RESET ERROR STATUS
 */
export const RESET_ERROR_STATUS = 'RESET_ERROR_STATUS';

 /**
 * UPDATE USER GROUP
 */
export const ADD_USER_GROUP_SUCCESS = 'ADD_USER_GROUP_SUCCESS';
export const DELETE_USERS_GROUP_SUCCRESS = 'DELETE_USERS_GROUP_SUCCRESS';