import { post, get, put, del } from '../helpers/apiHelper';
import { API_URL } from '../constants';

const list = async () => {
  return await get({
    url: API_URL.group.list,
  });
};

const getAll = async () => {
  return await get({
    url: API_URL.group.getAll,
  });
};

const subList = async id => {
  return await get({
    url: API_URL.group.subList(id),
  });
};

const update = async ({ id, group }) => {
  return await put({
    url: `${API_URL.group.update}${id}`,
    data: group,
  });
};

const create = async group => {
  return await post({
    url: API_URL.group.create,
    data: group,
  });
};

const archive = async id => {
  return await del({
    url: `${API_URL.group.archive}${id}`,
  });
};

const createCategoryDependencies = async ({ groupId, categoryIds }) => {
  return await post({
    url: API_URL.group.categoryDependencies,
    data: {
      group_id: groupId,
      category_ids: categoryIds,
    },
  });
};

export const groupService = {
  createCategoryDependencies,
  list,
  getAll,
  subList,
  update,
  create,
  archive,
};
