/* eslint-disable no-unused-vars */
import { get as _get } from 'lodash';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { orderService } from 'services';
import {
  getDeliveryStaffsFail,
  getDeliveryStaffsSuccess,
  getOrderHistoryFail,
  getOrderHistorySuccess,
  updateDeliveryMenFail,
  updateDeliveryMenSuccess,
} from 'store/actions';
import { catchError } from 'store/errors/actions';
import { isEmpty } from 'underscore';
import { updateUserOrderSuccess, addUserOrderSuccess } from '../users/actions';
import {
  addOrderFail,
  addOrderSuccess,
  addSelfProductFail,
  addSelfProductSuccess,
  deleteOrderFail,
  deleteOrderSuccess,
  deleteSelfProductFail,
  deleteSelfProductSuccess,
  getAllOrdersByStatusFail,
  getAllOrdersByStatusSuccess,
  getOderByDateSuccess,
  getOrderByDateFail,
  getOrderByIdFail,
  getOrderByIdSuccess,
  updateOrderFail,
  updateOrderStatusFail,
  updateOrderStatusSuccess,
  updateOrderSuccess,
  updateSelfProductFail,
  updateSelfProductSuccess,
  exportCsvSuccess,
  exportCsvFail,
  exportMultipleOrdersCsvSuccess,
  exportMultipleOrdersCsvFail,
  getOrderByDateRangeSuccess,
  getOrderByDateRangeFail,
} from './actions';
import {
  ADD_NEW_ORDER,
  ADD_ORDER_SELF_PRODUCTS,
  DELETE_ORDER,
  DELETE_ORDER_SELF_PRODUCTS,
  GET_ALL_ORDERS_BY_STATUS,
  GET_DELIVERY_STAFFS,
  GET_ORDER_BY_DATE,
  GET_ORDER_DETAIL,
  GET_ORDER_HISTORY,
  UPDATE_DELIVERY_MEN,
  UPDATE_ORDER,
  UPDATE_ORDER_SELF_PRODUCTS,
  UPDATE_ORDER_STATUS,
  EXPORT_CSV,
  EXPORT_MULTIPLE_ORDERS_CSV,
  GET_ORDER_BY_DATERANGE,
} from './actionTypes';
import { saveAs } from 'file-saver';
import { formatDate } from 'utils/date';

function* getOrdersByStatus({ payload }) {
  try {
    const res = _get(yield call(orderService.getByStatus, payload), 'results', []);
    yield put(getAllOrdersByStatusSuccess(res));
  } catch (error) {
    yield put(catchError(error));
    yield put(getAllOrdersByStatusFail(error));
  }
}

function* addNewOrder({ payload }) {
  try {
    const res = yield call(orderService.create, {
      date_of_delivery: _get(payload, 'dateOfDelivery', ''),
      products: _get(payload, 'products', ''),
      user_id: _get(payload, 'userId', ''),
      delivery_men: _get(payload, 'deliveryMan', ''),
      isSent: _get(payload, 'isPushNotification', ''),
    });
    const userReducer = yield select(state => state.users);
    const sourceUser = _get(userReducer, 'userDetail', {});
    yield put(addOrderSuccess(_get(res, 'results', {})));
    if (!isEmpty(sourceUser)) yield put(addUserOrderSuccess(_get(res, 'results', {})));
  } catch (e) {
    yield put(catchError(e));
    yield put(addOrderFail(e));
  }
}

function* updateOrder({ payload }) {
  try {
    const res = yield call(orderService.update, {
      ...payload,
    });
    yield put(updateOrderSuccess(_get(res, 'results', {})));
  } catch (e) {
    yield put(catchError(e));
    yield put(updateOrderFail(e));
  }
}

function* updateOrderStatus({ payload }) {
  try {
    const { order } = payload;
    const status = order.status;
    const res = yield call(orderService.update, {
      id: order.id,
      status,
      isSent: order.isPushNotification,
    });
    const newOrderObject = _get(res, 'results', {});
    const orderReducer = yield select(state => state.orders);
    const sourceOrders = _get(orderReducer, 'orders', {});
    const userReducer = yield select(state => state.users);
    const sourceUser = _get(userReducer, 'userDetail', {});
    if (!isEmpty(sourceOrders)) {
      const orders = sourceOrders.filter(
        item => item.id.toString() !== newOrderObject.id.toString()
      );
      yield put(
        updateOrderStatusSuccess({
          status,
          orders,
        })
      );
    }
    if (!isEmpty(sourceUser)) yield put(updateUserOrderSuccess({ id: order.id, status }));
  } catch (e) {
    yield put(updateOrderStatusFail(e));
  }
}

function* deleteOrder({ payload }) {
  try {
    const id = payload;
    yield call(orderService.archive, id);
    yield put(deleteOrderSuccess(id));
  } catch (e) {
    yield put(deleteOrderFail(e));
  }
}

function* getOrderById({ payload }) {
  try {
    const res = yield call(orderService.getById, payload);
    yield put(getOrderByIdSuccess(_get(res, 'results', {})));
  } catch (error) {
    yield put(getOrderByIdFail(error));
  }
}

function* addSelfProduct({ payload }) {
  try {
    const res = yield call(orderService.updateSelfProduct, payload);
    yield put(addSelfProductSuccess(_get(res, 'results', {})));
  } catch (error) {
    yield put(addSelfProductFail(error));
  }
}

function* deleteSelfProduct({ payload }) {
  try {
    const infoID = _get(payload, ['product', 'info'], {});
    const res = yield call(orderService.updateSelfProduct, {
      id: payload.id,
      product: {
        productId: _get(payload, ['product', 'id'], ''),
        amount: 0,
        isPushNotification: payload.isPushNotification,
        infoId: _get(infoID, 'id', 0),
      },
    });
    yield put(deleteSelfProductSuccess(_get(res, 'results', {})));
  } catch (error) {
    yield put(deleteSelfProductFail(error));
  }
}

function* updateSelfProduct({ payload }) {
  try {
    const res = yield call(orderService.updateSelfProduct, payload);
    yield put(updateSelfProductSuccess(_get(res, 'results', {})));
  } catch (error) {
    yield put(updateSelfProductFail(error));
  }
}

function* getOrderByDate({ payload }) {
  try {
    const res = yield call(orderService.getByDate, {
      date: _get(payload, 'date', ''),
      isCheckDelivery: _get(payload, 'isCheckDelivery', '')
    });
    yield put(
      getOderByDateSuccess({
        result: _get(res, 'results', []),
        currentDate: payload,
      })
    );
  } catch (error) {
    yield put(getOrderByDateFail(error));
  }
}

function* getOrderByDateRange({ payload }) {
  try {
    const res = yield call(orderService.getByDateRange, payload);
    yield put(
      getOrderByDateRangeSuccess({
        ...res?.results,
        range: payload
      })
    );
  } catch (error) {
    yield put(getOrderByDateRangeFail(error));
  }
}

function* getOrderHistory({ payload }) {
  try {
    const res = yield call(orderService.getOrderHistory, payload);
    yield put(getOrderHistorySuccess(_get(res, 'results', [])));
  } catch (error) {
    yield put(catchError(error));
    yield put(getOrderHistoryFail(error));
  }
}

function* getDeliveryStaffs() {
  try {
    const res = yield call(orderService.getDeliveryStaffs);
    yield put(getDeliveryStaffsSuccess(_get(res, 'results', [])));
  } catch (error) {
    yield put(catchError(error));
    yield put(getDeliveryStaffsFail(error));
  }
}

function* updateDeliveryMen({ payload }) {
  try {
    const res = yield call(orderService.updateDeliveryStaffs, {
      orderId: _get(payload, 'orderId', ''),
      deliveryMenId: _get(payload, 'deliveryMenId', ''),
    });
    yield put(
      updateDeliveryMenSuccess({
        results: _get(res, 'results', []),
        isOrderDetail: _get(payload, 'isOrderDetail', false),
      })
    );
  } catch (error) {
    yield put(catchError(error));
    yield put(updateDeliveryMenFail(error));
  }
}

function* exportMultipleOrdersCsv({ payload }) {
  try {
    const { selectedRecords, isFilteringByDateRange, range } = payload;
    const result = yield call(orderService.exportMultipleOrdersCsv, selectedRecords);
    const path = range?.end ? `commandes_${formatDate(range?.start,'-')}_${formatDate(range?.end, '-')}.csv` : `commandes_${formatDate(range?.start,'-')}.csv`;
    yield saveAs(result, path);
  } catch (error) {
    yield put(exportMultipleOrdersCsvFail(error));
  }
}

function* exportOrderCsv({ payload }) {
  try {
    const id =  _get(payload, 'orderId', '');
    const orderNumber =  _get(payload, 'orderNumber', '');
    const brandName =  _get(payload, 'brandName', '');
    const orderDate =  _get(payload, 'orderDate', '');
    const res = yield call(orderService.exportCsv, id);
    yield put(exportCsvSuccess(_get(res, 'results', [])));
    saveAs(res, `${brandName}_${orderNumber}_${orderDate}.csv`);
  } catch (error) {
    yield put(exportCsvFail(error));
  }
}

function* ordersSaga() {
  yield takeEvery(UPDATE_ORDER_SELF_PRODUCTS, updateSelfProduct);
  yield takeEvery(DELETE_ORDER_SELF_PRODUCTS, deleteSelfProduct);
  yield takeEvery(ADD_ORDER_SELF_PRODUCTS, addSelfProduct);
  yield takeEvery(ADD_NEW_ORDER, addNewOrder);
  yield takeEvery(UPDATE_ORDER, updateOrder);
  yield takeEvery(UPDATE_ORDER_STATUS, updateOrderStatus);
  yield takeEvery(DELETE_ORDER, deleteOrder);
  yield takeEvery(GET_ALL_ORDERS_BY_STATUS, getOrdersByStatus);
  yield takeEvery(GET_ORDER_DETAIL, getOrderById);
  yield takeEvery(GET_ORDER_BY_DATE, getOrderByDate);
  yield takeEvery(GET_ORDER_HISTORY, getOrderHistory);
  yield takeEvery(GET_DELIVERY_STAFFS, getDeliveryStaffs);
  yield takeEvery(UPDATE_DELIVERY_MEN, updateDeliveryMen);
  yield takeEvery(EXPORT_CSV, exportOrderCsv);
  yield takeEvery(EXPORT_MULTIPLE_ORDERS_CSV, exportMultipleOrdersCsv);
  yield takeEvery(GET_ORDER_BY_DATERANGE, getOrderByDateRange);
}

export default ordersSaga;
