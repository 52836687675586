
export const REGEX = {
    // eslint-disable-next-line no-useless-escape
    EMAIL: '^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$',
    PASSWORD: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{7,}$',
    NUMBER: '[0-9]*$',
    // eslint-disable-next-line no-useless-escape
    PHONE_NUMBER: '^(\+)?(\d{1,2})?[( .-]*(\d{3})[) .-]*(\d{3,4})[ .-]?(\d{4})$' 
};

export const ERROR_MESSAGE = {
    EMAIL : "Format d 'email invalide",
    PHONE_NUMBER: "phone number should be +(XXX)-XX-XX-XX",
    PASSWORD: "Le mot de passe doit contenir 7 caractères ou plus avec au moins un capital"
};