import {Col, Container, Row} from 'reactstrap';
import error from '../../assets/images/logo.jpg';
import React from 'react';

const EmpyDataForm = () => {
	return (
		<React.Fragment>
			<Container>
				<Row>
					<Col lg='12'>
						<div className='text-center mb-5 mt-5'>
							<h4 className='text-uppercase'>PAS DE DONNÉES</h4>
						</div>
					</Col>
				</Row>
				<Row className='justify-content-center'>
					<Col md='8' xl='6'>
						<div>
							<img src={error} alt='' className='img-fluid' style={{ opacity: 0.5 }}/>
						</div>
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	);
};

export default EmpyDataForm;