import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";

import { logoutUser } from "store/auth/login/actions";

import { useDispatch } from "react-redux";

const Logout = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logoutUser(history));
  }, [dispatch]);

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);
