import { get as _get } from 'lodash';

export const updatePosition = (array, { oldPosition, newPosition }) => {
  let swappedItem;
  const newArray = [...array].filter(item => {
    if (_get(item, 'position', '') !== oldPosition) return true;
    swappedItem = { ...item };
    return false;
  });

  const isContrary = oldPosition > newPosition;

  const getPreservedCondition = position =>
    !isContrary
      ? position < oldPosition || position > newPosition
      : position < newPosition || position > oldPosition;

  const result = newArray.map(item => {
    const position = _get(item, 'position', '');
    if (getPreservedCondition(position)) return item;
    return {
      ...item,
      position: isContrary ? position + 1 : position - 1,
    };
  });
  result.splice(newPosition, 0, {
    ...swappedItem,
    position: newPosition,
  });

  return result;
};

export const handleAddCategory = (categories, newCategory) => {
  const result = [...categories].map(category => {
    const oldPosition = _get(category, 'position', -1);
    if (oldPosition < _get(newCategory, 'position', -1)) return { ...category };
    return { ...category, position: oldPosition + 1 };
  });
  return [...result, newCategory];
};

export const handleAddSubCategory = (categories, newSubCategory) => {
  const result = [...categories].map(category => {
    if (_get(category, 'id', '').toString() !== _get(newSubCategory, 'type', '').toString())
      return category;
    const subCats = Object.values(_get(category, 'sub', []));
    return {
      ...category,
      sub: Object.assign({}, handleAddCategory(subCats, newSubCategory)),
    };
  });
  return [...result];
};

export const handleDeleteCategory = (categories, positionDelete, categoryIdDeleted) => {
  const newCategories = [...categories].filter(
    category => _get(category, 'id', '').toString() !== categoryIdDeleted.toString()
  );
  const result = [...newCategories].map(category => {
    const oldPosition = _get(category, 'position', -1);
    if (oldPosition < positionDelete) return { ...category };
    return { ...category, position: oldPosition - 1 };
  });
  return [...result];
};

export const handleUpdateCategory = (categories, newCategory) => {
  const categoryId = _get(newCategory, 'id', '').toString();
  const originalCategory = categories.find(
    category => _get(category, 'id', '').toString() === categoryId
  );
  const originalPosition = _get(originalCategory, 'position', '');
  const result = updatePosition(categories, {
    oldPosition: originalPosition,
    newPosition: parseInt(_get(newCategory, 'position', '')),
  });
  return [...result].map(category =>
    _get(category, 'id', '').toString() === categoryId ? { ...category, ...newCategory } : category
  );
};

export const handleUpdateSubCategory = (categories, newSubCategory) => {
  const { results, categoryId } = newSubCategory;
  return [...categories].map(category => {
    if (_get(category, 'id', '').toString() !== categoryId.toString()) return category;
    const subCats = Object.values(_get(category, 'sub', []));
    const result = handleUpdateCategory(subCats, results);
    return {
      ...category,
      sub: Object.assign({}, result),
    };
  });
};

export const updateAmountProductForCategory = (categories, resPayload) => {
  let { result, categoryId, isSub, subCategoryId } = resPayload;

  const totalProduct = _get(result, 'total_product', 0);
  const res = [...categories].map(category => {
    if (_get(category, 'id', '').toString() !== categoryId.toString()) return category;
    if (!isSub) return { ...category, total_product: totalProduct };
    const subCats = Object.values(_get(category, 'sub', []));
    const subCatsRes = subCats.map(subCategory => {
      if (_get(subCategory, 'id', '').toString() === subCategoryId.toString())
        return { ...subCategory, total_product: totalProduct };
      return subCategory;
    });
    return {
      ...category,
      sub: Object.assign({}, subCatsRes),
    };
  });
  return [...res];
};
