import PropTypes from 'prop-types';
import React from 'react';
import 'react-drawer/lib/react-drawer.css';
//i18n
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logoLightSvg from '../../assets/images/logo-light.svg';
import logo from '../../assets/images/logo.svg';
// Redux Store
import { changeSidebarType, toggleLeftmenu } from '../../store/actions';
// Import menuDropdown
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';

const Header = () => {
  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable');
    } else {
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  }

  return (
    <React.Fragment>
      <header id='page-topbar'>
        <div className='navbar-header'>
          <div className='d-flex'>
            <div className='navbar-brand-box d-lg-none d-md-block'>
              <Link to='/' className='logo logo-dark'>
                <span className='logo-sm'>
                  <img src={logo} alt='' height='22' />
                </span>
              </Link>

              <Link to='/' className='logo logo-light'>
                <span className='logo-sm'>
                  <img src={logoLightSvg} alt='' height='22' />
                </span>
              </Link>
            </div>

            <button
              type='button'
              onClick={() => {
                tToggle();
              }}
              className='btn btn-sm px-3 font-size-16 header-item '
              id='vertical-menu-btn'
            >
              <i className='fa fa-fw fa-bars' />
            </button>
          </div>
          <div className='d-flex'>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const { layoutType, leftMenu, leftSideBarType } = state.Layout;
  return { layoutType, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  toggleLeftmenu,
  changeSidebarType
})(withTranslation()(Header));
