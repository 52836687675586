import {
  ADD_GROUP_SUBGROUPS_DEPENDENCIES,
  ADD_GROUP_SUBGROUPS_DEPENDENCIES_FAIL,
  ADD_GROUP_SUBGROUPS_DEPENDENCIES_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_FAIL,
  ADD_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_ON_GROUP,
  DELETE_USER_ON_GROUP_FAIL,
  DELETE_USER_ON_GROUP_SUCCESS,
  DELETE_USER_SUCCESS,
  GET_FILTERED_USER,
  GET_FILTERED_USER_BY_GROUP,
  GET_FILTERED_USER_BY_GROUP_FAIL,
  GET_FILTERED_USER_BY_GROUP_SUCCESS,
  GET_FILTERED_USER_FAILURE,
  GET_FILTERED_USER_SUCCESS,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USER_DETAIL,
  GET_USER_DETAIL_FAIL,
  GET_USER_DETAIL_SUCCESS,
  REMOVE_FILTERED_USER,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_ORDER_SUCCESS,
  UPDATE_USER_STATUS,
  UPDATE_USER_STATUS_FAIL,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_SUCCESS,
  ADD_USER_FAVORITE_PRODUCT,
  ADD_USER_FAVORITE_PRODUCT_SUCCESS,
  ADD_USER_FAVORITE_PRODUCT_FAIL,
  ADD_USER_ORDER_SUCCESS,
  RESET_ERROR_STATUS,
  EXPORT_USER_CSV,
  EXPORT_USER_CSV_SUCCESS,
  EXPORT_USER_CSV_FAIL
} from './actionTypes';

export const getFilteredUsers = keyword => ({
  type: GET_FILTERED_USER,
  payload: keyword
});

export const getFilteredUsersSuccess = keyword => ({
  type: GET_FILTERED_USER_SUCCESS,
  payload: keyword
});

export const getFilteredUsersFailure = keyword => ({
  type: GET_FILTERED_USER_FAILURE,
  payload: keyword
});

export const getUsers = () => ({
  type: GET_USERS
});

export const getUserSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users
});

export const getUserFail = error => ({
  type: GET_USERS_FAIL,
  payload: error
});

export const addUserIntoGroup = payload => ({
  type: ADD_GROUP_SUBGROUPS_DEPENDENCIES,
  payload
});

export const addUserIntoGroupSuccess = payload => ({
  type: ADD_GROUP_SUBGROUPS_DEPENDENCIES_SUCCESS,
  payload
});

export const addUserIntoGroupFail = errors => ({
  type: ADD_GROUP_SUBGROUPS_DEPENDENCIES_FAIL,
  payload: errors
});

export const addNewUser = user => ({
  type: ADD_NEW_USER,
  payload: user
});

export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user
});

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error
});

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user
});

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user
});

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error
});

export const deleteUser = user => {
  return {
    type: DELETE_USER,
    payload: user
  };
};

export const deleteUserSuccess = id => ({
  type: DELETE_USER_SUCCESS,
  payload: id
});

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error
});

export const resetErrorStatus = () => ({
  type: RESET_ERROR_STATUS
});

export const getUserDetail = user => ({
  type: GET_USER_DETAIL,
  payload: user
});

export const getUserDetailSuccess = user => ({
  type: GET_USER_DETAIL_SUCCESS,
  payload: user
});

export const getUserDetailFail = error => ({
  type: GET_USER_DETAIL_FAIL,
  payload: error
});

export const updateUserOrderSuccess = payload => ({
  type: UPDATE_USER_ORDER_SUCCESS,
  payload
});

export const addUserOrderSuccess = payload => ({
  type: ADD_USER_ORDER_SUCCESS,
  payload
});

export const updateUserStatus = payload => ({
  type: UPDATE_USER_STATUS,
  payload
});

export const updateUserStatusSuccess = payload => ({
  type: UPDATE_USER_STATUS_SUCCESS,
  payload
});

export const updateUserStatusFail = error => ({
  type: UPDATE_USER_STATUS_FAIL,
  payload: error
});

export const getFilteredUsersByGroup = keyword => ({
  type: GET_FILTERED_USER_BY_GROUP,
  payload: keyword
});

export const getFilteredUsersByGroupSuccess = keyword => ({
  type: GET_FILTERED_USER_BY_GROUP_SUCCESS,
  payload: keyword
});

export const getFilteredUsersByGroupFail = keyword => ({
  type: GET_FILTERED_USER_BY_GROUP_FAIL,
  payload: keyword
});

export const removeFilteredUser = () => ({
  type: REMOVE_FILTERED_USER
});

export const deleteUserOnGroup = keyword => ({
  type: DELETE_USER_ON_GROUP,
  payload: keyword
});

export const deleteUserOnGroupFail = errors => ({
  type: DELETE_USER_ON_GROUP_FAIL,
  payload: errors
});

export const deleteUserOnGroupSuccess = payload => ({
  type: DELETE_USER_ON_GROUP_SUCCESS,
  payload
});

export const addUserFavoriteProduct = product => ({
  type: ADD_USER_FAVORITE_PRODUCT,
  payload: product
});

export const addUserFavoriteProductSuccess = payload => ({
  type: ADD_USER_FAVORITE_PRODUCT_SUCCESS,
  payload
});

export const addUserFavoriteProductFail = errors => ({
  type: ADD_USER_FAVORITE_PRODUCT_FAIL,
  payload: errors
});

export const exportUserCsv = payload => ({
  type: EXPORT_USER_CSV,
  payload
});

export const exportUserCsvSuccess = payload => ({
  type: EXPORT_USER_CSV_SUCCESS,
  payload
});

export const exportUserCsvFail = error => ({
  type: EXPORT_USER_CSV_FAIL,
  payload: error
});
