import React  from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { SimpleTable } from 'components/Common/TableForm/SimpleTable';

const SimpleOrderBoard = () => {
  return (
    <Container fluid>
      <Breadcrumbs title='orders' breadcrumbItem='simple mode' />
      <Row>
        <Col xl='12'>
          <SimpleTable />
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(SimpleOrderBoard);
