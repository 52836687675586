/* eslint-disable no-unused-vars */
import { AvField, AvForm } from 'availity-reactstrap-validation';
import {
  PRODUCT_ACTIONS,
  PRODUCT_OPTION_LABELS,
  PRODUCT_OPTION_PROP_KEY,
} from 'components/Common/ProductForm/constants';
import { get as _get, isEmpty, isEqual as isPropEqual, has } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Input, Row } from 'reactstrap';
import { getBase64 } from 'utils/file';
import { ACTION_LABELS } from './constants';

const ProductForm = props => {
  const { handleSubmit, product = {}, action, t: translator } = props;

  const isUpdating = useMemo(() => action === PRODUCT_ACTIONS.UPDATE, [action]);

  const [file, setFile] = useState({});
  const [image, setImage] = useState({});

  const [isProductForm, setIsProductForm] = useState(false);
  const [newProductOptions, setNewProductOptions] = useState([{}]);
  const [validationRequiredValue, setValidationRequiredValue] = useState({});
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const getImage = input => {
    return `${process.env.REACT_APP_API_URL}/${input}`;
  };

  const info = _get(product, 'info', '');

  useEffect(() => {
    if (isEmpty(product) || isEmpty(info) || isEmpty(_get(product, 'image', ''))) return;
    setImage(getImage(_get(product, 'image', '')));
  }, [product]);

  useEffect(() => {
    if(isUpdating) setNewProductOptions([]);
  },[action]);

  const getProdOptionsValues = values => {
    const keys = Object.keys(values).reduce((acc, cur) => {
      if (
        cur.includes(PRODUCT_OPTION_LABELS.genCode) ||
        cur.includes(PRODUCT_OPTION_LABELS.unitWeight) ||
        cur.includes(PRODUCT_OPTION_LABELS.packaging)
      ) {
        return [...acc, cur];
      }
      return acc;
    }, []);
    return keys.reduce((acc, cur) => {
      const name = cur.split('--');
      const type = name[0].split('-');
      const id = name[1];
      return {
        ...acc,
        [`${type[0]}${id}`]: {
          ..._get(acc, `${type[0]}${id}`, {}),
          id: type[0] === 'new' ? undefined : id,
          [PRODUCT_OPTION_PROP_KEY[type[1]]]: values[cur],
        },
      };
    }, {});
  };

  const onSubmit = (e, values) => {
    let submitProduct = {
      title: _get(values, 'title', ''),
      dlc: _get(values, 'dlc', ''),
      description: _get(values, 'description', ''),
      item_code: _get(values, 'item_code', ''),
      image: file,
    };

    if (isUpdating) {
      submitProduct = {
        ...submitProduct,
        info: [...Object.values(getProdOptionsValues(values))],
      };
    } else {
      submitProduct = {
        ...submitProduct,
        gen_code: _get(values, `new-${PRODUCT_OPTION_LABELS.genCode}--0`, ''),
        packaging: _get(values, `new-${PRODUCT_OPTION_LABELS.packaging}--0`, ''),
        unit_weight: _get(values, `new-${PRODUCT_OPTION_LABELS.unitWeight}--0`, ''),
      };
    }
    !isProductForm &&
      handleSubmit(
        { action: PRODUCT_ACTIONS.UPDATE, ...e },
        {
          ...submitProduct,
        }
      );
  };

  const onFileChange = ({ target }) => {
    const targetFile = _get(target, ['files', '0'], '');
    getBase64(targetFile, res => {
      setImage(res);
    });
    setFile(targetFile);
  };

  const onClickProductForm = () => {
    setIsProductForm(false);
  };

  const opacityProduct = { opacity: isProductForm ? 0.25 : 1 };

  const customValidate = (value, ctx, input, cb) => {
    if (
      isSubmitClicked ||
      (has(input, ['props', 'id']) && has(validationRequiredValue, _get(input, ['props', 'id'])))
    ) {
      const isValid = !isEmpty(value);
      cb(isValid);
    }
  };

  const productInfo = (i, key, prodOptionAction = '') => {
    const genCodeFieldName =
      prodOptionAction === 'new'
        ? `new-${PRODUCT_OPTION_LABELS.genCode}--${key}`
        : `existed-${PRODUCT_OPTION_LABELS.genCode}--${i.id}`;
    const unitWeightFieldName =
      prodOptionAction === 'new'
        ? `new-${PRODUCT_OPTION_LABELS.unitWeight}--${key}`
        : `existed-${PRODUCT_OPTION_LABELS.unitWeight}--${i.id}`;
    const packagingFieldName =
      prodOptionAction === 'new'
        ? `new-${PRODUCT_OPTION_LABELS.packaging}--${key}`
        : `existed-${PRODUCT_OPTION_LABELS.packaging}--${i.id}`;
    return (
      <Row key={'info' + key}>
        <Col className='col-6 modal-body'>
          <div className='mb-3'>
            <AvField
              name={genCodeFieldName}
              label={translator('gen code')}
              type='text'
              errorMessage={translator('invalid gen code')}
              value={i.gen_code || ''}
            />
          </div>
          <div className='mb-3'>
            <AvField
              name={unitWeightFieldName}
              label={translator('unit weight')}
              type='text'
              errorMessage={translator('invalid unit weight')}
              value={i.unit_weight || ''}
            />
          </div>
        </Col>
        <Col className='col-6 modal-body'>
          <div className='mb-3'>
            <AvField
              name={packagingFieldName}
              label={translator('packaging')}
              type='text'
              errorMessage={translator('invalid packaging')}
              value={i.packaging || ''}
            />
          </div>
        </Col>
      </Row>
    );
  };
  return (
    <AvForm onValidSubmit={onSubmit}>
      <Row form>
        <Row onClick={onClickProductForm} style={opacityProduct}>
          {isUpdating && (
            <div className='modal-header'>
              <h5 className='modal-title mt-0' id='myModalLabel'>
                {translator('product')}
              </h5>
            </div>
          )}
          <Row>
            <Col className='col-6 modal-body'>
              <div className='mb-3'>
                <AvField
                  name='item_code'
                  label={translator('item code')}
                  type='text'
                  errorMessage={translator('invalid item code')}
                  validate={{
                    required: { value: true }
                  }}
                  value={product.item_code || ''}
                />
              </div>
              <div className='mb-3'>
                <AvField
                  name='description'
                  label={translator('description')}
                  type='text'
                  errorMessage={translator('invalid description')}
                  value={product.description || ''}
                />
              </div>
              <div className='mb-3'>
                <div className='mt-4 mt-md-0'>
                  <Input
                    name='image'
                    type='file'
                    accept='image/*'
                    className='form-control'
                    onChange={onFileChange}
                  />
                </div>
              </div>
              {!isEmpty(_get(product, 'image', '')) && <img src={getImage(_get(product, 'image', ''))} className='mb-0 avatar-md' />}
            </Col>
            <Col className='col-6 modal-body'>
              <div className='mb-3'>
                <AvField
                  name='title'
                  label={translator('title')}
                  type='text'
                  errorMessage={translator('invalid title')}
                  validate={{ async: customValidate }}
                  onChange={e => {
                    setValidationRequiredValue({
                      ...validationRequiredValue,
                      title: e.target.value,
                    });
                  }}
                  value={product.title || ''}
                />
              </div>
              <div className='mb-3'>
                <AvField
                  name='dlc'
                  label={translator('dlc')}
                  type='text'
                  errorMessage={translator('invalid dlc')}
                  value={product.dlc || ''}
                />
              </div>
            </Col>
          </Row>
          {!isEmpty(info) && info.map((i, key) => productInfo(i, key))}
        </Row>
        <Row>
          {isUpdating && <label className='control-label'>{translator('new product option')}</label>}
          {newProductOptions.map((newProduct, index) => productInfo('', index, 'new'))}
        </Row>
        {isUpdating && (
          <Row>
            <Col>
              <div className='text-start'>
                <button
                  onClick={e => {
                    e.preventDefault();
                    setNewProductOptions([...newProductOptions, {}]);
                    return false;
                  }}
                  className='btn btn-primary'
                >
                  {translator('add product option')}
                </button>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <div className='text-end'>
              <button
                type='submit'
                className='btn btn-success save-customer'
                onClick={() => setIsSubmitClicked(true)}
              >
                {translator(isUpdating ? 'submit' : ACTION_LABELS[action])}
              </button>
            </div>
          </Col>
        </Row>
      </Row>
    </AvForm>
  );
};

ProductForm.propTypes = {
  handleSubmit: PropTypes.func,
  product: PropTypes.object,
  action: PropTypes.string,
  t: PropTypes.any
};

function isEqual(prev, next) {
  if (isPropEqual(prev.optionCategory, next.optionCategory)) {
    return true;
  } else if (isPropEqual(prev.optionSubCategories, next.optionSubCategories)) {
    return true;
  } else return false;
}

export default memo(withTranslation()(ProductForm), isEqual);
