export * from './api';
export * from './layout';
export * from './messages';
export * from './utils';
const BASE_API_URL = `${process.env.REACT_APP_API_URL}/api-admin/admin`;

export const APi_URL = {
  admin: {
    login: `${BASE_API_URL}/login`,
  },
};

export const APP = {
  ENTERPRISE_NAME: 'Le Diamant Du Terroir',
  EMAIL: 'digitalrubis@gmail.com',
  TELEPHONE: '+33 6 19 40 44 93/ +33 4 22 53 15 15',
};
