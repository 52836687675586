import { post, get, put, del } from '../helpers/apiHelper';
import { API_URL } from '../constants';

const getProfile = async () => {
  const id = localStorage.getItem('id');

  return await get({
    url: API_URL.admin.getProfile + id,
  });
};

const list = async () => {
  return await get({
    url: API_URL.admin.list,
  });
};

const update = async ({ id, admin }) => {
  return await put({
    url: API_URL.admin.update(id),
    data: admin,
  });
};

const create = async admin => {
  return await post({
    url: API_URL.admin.create,
    data: admin,
  });
};

const archive = async admin => {
  return await del({
    url: `${API_URL.admin.archive}${admin.id}`,
  });
};

export const adminService = {
  getProfile,
  list,
  update,
  create,
  archive,
};
