import { SUPERIOR_ADMIN_ROLE } from 'constants/index';
import { get as _get, isEmpty } from 'lodash';
// MetisMenu
import MetisMenu from 'metismenujs';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState, useMemo } from 'react';
//i18n
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// //Import Scrollbar
import SimpleBar from 'simplebar-react';
import { PERMISSIONS } from 'utils/permissions';

import { ROUTES } from 'routes/constants';

const SidebarContent = props => {
  const ref = useRef();
  const { profile, myPermissions, role } = useSelector(state => ({
    profile: _get(state, ['Profile', 'profile'], {}),
    myPermissions: _get(state, ['permissions', 'myPermissions'], []),
    role: _get(state, ['Profile', 'profile', 'role'], undefined)
  }));

  const [isSuperior, setIsSuperior] = useState(false);
  useEffect(() => {
    setIsSuperior(role === SUPERIOR_ADMIN_ROLE);
  }, [role]);

  const [isFullPermission, setPermission] = useState(false);

  useEffect(() => {
    if (profile) setPermission(!_get(profile, ['role'], 1));
  }, [profile]);

  const [interfaceManagementPermission, setInterfaceManagementPermission] = useState(false);

  const permission = useMemo(() => myPermissions.map(per => _get(per, 'name', ''))  ,[myPermissions]);

  useEffect(() => {
    if (isEmpty(permission)) return;
    setInterfaceManagementPermission(permission.includes(PERMISSIONS.ACCESS_MANAGEMENT_INTERFACE));
  }, [permission]);

  const orderManagementPermission = useMemo(() => {
    const availablePermissions = ROUTES.orders.availablePermissions;
    return permission.some(per => {
      return availablePermissions.includes(per);
    });
  },[permission]);

  const customerManagementPermission = useMemo(() => {
    const availablePermissions = ROUTES.userList.availablePermissions;
    return permission.some(per => {
      return availablePermissions.includes(per);
    });
  },[permission]);

  const productManagementPermission = useMemo(() => {
    const availablePermissions = ROUTES.productList.availablePermissions;
    return permission.some(per => {
      return availablePermissions.includes(per);
    });
  },[permission]);

  const groupManagementPermission = useMemo(() => {
    const availablePermissions = ROUTES.groupList.availablePermissions;
    return permission.some(per => {
      return availablePermissions.includes(per);
    });
  },[permission]);

  const notiManagementPermission = useMemo(() => {
    const notiAvailablePermissions = ROUTES.notifications.availablePermissions;
    const messageAvailablePermissions = ROUTES.messages.availablePermissions;
    return permission.some(per => {
      return notiAvailablePermissions.includes(per) || messageAvailablePermissions.includes(per);
    });
  },[permission]);

  const categoriesManagementPermission = useMemo(() => {
    const availablePermissions = ROUTES.categoriesList.availablePermissions;
    return permission.some(per => {
      return availablePermissions.includes(per);
    });
  },[permission]);

  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem = null;
      const ul = document.getElementById('side-menu');
      const items = ul.getElementsByTagName('a');
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className='h-100' ref={ref}>
        <div id='sidebar-menu'>
          <ul className='metismenu list-unstyled' id='side-menu'>
            <li className='menu-title'>{props.t('menu')} </li>

            <li
              className={
                isSuperior || orderManagementPermission
                  ? 'metismenu__item--visible'
                  : 'metismenu__item--invisible'
              }
            >
              <Link to='/orders' className=''>
                <i className='bx bxs-cart' />
                <span>{props.t('orders')}</span>
              </Link>
            </li>

            <li
              className={
                isSuperior || isFullPermission || interfaceManagementPermission
                  ? 'metismenu__item--visible'
                  : 'metismenu__item--invisible'
              }
            >
              <Link to='/admins' className=''>
                <i className='bx bxs-user-detail' />
                <span>{props.t('users')}</span>
              </Link>
            </li>

            <li
              className={
                isSuperior || interfaceManagementPermission
                  ? 'metismenu__item--visible'
                  : 'metismenu__item--invisible'
              }
            >
              <Link to='/staff-groups' className=''>
                <i className='bx bxs-group' />
                <span>{props.t("users groups")}</span>
              </Link>
            </li>

            <li
              className={
                isSuperior || customerManagementPermission
                  ? 'metismenu__item--visible'
                  : 'metismenu__item--invisible'
              }
            >
              <Link to='/users' className=''>
                <i className='bx bxs-user' />
                <span>{props.t('customers')}</span>
              </Link>
            </li>

            <li
              className={
                isSuperior || groupManagementPermission
                  ? 'metismenu__item--visible'
                  : 'metismenu__item--invisible'
              }
            >
              <Link to='/groups' className=''>
                <i className='bx bxs-group' />
                <span>{props.t("customerss groups")}</span>
              </Link>
            </li>
            <li
              className={
                isSuperior || categoriesManagementPermission
                  ? 'metismenu__item--visible'
                  : 'metismenu__item--invisible'
              }
            >
              <Link to='/categories' className=''>
                <i className='bx bx-outline' />
                <span>{props.t('categories')}</span>
              </Link>
            </li>

            <li
              className={
                isSuperior || productManagementPermission
                  ? 'metismenu__item--visible'
                  : 'metismenu__item--invisible'
              }
            >
              <Link to='/products' className=''>
                <i className='bx bxl-product-hunt' />
                <span>{props.t('products')}</span>
              </Link>
            </li>

            <li
              className={
                isSuperior || isFullPermission || notiManagementPermission
                  ? 'metismenu__item--visible'
                  : 'metismenu__item--invisible'
              }
            >
              <Link to='/notifications' className=''>
                <i className='bx bx-notification' />
                <span>{props.t('notifications')}</span>
              </Link>
            </li>

            <li
              className={
                isSuperior || isFullPermission || notiManagementPermission
                  ? 'metismenu__item--visible'
                  : 'metismenu__item--invisible'
              }
            >
              <Link to='/messages' className=''>
                <i className='bx bx bx-message' />
                <span>{props.t('messages')}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
};

export default withRouter(withTranslation()(SidebarContent));
