import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { switchTable } from 'store/orders/actions';

export const OrderContext = React.createContext();

export const OrderProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [isSimpleDisplayMode, setDisplayMode] = useState(true);

  const toggleDisplayMode = () => {
    dispatch(switchTable());
    setDisplayMode(!isSimpleDisplayMode);
  };
  return (
    <OrderContext.Provider
      value={{
        toggleDisplayMode,
        isSimpleDisplayMode,
        setDisplayMode,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

OrderProvider.propTypes = {
  children: PropTypes.any,
};
