/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { Table } from 'antd';
import { get as _get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Input } from 'reactstrap';

const GroupsForCategoryRow = props => {
  const { rowDatas, onChecked } = props;
  const { subs: subGroups } = rowDatas;

  const { t: translator } = props;

  const columnFormat = [
    {
      dataIndex: 'name',
      title: translator('name'),
      width: '53.25%',
      render: (cellContent, row) => <div className='column-content'>{row.name}</div>
    },
    {
      dataIndex: 'checkedbox',
      title: translator('seen and select categories'),
      isDummyField: true,
      render: (cellContent, row) => {
        const permission = _get(row, 'permission', false);
        const groupId = _get(row, 'id', '');
        return (
          <Input
            type='checkbox'
            key={groupId}
            onChange={() => onChecked(row)}
            defaultChecked={permission}
          />
        );
      }
    }
  ];

  return (
    <Table
      showHeader={false}
      rowKey='id'
      dataSource={subGroups}
      columns={columnFormat}
      pagination={false}
    ></Table>
  );
};

GroupsForCategoryRow.propTypes = {
  t: PropTypes.any,
  onChecked: PropTypes.func
};

export default withTranslation()(GroupsForCategoryRow);
