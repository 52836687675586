import { get as _get } from 'lodash';
import { API_URL } from '../constants';
import { del, get, post, put } from '../helpers/apiHelper';
import { formatDate } from 'utils/date';

const updateSelfProduct = async ({ id, product }) => {
  return await put({
    url: API_URL.order.updateProduct(id),
    data: {
      product_id: _get(product, 'productId'),
      amount: _get(product, 'amount'),
      isSent: _get(product, 'isPushNotification'),
      info_id: _get(product, 'infoId'),
    },
  });
};

const list = async userId => {
  return await get({
    url: API_URL.order.list(userId),
  });
};

const update = async ({ id, ...rest }) => {
  return await put({
    url: `${API_URL.order.update(id)}`,
    data: { ...rest },
  });
};

const create = async order => {
  return await post({
    url: API_URL.order.create,
    data: order,
  });
};

const archive = async id => {
  return await del({
    url: `${API_URL.order.archive(id)}`,
  });
};

const getByStatus = async (page = 1) => {
  return await get({
    url: API_URL.order.getByStatus,
    config: {
      params: {
        page,
      },
    },
  });
};

const getById = async id => {
  return await get({
    url: `${API_URL.order.getById(id)}`,
  });
};

const getByDate = async ({ date, isCheckDelivery }) => {
  return await post({
    url: API_URL.order.getByDate(date),
    data: {
      isCheckDelivery: isCheckDelivery,
    },
  });
};

const getByDateRange = async (payload) => {
  
  const dateRange = {
    start: formatDate(payload.start),
    end: formatDate(payload.end),
  };
  return await post({
    url: API_URL.order.getOrdersByDateRange(dateRange),
    data: {
      page: payload?.page || 1
    }
  });
};

const getOrderHistory = async id => {
  return await get({
    url: `${API_URL.order.getOrderHistory(id)}`,
  });
};

const getDeliveryStaffs = async () => {
  return await get({
    url: API_URL.order.deliveryStaffs,
  });
};

const updateDeliveryStaffs = async ({ orderId, deliveryMenId }) => {
  return await put({
    url: API_URL.order.updateDeliveryMen(orderId),
    data: {
      delivery_men: deliveryMenId,
    },
  });
};

const exportCsv = async id => {
  return await get({
    url: `${API_URL.order.exportCsv(id)}`,
    config: {
      responseType: 'blob',
    },
  });
};

const exportMultipleOrdersCsv = async ids => {
  const dataCsv = await post({
    url: API_URL.order.exportMultipleOrdersCsv,
    config: {
      responseType: 'blob',
    },
    data: { ids },
  });
  return dataCsv;
};

export const orderService = {
  list,
  update,
  create,
  archive,
  getByStatus,
  getById,
  updateSelfProduct,
  getByDate,
  getOrderHistory,
  getDeliveryStaffs,
  updateDeliveryStaffs,
  exportCsv,
  exportMultipleOrdersCsv,
  getByDateRange,
};
