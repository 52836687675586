/* PERMISSIONS */
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_FAIL = 'GET_PERMISSIONS_FAIL';
export const GET_MY_PERMISSIONS = 'GET_MY_PERMISSIONS';
export const GET_MY_PERMISSIONS_SUCCESS = 'GET_MY_PERMISSIONS_SUCCESS';
export const GET_MY_PERMISSIONS_FAIL = 'GET_MY_PERMISSIONS_FAIL';


/**
 * RESET ERROR STATUS
 */
 export const RESET_ERROR_STATUS = 'RESET_ERROR_STATUS';