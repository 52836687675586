import {
  GET_PRODUCTS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  ADD_NEW_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  UPDATE_CATEGORIES_FOR_PRODUCT,
  UPDATE_CATEGORIES_FOR_PRODUCT_FAIL,
  UPDATE_CATEGORIES_FOR_PRODUCT_SUCCESS,
  RESET_ERROR_STATUS,
  GET_FILTERED_PRODUCTS_BY_USER_ID,
  GET_FILTERED_PRODUCTS_BY_USER_ID_SUCCESS,
  GET_FILTERED_PRODUCTS_BY_USER_ID_FAIL,
  GET_FILTERED_PRODUCTS,
  GET_FILTERED_PRODUCTS_SUCCESS,
  GET_FILTERED_PRODUCTS_FAIL,
  GET_PRODUCTS_BY_ID,
  GET_PRODUCTS_BY_ID_SUCCESS,
  GET_PRODUCTS_BY_ID_FAIL
} from './actionTypes';

export const getFilteredProductByUserId = userId => ({
  type: GET_FILTERED_PRODUCTS_BY_USER_ID,
  payload: userId
});

export const getFilteredProductByUserIdSuccess = products => ({
  type: GET_FILTERED_PRODUCTS_BY_USER_ID_SUCCESS,
  payload: products
});

export const getFilteredProductByUserIdFail = errors => ({
  type: GET_FILTERED_PRODUCTS_BY_USER_ID_FAIL,
  payload: errors
});

export const getProducts = () => ({
  type: GET_PRODUCTS
});

export const getProductsSuccess = products => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: products
});

export const getProductsByIdFail = error => ({
  type: GET_PRODUCTS_BY_ID_FAIL,
  payload: error
});

export const getProductsById = userId => ({
  type: GET_PRODUCTS_BY_ID,
  payload: userId
});

export const getProductsByIdSuccess = products => ({
  type: GET_PRODUCTS_BY_ID_SUCCESS,
  payload: products
});

export const getProductsFail = error => ({
  type: GET_PRODUCTS_FAIL,
  payload: error
});

export const addNewProduct = product => ({
  type: ADD_NEW_PRODUCT,
  payload: product
});

export const addProductSuccess = product => ({
  type: ADD_PRODUCT_SUCCESS,
  payload: product
});

export const addProductFail = error => ({
  type: ADD_PRODUCT_FAIL,
  payload: error
});

export const updateProduct = product => ({
  type: UPDATE_PRODUCT,
  payload: product
});

export const updateProductSuccess = product => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: product
});

export const updateProductFail = error => ({
  type: UPDATE_PRODUCT_FAIL,
  payload: error
});

export const updateCategoriesForProduct = product => ({
  type: UPDATE_CATEGORIES_FOR_PRODUCT,
  payload: product
});

export const updateCategoriesForProductFail = error => ({
  type: UPDATE_CATEGORIES_FOR_PRODUCT_FAIL,
  payload: error
});

export const updateCategoriesForProductSuccess = product => ({
  type: UPDATE_CATEGORIES_FOR_PRODUCT_SUCCESS,
  payload: product
});

export const deleteProduct = product => {
  return {
    type: DELETE_PRODUCT,
    payload: product
  };
};

export const deleteProductSuccess = id => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: id
});

export const deleteProductFail = error => ({
  type: DELETE_PRODUCT_FAIL,
  payload: error
});

export const resetErrorStatus = () => ({
  type: RESET_ERROR_STATUS
});

export const getFilteredProduct = keyword => ({
  type: GET_FILTERED_PRODUCTS,
  payload: keyword
});

export const getFilteredProductSuccess = products => ({
  type: GET_FILTERED_PRODUCTS_SUCCESS,
  payload: products
});

export const getFilteredProductFail = errors => ({
  type: GET_FILTERED_PRODUCTS_FAIL,
  payload: errors
});
