/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Card } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { get as _get, isEmpty } from 'lodash';

import BootstrapTable from 'react-bootstrap-table-next';
import logo from '../../assets/images/logo.jpg';

const PrintOrders = () => {
  const [recievedMessage, setRecievedMessage] = useState('');

  useEffect(() => {
    window.addEventListener('message', function (e) {
      setRecievedMessage(e.data);
    });
  }, []);

  const newOrders = _get(recievedMessage, 'newOrders', []);
  const productsSum = _get(recievedMessage, 'productsSum', {});
  const maxProduct = _get(recievedMessage, 'maxProduct', {});
  const date = _get(recievedMessage, 'date', '');
  const pathName = _get(recievedMessage, 'pathName', '');
  const name = _get(recievedMessage, 'name', '');

  let title = pathName === '/profile' ? `BON DE LIVRAISON: ${name}` : 'BON DE COMMANDE: GÉNÉRAL';
  let products = [];

  for (const [key, item] of Object.entries(productsSum)) {
    item.forEach(product => {
      products = [...products, product];
    });
  }

  const categoriesLabel = [];

  for (const [key] of Object.entries(productsSum)) {
    categoriesLabel.push(key);
  }

  const customProductCode = prod => {
    if (!prod) return '';
    const unitWeight = isEmpty(_get(prod, ['info', 'unit_weight'], ''))
        ? ''
        : _get(prod, ['info', 'unit_weight'], '');
    return `${_get(prod, 'amount', '')} ${_get(prod, 'item_code', '')}  ${unitWeight}`;
  };

  let res = [];

  Object.entries(productsSum).forEach(item => {
    const pros = item[1];
    const arrCustomPros = [];
    pros.map((arr) => {
      arrCustomPros.push(customProductCode(arr));
    });
    const obj = {
      'category': item[0],
      'products': arrCustomPros.join(' / ').replace(/^[/\s]+|[/\s]+$/g, '')
    };
    res.push(obj);
  });

  const productsColumn = [
    {
      dataField: 'category',
      text: 'Catégorie'
    },
    {
      dataField: 'products',
      text: 'Produits',
      sort: true,
      formatter: (cellContent, row) => (
          <>
            <p className='mb-0 break-line'>{_get(row, 'products', '')}</p>
          </>
      ),
    }
  ];

  const renderRow = row => {
    const privateComment = _get(row, 'private_comment', '');
    const publicComment = _get(row, 'comment', '');
    let result = [];
    if (!isEmpty(privateComment) && isEmpty(publicComment)) {
      result = [`Commentaire: ${privateComment}`];
    } else if (isEmpty(privateComment) && !isEmpty(publicComment)) {
      result = [`Commentaire: ${publicComment}`];
    } else if (!isEmpty(privateComment) && !isEmpty(publicComment)) {
      result = [`Commentaire: ${privateComment} | ${publicComment}`];
    }
    const deliveryMen = _get(row, ['delivery_men', 'name'], '');
    result = !isEmpty(deliveryMen ) ? [...result,`Livreur: ${deliveryMen}`] : [...result];
    return result.join(' / ');
  };

  const ordersColumns = [
    {
      dataField: 'brand_name',
      text: `Clients`,
      formatter: (cellContent, row) => {
        return (
          <>
            <p className='mb-0'>{_get(row, ['user', 'brand_name'], '')}</p>
          </>
        );
      },
    },
    {
      dataField: 'products',
      text: 'Commandes',
      classes: 'customTd',
      formatter: (cellContent, row) => {
        const productDetail = _get(row, 'products', []);
        const arrProducts = Object.values(productDetail);
        const countArrProducts = arrProducts.length;
        if (countArrProducts < maxProduct) {
          for (let i = 0; i < maxProduct - countArrProducts; i++) {
            arrProducts.push({
              id: 'prod' + i,
            });
          }
        }

        const arrCustomProducts = [];
        arrProducts.map((arr, index) => {
          arrCustomProducts.push(customProductCode(arr));
        });

        return (
          <div>
            <p className='mt-1 ms-2 break-line'>{arrCustomProducts.join(' / ').replace(/^[/\s]+|[/\s]+$/g, '')}</p>
            <p className='mt-1 ms-2 break-line'>
              {renderRow(row)}
            </p>
          </div>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div style={{ fontSize: 12, color: 'black' }} className='page-content'>
        <Container fluid>
          <Breadcrumbs title='orders' breadcrumbItem='orders' />
          <Row className='text-center'>
            <Col>
              <img src={logo} alt='' height='70' className='mb-3'/>
            </Col>
            <Col>
              {title}
              <br />
              Date de livraison: {date.replace(/(\d{4})(\d{2})(\d{2})/g, '$3/$2/$1')}
            </Col>
          </Row>
          <Row>
            <Col className='col-12'>
              <Card>
                <div className='table-responsive'>
                  <BootstrapTable
                    responsive
                    bordered={true}
                    striped={true}
                    classes={'table align-middle table-nowrap table-check'}
                    headerWrapperClasses={'table-light'}
                    keyField='id'
                    data={newOrders || []}
                    bar
                    columns={ordersColumns}
                  />
                </div>
              </Card>
            </Col>
          </Row>
          {!isEmpty(categoriesLabel) && (
            <Row>
              <Col className='col-12'>
                <Card>
                  <div className='table-responsive'>
                    <BootstrapTable
                        responsive
                        bordered={true}
                        striped={false}
                        classes={'table align-middle table-nowrap table-check'}
                        headerWrapperClasses={'table-light'}
                        keyField='id'
                        data={res || []}
                        columns={productsColumn}
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrintOrders;
