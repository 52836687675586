const BASE_API_URL = `${process.env.REACT_APP_API_URL}/api-admin/admin`;

export const API_URL = {
  authentication: {
    login: `${BASE_API_URL}/login`
  },
  admin: {
    getProfile: `${BASE_API_URL}/`,
    list: `${BASE_API_URL}/index`,
    create: `${BASE_API_URL}`,
    update: id => `${BASE_API_URL}/${id}`,
    archive: `${BASE_API_URL}/`
  },
  group: {
    list: `${BASE_API_URL}/group/index`,
    getAll: `${BASE_API_URL}/group/list`,
    create: `${BASE_API_URL}/group`,
    update: `${BASE_API_URL}/group/`,
    archive: `${BASE_API_URL}/group/`,
    subList: groupId => `${BASE_API_URL}/group/${groupId}/subs`,
    categoryDependencies: `${BASE_API_URL}/permissionCategory`
  },
  user: {
    search: `${BASE_API_URL}/user/search`,
    getProfile: `${BASE_API_URL}/user/`,
    list: `${BASE_API_URL}/user/index`,
    create: `${BASE_API_URL}/user`,
    update: `${BASE_API_URL}/user/`,
    archive: `${BASE_API_URL}/user/`,
    addUserIntoGroup: groupId => `${BASE_API_URL}/permissionUser/${groupId}`,
    getById: userId => `${BASE_API_URL}/user/${userId}`,
    updateUserStatus: `${BASE_API_URL}/user/updateStatus`,
    searchByGroup: `${BASE_API_URL}/user/searchByGroup`,
    deleteUserOnGroup: groupId => `${BASE_API_URL}/permissionUser/delete/${groupId}`,
    addUserFavoriteProduct: userId => `${BASE_API_URL}/favorite/${userId}`,
    exportCsv: userId => `${BASE_API_URL}/user/${userId}/export`
  },
  category: {
    list: `${BASE_API_URL}/category/index`,
    create: `${BASE_API_URL}/category`,
    update: `${BASE_API_URL}/category/`,
    archive: `${BASE_API_URL}/category/`,
    getSubs: categoryId => `${BASE_API_URL}/category/${categoryId}/subs`,
    listWithoutSubs: `${BASE_API_URL}/category/list`,
    groupsForCategory: categoryId => `${BASE_API_URL}/category/${categoryId}/group`,
    getProductsForCategory: categoryId => `${BASE_API_URL}/category/${categoryId}/product`,
    updateGroupsForCategory: categoryId => `${BASE_API_URL}/category/${categoryId}/group`,
    updateProductsForCategory: categoryId => `${BASE_API_URL}/category/${categoryId}/product`,
    listWithoutSubsByUserId: `${BASE_API_URL}/category/permission`,
  },
  order: {
    updateProduct: productId => `${BASE_API_URL}/order/${productId}/product`,
    get: orderId => `${BASE_API_URL}/order/${orderId}`,
    list: userId => `${BASE_API_URL}/user/${userId}/orders`,
    create: `${BASE_API_URL}/order`,
    archive: orderId => `${BASE_API_URL}/order/${orderId}`,
    update: orderId => `${BASE_API_URL}/order/${orderId}`,
    getByStatus: `${BASE_API_URL}/order/index`,
    getById: orderId => `${BASE_API_URL}/order/${orderId}`,
    getByDate: date => `${BASE_API_URL}/order/by-date/${date}`,
    getOrderHistory: id => `${BASE_API_URL}/historyOrder/${id}`,
    deliveryStaffs: `${BASE_API_URL}/delivery/staffs`,
    updateDeliveryMen: orderId => `${BASE_API_URL}/order/${orderId}/updateDeliveryMen`,
    exportCsv: id => `${BASE_API_URL}/order/${id}/export`,
    exportMultipleOrdersCsv: `${BASE_API_URL}/order/export`,
    getOrdersByDateRange: ({ start, end }) => `${BASE_API_URL}/order/created-date/${start}/${end}`
  },
  product: {
    listByUserId: userId => `${BASE_API_URL}/user/${userId}/products`,
    list: `${BASE_API_URL}/product/index`,
    create: `${BASE_API_URL}/product`,
    update: `${BASE_API_URL}/product/`,
    archive: `${BASE_API_URL}/product/`,
    updateCategories: `${BASE_API_URL}/permissionProduct`,
    filterProducts: `${BASE_API_URL}/product/search`,
    listProductsByUserId: `${BASE_API_URL}/product/permission`,
  },
  notifications: {
    list: `${BASE_API_URL}/notification/index`,
    create: `${BASE_API_URL}/notification`
  },
  messages: {
    list: `${BASE_API_URL}/messages/index`,
    create: `${BASE_API_URL}/messages`,
    listUserForMessages: messageId => `${BASE_API_URL}/messages/${messageId}/user`,
    update: messageId => `${BASE_API_URL}/messages/${messageId}`,
  },
  staffGroup: {
    list: `${BASE_API_URL}/team/index`,
    create: `${BASE_API_URL}/team`,
    update: teamId => `${BASE_API_URL}/team/${teamId}`,
    archive: `${BASE_API_URL}/team/`
  },
  permissions: {
    list: `${BASE_API_URL}/permission/index`,
    myPermissions: `${BASE_API_URL}/permission`
  }
};

export const APP = {
  ENTERPRISE_NAME: 'Le Diamant Du Terroir',
  EMAIL: 'digitalrubis@gmail.com',
  TELEPHONE: '+33 6 19 40 44 93/ +33 4 22 53 15 15'
};
