import { API_URL } from '../constants';
import { del, get, post, put } from '../helpers/apiHelper';

const list = async () => {
  const token = localStorage.getItem('diament');
  return await get({
    url: API_URL.user.list,
    config: {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  });
};

const update = async ({ id, user }) => {
  const token = localStorage.getItem('diament');
  return await put({
    url: `${API_URL.user.update}${id}`,
    data: user,
    config: {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  });
};

const create = async user => {
  const token = localStorage.getItem('diament');
  return await post({
    url: API_URL.user.create,
    data: user,
    config: {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  });
};

const archive = async user => {
  const token = localStorage.getItem('diament');
  return await del({
    url: `${API_URL.user.archive}${user.id}`,
    config: {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  });
};

const addUserIntoGroup = async ({ userId, groupId }) => {
  return await post({
    url: API_URL.user.addUserIntoGroup(groupId),
    data: {
      user_id: userId
    }
  });
};

const search = async query => {
  return await post({
    url: API_URL.user.search,
    data: {
      query
    }
  });
};

const getById = async id => {
  return await get({
    url: `${API_URL.user.getById(id)}`
  });
};

const searchByGroup = async ({ query, groupId }) => {
  return await post({
    url: API_URL.user.searchByGroup,
    data: {
      query,
      group_id: groupId
    }
  });
};

const updateUserStatus = async ({ userIds, userStatus }) => {
  return await post({
    url: API_URL.user.updateUserStatus,
    data: {
      ids: userIds,
      status: userStatus
    }
  });
};

const deleteUserOnGroup = async ({ groupId, userIds }) => {
  return await post({
    url: API_URL.user.deleteUserOnGroup(groupId),
    data: {
      user_ids: userIds
    }
  });
};

const addUserFavoriteProduct = async ({ userId, productId })  =>{
  return await put({
    url: `${API_URL.user.addUserFavoriteProduct(userId)}`,
    data: {
      product_id: productId
    }
  });
};

const exportUserCsv = async ({ range, userId }) => {
  return await post({
    url: API_URL.user.exportCsv(userId),
    data: {
      start: range?.start,
      end: range?.end
    },
    config: {
      responseType: 'blob',
    },
  });
};

export const userService = {
  addUserIntoGroup,
  list,
  update,
  create,
  archive,
  search,
  getById,
  updateUserStatus,
  searchByGroup,
  deleteUserOnGroup,
  addUserFavoriteProduct,
  exportUserCsv
};
