import { get as _get } from 'lodash';
import { call, put, takeEvery } from 'redux-saga/effects';
import { categoryService } from 'services';
import { catchError } from 'store/errors/actions';
import {
  addCategoryFail,
  addCategorySuccess,
  deleteCategoryFail,
  deleteCategorySuccess,
  getCategoriesFail,
  getCategoriesSuccess,
  updateCategoryFail,
  updateCategorySuccess,
  getSubCategoriesSuccess,
  getSubCategoriesFail,
  addSubCategorySuccess,
  updateSubCategorySuccess,
  deleteSubCategorySuccess,
  getCategoriesWithoutSubsFail,
  getCategoriesWithoutSubsSuccess,
  getCategoriesWithoutSubsByIdFail,
  getCategoriesWithoutSubsByIdSuccess,
  swapCategoryPositionSuccess,
  swapCategoryPositionFailure,
  swapSubCategoryPositionSuccess,
  getGroupsForCategorySuccess,
  getGroupsForCategoryFail,
  getProductsForCategorySuccess,
  getProductsForCategoryFail,
  updateGroupsForCategorySuccess,
  updateGroupsForCategoryFail,
  updateProductsForCategorySuccess,
  updateProductsForCategoryFail
} from './actions';
import {
  ADD_NEW_CATEGORY,
  DELETE_CATEGORY,
  GET_CATEGORIES,
  UPDATE_CATEGORY,
  GET_SUB_CATEGORIES,
  GET_CATEGORIES_WITHOUT_SUBS,
  GET_CATEGORIES_WITHOUT_SUBS_BY_ID,
  SWAP_CATEGORY_POSITION,
  GET_GROUPS_FOR_CATEGORY,
  GET_PRODUCTS_FOR_CATEGORY,
  UPDATE_GROUPS_FOR_CATEGORY,
  UPDATE_PRODUCTS_FOR_CATEGORY
} from './actionTypes';

function* fetchCategoriesFromBE() {
  try {
    const res = yield call(categoryService.list);
    const objRespond = getCategoriesSuccess(_get(res, 'results', {}));
    const objCategories = _get(objRespond, 'payload', {});
    const arrCategories = Object.values(objCategories);
    yield put(getCategoriesSuccess(arrCategories));
  } catch (error) {
    yield put(catchError(error));
    yield put(getCategoriesFail(error));
  }
}

function* fetchSubCategoriesFromBE({ payload }) {
  try {
    const res = yield call(categoryService.getSubs, payload);
    const objRespond = getCategoriesSuccess(_get(res, 'results', {}));
    const objSubCategories = _get(objRespond, 'payload', {});
    const arrSubCategories = Object.values(objSubCategories);
    yield put(getSubCategoriesSuccess(arrSubCategories));
  } catch (error) {
    yield put(catchError(error));
    yield put(getSubCategoriesFail(error));
  }
}

function* addNewCategory({ payload }) {
  try {
    const position = _get(payload, 'position', 0);
    const type = _get(payload, 'type', null);
    let obj = {
      name: _get(payload, 'name', ''),
      description: _get(payload, 'description', ''),
      permission_category: _get(payload, 'permissionCategory', ''),
      position: position === 0 ? 0 : position + 1
    };
    obj = type
      ? {
          ...obj,
          type
        }
      : obj;
    const res = yield call(categoryService.create, obj);
    const results = _get(res, 'results', {});
    yield put(type ? addSubCategorySuccess(results) : addCategorySuccess(results));
  } catch (error) {
    yield put(catchError(error));
    yield put(addCategoryFail(error));
  }
}

function* swapCategoryPosition({ payload }) {
  try {
    yield call(categoryService.update, {
      id: _get(payload, 'id', ''),
      category: {
        position: _get(payload, 'newPosition', '')
      }
    });
    const type = _get(payload, 'type', '');
    yield !type
      ? put(swapCategoryPositionSuccess(payload))
      : put(swapSubCategoryPositionSuccess(payload));
  } catch (errors) {
    yield put(catchError(errors));
    yield put(swapCategoryPositionFailure(errors));
  }
}

function* updateCategory({ payload }) {
  try {
    const newPosition = _get(payload, 'position', 0);
    const oldPosition = _get(payload, 'oldPosition', 0);
    const type = _get(payload, 'type', null);
    const sub = _get(payload, 'sub', {});
    let obj = {
      name: _get(payload, 'name', ''),
      description: _get(payload, 'description', '')
    };
    obj =
      newPosition === 0
        ? obj
        : {
            ...obj,
            position: newPosition > oldPosition ? newPosition : newPosition + 1
          };
    obj = type
      ? {
          ...obj,
          type
        }
      : obj;
    const res = yield call(categoryService.update, {
      id: _get(payload, 'id', ''),
      category: obj
    });
    const results = _get(res, 'results', {});
    yield put(
      type
        ? updateSubCategorySuccess({
            categoryId: type,
            results
          })
        : updateCategorySuccess({ ...results, sub })
    );
  } catch (error) {
    yield put(catchError(error));
    yield put(updateCategoryFail(error));
  }
}

function* deleteCategory({ payload }) {
  try {
    const id = _get(payload, 'id', '');
    const type = _get(payload, 'type');
    yield call(categoryService.archive, id);
    yield put(
      type
        ? deleteSubCategorySuccess({
            id,
            categoryId: type
          })
        : deleteCategorySuccess({ id, position: _get(payload, 'position', -1) })
    );
  } catch (error) {
    yield put(catchError(error));
    yield put(deleteCategoryFail(error));
  }
}

function* fetchCategoriesWithoutSubsFromBE() {
  try {
    const res = yield call(categoryService.listWithoutSubs);
    const objRespond = getCategoriesWithoutSubsSuccess(_get(res, 'results', {}));
    const objCategories = _get(objRespond, 'payload', {});
    const arrCategories = Object.values(objCategories);
    yield put(getCategoriesWithoutSubsSuccess(arrCategories));
  } catch (error) {
    yield put(catchError(error));
    yield put(getCategoriesWithoutSubsFail(error));
  }
}

function* fetchCategoriesWithoutSubsByUserIdFromBE({ payload }) {
  try {
    const res = yield call(categoryService.listWithoutSubsByUserId, payload);
    const objRespond = getCategoriesWithoutSubsByIdSuccess(_get(res, 'results', []));
    const objCategories = _get(objRespond, 'payload', {});
    const arrCategories = Object.values(objCategories);
    yield put(getCategoriesWithoutSubsByIdSuccess(arrCategories));
  } catch (error) {
    yield put(catchError(error));
    yield put(getCategoriesWithoutSubsByIdFail(error));
  }
}

function* getGroupsForCategory({ payload }) {
  try {
    const res = yield call(categoryService.getGroupsForCategory, payload);
    yield put(getGroupsForCategorySuccess(_get(res, 'results', {})));
  } catch (error) {
    yield put(catchError(error));
    yield put(getGroupsForCategoryFail(error));
  }
}

function* getProductsForCategory({ payload }) {
  try {
    const res = yield call(categoryService.getProductsForCategory, payload);
    yield put(getProductsForCategorySuccess(_get(res, 'results', {})));
  } catch (error) {
    yield put(catchError(error));
    yield put(getProductsForCategoryFail(error));
  }
}

function* updateGroupsForCategory({ payload }) {
  try {
    const res = yield call(categoryService.updateGroupsForCategory, {
      categoryId: _get(payload, 'categoryId', ''),
      groupIds: _get(payload, 'groupIds', [])
    });
    yield put(updateGroupsForCategorySuccess(_get(res, 'results', {})));
  } catch (error) {
    yield put(catchError(error));
    yield put(updateGroupsForCategoryFail(error));
  }
}

function* updateProductsForCategory({ payload }) {
  try {
    const categoryId = _get(payload, 'categoryId', '');
    const subCategoryId = _get(payload, 'subCategoryId', '');
    const isSub = _get(payload, 'isSub', false);
    const res = yield call(categoryService.updateProductsForCategory, {
      categoryId: isSub ? subCategoryId : categoryId,
      productIds: _get(payload, 'productIds', [])
    });
    yield put(
      updateProductsForCategorySuccess({
        result: _get(res, 'results', {}),
        categoryId,
        isSub,
        subCategoryId
      })
    );
  } catch (error) {
    yield put(catchError(error));
    yield put(updateProductsForCategoryFail(error));
  }
}

function* categoriesSaga() {
  yield takeEvery(GET_CATEGORIES, fetchCategoriesFromBE);
  yield takeEvery(GET_CATEGORIES_WITHOUT_SUBS, fetchCategoriesWithoutSubsFromBE);
  yield takeEvery(GET_CATEGORIES_WITHOUT_SUBS_BY_ID, fetchCategoriesWithoutSubsByUserIdFromBE);
  yield takeEvery(GET_SUB_CATEGORIES, fetchSubCategoriesFromBE);
  yield takeEvery(ADD_NEW_CATEGORY, addNewCategory);
  yield takeEvery(UPDATE_CATEGORY, updateCategory);
  yield takeEvery(DELETE_CATEGORY, deleteCategory);
  yield takeEvery(SWAP_CATEGORY_POSITION, swapCategoryPosition);
  yield takeEvery(GET_GROUPS_FOR_CATEGORY, getGroupsForCategory);
  yield takeEvery(GET_PRODUCTS_FOR_CATEGORY, getProductsForCategory);
  yield takeEvery(UPDATE_GROUPS_FOR_CATEGORY, updateGroupsForCategory);
  yield takeEvery(UPDATE_PRODUCTS_FOR_CATEGORY, updateProductsForCategory);
}

export default categoriesSaga;
