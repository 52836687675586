import {
  GET_ADMINS,
  GET_ADMINS_FAIL,
  GET_ADMINS_SUCCESS,
  ADD_NEW_ADMIN,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAIL,
  UPDATE_ADMIN,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_FAIL,
  DELETE_ADMIN,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAIL,
  RESET_ERROR_STATUS
} from './actionTypes';

const INIT_STATE = {
  error: '',
  success: '',
};

const AdminsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_NEW_ADMIN:
    case UPDATE_ADMIN:
    case GET_ADMINS:
    case DELETE_ADMIN:
      return {
        ...state,
        isLoadingAdmins: true,
        isSuccess: false,
        errors: '',
      };
    case GET_ADMINS_SUCCESS:
      return {
        ...state,
        isLoadingAdmins: false,
        admins: action.payload,
      };
    case GET_ADMINS_FAIL:
      return {
        ...state,
        isLoadingAdmins: false,
        errors: action.payload,
      };
    case ADD_ADMIN_SUCCESS:
      return {
        ...state,
        isLoadingAdmins: false,
        admins: [action.payload, ...state.admins],
        isSuccess: true,
      };
    case ADD_ADMIN_FAIL:
      return {
        ...state,
        isLoadingAdmins: false,
        errors: action.payload,
        isSuccess: false,
      };
    case UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        isLoadingAdmins: false,
        admins: state.admins.map(admin =>
          admin.id.toString() === action.payload.id.toString()
            ? { admin, ...action.payload }
            : admin
        ),
        isSuccess: true,
      };
    case UPDATE_ADMIN_FAIL:
      return {
        ...state,
        isLoadingAdmins: false,
        errors: action.payload,
        isSuccess: false,
      };
    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        isLoadingAdmins: false,
        admins: state.admins.filter(admin => admin.id.toString() !== action.payload.toString()),
        isSuccess: true,
      };
    case DELETE_ADMIN_FAIL:
      return {
        ...state,
        isLoadingAdmins: false,
        errors: action.payload,
        isSuccess: false,
      };
    case RESET_ERROR_STATUS:
      return {
        ...state,
        errors: undefined,
        isSuccess: false,
      };
    default:
      return state;
  }
};

export default AdminsReducer;
