import { post, get, del, put } from '../helpers/apiHelper';
import { API_URL } from '../constants';
import { getFormData } from 'utils/api';

const list = async () => {
  return await get({
    url: API_URL.category.list
  });
};

const update = async ({ id, category }) => {
  return await post({
    url: `${API_URL.category.update}${id}`,
    data: getFormData(category),
    config: {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  });
};

const create = async category => {
  return await post({
    url: API_URL.category.create,
    data: getFormData(category),
    config: {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  });
};

const archive = async id => {
  return await del({
    url: `${API_URL.category.archive}${id}`
  });
};

const getSubs = async categoryId => {
  return await get({
    url: API_URL.category.getSubs(categoryId)
  });
};

const listWithoutSubs = async () => {
  return await get({
    url: API_URL.category.listWithoutSubs
  });
};

const listWithoutSubsByUserId = async data => {
  return await post({
    url: API_URL.category.listWithoutSubsByUserId,
    data
  });
};

const getGroupsForCategory = async categoryId => {
  return await get({
    url: API_URL.category.groupsForCategory(categoryId)
  });
};

const getProductsForCategory = async categoryId => {
  return await get({
    url: API_URL.category.getProductsForCategory(categoryId)
  });
};

const updateGroupsForCategory = async ({ categoryId, groupIds }) => {
  return await put({
    url: API_URL.category.updateGroupsForCategory(categoryId),
    data: {
      group_ids: groupIds
    }
  });
};

const updateProductsForCategory = async ({ categoryId, productIds }) => {
  return await put({
    url: API_URL.category.updateProductsForCategory(categoryId),
    data: {
      product_ids: productIds
    }
  });
};

export const categoryService = {
  list,
  update,
  create,
  archive,
  getSubs,
  listWithoutSubs,
  listWithoutSubsByUserId,
  getGroupsForCategory,
  getProductsForCategory,
  updateGroupsForCategory,
  updateProductsForCategory
};
