import { get as _get } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table } from 'react-super-responsive-table';
import { Container, Col, Row } from 'reactstrap';
import { getOrderById } from 'store/orders/actions';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import * as moment from 'moment';
import logo from '../../assets/images/logo.jpg';

const PrintOrderDetail = props => {
  const orderId = _get(props, ['match', 'params', 'id'], '');
  const dispatch = useDispatch();

  const { orderDetail } = useSelector(state => {
    return {
      orderDetail: _get(state, ['orders', 'orderDetail'], {})
    };
  });

  useEffect(() => {
    dispatch(getOrderById(orderId));
  }, []);

  const handleValidDate = date => {
    return moment(new Date(date)).format('DD MMM Y');
  };

  const products = _get(orderDetail, 'products', []);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='orders' breadcrumbItem='orderdetail' />
          <Row className='text-center'>
            <Col>
              <img src={logo} alt='' height='70' className='mb-3'/>
            </Col>
            <Col>
              BON DE COMMANDE
              <br />
              APPLICATION MOBILE
              <br />
              <br />
              Client: {_get(orderDetail, ['user', 'brand_name'], '')}
            </Col>
          </Row>
          <Row className='mt-3'>
            <Table className='table table-bordered mb-0'>
              <thead className='table-light'>
                <tr>
                  <th>Numéro de commande</th>
                  <th>Numéro de facture</th>
                  <th>Date commande</th>
                  <th>Date livraison</th>
                  <th>Code client</th>
                  <th>Livreur</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{_get(orderDetail, 'code', '')}</td>
                  <td>{_get(orderDetail, 'bill_number', '')}</td>
                  <td>{handleValidDate(_get(orderDetail, 'created_at', ''))}</td>
                  <td>{handleValidDate(_get(orderDetail, 'date_of_delivery', ''))}</td>
                  <td>{_get(orderDetail, ['user', 'code'], '')}</td>
                  <td>{_get(orderDetail, ['delivery_men', 'name'], '')}</td>
                </tr>
              </tbody>
            </Table>
          </Row>
          <Row className='mt-3'>
            <Table className='table table-bordered mb-0'>
              <tbody>
                <tr>
                  <td className='table-print comment-print'>Commentaire</td>
                  <td>
                    {_get(orderDetail, 'comment', '')}
                    <br />
                    {_get(orderDetail, 'private_comment', '')}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>
          <Row className='mt-3'>
            <Table className='table table-bordered mb-0'>
              <thead className='table-light'>
                <tr>
                  <th className='table-print'>Code article</th>
                  <th>Produit</th>
                  <th>Quantité</th>
                </tr>
              </thead>
              <tbody>
                {products.map(product => (
                  <tr key={_get(product, 'id', '')}>
                    <td className='table-print'>{_get(product, 'item_code', '')}</td>
                    <td>{_get(product, 'title', '')}</td>
                    <td>{_get(product, 'amount', '')}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PrintOrderDetail);
