import { PERMISSIONS } from 'utils/permissions';

export const ROUTES = {
    userList: {
        url: '/users',
        availablePermissions: [
            PERMISSIONS.CUSTOMER_MANAGEMENT.CREATE_CUSTOMER,
            PERMISSIONS.CUSTOMER_MANAGEMENT.CHANGE_STATUS_CUSTOMER,
            PERMISSIONS.CUSTOMER_MANAGEMENT.MODIFY_DELETE_CUSTOMER
        ],
    },
    userDetail: {
        url: '/user/:id',
        availablePermissions: [
            PERMISSIONS.CUSTOMER_MANAGEMENT.CHANGE_STATUS_CUSTOMER,
            PERMISSIONS.CUSTOMER_MANAGEMENT.MODIFY_DELETE_CUSTOMER
        ],
    },
    orders: {
        url: '/orders',
        availablePermissions: [
            PERMISSIONS.ORDER_MANAGEMENT.ACCESS_ORDER_FULL,
            PERMISSIONS.ORDER_MANAGEMENT.ACCESS_ORDER_SIMP,
            PERMISSIONS.ORDER_MANAGEMENT.ADD_ORDER,
            PERMISSIONS.ORDER_MANAGEMENT.ASSIGN_DELIVERY,
            PERMISSIONS.ORDER_MANAGEMENT.CHANGE_STATUS_ORDER,
            PERMISSIONS.ORDER_MANAGEMENT.MODIFY_DELETE_ORDER,
        ],
    },
    orderDetail: {
        url: '/order',
        availablePermissions: [
            PERMISSIONS.ORDER_MANAGEMENT.ASSIGN_DELIVERY,
            PERMISSIONS.ORDER_MANAGEMENT.CHANGE_STATUS_ORDER,
            PERMISSIONS.ORDER_MANAGEMENT.MODIFY_DELETE_ORDER,
            PERMISSIONS.ORDER_MANAGEMENT.MODIFY_DELETE_PRODUCT_ORDER,
            PERMISSIONS.ORDER_MANAGEMENT.ADD_PRODUCT_ORDER
        ],
    },
    categoriesList: {
        url: '/categories',
        availablePermissions: [
            PERMISSIONS.PRODUCT_MANAGEMENT.CREATE_PRODUCT_CATEGORIES,
            PERMISSIONS.PRODUCT_MANAGEMENT.EDIT_DELETE_CATEGORIES
        ],
    },
    productList: {
        url: '/products',
        availablePermissions: [
            PERMISSIONS.PRODUCT_MANAGEMENT.CREATE_PRODUCTS,
            PERMISSIONS.PRODUCT_MANAGEMENT.EDIT_DELETE_PRODUCTS
        ],
    },
    groupList: {
        url: '/groups',
        availablePermissions: [
            PERMISSIONS.CUSTOMER_MANAGEMENT.CREATE_CUSTOMER_GROUP,
            PERMISSIONS.CUSTOMER_MANAGEMENT.MODIFY_DELETE_CUSTOMER_GROUP
        ],
    },
    notifications: {
        url: '/notifications',
        availablePermissions: [
            PERMISSIONS.MESSAGES_NOTIFICATIONS
        ],
    },
    messages: {
        url: '/messages',
        availablePermissions: [
            PERMISSIONS.MESSAGES_NOTIFICATIONS
        ],
    },
    staffGroup: {
        url: '/staff-groups',
        availablePermissions: [
            PERMISSIONS.ACCESS_MANAGEMENT_INTERFACE
        ],
    }
};
