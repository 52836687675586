import React from "react";

import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

const WelcomeComp = () => {
  return (
    <React.Fragment>
      <Card className='overflow-hidden'>
        <div className='bg-primary bg-soft'>
          <Row>
            <Col xs='7'>
              <div className='text-primary p-3'>
                <h5 className='text-primary'>Welcome Back !</h5>
                <p>Skote Dashboard</p>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody className='pt-0'>
          <Row>
            <Col sm='4'>
              <h5 className='font-size-15 text-truncate'>Henry Price</h5>
              <p className='text-muted mb-0 text-truncate'>UI/UX Designer</p>
            </Col>

            <Col sm='8'>
              <div className='pt-4'>
                <Row>
                  <Col xs='6'>
                    <h5 className='font-size-15'>125</h5>
                    <p className='text-muted mb-0'>Projects</p>
                  </Col>
                  <Col xs='6'>
                    <h5 className='font-size-15'>$1245</h5>
                    <p className='text-muted mb-0'>Revenue</p>
                  </Col>
                </Row>
                <div className='mt-4'>
                  <Link
                    to=''
                    className='btn btn-primary  btn-sm'
                  >
                    View Profile <i className='mdi mdi-arrow-right ms-1'></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default WelcomeComp;
