import { AvForm } from 'availity-reactstrap-validation';
// eslint-disable-next-line no-unused-vars
import { get as _get, isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ACTION_LABELS } from './constants';
import { withTranslation } from 'react-i18next';
import { customStyleSelect } from '../../../constants/styles';

const DeliveryMenForm = props => {
  const { handleSubmit, deliveryMen = {}, action, deliveryStaffs, t: translator } = props;
  const [selectedDeliveryMen, setSelectedDeliveryMen] = useState({});

  useEffect(() => {
    if (!isEmpty(deliveryMen) || !isNull(deliveryMen)) {
      setSelectedDeliveryMen({
        id: deliveryMen.id,
        label: deliveryMen.name,
        name: deliveryMen.name
      });
    }
  }, []);

  const optionDeliveryMen = deliveryStaffs.map(deliveryStaff => {
    const name = _get(deliveryStaff, 'name', '');
    const objDeliveryMens = {
      options: [
        {
          id: _get(deliveryStaff, 'id', ''),
          label: name,
          value: name
        }
      ]
    };
    return objDeliveryMens;
  });

  const onSubmit = (e, values) => {
    handleSubmit(e, {
      ...values,
      deliveryMen: selectedDeliveryMen || ''
    });
  };

  const handleSelectDeliveryMen = target => {
    setSelectedDeliveryMen(target);
  };

  return (
    <AvForm onValidSubmit={onSubmit}>
      <div className='mb-4'>
        <label className='control-label'>{translator('delivery man')}</label>
        <Select
          styles={customStyleSelect}
          value={selectedDeliveryMen || ''}
          onChange={values => {
            handleSelectDeliveryMen(values);
          }}
          options={optionDeliveryMen}
          classNamePrefix='select2-selection'
          placeholder={translator('select')}
        />
      </div>
      <div className='text-end'>
        <button type='submit' className='btn btn-success save-customer'>
          {translator(ACTION_LABELS[action])}
        </button>
      </div>
    </AvForm>
  );
};

DeliveryMenForm.propTypes = {
  handleSubmit: PropTypes.func,
  deliveryMen: PropTypes.object,
  action: PropTypes.string,
  deliveryStaffs: PropTypes.array,
  t: PropTypes.any
};

export default withTranslation()(DeliveryMenForm);
