export const SELECTIVE_TAB = {
  NEW_ORDER: 'new order',
  PREPARATION: 'preparation',
  DELAYED: 'delayed',
  PROCESSED: 'processed',
};

export const STATUS = {
  1: SELECTIVE_TAB.NEW_ORDER,
  2: SELECTIVE_TAB.PREPARATION,
  3: SELECTIVE_TAB.DELAYED,
  4: SELECTIVE_TAB.PROCESSED,
};

export const ORDER_ACTION = {
  ADDING_PRODUCT: 'ADDING_PRODUCT',
  EDITTING_PRODUCT: 'EDITTING_PRODUCT',
  DELETING_PRODUCT: 'DELETING_PRODUCT',
  UPDATE_ORDER_STATUS: 'UPDATE_ORDER_STATUS',
  UPDATE_ORDER: 'UPDATE_ORDER',
};

export const ORDER_HIGHER_ACTION_VALUE = {
  ADD_NEW_ORDER: 'ADD_NEW_ORDER',
  PRINT_PDF: 'PRINT_PDF',
  EXPORT_CSV: 'EXPORT_CSV',
  DELETE_ORDER: 'DELETE_ORDER'
};

export const ORDER_HIGHER_ACTION_LABELS = {
  ADD_NEW_ORDER: 'add new order',
  PRINT_PDF: 'print pdf',
  EXPORT_CSV: 'export csv',
  DELETE_ORDER: 'delete order',
};

export const ORDER_HIGHER_ACTION = [
  {
    value: ORDER_HIGHER_ACTION_VALUE.ADD_NEW_ORDER,
    label: ORDER_HIGHER_ACTION_LABELS.ADD_NEW_ORDER,
  },
  {
    value: ORDER_HIGHER_ACTION_VALUE.PRINT_PDF,
    label: ORDER_HIGHER_ACTION_LABELS.PRINT_PDF,
  },
  {
    value: ORDER_HIGHER_ACTION_VALUE.EXPORT_CSV,
    label: ORDER_HIGHER_ACTION_LABELS.EXPORT_CSV,
  },
  {
    value: ORDER_HIGHER_ACTION_VALUE.DELETE_ORDER,
    label: ORDER_HIGHER_ACTION_LABELS.DELETE_ORDER,
  },
];

export const SELECTIVE_TAB_VALUE = {
  [SELECTIVE_TAB.NEW_ORDER]: '1',
  [SELECTIVE_TAB.PREPARATION]: '2',
  [SELECTIVE_TAB.DELAYED]: '3',
  [SELECTIVE_TAB.PROCESSED]: '4',
};

export const SELECTIVE_TAB_INDEX = {
  [SELECTIVE_TAB.NEW_ORDER]: '0',
  [SELECTIVE_TAB.PREPARATION]: '1',
  [SELECTIVE_TAB.DELAYED]: '2',
  [SELECTIVE_TAB.PROCESSED]: '3',
};

export const REFRESH_DURATION = 60000;