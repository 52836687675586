import { INFERIOR_ADMIN_ROLE, SUPERIOR_ADMIN_ROLE } from 'constants/index';
import { get as _get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { getProfile } from 'store/actions';
import { getMyPermissions } from 'store/permission/actions';
import { PERMISSIONS } from 'utils/permissions';
import { ROUTES } from './constants';
import { intersection } from 'lodash';

const LOGIN_PATH = '/login';
const NOT_FOUND = '/pages-404';
const PROFILE = '/profile';
const ORDERS = '/orders';

const SuperAdminMiddleware = props => {
  const { component: Component, layout: Layout, ...rest } = props;
  const { role, myPermissions } = useSelector(state => ({
    role: _get(state, ['Profile', 'profile', 'role'], undefined),
    myPermissions: _get(state, ['permissions', 'myPermissions'], []),
  }));

  const [isAccessAdminPermission, setIsAccessAdminPermission] = useState(true);

  useEffect(() => {
    if (isEmpty(myPermissions)) return;
    const arr = [];
    myPermissions.forEach(permission => {
      const permissionName = _get(permission, 'name', '');
      arr.push(permissionName);
    });
    setIsAccessAdminPermission(arr.includes(PERMISSIONS.ACCESS_MANAGEMENT_INTERFACE));
  }, [myPermissions]);
  return (
    <Authmiddleware {...props}>
      <Route
        {...rest}
        render={componentProps => {
          if (role === undefined) return '';
          if (role === INFERIOR_ADMIN_ROLE && !isAccessAdminPermission) {
            return (
              <Redirect to={{ pathname: NOT_FOUND, state: { from: componentProps.location } }} />
            );
          }
          return (
            <Layout>
              <Component {...componentProps} />
            </Layout>
          );
        }}
      />
    </Authmiddleware>
  );
};

SuperAdminMiddleware.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

const Authmiddleware = ({ component: Component, layout: Layout, isAuthProtected, ...rest }) => {
  const history = useHistory();
  const isTokenExisted = !isEmpty(localStorage.getItem('diament'));
  const isOnLogin = useMemo(() => _get(history, ['location', 'pathname'], '') === LOGIN_PATH);
  const { profile, myPermissions } = useSelector(state => ({
    profile: _get(state, ['Profile', 'profile'], {}),
    myPermissions: _get(state, ['permissions', 'myPermissions'], []),
  }));
  const { path } = rest;

  const dispatch = useDispatch();
  useEffect(() => {
    if (isEmpty(profile) && isTokenExisted) {
      dispatch(getProfile());
    }
    if (isEmpty(myPermissions)) {
      dispatch(getMyPermissions());
    }
  }, []);

  const checkPermission = useCallback(() => {
    //first load and data is empty
    if (isEmpty(myPermissions) || isEmpty(profile)) return true;

    const permissionNames = myPermissions.map(permission => permission.name);
    const routePermissions = _get(
      Object.values(ROUTES).find(route => route.url === path),
      'availablePermissions',
      []
    );

    // current route does not need permission
    if (isEmpty(routePermissions)) return true;

    return (
      _get(profile, 'role', undefined) === SUPERIOR_ADMIN_ROLE ||
      routePermissions.some(permission => permissionNames.includes(permission))
    );
  }, [[path, myPermissions, profile]]);

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !isTokenExisted && !isOnLogin) {
          return <Redirect to={{ pathname: LOGIN_PATH, state: { from: props.location } }} />;
        }
        if (isEmpty(myPermissions) || isEmpty(profile)) return <></>;
        if (!checkPermission())
          return <Redirect to={{ pathname: NOT_FOUND, state: { from: props.location } }} />;

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

const ExcludedMiddleware = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

ExcludedMiddleware.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

const PublicMiddleware = ({ component: Component, layout: Layout, ...rest }) => {
  const dispatch = useDispatch();
  const { myPermissions } = useSelector(state => ({
    myPermissions: _get(state, ['permissions', 'myPermissions'], []),
  }));
  const permissionNames = myPermissions.map(item => item.name);
  const isAllowToAccessOrders = !isEmpty(
    intersection(ROUTES.orders.availablePermissions, permissionNames)
  );
  const isTokenExisted = !isEmpty(localStorage.getItem('diament'));
  useEffect(() => {
    if (isTokenExisted && isEmpty(myPermissions)) {
      dispatch(getMyPermissions());
    }
  }, [isTokenExisted]);

  return isTokenExisted ? (
    <Route
      {...rest}
      render={props => {
        if (isEmpty(myPermissions)) return <></>;
        return isAllowToAccessOrders ? (
          <Redirect to={{ pathname: ORDERS, state: { from: props.location } }} />
        ) : (
          <Redirect to={{ pathname: PROFILE, state: { from: props.location } }} />
        );
      }}
    />
  ) : (
    <Route
      {...rest}
      render={props => {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

PublicMiddleware.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export { Authmiddleware, SuperAdminMiddleware, ExcludedMiddleware, PublicMiddleware };
