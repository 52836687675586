/* eslint-disable no-unused-vars */
import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { 
  GET_PROFILE, 
  UPDATE_PROFILE
} from "./actionTypes";
import { adminService } from "services/Admin.service";
import { 
  getProfileSuccess, 
  getProfileError,
  updateProfileSuccess,
  updateProfileFail
} from "store/actions";
import { get as _get, isEmpty } from 'lodash';
import { catchError } from 'store/errors/actions';

function* getProfileAdmin({ payload: { user }}){
    try{
        const response = yield call(adminService.getProfile, user);
        const profile = _get(response, ['results']);
        yield put(getProfileSuccess(profile));
    }catch(error){
      yield put(getProfileError(error));
    }
}

function* updateProfile({ payload }) {
  try {
    const oldPassword = _get(payload, 'oldPassword', '');
    const newPassword = _get(payload, 'newPassword', '');
    const res = !isEmpty(oldPassword || newPassword) ? yield call(adminService.update, {
      id: _get(payload, 'id', ''),
      admin: {
        name: _get(payload, 'name', ''),
        email: _get(payload, 'email', ''),
        first_name: _get(payload, 'firstName', ''),
        last_name: _get(payload, 'lastName', ''),
        phone_number: _get(payload, 'phoneNumber', ''),
        old_password:_get(payload, 'oldPassword', ''),
        new_password:_get(payload, 'newPassword', ''),
      }
    }) : yield call(adminService.update, {
      id: _get(payload, 'id', ''),
      admin: {
        name: _get(payload, 'name', ''),
        email: _get(payload, 'email', ''),
        first_name: _get(payload, 'firstName', ''),
        last_name: _get(payload, 'lastName', ''),
        phone_number: _get(payload, 'phoneNumber', ''),
      }
    });
    yield put(updateProfileSuccess(_get(res, 'results', {})));
  } catch (e) {
    yield put(catchError(e));
    yield put(updateProfileFail(e));
  }
}

export function* watchProfile() {
  yield takeEvery(GET_PROFILE, getProfileAdmin);
  yield takeEvery(UPDATE_PROFILE, updateProfile);
}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;
