/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import { Spin } from 'antd';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { PushNotificationForm } from 'components/Common/PushNotificationForm';
import { EllipseSpan } from 'components/Common/StyledComponents/Span';
import { TooltipComponent } from 'components/Common/Tooltip';
import { showNotification } from 'constants/messages';
import { get as _get, isEmpty } from 'lodash';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { getNotificationHistory, pushNotification } from 'store/pushNotifications/actions';
import { SIZEPERPAGE } from 'constants/utils';

const NotificationsList = props => {
  const dispatch = useDispatch();

  const [confirmation, setConfirmation] = useState(false);
  const [notification, setNotification] = useState({});

  const { t: translator } = props;

  const { errors, isSuccess, isProcessing, notificationHistory } = useSelector(state => {
    return {
      errors: _get(state, ['notifications', 'errors'], ''),
      isSuccess: _get(state, ['notifications', 'isSuccess'], ''),
      isProcessing: _get(state, ['notifications', 'isProcessing'], false),
      notificationHistory: _get(state, ['notifications', 'notificationHistory'], [])
    };
  });

  const handleValidDate = date => {
    return moment(new Date(date)).format('DD/MM/YYYY');
  };

  const ListNotificationsColumns = [
    {
      dataField: 'created_at',
      text: translator('date'),
      formatter: (cellContent, row) => (
        <>
          <p className='mb-0'>{handleValidDate(_get(row, 'created_at', ''))}</p>
        </>
      )
    },
    {
      dataField: 'admin_name',
      text: translator('sender'),
      sort: true
    },
    {
      dataField: 'title',
      text: translator('title'),
      sort: true
    },
    {
      dataField: 'content',
      text: translator('content'),
      formatter: (cellContent, row) => {
        const contentMessage = _get(row, 'content', '');
        return (
          <TooltipComponent
            id={_get(row, 'id', '').toString()}
            tag={translator('content')}
            content={contentMessage}
          >
            <EllipseSpan>{contentMessage}</EllipseSpan>
          </TooltipComponent>
        );
      }
    },
    {
      dataField: 'images',
      text: translator('Recipients'),
      formatter: (cellContent, row) => {
        const recipients = row.group_ids ?
            _get(row, 'group_names', '').join(', ') :
            _get(row, 'user_names', '').join(', ');
        return (
          <TooltipComponent
            id={_get(row, 'id', '').toString()}
            tag={translator('Recipients')}
            content={recipients}
          >
            <EllipseSpan>{recipients}</EllipseSpan>
          </TooltipComponent>
        );
      }
    },
    {
      dataField: 'receiver',
      text: translator('customers'),
      sort: true,
      formatter: (cellContent, row) => {
        const userReceivers = _get(row, 'user_ids', []);
        return <p className='mb-0'>{userReceivers.length}</p>;
      }
    }
  ];

  const [pageOptionsListNotifications, setpageOptionsListNotifications] = useState({
    sizePerPage: SIZEPERPAGE,
    totalSize: !isEmpty(notificationHistory) ? notificationHistory.length : 0,
    hideSizePerPage: true
  });

  useEffect(() => {
    if (!isEmpty(notificationHistory))
      setpageOptionsListNotifications({
        sizePerPage: SIZEPERPAGE,
        hideSizePerPage: true
      });
  }, [notificationHistory]);

  const handleValidNotificationSubmit = value => {
    setNotification(value);
    setConfirmation(true);
  };

  const handleSubmit = () => {
    setConfirmation(false);
    return !isProcessing && dispatch(pushNotification(notification));
  };

  useEffect(() => {
    dispatch(getNotificationHistory());
  }, []);

  useEffect(() => {
    if (!isEmpty(errors)) showNotification(errors);
    if (isSuccess) showNotification('');
  }, [errors, isSuccess]);

  useEffect(() => {
    // dispatch(getNotificationsHistoty());
  }, []);

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc'
    }
  ];

  return (
    <Spin tip={translator('loading') + '...'} spinning={isProcessing}>
      <React.Fragment>
        <div className='page-content'>
          <MetaTags>
            <title>{`${translator('notifications')} | Le diamant du terroir`}</title>
          </MetaTags>
          {confirmation && (
            <SweetAlert
              title={translator('are you sure?')}
              warning
              showCancel
              confirmBtnBsStyle='success'
              cancelBtnBsStyle='danger'
              onConfirm={() => {
                handleSubmit();
              }}
              onCancel={() => {
                setConfirmation(false);
              }}
            />
          )}
          <Container fluid>
            <Breadcrumbs title={translator('manage')} breadcrumbItem='notifications' />
            <Row>
              <Col xs='12'>
                <Card>
                  <CardBody>
                    <React.Fragment>
                      <Row className='mb-2'>
                        <Col sm='4'>
                          <div className='search-box ms-2 mb-2 d-inline-block'>
                            <div className='position-relative'></div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl='12'>
                          <PushNotificationForm handleSubmit={handleValidNotificationSubmit} />
                        </Col>
                      </Row>
                    </React.Fragment>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {!isEmpty(notificationHistory) && (
              <Row>
                <Card>
                  <CardBody>
                    <CardTitle tag='h5'>{translator('list notifications')}</CardTitle>
                    <div className='table-responsive'>
                      <BootstrapTable
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        classes={'table align-middle table-nowrap table-check'}
                        headerWrapperClasses={'table-light'}
                        keyField='id'
                        pagination={paginationFactory(pageOptionsListNotifications)}
                        data={notificationHistory}
                        columns={ListNotificationsColumns}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Row>
            )}
          </Container>
        </div>
      </React.Fragment>
    </Spin>
  );
};

NotificationsList.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(NotificationsList);
