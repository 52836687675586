import { call, put, takeEvery } from 'redux-saga/effects';
import { groupService } from 'services';
import { get as _get } from 'lodash';
import { catchError } from 'store/errors/actions';
import {
  GET_GROUPS,
  ADD_NEW_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  GET_SUB_GROUPS,
  ADD_GROUP_CATEGORY_DEPENDENCIES,
  GET_ALL_GROUPS
} from './actionTypes';
import {
  getGroupsSuccess,
  addGroupSuccess,
  addGroupFail,
  updateGroupSuccess,
  updateGroupFail,
  deleteGroupSuccess,
  deleteGroupFail,
  getSubGroupsSuccess,
  getSubGroupsFail,
  addSubGroupSuccess,
  updateSubGroupSuccess,
  deleteSubGroupSuccess,
  addGroupCategoryDependenciesSuccess,
  addGroupCategoryDependenciesFail,
  getAllGroupsSuccess
} from './actions';

function* fetchGroupsFromBE() {
  try {
    const res = _get(yield call(groupService.list), 'results', {});
    const resArray = Object.keys(res).map(key => {
      return res[key];
    });
    yield put(getGroupsSuccess(resArray));
  } catch (error) {
    yield put(catchError(error));
  }
}

function* fetchAllGroupsFromBE() {
  try {
    const res = _get(yield call(groupService.getAll), 'results', {});
    const resArray = Object.keys(res).map(key => {
      return res[key];
    });
    yield put(getAllGroupsSuccess(resArray));
  } catch (error) {
    yield put(catchError(error));
  }
}

function* addNewGroup({ payload }) {
  try {
    const type = _get(payload, 'type', null);
    let obj = {
      name: _get(payload, 'name', ''),
      description: _get(payload, 'description', ''),
    };
    if (type !== null) {
      obj = {
        ...obj,
        type,
      };
    }
    const res = yield call(groupService.create, obj);
    const results = _get(res, 'results', {});
    yield put(type ? addSubGroupSuccess(results) : addGroupSuccess(results));
  } catch (error) {
    yield put(catchError(error));
    yield put(addGroupFail(error));
  }
}

function* updateGroup({ payload }) {
  try {
    const type = _get(payload, 'type', null);
    const sub = _get(payload, 'sub', {});
    const res = yield call(groupService.update, {
      id: _get(payload, 'id', ''),
      group: {
        name: _get(payload, 'name', ''),
        type: _get(payload, 'type', ''),
        description: _get(payload, 'description', ''),
      },
    });
    const results = _get(res, 'results', {});
    yield put(
      type
        ? updateSubGroupSuccess({ subGroup: results, groupId: type })
        : updateGroupSuccess({ ...results, sub })
    );
  } catch (error) {
    yield put(catchError(error));
    yield put(updateGroupFail(error));
  }
}

function* deleteGroup({ payload }) {
  try {
    const id = _get(payload, 'id', '');
    const type = _get(payload, 'type');
    yield call(groupService.archive, id);
    yield put(type ? deleteSubGroupSuccess({
      subGroupId: id,
      groupId: type,
    }) : deleteGroupSuccess(id));
  } catch (error) {
    yield put(catchError(error));
    yield put(deleteGroupFail(error));
  }
}

function* fetchSubGroupsFromBE({ payload }) {
  try {
    const res = _get(yield call(groupService.subList, payload), 'results', {});
    const resArray = Object.keys(res).map(key => {
      return res[key];
    });
    yield put(getSubGroupsSuccess(resArray));
  } catch (error) {
    yield put(catchError(error));
    yield put(getSubGroupsFail(error));
  }
}

function* addGroupCategoryDependencies({ payload }) {
  try {
    const res = _get(yield call(groupService.createCategoryDependencies, payload), 'results', {});
    const categories = _get(res, [1], []);
    const isSubGroupRequest = _get(payload, 'isSubGroupRequest', false);
    yield put(addGroupCategoryDependenciesSuccess({ ...categories, isSubGroupRequest }));
  } catch (error) {
    yield put(catchError(error));
    yield put(addGroupCategoryDependenciesFail(error));
  }
}

function* groupsSaga() {
  yield takeEvery(ADD_GROUP_CATEGORY_DEPENDENCIES, addGroupCategoryDependencies);
  yield takeEvery(GET_GROUPS, fetchGroupsFromBE);
  yield takeEvery(GET_ALL_GROUPS, fetchAllGroupsFromBE);
  yield takeEvery(ADD_NEW_GROUP, addNewGroup);
  yield takeEvery(UPDATE_GROUP, updateGroup);
  yield takeEvery(DELETE_GROUP, deleteGroup);
  yield takeEvery(GET_SUB_GROUPS, fetchSubGroupsFromBE);
}

export default groupsSaga;
