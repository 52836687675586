import { get as _get } from 'lodash';
import { call, put, takeEvery } from 'redux-saga/effects';
import { adminService } from 'services';
import { catchError } from 'store/errors/actions';
import {
  addAdminFail, addAdminSuccess, deleteAdminFail, deleteAdminSuccess, getAdminsFail, getAdminsSuccess, updateAdminFail, updateAdminSuccess
} from './actions';
import { ADD_NEW_ADMIN, DELETE_ADMIN, GET_ADMINS, UPDATE_ADMIN } from './actionTypes';

function* fetchAdminsFromBE() {
  try {
    const res = yield call(adminService.list);
    yield put(getAdminsSuccess(_get(res, 'results', [])));
  } catch (error) {
    yield put(catchError(error));
    yield put(getAdminsFail(error));
  }
}

function* addNewAdmin({ payload }) {
  try {
    const res = yield call(adminService.create, {
      name: _get(payload, 'name', ''),
      email: _get(payload, 'email', ''),
      password: _get(payload, 'password', ''),
      first_name: _get(payload, 'firstName', ''),
      last_name: _get(payload, 'lastName', ''),
      phone_number: _get(payload, 'phoneNumber', ''),
      status: _get(payload, 'status', ''),
      team_ids: _get(payload, 'teamIds', [])
    });
    yield put(addAdminSuccess(_get(res, 'results', {})));
  } catch (error) {
    yield put(catchError(error));
    yield put(addAdminFail(error));
  }
}

function* updateAdmin({ payload }) {
  try {
    const admin_password = _get(payload, 'password', '');
    let obj = {
      email: _get(payload, 'email', ''),
      code: _get(payload, 'code', ''),
      brand_name: _get(payload, 'brandName', ''),
      address: _get(payload, 'address', ''),
      status: _get(payload, 'status', ''),
      user_name: _get(payload, 'userName', ''),
      phone_number: _get(payload, 'phoneNumber', ''),
      team_ids: _get(payload, 'teamIds', [])
    };
    if (admin_password !== ''){
      obj = {
        ...obj,
        admin_password,
      };
    }
    const res = yield call(adminService.update, {
      id: _get(payload, 'id', ''),
      admin: obj
    });

    yield put(updateAdminSuccess(_get(res, 'results', {})));
  } catch (e) {
    yield put(catchError(e));
    yield put(updateAdminFail(e));
  }
}

function* deleteAdmin({ payload }) {
  try {
    const id = _get(payload, 'id', '');
    yield call(adminService.archive, {
      id
    });
    yield put(deleteAdminSuccess(id));
  } catch (e) {
    yield put(catchError(e));
    yield put(deleteAdminFail(e));
  }
}

function* adminsSaga() {
  yield takeEvery(GET_ADMINS, fetchAdminsFromBE);
  yield takeEvery(ADD_NEW_ADMIN, addNewAdmin);
  yield takeEvery(UPDATE_ADMIN, updateAdmin);
  yield takeEvery(DELETE_ADMIN, deleteAdmin);
}

export default adminsSaga;
