import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import { Row, Col, CardBody, Card, Container, Alert } from 'reactstrap';

// import { get as _get } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';

import { withRouter, Link, useHistory } from 'react-router-dom';

import { AvForm, AvField } from 'availity-reactstrap-validation';

import { loginUser } from '../../store/actions';
import { AUTHENTICATION } from 'constants/messages';
import { APP } from 'constants/index';
import { REGEX, ERROR_MESSAGE } from 'constants/regex';

import profile from 'assets/images/logo-diamant-long.png';
import logo from 'assets/images/logo.svg';
import { withTranslation } from 'react-i18next';
import styled from "styled-components";

const fieldsName = {
  username: 'username',
  password: 'password'
};

const fieldsLabel = {
  [fieldsName.username]: 'username',
  [fieldsName.password]: 'password'
};

const CustomDiv = styled.div`
  background-color: black !important;
`;

const Login = (props) => {
  const dispatch = useDispatch();
  const { error, isLoginSuccess } = useSelector(state => ({
    error: state.Login.error,
    isLoginSuccess: state.Login.isLoginSuccess
  }));

  const {t: translator} = props;

  const history = useHistory();
  useEffect(() => {
    if (isLoginSuccess) history.push('/');
  }, [isLoginSuccess]);

  const handleValidSubmit = (event, values) => {
    dispatch(
      loginUser({
        ...values
        // email: _get(values,'email','').toLowerCase(),
      })
    );
  };

  const requiredErrorMessage = useMemo(() => `${translator('this field is required')}!`,[]);

  return (
    <React.Fragment>
      <div className='home-btn d-none d-sm-block'>
        <Link to='/' className='text-dark'>
          <i className='fas fa-home h2' />
        </Link>
      </div>
      <div className='account-pages my-5 pt-sm-5'>
        <Container>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5}>
              <Card className='overflow-hidden'>
                <CustomDiv className='bg-primary'>
                  <Row>
                    <Col xs={6}>
                      <div className='text-white p-4'>
                        <h5 className='text-white'>{props.t('welcome')}</h5>
                        <p>{`${props.t('sign in to continue to')} ${APP.ENTERPRISE_NAME}.`}</p>
                      </div>
                    </Col>
                    <Col className='col-5 align-self-center'>
                      <img src={profile} alt='' className='img-fluid' />
                    </Col>
                  </Row>
                </CustomDiv>
                <CardBody className='pt-0'>
                  <div>
                    <Link to='/' className='auth-logo-light'>
                      <div className='avatar-md profile-user-wid mb-4'>
                        <span className='avatar-title rounded-circle bg-light'>
                          <img src={logo} alt='' className='rounded-circle' height='34' />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className='p-2'>
                    <AvForm
                      className='form-horizontal'
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      {error ? (
                        <Alert color='danger'>{translator(AUTHENTICATION.LOGIN.LOGIN_FAILURE)}</Alert>
                      ) : null}
                      <div className='mb-3'>
                        <AvField
                          name={fieldsName.username}
                          label={props.t(fieldsLabel.username)}
                          className='form-control'
                          placeholder={translator('enter username')}
                          type='text'
                          required
                          validate={{
                            required: {
                              value: true,
                              errorMessage: requiredErrorMessage
                            }
                          }}
                        />
                      </div>
                      <div className='mb-3'>
                        <AvField
                          name={fieldsName.password}
                          label={props.t(fieldsLabel.password)}
                          type='password'
                          required
                          validate={{
                            pattern: {
                              value: REGEX.PASSWORD,
                              errorMessage: translator(ERROR_MESSAGE.PASSWORD)
                            },
                            required: {
                              value: true,
                              errorMessage: requiredErrorMessage
                            }
                          }}
                          placeholder= {translator('enter password')}
                        />
                      </div>
                      <div className='mt-3 d-grid'>
                        <button className='btn btn-primary btn-block' type='submit'>
                          {props.t('login')}
                        </button>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className='mt-5 text-center'>
                <p>
                  © {new Date().getFullYear()} {APP.ENTERPRISE_NAME}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(Login));

Login.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any
};
