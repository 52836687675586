import { isEmpty } from 'lodash';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

export const showSuccessToast = () => {
  const title = 'Succès!';
  let message = '';

  //position class
  let positionClass = 'toast-top-right';
  toastr.success(message, title);

  toastr.options = {
    positionClass: positionClass,
    timeOut: 1000,
    extendedTimeOut: 1000,
    closeButton: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
  };
};

export const showErrorToast = errors => {
  let message = errors;
  let title = 'Quelque chose ne va pas!';

  //position class
  let positionClass = 'toast-top-right';
  const translate = {
    "EXISTED products.products_item_code_unique": "Un produit avec le même code produit existe déjà !",
    "EXISTED users.users_user_name_unique": "Un client avec le même nom existe déjà !",
    "EXISTED users.users_code_unique": "Un client avec le même code client existe déjà !",
    "EXISTED groups.groups_name_unique": "Un groupe de clients avec le même nom existe déjà !",
    "EXISTED categories.categories_name_unique": "Une catégorie de produits avec le même nom existe déjà !",
    "EXISTED teams.teams_name_unique": "Un groupe d’utilisateurs avec le même nom existe déjà !"
  };
  isEmpty(message) ? toastr.error(message, title) : toastr.error(translate[message]);

  toastr.options = {
    positionClass: positionClass,
    timeOut: 1000,
    extendedTimeOut: 1000,
    closeButton: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
  };
};

export const showNotification = errors => {
  isEmpty(errors) ? showSuccessToast() : showErrorToast(errors);
};

export const AUTHENTICATION = {
  LOGIN: {
    LOGIN_SUCCESS: 'successfully logged in',
    LOGIN_FAILURE: 'username or password is incorrect',
  },
};

export const ERROR_MESSAGE = {
  COMMON: 'something went wrong'
};
