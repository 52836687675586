/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { AvForm } from 'availity-reactstrap-validation';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Col, Row, Input } from 'reactstrap';
import React, { useState, forwardRef } from 'react';
import { DatePicker } from 'antd';
import styled from 'styled-components';

const StyledInput = styled(Input)`
  width: 100%;
`;

const DatePickerCustom = styled(DatePicker)`
  width: 100%;
  border-color: #848494;
  border-radius: 5px;
`;

const Form = props => {
  const { t: translator, handleSubmit } = props;

  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  const handleSelectedStartDate = targetDate => {
    setSelectedStartDate(targetDate);
  };

  const handleSelecteEnddDate = targetDate => {
    setSelectedEndDate(targetDate);
  };

  const handleValidSubmit = (e, value) => {
    handleSubmit({
      start: selectedStartDate,
      end: selectedEndDate
    });
  };

  const CustomInputDateTime = forwardRef(({ value, onClick }, ref) => (
    <StyledInput
      onClick={onClick}
      ref={ref}
      defaultValue={moment(new Date(value)).format('DD/MM/YYYY')}
    />
  ));

  return (
    <AvForm onValidSubmit={handleValidSubmit}>
      <Row form>
        <Col className='col-12 mb-3'>
          <h6>{translator('start date')}: </h6>
          <DatePickerCustom
            selected={selectedStartDate}
            onChange={values => {
              handleSelectedStartDate(values);
            }}
            customInput={<CustomInputDateTime />}
            format='DD/MM/YYYY'
            placeholder=''
          />
        </Col>
        <Col className='col-12 mb-3'>
          <h6>{translator('end date')}: </h6>
          <DatePickerCustom
            selected={selectedEndDate}
            onChange={values => {
              handleSelecteEnddDate(values);
            }}
            customInput={<CustomInputDateTime />}
            format='DD/MM/YYYY'
            placeholder=''
          />
        </Col>

        <Col>
          <div className='text-end'>
            <button type='submit' className='btn btn-success save-customer'>
              Exporter CSV
            </button>
          </div>
        </Col>
      </Row>
    </AvForm>
  );
};

export const ExportCSVForm = withTranslation()(Form);

Form.propTypes = {
  handleSubmit: PropTypes.func,
  userId: PropTypes.number,
  t: PropTypes.any,
};
