import { AvField, AvForm } from 'availity-reactstrap-validation';
import { ERROR_MESSAGE, REGEX } from 'constants/regex';
import { get as _get, isEqual as isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { Col, Container, Row, Input, Label } from 'reactstrap';
import { ACTION_LABELS, USER_ACTIONS } from './constant';
import { customStyleSelect } from '../../../constants/styles';
import { useSelector } from 'react-redux';

const Form = props => {
  const { handleSubmit, user = {}, action, t: translator } = props;
  const { profile } = useSelector(state => ({
    profile: _get(state, ['Profile', 'profile'], {}),
  }));

  const optionStatus = useMemo(
    () => [
      {
        options: [
          {
            label: translator('active'),
            value: 'active'
          },
          {
            label: translator('inactive'),
            value: 'inactive'
          }
        ]
      }
    ],
    []
  );

  const [selectedStatus, setSelectedStatus] = useState(optionStatus);
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const requiredErrorMessage = useMemo(() => `${translator('this field is required')}!`, []);

  const togglePasswordVisiblity = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  useEffect(() => {
    const status = _get(user, 'status', '');
    if (!isEmpty(status)) {
      setSelectedStatus({ label: translator(status), value: status });
    }
  }, []);

  const handleSelectStatus = targetStatus => {
    setSelectedStatus(targetStatus);
  };

  const onSubmit = (e, values) => {
    handleSubmit(e, {
      ...values,
      status: selectedStatus.value || 'active'
    });
  };

  return (
    <AvForm onValidSubmit={onSubmit}>
      <Container>
        <div className='add-user-modal'>
          <Row>
            <Col xs='6' sm='6'>
              <div className='mb-3'>
                <AvField
                  name='code'
                  label={translator('code')}
                  type='text'
                  errorMessage={translator('invalid code')}
                  validate={{
                    required: { value: true }
                  }}
                  value={user.code || ''}
                />
              </div>
              <div className='mb-3'>
                <AvField
                  name='user_name'
                  label={translator('username')}
                  type='text'
                  errorMessage={translator('invalid username')}
                  validate={{
                    required: { value: true }
                  }}
                  value={user.userName || ''}
                />
              </div>
              <div className='mb-3'>
                <AvField
                  name='brand_name'
                  label={translator('brand name')}
                  type='text'
                  errorMessage={translator('invalid branch name')}
                  validate={{
                    required: { value: true }
                  }}
                  value={user.brandName || ''}
                />
              </div>
              <div className='mb-3'>
                <label className='control-label'>{translator('status')}</label>
                <Select
                  styles={customStyleSelect}
                  value={selectedStatus || ''}
                  onChange={values => {
                    handleSelectStatus(values);
                  }}
                  options={optionStatus}
                  classNamePrefix='select2-selection'
                  placeholder={translator('select')}
                  isDisabled={profile?.role === 0 ? false : !user.isChangeStatusPermission}
                />
              </div>
            </Col>
            <Col xs='6' sm='6'>
              <div className='mb-3'>
                <AvField
                  name='phone_number'
                  label={translator('phone')}
                  type='text'
                  validate={{
                    pattern: {
                      value: REGEX.NUMBER,
                      errorMessage: ERROR_MESSAGE.PHONE_NUMBER
                    }
                  }}
                  value={user.phoneNumber || ''}
                />
              </div>
              <div className='mb-3'>
                <AvField
                  name='email'
                  label={translator('email')}
                  type='email'
                  validate={{
                    pattern: {
                      value: REGEX.EMAIL,
                      errorMessage: ERROR_MESSAGE.EMAIL
                    }
                  }}
                  value={user.email || ''}
                />
              </div>
              <div className='mb-3'>
                <AvField
                  name='address'
                  label={translator('address')}
                  value={user.address || ''}
                />
              </div>
              <div className='mb-3'>
                <AvField
                  name='password'
                  autoComplete='on'
                  label={translator('password')}
                  type={isPasswordShown ? 'text' : 'password'}
                  validate={action === USER_ACTIONS.CREATE ? {
                    pattern: {
                      value: REGEX.PASSWORD,
                      errorMessage: ERROR_MESSAGE.PASSWORD
                    },
                    required: {
                      value: true,
                      errorMessage: requiredErrorMessage
                    }
                  } : {
                    pattern: {
                      value: REGEX.PASSWORD,
                      errorMessage: ERROR_MESSAGE.PASSWORD
                    },
                    required: {
                      value: false,
                    }
                  }}
                  value={user.password || ''}
                />
                <div className='password-input'>
                  <Input
                    type='checkbox'
                    onChange={() => togglePasswordVisiblity()}
                  />
                  <Label className='pushNoti_checkbox'>
                    {translator('show password')}{' '}
                  </Label>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Row>
        <Col>
          <div className='text-end'>
            <button type='submit' className='btn btn-success save-customer'>
              {translator(ACTION_LABELS[action])}
            </button>
          </div>
        </Col>
      </Row>
    </AvForm>
  );
};

Form.propTypes = {
  handleSubmit: PropTypes.func,
  user: PropTypes.object,
  action: PropTypes.string,
  t: PropTypes.any,
  isUpdateUser: PropTypes.bool
};

export const UserForm = withTranslation()(Form);
