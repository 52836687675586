/* eslint-disable react/display-name */
import { Table, Spin } from 'antd';
import 'antd/dist/antd.css';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { CategoryForm } from 'components/Common/CategoryForm';
import { CATEGORY_ACTIONS } from 'components/Common/CategoryForm/constants';
import { SUPERIOR_ADMIN_ROLE } from 'constants/index';
import { showNotification } from 'constants/messages';
import { get as _get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactDragListView from 'react-drag-listview';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown
} from 'reactstrap';
import {
  addNewCategory,
  deleteCategory,
  getCategories,
  resetErrorStatus,
  swapCategoryPosition,
  updateCategory
} from 'store/categories/actions';
import { PERMISSIONS } from 'utils/permissions';
import CategoriesExpandableRow from './ExpandableRow';
import { sortListByPosition } from './utils';
import { withTranslation } from 'react-i18next';
import { SIZEPERPAGE } from 'constants/utils';

const onClickAction = 'onclick-action';

const CategoryList = props => {
  const dispatch = useDispatch();

  const { t: translator } = props;

  const { categories, errors, isSuccess, myPermissions, role, isLoadingCategories } = useSelector(
    state => {
      return {
        isLoadingCategories: _get(state, ['categories', 'isLoadingCategories'], false),
        categories: _get(state, ['categories', 'categories'], []),
        errors: _get(state, ['categories', 'errors', 'errors'], ''),
        isSuccess: _get(state, ['categories', 'isSuccess'], ''),
        myPermissions: _get(state, ['permissions', 'myPermissions'], []),
        role: _get(state, ['Profile', 'profile', 'role'], undefined)
      };
    }
  );

  const [isSuperior, setIsSuperior] = useState(false);
  useEffect(() => {
    setIsSuperior(role === SUPERIOR_ADMIN_ROLE);
  }, [role]);

  const [handlingRowType, setHandlingRowType] = useState('');
  const [confirm_both, setconfirm_both] = useState(false);
  const [categoryDelete, setCategoryDelete] = useState([]);
  const [edittingCategory, setEdittingCategory] = useState({});
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isHandlingMainCategory, setIsHandlingMainCategory] = useState(false);

  const categoriesData = useMemo(() => sortListByPosition(categories), [categories]);

  const [isCreateCategoriesPermission, setIsCreateCategoriesPermission] = useState(false);
  const [isEditDeleteCategoriesPermission, setIsEditDeleteCategoriesPermission] = useState(false);
  useEffect(() => {
    if (isEmpty(myPermissions)) return;
    const arr = [];
    myPermissions.forEach(permission => {
      const permissionName = _get(permission, 'name', '');
      arr.push(permissionName);
    });
    setIsCreateCategoriesPermission(
      arr.includes(PERMISSIONS.PRODUCT_MANAGEMENT.CREATE_PRODUCT_CATEGORIES)
    );
    setIsEditDeleteCategoriesPermission(
      arr.includes(PERMISSIONS.PRODUCT_MANAGEMENT.EDIT_DELETE_CATEGORIES)
    );
  }, [myPermissions]);

  const handleAddSubCategory = category => {
    setEdittingCategory({
      type: category.id
    });
    setIsHandlingMainCategory(false);
    setIsEdit(false);
    toggle();
  };

  const CategoriesColumnsNoAction = [
    {
      dataIndex: 'name',
      title: translator('name'),
      className: 'category-column category-name',
      width: '50%'
    },
    {
      dataIndex: 'total_product',
      title: translator('products amount'),
      className: 'category-column category-product-amount',
      width: '40%'
    }
  ];

  const CategoriesColumns =
    isSuperior || isEditDeleteCategoriesPermission || isCreateCategoriesPermission
      ? [
          ...CategoriesColumnsNoAction,
          {
            dataIndex: 'menu',
            title: translator('action'),
            className: 'category-column category-action',
            width: '10%',
            // eslint-disable-next-line no-unused-vars
            render: (text, record, index) => (
              <UncontrolledDropdown direction='left'>
                <DropdownToggle href='#' className='card-drop' tag='i'>
                  <i className='mdi mdi-dots-horizontal font-size-18' action={onClickAction} />
                </DropdownToggle>
                <DropdownMenu className='dropdown-menu-end'>
                  {(isSuperior || isCreateCategoriesPermission) && (
                    <DropdownItem
                      onClick={() => handleAddSubCategory(record)}
                      action={onClickAction}
                    >
                      <i className='fas fa-plus text-success me-1' />
                      {translator('add sub category')}
                    </DropdownItem>
                  )}
                  {(isSuperior || isEditDeleteCategoriesPermission) && (
                    <>
                      <DropdownItem
                        onClick={() => handleEditCategory(record)}
                        action={onClickAction}
                      >
                        <i className='fas fa-pencil-alt text-success me-1' />
                        {translator('edit')}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setconfirm_both(true);
                          setCategoryDelete(record);
                        }}
                        action={onClickAction}
                      >
                        <i className='fas fa-trash-alt text-danger me-1' />
                        {translator('delete')}
                      </DropdownItem>
                    </>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            )
          }
        ]
      : CategoriesColumnsNoAction;

  const toggle = () => {
    setModal(!modal);
  };

  const handleEditCategory = category => {
    setEdittingCategory({
      id: category.id,
      name: category.name,
      description: category.description,
      sub: category.sub,
      oldPosition: category.position
    });
    setIsHandlingMainCategory(true);
    setIsEdit(true);
    toggle();
  };

  const handleDeleteCategory = category => {
    dispatch(deleteCategory(category));
  };

  const handleValidCategorySubmit = (e, values) => {
    const newCategory =
      !isEdit && !isHandlingMainCategory
        ? {
            type: edittingCategory.type,
            name: values.name,
            description: values.description,
            position: values.position,
            permissionCategory: _get(values, 'permissionCategory', '')
          }
        : {
            id: edittingCategory.id,
            name: values.name,
            description: values.description,
            sub: edittingCategory.sub,
            position: values.position,
            permissionCategory: _get(values, 'permissionCategory', ''),
            oldPosition: _get(edittingCategory, 'oldPosition', 0)
          };
    isEdit ? dispatch(updateCategory(newCategory)) : dispatch(addNewCategory(newCategory));
    toggle();
  };

  const onSubmit = (e, values) => {
    return isHandlingMainCategory
      ? handleValidCategorySubmit(e, values)
      : handleValidSubCategorySubmit(e, values);
  };

  useEffect(() => {
    if (!isEmpty(errors)) showNotification(errors);
    if (isSuccess) showNotification('');
    dispatch(resetErrorStatus());
  }, [errors, isSuccess]);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const handleAddNewCategory = () => {
    setEdittingCategory({});
    setIsEdit(false);
    toggle();
    setIsHandlingMainCategory(true);
  };

  const handleEditSubCategory = ({ id, category }) => {
    setEdittingCategory({
      id: category.id,
      name: category.name,
      description: category.description,
      type: id,
      oldPosition: category.position
    });
    setIsHandlingMainCategory(false);
    setIsEdit(true);
    toggle();
  };

  const handleDeleteSubCategory = ({ id, category }) => {
    dispatch(
      deleteCategory({
        ...category,
        type: id
      })
    );
  };

  const handleValidSubCategorySubmit = (e, values) => {
    const newCategory = {
      id: edittingCategory.id,
      name: values.name,
      description: values.description,
      type: edittingCategory.type,
      permissionCategory: _get(values, 'permissionCategory', ''),
      position: values.position,
      oldPosition: _get(edittingCategory, 'oldPosition', 0)
    };
    isEdit ? dispatch(updateCategory(newCategory)) : dispatch(addNewCategory(newCategory));
    toggle();
  };

  const dragProperties = {
    onDragEnd(fromIndex, toIndex) {
      let oldPosition;
      let newPosition;
      let categoryId;
      let id;
      if (handlingRowType === 'main') {
        oldPosition = _get(categoriesData, [fromIndex - 1, 'position'], '');
        newPosition = _get(categoriesData, [toIndex - 1, 'position'], '');
        id = _get(categoriesData, [fromIndex - 1, 'id'], '');
      } else {
        const targetCategory = categoriesData.find(category => category.id === edittingCategory.id);
        const subCats = sortListByPosition(Object.values(_get(targetCategory, 'sub', {})));

        oldPosition = _get(subCats, [fromIndex, 'position'], '');
        newPosition = _get(subCats, [toIndex, 'position'], '');

        id = _get(subCats, [fromIndex, 'id'], '');
        categoryId = edittingCategory.id;
      }

      dispatch(
        swapCategoryPosition({
          oldPosition,
          newPosition,
          id,
          type: categoryId
        })
      );
    },
    ignoreSelector: 'tr.ant-table-expanded-row',
    nodeSelector: 'tr.ant-table-row'
  };

  const swapSubCategoryPosition = (categoryId, subCategoriesData, { fromIndex, toIndex }) => {
    dispatch(
      swapCategoryPosition({
        oldPosition: _get(subCategoriesData, [fromIndex - 1, 'position'], ''),
        newPosition: _get(subCategoriesData, [toIndex - 1, 'position'], ''),
        id: _get(subCategoriesData, [fromIndex - 1, 'id'], ''),
        type: categoryId
      })
    );
  };

  return (
    <Spin tip={translator('loading') + '...'} spinning={isLoadingCategories}>
      <React.Fragment>
        <div className='page-content'>
          <MetaTags>
            <title>{`${translator('categories')} | Le diamant du terroir`}</title>
          </MetaTags>
          {confirm_both && (
            <SweetAlert
              title={translator('are you sure?')}
              warning
              showCancel
              confirmBtnBsStyle='success'
              cancelBtnBsStyle='danger'
              onConfirm={() => {
                handleDeleteCategory(categoryDelete);
                setconfirm_both(false);
              }}
              onCancel={() => {
                setconfirm_both(false);
              }}
            />
          )}
          <Container fluid>
            <Breadcrumbs title={translator('manage')} breadcrumbItem={translator('categories')} />
            <Row>
              <Col xs='12'>
                <Card>
                  <CardBody>
                    <React.Fragment>
                      <Row className='mb-2'>
                        <Col sm='4'>
                          <div className='search-box ms-2 mb-2 d-inline-block'>
                            <div className='position-relative' />
                          </div>
                        </Col>
                        <Col sm='8'>
                          {(isSuperior || isCreateCategoriesPermission) && (
                            <div className='text-sm-end'>
                              <Button
                                type='button'
                                color='success'
                                className='btn-rounded  mb-2 me-2'
                                onClick={handleAddNewCategory}
                              >
                                <i className='mdi mdi-plus me-1' />
                                {translator('new category')}
                              </Button>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl='12'>
                          <div className='table-responsive'>
                            <ReactDragListView {...dragProperties}>
                              <Table
                                onRow={() => {
                                  return {
                                    onMouseDown: () => {
                                      setHandlingRowType('main');
                                    },
                                    onMouseUp: () => {
                                      setHandlingRowType('');
                                    }
                                  };
                                }}
                                rowKey='id'
                                pagination={{ pageSize: SIZEPERPAGE }}
                                dataSource={categoriesData}
                                columns={CategoriesColumns}
                                sticky={true}
                                expandable={{
                                  expandedRowRender: row => (
                                    <CategoriesExpandableRow
                                      setEdittingCategory={setEdittingCategory}
                                      setHandlingRowType={setHandlingRowType}
                                      rowDatas={row}
                                      swapSubCategoryPosition={swapSubCategoryPosition}
                                      handleEditSubCategory={handleEditSubCategory}
                                      handleDeleteSubCategory={handleDeleteSubCategory}
                                      handleValidSubCategorySubmit={handleValidSubCategorySubmit}
                                      isEditDeletePermission={
                                        isEditDeleteCategoriesPermission || isSuperior
                                      }
                                    />
                                  )
                                }}
                              />
                            </ReactDragListView>
                          </div>
                          <Modal isOpen={modal} toggle={toggle} scrollable={true} size='lg'>
                            <ModalHeader toggle={toggle} tag='h4'>
                              {isEdit
                                ? translator('edit category')
                                : !isHandlingMainCategory
                                ? translator('add sub category')
                                : translator('add category')}
                            </ModalHeader>
                            <ModalBody>
                              <CategoryForm
                                category={edittingCategory}
                                action={
                                  isEdit
                                    ? CATEGORY_ACTIONS.UPDATE
                                    : !isHandlingMainCategory
                                    ? CATEGORY_ACTIONS.CREATE_SUB
                                    : CATEGORY_ACTIONS.CREATE
                                }
                                isSub={!isHandlingMainCategory}
                                handleSubmit={onSubmit}
                              />
                            </ModalBody>
                          </Modal>
                        </Col>
                      </Row>
                    </React.Fragment>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </Spin>
  );
};

CategoryList.propTypes = {
  categories: PropTypes.array,
  onGetCategories: PropTypes.func,
  onAddNewCategory: PropTypes.func,
  onDeleteCategory: PropTypes.func,
  onUpdateCategory: PropTypes.func,
  t: PropTypes.any
};

export default withTranslation()(CategoryList);
