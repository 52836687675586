// /* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import Breadcrumbs from 'components/Common/Breadcrumb';
import GroupForm from 'components/Common/GroupForm';
import { GROUP_ACTIONS } from 'components/Common/GroupForm/constants';
import { showNotification } from 'constants/messages';
import { get as _get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import { Spin } from 'antd';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Table } from 'antd';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { GroupsExpandableRow } from './ExpandableRow';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown
} from 'reactstrap';
import { removeFilteredUser } from 'store/actions';
import {
  addGroupCategoryDependencies,
  addNewGroup,
  deleteGroup,
  getGroups,
  resetErrorStatus,
  updateGroup
} from 'store/groups/actions';
import { PERMISSIONS } from 'utils/permissions';
import { SUPERIOR_ADMIN_ROLE } from 'constants/index';
import { SIZEPERPAGE } from 'constants/utils';
import { sortListByPosition } from '../Categories/utils';
import { withTranslation } from 'react-i18next';

const onClickAction = 'onclick-action';

const GroupList = (props) => {
  const dispatch = useDispatch();

  const { t: translator } = props;

  const { groups, errors, isSuccess, myPermissions, role, isLoadingGroups } = useSelector(state => {
    return {
      isLoadingGroups: _get(state, ['groups', 'isLoadingGroups'], false),
      groups: _get(state, ['groups', 'groups'], []),
      categories: _get(state, ['categories', 'categories'], []),
      errors: _get(state, ['groups', 'errors', 'errors'], ''),
      isSuccess: _get(state, ['groups', 'isSuccess'], ''),
      myPermissions: _get(state, ['permissions', 'myPermissions'], []),
      role: _get(state, ['Profile', 'profile', 'role'], undefined)
    };
  });
  const groupsData = useMemo(() => sortListByPosition(groups), [groups]);

  const [isSuperior, setIsSuperior] = useState(false);
  useEffect(() => {
    setIsSuperior(role === SUPERIOR_ADMIN_ROLE);
  }, [role]);

  const [edittingGroup, setEdittingGroup] = useState({});

  const [confirmation, setConfirmation] = useState(false);
  const [groupDelete, setGroupDelete] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isHandlingMainGroup, setIsHandlingMainGroup] = useState(false);

  const [isCreateGroupPermission, setIsCreateGroupPermission] = useState(false);
  const [isModifyDeleteGroupPermission, setIsModifyDeleteGroupPermission] = useState(false);

  useEffect(() => {
    if (isEmpty(myPermissions)) return;
    const arr = [];
    myPermissions.forEach(permission => {
      const permissionName = _get(permission, 'name', '');
      arr.push(permissionName);
    });
    setIsCreateGroupPermission(arr.includes(PERMISSIONS.CUSTOMER_MANAGEMENT.CREATE_CUSTOMER_GROUP));
    setIsModifyDeleteGroupPermission(
      arr.includes(PERMISSIONS.CUSTOMER_MANAGEMENT.MODIFY_DELETE_CUSTOMER_GROUP)
    );
  }, [myPermissions]);

  const GroupsColumnsNoAction = [
    {
      dataIndex: 'name',
      title: translator('name'),
      width: '30%',
      className: 'group-name'
    },
    {
      dataIndex: 'total_user',
      title: translator('numbers of user'),
      className: 'group-column group-user-numbers',
      width: '30%',
      render: (cellContent, row) => <p>{_get(row, 'total_user', '')}</p>
    },
    {
      dataIndex: 'categories',
      title: translator('permissions'),
      className: 'group-column group-user-numbers',
      width: '30%',
      render: (cellContent, row) => <p>{_get(row, 'category', []).join(', ')}</p>
    }
  ];

  const GroupsColumns =
    isSuperior || isCreateGroupPermission || isModifyDeleteGroupPermission
      ? [
          ...GroupsColumnsNoAction,
          {
            dataIndex: 'menu',
            title: translator('action'),
            width: '10%',
            render: (text, record) => (
              <UncontrolledDropdown direction='left'>
                <DropdownToggle href='#' className='card-drop' tag='i'>
                  <i className='mdi mdi-dots-horizontal font-size-18' action={onClickAction} />
                </DropdownToggle>
                <DropdownMenu className='dropdown-menu-end'>
                  {(isSuperior || isCreateGroupPermission) && (
                    <DropdownItem onClick={() => handleAddSubGroup(record)} action={onClickAction}>
                      <i className='fas fa-plus text-success me-1' />
                      {translator('add sub group')}
                    </DropdownItem>
                  )}
                  {(isSuperior || isModifyDeleteGroupPermission) && (
                    <>
                      <DropdownItem onClick={() => handleEditGroup(record)} action={onClickAction}>
                        <i className='fas fa-pencil-alt text-success me-1' />
                        {translator('edit')}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setConfirmation(true);
                          setGroupDelete(record);
                        }}
                        action={onClickAction}
                      >
                        <i className='fas fa-trash-alt text-danger me-1' />
                        {translator('delete')}
                      </DropdownItem>
                    </>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            )
          }
        ]
      : GroupsColumnsNoAction;
  const [isCreateSub, setIsCreateSub] = useState(false);

  const handleAddSubGroup = group => {
    setEdittingGroup({
      type: group.id
    });
    setIsHandlingMainGroup(true);
    setIsEdit(false);
    setIsCreateSub(true);
    toggle();
  };

  const toggle = () => {
    dispatch(removeFilteredUser());
    setModal(!modal);
  };

  const handleEditGroup = group => {
    setEdittingGroup({
      id: group.id,
      name: group.name,
      description: group.description,
      type: group.type,
      sub: group.sub,
      categories: group.category
    });
    setIsEdit(true);
    toggle();
  };

  const handleDeleteGroup = group => {
    dispatch(deleteGroup(group));
  };

  const handleValidGroupSubmit = (e, values) => {
    const newGroup = isCreateSub
      ? { type: edittingGroup.type, name: values.name, description: values.description }
      : {
          id: edittingGroup.id,
          name: values.name,
          description: values.description,
          type: isHandlingMainGroup ? values.type : edittingGroup.type,
          sub: edittingGroup.sub
        };
    isEdit ? dispatch(updateGroup(newGroup)) : dispatch(addNewGroup(newGroup));
    toggle();
  };

  const onSubmit = (e, values) => {
    e.action === GROUP_ACTIONS.UPDATE && handleValidGroupSubmit(e, values);
    e.action === GROUP_ACTIONS.SELECT_CATEGORIES && handleUpdateCategoriesForGroup(values);
  };

  const handleUpdateCategoriesForGroup = values => {
    const arrCategories = Object.values(values);
    const payload = {
      groupId: edittingGroup.id,
      categoryIds: arrCategories.map(category => _get(category, 'id', '')),
      isSubGroupRequest: !isHandlingMainGroup
    };
    dispatch(addGroupCategoryDependencies(payload));
    toggle();
  };

  useEffect(() => {
    if (!isEmpty(errors)) showNotification(errors);
    if (isSuccess) showNotification('');
    dispatch(resetErrorStatus());
  }, [errors, isSuccess]);

  useEffect(() => {
    dispatch(getGroups());
  }, []);

  const handleAddNewGroup = () => {
    setIsEdit(false);
    toggle();
    setIsCreateSub(false);
    setIsHandlingMainGroup(true);
  };

  const handleEditSubGroup = (subGroup, groupId) => {
    setEdittingGroup({
      id: subGroup.id,
      name: subGroup.name,
      description: subGroup.description,
      type: groupId,
      categories: subGroup.category
    });
    setIsEdit(true);
    toggle();
    setIsHandlingMainGroup(false);
  };
  const handleDeleteSubGroup = (subGroup, groupId) => {
    setConfirmation(true);
    setGroupDelete({
      ...subGroup,
      type: groupId,
      isSub: true
    });
    setIsHandlingMainGroup(false);
  };

  return (
    <Spin tip={translator('loading') + '...'} spinning={isLoadingGroups}>
      <div className='page-content'>
        <MetaTags>
          <title>{`${translator('groups')} | Le diamant du terroir`}</title>
        </MetaTags>
        {confirmation && (
          <SweetAlert
            title={translator('are you sure?')}
            warning
            showCancel
            confirmBtnBsStyle='success'
            cancelBtnBsStyle='danger'
            onConfirm={() => {
              handleDeleteGroup(groupDelete);
              setConfirmation(false);
            }}
            onCancel={() => {
              setConfirmation(false);
            }}
          />
        )}
        <Container fluid>
          <Breadcrumbs title={translator('manage')} breadcrumbItem='groups' />
          <Row>
            <Col xs='12'>
              <Card>
                <CardBody>
                  <React.Fragment>
                    <Row className='mb-2'>
                      <Col sm='4'>
                        <div className='search-box ms-2 mb-2 d-inline-block'>
                          <div className='position-relative' />
                        </div>
                      </Col>
                      {(isSuperior || isCreateGroupPermission) && (
                        <Col sm='8'>
                          <div className='text-sm-end'>
                            <Button
                              type='button'
                              color='success'
                              className='btn-rounded  mb-2 me-2'
                              onClick={handleAddNewGroup}
                            >
                              <i className='mdi mdi-plus me-1' />
                              {translator('new group')}
                            </Button>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col xl='12'>
                        <div className='table-responsive'>
                          <Table
                            rowKey='id'
                            pagination={{ pageSize: SIZEPERPAGE}}
                            dataSource={groupsData}
                            columns={GroupsColumns}
                            sticky={true}
                            expandable={{
                              expandedRowRender: row => (
                                <GroupsExpandableRow
                                  key={`expandGroup${_get(row, 'id', '')}`}
                                  setEdittingGroup={setEdittingGroup}
                                  rowDatas={row}
                                  handleEditSubGroup={handleEditSubGroup}
                                  handleDeleteSubGroup={handleDeleteSubGroup}
                                  isPermission={isSuperior || isModifyDeleteGroupPermission}
                                />
                              )
                            }}
                          />
                        </div>
                        <Modal isOpen={modal} toggle={toggle} scrollable={true}  size='lg'>
                          <ModalHeader toggle={toggle} tag='h4'>
                            {isEdit ? translator('edit group') : isCreateSub ? translator('add sub group') : translator('add group')}
                          </ModalHeader>
                          <ModalBody>
                            <GroupForm
                              group={isEdit ? edittingGroup : {}}
                              action={
                                isEdit
                                  ? GROUP_ACTIONS.UPDATE
                                  : isCreateSub
                                  ? GROUP_ACTIONS.CREATE_SUB
                                  : GROUP_ACTIONS.CREATE
                              }
                              handleSubmit={onSubmit}
                            />
                          </ModalBody>
                        </Modal>
                      </Col>
                    </Row>
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Spin>
  );
};

GroupList.propTypes = {
  Groups: PropTypes.array,
  onGetGroups: PropTypes.func,
  onAddNewGroup: PropTypes.func,
  onDeleteGroup: PropTypes.func,
  onUpdateGroup: PropTypes.func,
  t: PropTypes.any
};

export default withTranslation()(GroupList);
