/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import ProductForm from 'components/Common/ProductForm';
import PropTypes from 'prop-types';
import { get as _get, isEmpty } from 'lodash';
import { Spin } from 'antd';
import * as moment from 'moment';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  Input,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Breadcrumbs from 'components/Common/Breadcrumb';
import {
  getProducts,
  addNewProduct,
  updateProduct,
  deleteProduct,
  updateCategoriesForProduct,
  resetErrorStatus,
  getFilteredProduct,
} from 'store/products/actions';
import { PRODUCT_ACTIONS } from 'components/Common/ProductForm/constants';
import { useSelector, useDispatch } from 'react-redux';
import MetaTags from 'react-meta-tags';
import SweetAlert from 'react-bootstrap-sweetalert';
import { showNotification } from 'constants/messages';
import { TooltipComponent } from 'components/Common/Tooltip';
import { EllipseSpan } from 'components/Common/StyledComponents/Span';
import EmpyDataForm from '../../components/Common/EmptyDataForm';
import { PERMISSIONS } from 'utils/permissions';
import { SUPERIOR_ADMIN_ROLE } from 'constants/index';
import { withTranslation } from 'react-i18next';
import { SIZEPERPAGE } from 'constants/utils';
import img from '../../assets/images/img.png';

const ProductList = props => {
  const dispatch = useDispatch();
  const { t: translator } = props;

  const { products, errors, isSuccess, isLoadingProducts, role, myPermissions, filteredProduct } =
    useSelector(state => {
      return {
        isLoadingProducts: _get(state, ['products', 'isLoadingProducts'], false),
        products: _get(state, ['products', 'products'], []),
        errors: _get(state, ['products', 'errors', 'errors'], ''),
        isSuccess: _get(state, ['products', 'isSuccess'], ''),
        role: _get(state, ['Profile', 'profile', 'role'], undefined),
        myPermissions: _get(state, ['permissions', 'myPermissions'], []),
        filteredProduct: _get(state, ['products', 'filteredProduct'], []),
      };
    });

  const [isSuperior, setIsSuperior] = useState(false);
  useEffect(() => {
    setIsSuperior(role === SUPERIOR_ADMIN_ROLE);
  }, [role]);

  const [isEditDeleteProductPermission, setIsEditDeleteProductPermission] = useState(false);
  const [searchKeywordProduct, setSearchKeywordProduct] = useState('');
  const [previousKeyword, setPreviousKeyword] = useState('');
  const [isSearched, setIsSearched] = useState(false);
  const [isCreateProducts, setIsCreateProducts] = useState(false);
  const [startFilterDate, setStartFilterDate] = useState(new Date());
  const [endFilterDate, setEndFilterDate] = useState(null);

  useEffect(() => {
    if (isEmpty(myPermissions)) return;
    const arr = [];
    myPermissions.forEach(permission => {
      const permissionName = _get(permission, 'name', '');
      arr.push(permissionName);
    });
    setIsEditDeleteProductPermission(
      arr.includes(PERMISSIONS.PRODUCT_MANAGEMENT.EDIT_DELETE_PRODUCTS)
    );
    setIsCreateProducts(arr.includes(PERMISSIONS.PRODUCT_MANAGEMENT.CREATE_PRODUCTS));
  }, [myPermissions]);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [productDelete, setProductDelete] = useState([]);
  const [edittingProduct, setEdittingProduct] = useState({});
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState({});

  const [pageOptions, setPageOptions] = useState({
    sizePerPage: SIZEPERPAGE,
    hideSizePerPage: true,
  });

  const onFilterDateChange = dates => {
    const [start, end] = dates;
    setStartFilterDate(start);
    setEndFilterDate(end);
  };

  useEffect(() => {
    if (!isEmpty(products))
      setPageOptions({
        sizePerPage: SIZEPERPAGE,
        hideSizePerPage: true,
      });
  }, [products]);

  const onSelectAllRecords = useCallback(
    e => {
      const selectedIds = e.target.checked
        ? products.reduce(
            (acc, prod) => ({
              ...acc,
              [prod.id]: true,
            }),
            {}
          )
        : {};
      setSelectedRecords(selectedIds);
    },
    [products]
  );

  const onSelectRecord = id => e => {
    setSelectedRecords({
      ...selectedRecords,
      [id]: e.target.checked,
    });
  };

  const ProductsColumnsNoAction = [
    {
      dataField: 'checkbox',
      isDummyField: true,
      text: <input type='checkbox' onChange={onSelectAllRecords} />,
      formatter: (cellContent, product) => (
        <input
          type='checkbox'
          checked={!!selectedRecords?.[product.id]}
          onChange={onSelectRecord(product.id)}
        />
      ),
    },
    {
      dataField: 'item_code',
      text: translator('item code'),
      sort: true,
    },
    {
      dataField: 'title',
      text: translator('title'),
      sort: true,
      formatter: (cellContent, row) => {
        const tooltipContent = <p className='mt-1 ms-2'>{_get(row, 'title', '')}</p>;
        return (
          <TooltipComponent id={`title_${_get(row, 'id', '').toString()}`} content={tooltipContent}>
            <EllipseSpan>{_get(row, 'title', '')}</EllipseSpan>
          </TooltipComponent>
        );
      }
    },
    {
      dataField: 'images',
      text: translator('image'),
      formatter: (cellContent, row) => (
        <>{<img src={row.image ? getImage(row.image) : img} className='mb-0 avatar-md' />}</>
      ),
    },
    {
      dataField: 'description',
      text: translator('description'),
      formatter: (cellContent, row) => {
        const tooltipContent = <p className='mt-1 ms-2'>{_get(row, 'description', '')}</p>;
        return (
          <TooltipComponent id={_get(row, 'id', '').toString()} content={tooltipContent}>
            <EllipseSpan>{_get(row, 'description', '')}</EllipseSpan>
          </TooltipComponent>
        );
      }
    },
    {
      dataField: 'dlc',
      text: translator('DLC'),
    },
    {
      dataField: 'joiningDate',
      text: translator('created at'),
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },
    {
      dataField: 'categories',
      text: translator('categories'),
      formatter: (cellContent, row) => {
        const categories = _get(row, 'category', []);
        const formaterCategories = categories.reduce((accumulator, cur) => {
          return accumulator + ', ' + cur.name;
        }, '');
        const tooltipContent = (
          <ul>
            {categories.map(subCat => (
              <li key={`sub-cat-${_get(subCat, 'name', '')}`}>{_get(subCat, 'name', '')}</li>
            ))}
          </ul>
        );
        return (
          <TooltipComponent
            id={_get(row, 'id', '').toString()}
            tag={translator('category')}
            content={tooltipContent}
          >
            <EllipseSpan>{formaterCategories.substring(1)}</EllipseSpan>
          </TooltipComponent>
        );
      },
    },
  ];

  const ProductsColumns =
    isSuperior || isEditDeleteProductPermission
      ? [
        ...ProductsColumnsNoAction,
        {
          dataField: 'menu',
          isDummyField: true,
          text: translator('action'),
          formatter: (cellContent, product) => (
            <UncontrolledDropdown direction='left'>
              <DropdownToggle href='#' className='card-drop' tag='i'>
                <i className='mdi mdi-dots-horizontal font-size-18' />
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu-end'>
                <DropdownItem onClick={() => handleEditProduct(product)}>
                  <i className='fas fa-pencil-alt text-success me-1' />
                  {translator('edit')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setConfirmDelete(true);
                    setProductDelete(product);
                  }}
                >
                  <i className='fas fa-trash-alt text-danger me-1' />
                  {translator('delete')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
      ]
      : ProductsColumnsNoAction;

  const toggle = () => {
    setModal(!modal);
  };

  const handleEditProduct = product => {
    setEdittingProduct({
      id: product.id,
      item_code: product.item_code,
      title: product.title,
      description: product.description,
      dlc: product.dlc,
      image: product.image,
      info: product.info,
    });
    setIsEdit(true);
    toggle();
  };

  const handleDeleteProduct = product => {
    dispatch(deleteProduct(product));
  };

  const onSearchProducts = () => {
    if (previousKeyword === searchKeywordProduct || isLoadingProducts) return;
    dispatch(getFilteredProduct({ query: searchKeywordProduct }));
    setPreviousKeyword(searchKeywordProduct);
    setIsSearched(true);
  };

  const handleValidProductSubmit = (e, values) => {
    const newProduct = {
      id: edittingProduct.id,
      ...values,
    };
    isEdit ? dispatch(updateProduct(newProduct)) : dispatch(addNewProduct(newProduct));
    toggle();
  };

  const onSubmit = (e, values) => {
    e.action === PRODUCT_ACTIONS.UPDATE && handleValidProductSubmit(e, values);
    e.action === PRODUCT_ACTIONS.SELECT_CATEGORIES && handleUpdateCategoriesForProduct(values);
  };

  const handleUpdateCategoriesForProduct = values => {
    const payload = {
      productId: edittingProduct.id,
      category: { ...values },
    };
    dispatch(updateCategoriesForProduct(payload));
    toggle();
  };

  useEffect(() => {
    if (!isEmpty(errors)) showNotification(errors);
    if (isSuccess) showNotification('');
    dispatch(resetErrorStatus());
  }, [errors, isSuccess]);

  useEffect(() => {
    dispatch(getProducts());
  }, []);

  const handleAddNewProduct = () => {
    setIsEdit(false);
    setEdittingProduct({});
    toggle();
  };

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc',
    },
  ];

  const handleValidDate = date => {
    return moment(new Date(date)).format('DD/MM/YYYY');
  };

  const getImage = image => {
    return `${process.env.REACT_APP_API_URL}/${image}`;
  };

  const displayProducts = useMemo(() => {
    const prods = isSearched ? filteredProduct : products;
    return prods.map(product => ({
      ...product,
      isSelected: selectedRecords?.[product.id],
    }));
  }, [isSearched, filteredProduct, products, selectedRecords]);

  return (
    <Spin spinning={isLoadingProducts} tip={translator('loading') + '...'}>
      <React.Fragment>
        <div className='page-content'>
          <MetaTags>
            <title>{translator('products')} | Le diamant du terroir</title>
          </MetaTags>
          {confirmDelete && (
            <SweetAlert
              title={translator('are you sure?')}
              warning
              showCancel
              confirmBtnBsStyle='success'
              cancelBtnBsStyle='danger'
              onConfirm={() => {
                handleDeleteProduct(productDelete);
                setConfirmDelete(false);
              }}
              onCancel={() => {
                setConfirmDelete(false);
              }}
            />
          )}
          <Container fluid>
            <Breadcrumbs title={translator('manage')} breadcrumbItem={translator('products')} />
            <Row>
              <Col xs='12'>
                <Card>
                  <CardBody>
                    <React.Fragment>
                      <Row className='mb-2'>
                        <Col sm='10'>
                          <Row>
                            <Col className='col-8'>
                              <Input
                                value={searchKeywordProduct}
                                type='text'
                                onChange={e => {
                                  setSearchKeywordProduct(_get(e, ['target', 'value'], ''));
                                }}
                              />
                            </Col>
                            <Col className='col-auto'>
                              <Button onClick={onSearchProducts}>{translator('search')}</Button>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm='2'>
                          {(isSuperior || isCreateProducts) && (
                            <div className='text-sm-end'>
                              <Button
                                type='button'
                                color='success'
                                className='btn-rounded  mb-2 me-2'
                                onClick={handleAddNewProduct}
                              >
                                <i className='mdi mdi-plus me-1' />
                                {translator('new product')}
                              </Button>
                            </div>
                          )}
                        </Col>
                      </Row>
                      {!isEmpty(products) ? (
                        <Row>
                          <Col xl='12'>
                            <div className='table-responsive'>
                              <BootstrapTable
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={'table align-middle table-nowrap table-check'}
                                headerWrapperClasses={'table-light'}
                                keyField='id'
                                pagination={paginationFactory(pageOptions)}
                                data={displayProducts}
                                columns={ProductsColumns}
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <EmpyDataForm />
                      )}
                      <Modal isOpen={modal} toggle={toggle} scrollable={true}>
                        <ModalHeader toggle={toggle} tag='h4'>
                          {isEdit ? translator('edit') : translator('add')}
                        </ModalHeader>
                        <ModalBody>
                          <ProductForm
                            product={edittingProduct}
                            action={isEdit ? PRODUCT_ACTIONS.UPDATE : PRODUCT_ACTIONS.CREATE}
                            handleSubmit={onSubmit}
                          />
                        </ModalBody>
                      </Modal>
                    </React.Fragment>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </Spin>
  );
};

ProductList.propTypes = {
  Products: PropTypes.array,
  onGetProducts: PropTypes.func,
  onAddNewProduct: PropTypes.func,
  onDeleteProduct: PropTypes.func,
  onUpdateProduct: PropTypes.func,
  t: PropTypes.any,
};

export default withTranslation()(ProductList);
