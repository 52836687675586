import React from "react";
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Images
import maintenance from "../../assets/images/maintenance.svg";
import logo from "../../assets/images/logo-dark.png";

const PagesMaintenance = () => {
  return (
    <React.Fragment>
      <section className='my-5 pt-sm-5'>
        <MetaTags>
          <title>Maintenance | Le diamant du terroir</title>
        </MetaTags>
        <Container>
          <Row>
            <Col xs='12' className='text-center'>
              <div className='home-wrapper'>
                <div className='mb-5'>
                  <Link to='/dashboard' className='d-block auth-logo' >
                    <img src={logo} alt='logo' height='20' />
                  </Link>
                </div>

                <Row className='justify-content-center'>
                  <Col sm={4}>
                    <div className='maintenance-img'>
                      <img
                        src={maintenance}
                        alt=''
                        className='img-fluid mx-auto d-block'
                      />
                    </div>
                  </Col>
                </Row>
                <h3 className='mt-5'>Site is Under Maintenance</h3>
                <p>Please check back in sometime.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default PagesMaintenance;
