import { call, put, takeEvery } from 'redux-saga/effects';
import { notificationService } from 'services/Notifications.service';
import { get as _get } from 'lodash';
import { catchError } from 'store/errors/actions';
import {
    PUSH_NOTIFICATION,
    GET_NOTIFICATION_HISTORY,
  } from './actionTypes';
import {
    pushNotificationSuccess,
    pushNotificationFailure,
    getNotificationHistorySuccess,
    getNotificationHistoryFailure,
} from './actions';

function* pushNotification({ payload }) {
  try {
    const res = yield call(notificationService.create, payload);
    yield put(pushNotificationSuccess(_get(res, 'results', [])));
  } catch (error) {
    yield put(catchError(error));
    yield put(pushNotificationFailure(error));
  }
}

function* getListNotifications() {
  try {
    const res = yield call(notificationService.list);
    yield put(getNotificationHistorySuccess(_get(res, 'results', [])));
  } catch (error) {
    yield put(catchError(error));
    yield put(getNotificationHistoryFailure(error));
  }
}

function* notificationSaga() {
  yield takeEvery(PUSH_NOTIFICATION, pushNotification);
  yield takeEvery(GET_NOTIFICATION_HISTORY, getListNotifications);
}

export default notificationSaga;
