/* eslint-disable react/display-name */
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { showNotification } from 'constants/messages';
import { get as _get, isEmpty, isEqual as isPropEqual } from 'lodash';
import { enhancedGet } from 'utils';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Col, Input, Row } from 'reactstrap';
import {
  addUserIntoGroup,
  deleteUserOnGroup,
  getFilteredUsers,
  getFilteredUsersByGroup,
  removeFilteredUser,
  resetErrorStatus
} from 'store/actions';
import { ACTION_LABELS, GROUP_ACTIONS } from './constants';
import { withTranslation } from 'react-i18next';
import { customStyleSelect } from '../../../constants/styles';
import { SIZEPERPAGE } from 'constants/utils';

let arrUserIds = [];

const GroupForm = props => {
  const dispatch = useDispatch();
  const { isSuccess, errors, userSearched, isLoadingUsers, usesrByGroupSearched } = useSelector(
    state => {
      return {
        groups: _get(state, ['groups', 'groups'], []),
        isLoadingUsers: _get(state, ['users', 'isLoadingUsers', false]),
        isLoadingCategories: _get(state, ['categories', 'isLoadingCategories', false]),
        userSearched: _get(state, ['users', 'filteredUsers'], []),
        usesrByGroupSearched: _get(state, ['users', 'filteredUsersByGroup'], []),
        errors: _get(state, ['users', 'errors', 'errors'], ''),
        isSuccess: _get(state, ['users', 'isSuccess'], '')
      };
    }
  );

  const { handleSubmit, group = {}, action, t: translator } = props;
  const groupId = _get(group, 'id', '');
  const parentGroupId = _get(group, 'type', undefined);

  const [isUserForm, setIsUserForm] = useState(true);
  const [isGroupForm, setIsGroupForm] = useState(false);
  const [optionUserSearched, setOptionUserSearched] = useState([]);
  const [selectedUserSearched, setSelectedUserSearched] = useState([]);
  const [searchKeywordUser, setSearchKeywordUser] = useState('');
  const [isOpenMenuUser, setIsOpenMenuUser] = useState(false);
  const [isLoadingUserSelect, setIsLoadingUserSelect] = useState(false);
  const [isDisableBtnUpdateUser, setIsDisableBtnUpdateUser] = useState(false);
  const [isAddUserGroup, setIsAddUserGroup] = useState(false);
  const [searchKeywordUserByGroup, setSearchKeywordUserByGroup] = useState('');

  useEffect(() => {
    dispatch(getFilteredUsersByGroup({ query: '', groupId, isGetAllUser: true }));
  }, []);

  useEffect(() => {
    if (!isEmpty(errors)) showNotification(errors);
    if (isSuccess) showNotification('');
    dispatch(resetErrorStatus());
  }, [errors, isSuccess]);

  useEffect(() => {
    if (!isEmpty(usesrByGroupSearched))
      setPageOptions({
        sizePerPage: SIZEPERPAGE,
        hideSizePerPage: true
      });
  }, [usesrByGroupSearched]);

  useEffect(() => {
    if (isEmpty(userSearched)) {
      return;
    }
    setIsLoadingUserSelect(false);
    setIsAddUserGroup(true);
    const filterUserSearched = userSearched.map(user => {
      return { id: user.id, label: user.brand_name, value: user.user_name + ' ' + user.code + ' ' +user.brand_name };
    });
    setOptionUserSearched(filterUserSearched);
    setIsOpenMenuUser(true);
  }, [userSearched]);

  const onClickGroupForm = () => {
    setIsUserForm(true);
    setIsGroupForm(false);
    setIsDisableBtnUpdateUser(false);
  };

  const onClickUserForm = () => {
    setIsUserForm(false);
    setIsGroupForm(true);
    setIsDisableBtnUpdateUser(true);
  };

  const handleSelectUserSearched = targetUser => {
    setSelectedUserSearched(targetUser);
    setIsOpenMenuUser(false);
    setSearchKeywordUser('');
    dispatch(removeFilteredUser());
  };

  const onSubmit = (e, values) => {
    !isGroupForm &&
      handleSubmit(
        { action: GROUP_ACTIONS.UPDATE, ...e },
        {
          ...values
        }
      );
  };

  const onSearchUserSubmit = () => {
    setIsLoadingUserSelect(true);
    if (isLoadingUsers) return;
    dispatch(getFilteredUsers(searchKeywordUser));
  };

  const internalFilteredUsers = useMemo(() => {
    const keyWordLowwer = searchKeywordUserByGroup.toLowerCase();
    return usesrByGroupSearched.filter(user =>
      enhancedGet(user, 'user_name', '').toLowerCase().includes(keyWordLowwer) ||
      enhancedGet(user, 'code', '').toLowerCase().includes(keyWordLowwer) ||
      enhancedGet(user, 'brand_name', '').toLowerCase().includes(keyWordLowwer)
    );
  }, [searchKeywordUserByGroup, usesrByGroupSearched]);

  const onAddUserSubmit = () => {
    setIsAddUserGroup(false);
    dispatch(
      addUserIntoGroup({
        groupId,
        userId: _get(selectedUserSearched, 'id', ''),
        parentGroupId
      })
    );
  };

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc'
    }
  ];

  const [pageOptions, setPageOptions] = useState({
    sizePerPage: SIZEPERPAGE,
    totalSize: usesrByGroupSearched.length,
    hideSizePerPage: true
  });

  const UsersByGroupColumns = [
    {
      dataField: 'brand_name',
      text: translator('customer name'),
      sort: true
    },
    {
      dataField: 'checkedbox',
      text: translator('delete'),
      isDummyField: true,
      formatter: (cellContent, row) => (
        <Input type='checkbox' key={_get(row, 'id', '')} onChange={() => handleClickChecked(row)} />
      )
    }
  ];

  const handleClickChecked = user => {
    const userId = _get(user, 'id', '');
    const isUserChecked = arrUserIds.some(newUserId => newUserId.toString() === userId.toString());
    if (!isUserChecked) {
      arrUserIds = [...arrUserIds, userId];
    } else {
      arrUserIds = arrUserIds.filter(newUserId => newUserId.toString() !== userId.toString());
    }
  };

  const onDeleteUsers = () => {
    dispatch(deleteUserOnGroup({ userIds: arrUserIds, groupId, parentGroupId }));
    arrUserIds = [];
  };

  const opacityGroup = { opacity: isGroupForm ? 0.25 : 1 };
  const opacityUser = { opacity: isUserForm ? 0.25 : 1 };
  return (
    <AvForm onValidSubmit={onSubmit}>
      <Row form>
        <Row onClick={onClickGroupForm} style={opacityGroup}>
          <Col className='col-12'>
            <div className='mb-3'>
              <AvField
                name='name'
                label={translator('name')}
                type='text'
                errorMessage={translator('invalid name')}
                validate={{
                  required: { value: true }
                }}
                value={group.name || ''}
              />
            </div>
            <div className='mb-3'>
              <AvField
                name='description'
                label={translator('description')}
                type='textarea'
                value={group.description || ''}
              />
            </div>
            <div className='text-end'>
              <button
                type='submit'
                className='btn btn-success save-customer'
                disabled={isDisableBtnUpdateUser}
              >
                {translator(ACTION_LABELS[action])}
              </button>
            </div>
          </Col>
        </Row>
        {action === GROUP_ACTIONS.UPDATE && (
          <Row>
            <div className='modal-header pl-0'>
              <h4 className='modal-title mt-0' id='myModalLabel'>
                {translator('edit member')}
              </h4>
            </div>
          </Row>
        )}

        {action === GROUP_ACTIONS.UPDATE && (
          <Row onClick={onClickUserForm} style={opacityUser}>
            <div className='modal-body'>
              <div className='mb-3'>
                <Row>
                  <label className='control-label'>{translator('search all')}: </label>
                  <Col className='col-8'>
                    <Select
                      styles={customStyleSelect}
                      value={selectedUserSearched}
                      inputValue={searchKeywordUser}
                      onChange={values => {
                        handleSelectUserSearched(values);
                      }}
                      onInputChange={(value, act) => {
                        act.action === 'input-change' && setSearchKeywordUser(value);
                      }}
                      options={optionUserSearched}
                      classNamePrefix='select2-selection'
                      menuIsOpen={isOpenMenuUser}
                      placeholder={translator('customer name or code') + '...'}
                      isLoading={isLoadingUserSelect}
                      isClearable
                    />
                  </Col>
                  <Col className='col-4'>
                    <Row>
                      <Col className='col-6'>
                        <Button onClick={onSearchUserSubmit} disabled={!isDisableBtnUpdateUser}>
                          {translator('search')}
                        </Button>
                      </Col>
                      <Col className='col-6'>
                        <Button
                          onClick={onAddUserSubmit}
                          color='success'
                          disabled={!isAddUserGroup}
                        >
                          {translator('add')}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className='mb-4'>
                <Row>
                  <label className='control-label'>{translator('search by group')}: </label>
                  <Col className='col-8'>
                    <Input
                      value={searchKeywordUserByGroup}
                      type='text'
                      onChange={e => {
                        setSearchKeywordUserByGroup(_get(e, ['target', 'value'], ''));
                      }}
                      placeholder={translator('customer name or code') + '...'}
                    />
                  </Col>
                </Row>
              </div>
              <div className='mb-1'>
                <Row>
                  <Col xl='12'>
                    <div className='table-responsive'>
                      <BootstrapTable
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        classes={'table align-middle table-nowrap table-check'}
                        headerWrapperClasses={'table-light'}
                        keyField='id'
                        pagination={paginationFactory(pageOptions)}
                        data={internalFilteredUsers}
                        columns={UsersByGroupColumns}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className='text-end'>
                <Button
                  onClick={onDeleteUsers}
                  disabled={!isDisableBtnUpdateUser}
                  className='btn btn-danger'
                >
                  {translator('delete')}
                </Button>
              </div>
            </div>
          </Row>
        )}
      </Row>
    </AvForm>
  );
};

GroupForm.propTypes = {
  handleSubmit: PropTypes.func,
  group: PropTypes.object,
  action: PropTypes.string,
  t: PropTypes.any
};

function isEqual(prev, next) {
  if (isPropEqual(prev.userSearched, next.userSearched)) {
    return true;
  } else if (isPropEqual(prev.usesrByGroupSearched, next.usesrByGroupSearched)) {
    return true;
  } else return false;
}

export default memo(withTranslation()(GroupForm), isEqual);
