import {
  PUSH_MESSAGE,
  PUSH_MESSAGE_SUCCESS,
  PUSH_MESSAGE_FAILURE,
  GET_MESSAGE_HISTORY,
  GET_MESSAGE_HISTORY_SUCCESS,
  GET_MESSAGE_HISTORY_FAILURE,
  GET_USER_FOR_MESSAGE,
  UPDATE_MESSAGE,
  GET_USER_FOR_MESSAGE_SUCCESS,
  GET_USER_FOR_MESSAGE_FAILURE,
  UPDATE_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_FAILURE
} from './actionTypes';
import { get as _get } from 'lodash';

const INIT_STATE = {
  errors: '',
  success: ''
};

const NotificationsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PUSH_MESSAGE:
    case GET_MESSAGE_HISTORY:
    case GET_USER_FOR_MESSAGE:
    case UPDATE_MESSAGE:
      return {
        ...state,
        isProcessing: true
      };
    case PUSH_MESSAGE_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        messageHistory: [_get(action, 'payload', {}), ..._get(state, 'messageHistory', [])]
      };
    case PUSH_MESSAGE_FAILURE:
      return {
        ...state,
        isProcessing: false,
        errors: _get(action, 'payload', {})
      };
    case GET_MESSAGE_HISTORY_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        messageHistory: _get(action, 'payload', [])
      };
    case GET_MESSAGE_HISTORY_FAILURE:
      return {
        ...state,
        isProcessing: false,
        messageHistory: [],
        errors: _get(action, 'payload', {})
      };
    case GET_USER_FOR_MESSAGE_SUCCESS:
      return {
        ...state,
        userByMessages: _get(action,'payload',[]),
        isProcessing: false
      };
    case GET_USER_FOR_MESSAGE_FAILURE:
      return {
        ...state,
        userByMessages: [],
        isProcessing: false
      };
    case UPDATE_MESSAGE_SUCCESS:
      return {
        ...state,
        messageHistory: _get(state,'messageHistory',[]).map(message => {
          if(_get(message,'id','') === _get(action,['payload', 'id'],''))
            return {..._get(action,'payload')};
          return message;
        }),
        isProcessing: false,
      };
    case UPDATE_MESSAGE_FAILURE:
      return {
        ...state,
        isProcessing: false,
      };
    default:
      return state;
  }
};

export default NotificationsReducer;
