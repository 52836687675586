export const PUSH_MESSAGE = 'PUSH_MESSAGE';
export const PUSH_MESSAGE_SUCCESS = 'PUSH_MESSAGE_SUCCESS';
export const PUSH_MESSAGE_FAILURE = 'PUSH_MESSAGE_FAILURE';

export const GET_MESSAGE_HISTORY = 'GET_MESSAGE_HISTORY';
export const GET_MESSAGE_HISTORY_SUCCESS = 'GET_MESSAGE_HISTORY_SUCCESS';
export const GET_MESSAGE_HISTORY_FAILURE = 'GET_MESSAGE_HISTORY_FAILURE';

export const GET_USER_FOR_MESSAGE = 'GET_USER_FOR_MESSAGE';
export const GET_USER_FOR_MESSAGE_SUCCESS = 'GET_USER_FOR_MESSAGE_SUCCESS';
export const GET_USER_FOR_MESSAGE_FAILURE = 'GET_USER_FOR_MESSAGE_FAILURE';

export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS';
export const UPDATE_MESSAGE_FAILURE = 'UPDATE_MESSAGE_FAILURE';