import axios from 'axios';
import { get as _get } from 'lodash';

const axiosApi = axios.create({});

axiosApi.interceptors.request.use(config => {
  const token = localStorage.getItem('diament');
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosApi.interceptors.response.use(
  response => {
    const status = _get(response, ['data', 'status']);
    if (status) {
      return status === 'OK' ? response : Promise.reject(response.data.errors);
    }
    return response;
  },
  error => {
    return Promise.reject(error.response.data);
  }
);

export async function get({ url, config = {} }) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post({ url, data, config = {} }) {
  return await axiosApi.post(url, data, { ...config }).then(response => response.data);
}

export async function put({ url, data, config = {} }) {
  return await axiosApi.put(url, { ...data }, { ...config }).then(response => response.data);
}

export async function del({ url, config = {} }) {
  return await axiosApi.delete(url, { ...config }).then(response => response.data);
}