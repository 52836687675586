/* eslint-disable react/display-name */
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { EllipseSpan } from 'components/Common/StyledComponents/Span';
import { TooltipComponent } from 'components/Common/Tooltip';
import { get as _get, isEmpty } from 'lodash';
import * as moment from 'moment';
import React, { useEffect, useState, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Table } from 'react-super-responsive-table';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown,
  Dropdown,
} from 'reactstrap';
import {
  updateOrderStatus,
  addNewOrder,
  resetErrorStatus as resetOrderErrorStatus,
} from 'store/orders/actions';
import {
  getUserDetail,
  addUserFavoriteProduct,
  resetErrorStatus as resetProductErrorStatus,
  exportUserCsv
} from 'store/users/actions';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { SELECTIVE_TAB, SELECTIVE_TAB_VALUE } from '../Orders/constants';
import { OrderForm } from 'components/Common/OrderForm';
import styled from 'styled-components';
import { ORDER_HIGHER_ACTION, ORDER_HIGHER_ACTION_VALUE } from './constants';
import { AddProductForm } from 'components/Common/OrderForm/addProductForm';
import { PERMISSIONS } from 'utils/permissions';
import { SUPERIOR_ADMIN_ROLE } from 'constants/index';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { showNotification } from 'constants/messages';
import { SIZEPERPAGE } from 'constants/utils';
import { ExportCSVForm } from 'components/Common/OrderForm/exportCsv';

const Icon = styled.i`
  margin-left: 5px;
`;

const onClickAction = 'onclick-action';

const UserDetail = props => {
  const userId = _get(props, ['match', 'params', 'id'], '');
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translator } = props;
  const {
    userDetail,
    myPermissions,
    role,
    orderError,
    orderSuccess,
    productError,
    productSuccess,
  } = useSelector(state => {
    return {
      userDetail: _get(state, ['users', 'userDetail'], []),
      myPermissions: _get(state, ['permissions', 'myPermissions'], []),
      role: _get(state, ['Profile', 'profile', 'role'], undefined),
      orderError: _get(state, ['orders', 'errors']),
      orderSuccess: _get(state, ['orders', 'isSuccess'], ''),
      productError: _get(state, ['users', 'errors']),
      productSuccess: _get(state, ['users', 'isSuccess'], ''),
    };
  });

  const [isSuperior, setIsSuperior] = useState(false);
  useEffect(() => {
    setIsSuperior(role === SUPERIOR_ADMIN_ROLE);
  }, [role]);

  const groups = _get(userDetail, 'groups', []);
  const orders = _get(userDetail, 'orders', []);
  const products = _get(userDetail, ['favorite', 'products'], []);
  const messages = _get(userDetail, 'messages', []);
  const notifications = _get(userDetail, 'notifications', []);

  const [isOpenUpdateModal, setUpdateModalStat] = useState(false);
  const [editingOrder, setEdittingOrder] = useState({});
  const [isActionDropdownOpen, setIsActionDropdownOpen] = useState(false);
  const [isAddingNewOrder, setIsAddingNewOrder] = useState(false);
  const [isAddingNewProduct, setIsAddingNewProduct] = useState(false);
  const [isExportingCsv, setIsExportingCsv] = useState(false);

  const [isAddRemoveFavouritePermission, setIsAddRemoveFavouritePermission] = useState(false);
  const [isAddOrderPermission, setIsAddOrderPermission] = useState(false);
  const [isExportCsvPermission, setIsExportCsvPermission] = useState(false);
  const [orderReverse, setOrderReverse] = useState(orders);
  const [messageReverse, setMessageReverse] = useState(messages);
  const [notificationReverse, setNotificationReverse] = useState(notifications);

  useEffect(() => {
    if (isEmpty(orders)) return;
    setOrderReverse(orders.reverse());
    setMessageReverse(messages.reverse());
    setNotificationReverse(notifications.reverse());
  }, [orders]);

  useEffect(() => {
    if (isEmpty(myPermissions)) return;
    const arr = [];
    myPermissions.forEach(permission => {
      const permissionName = _get(permission, 'name', '');
      arr.push(permissionName);
    });
    setIsAddRemoveFavouritePermission(
      arr.includes(PERMISSIONS.CUSTOMER_MANAGEMENT.ADD_REMOVE_FAVOURITES_CUSTOMER)
    );
    setIsAddOrderPermission(arr.includes(PERMISSIONS.ORDER_MANAGEMENT.ADD_ORDER));
    setIsExportCsvPermission(arr.includes(PERMISSIONS.ORDER_MANAGEMENT.EXPORT_CSV));
  }, [myPermissions]);

  const toggleAddOrder = () => {
    setIsAddingNewOrder(!isAddingNewOrder);
  };

  const toggleAddProduct = () => {
    setIsAddingNewProduct(!isAddingNewProduct);
  };

  const toggleExportCsv = () => {
    setIsExportingCsv(!isExportingCsv);
  };

  const toggleActionDropdown = () => setIsActionDropdownOpen(prevState => !prevState);

  useEffect(() => {
    dispatch(getUserDetail(userId));
  }, []);

  const handleValidDate = date => {
    return moment(new Date(date)).format('DD/MM/YYYY');
  };

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc',
    },
  ];

  const ordersColumns = [
    {
      dataField: 'code',
      text: translator('order number'),
    },
    {
      dataField: 'deliveryDate',
      text: translator('delivery date'),
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className='mb-0'>{handleValidDate(_get(row, 'date_of_delivery', ''))}</p>
        </>
      ),
    },
    {
      dataField: 'createdDate',
      text: translator('created date'),
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className='mb-0'>{handleValidDate(_get(row, 'created_at', ''))}</p>
        </>
      ),
    },
    {
      dataField: 'products',
      text: translator('product'),
      sort: true,
      formatter: (cellContent, row) => {
        const _products = _get(row, 'products', []);
        const arrProducts = Object.values(_products);
        const product = arrProducts.reduce((accumulator, pro) => {
          const unitWeight = _get(pro.info, 'unit_weight', '');
          return accumulator + ', ' + pro.amount + ' ' + pro.title + ' ' + unitWeight;
        }, '');
        const tooltipContent = (
          <ul>
            {arrProducts.map(arr => (
              <li key={`list-order-${_get(arr, 'id', '')}`}>
                {_get(arr, 'amount', '') +
                  ' ' +
                  _get(arr, 'title', '') +
                  ' ' +
                  _get(arr, ['info', 'unit_weight'], '')}
              </li>
            ))}
          </ul>
        );
        return (
          <TooltipComponent
            id={_get(row, 'id', '').toString()}
            tag={translator('product')}
            content={tooltipContent}
          >
            <EllipseSpan>{product.substring(1)}</EllipseSpan>
          </TooltipComponent>
        );
      },
    },
    {
      dataField: 'status',
      text: translator('status'),
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className='mb-0'>
            {row.status === 1
              ? translator(SELECTIVE_TAB.NEW_ORDER)
              : row.status === 2
              ? translator(SELECTIVE_TAB.PREPARATION)
              : row.status === 3
              ? translator(SELECTIVE_TAB.DELAYED)
              : row.status === 4
              ? translator(SELECTIVE_TAB.PROCESSED)
              : ''}
          </p>
        </>
      ),
    },
    {
      dataField: 'menu',
      isDummyField: true,
      text: translator('action'),
      formatter: (cellContent, order) => (
        <UncontrolledDropdown direction='left'>
          <DropdownToggle href='#' className='card-drop' tag='i'>
            <i className='mdi mdi-dots-horizontal font-size-18' action={onClickAction} />
          </DropdownToggle>
          <DropdownMenu className='dropdown-menu-end'>
            <DropdownItem onClick={() => handleEditOrder(order)} action={onClickAction}>
              <i className='fas fa-pencil-alt text-success me-1' />
              {translator('edit')}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const handleAddOrder = order => {
    dispatch(
      addNewOrder({
        ...order,
        userId: _get(userDetail, 'id', ''),
      })
    );
    toggleAddOrder();
  };

  const handleEditOrder = order => {
    setUpdateModalStat(true);
    setEdittingOrder(order);
  };

  const toggle = () => {
    setUpdateModalStat(!isOpenUpdateModal);
  };

  const handleUpdateOrder = (e, values) => {
    const status = _get(values, 'stat', '').toString();
    const prevStatus = _get(editingOrder, 'status', '').toString();
    if (status === prevStatus) return toggle();
    dispatch(
      updateOrderStatus({
        order: {
          id: _get(editingOrder, 'id', ''),
          status: _get(values, 'stat', ''),
        },
        prevStatus:
          prevStatus === SELECTIVE_TAB_VALUE[SELECTIVE_TAB.NEW_ORDER]
            ? 0
            : SELECTIVE_TAB_VALUE[SELECTIVE_TAB.PREPARATION]
            ? 1
            : SELECTIVE_TAB_VALUE[SELECTIVE_TAB.DELAYED]
            ? 2
            : SELECTIVE_TAB_VALUE[SELECTIVE_TAB.PROCESSED] && 3,
      })
    );
    toggle();
  };

  const getImage = image => {
    return `${process.env.REACT_APP_API_URL}/${image}`;
  };

  const staticProductsColumns = [
    {
      dataField: 'item_code',
      text: translator('item code'),
      sort: true,
    },
    {
      dataField: 'title',
      text: translator('title'),
      sort: true,
    },
    {
      dataField: 'image',
      text: translator('image'),
      formatter: (cellContent, row) => (
        <>{row.image && <img src={getImage(row.image)} className='mb-0 avatar-md' />}</>
      ),
    },
    {
      dataField: 'dlc',
      text: translator('DLC'),
    },
    {
      dataField: 'description',
      text: 'Description',
      formatter: (cellContent, row) => {
        const tooltipContent = <ul>{_get(row, 'description', '')}</ul>;
        return (
          <TooltipComponent
            id={_get(row, 'id', '').toString()}
            tag='Description'
            content={tooltipContent}
          >
            <EllipseSpan>{_get(row, 'description', '')}</EllipseSpan>
          </TooltipComponent>
        );
      },
    },
  ];

  const ProductsColumns = useMemo(() => {
    if (isAddRemoveFavouritePermission || isSuperior)
      return [
        ...staticProductsColumns,
        {
          dataField: 'menu',
          isDummyField: true,
          text: translator('action'),
          formatter: (cellContent, row) => (
            <UncontrolledDropdown direction='left'>
              <DropdownToggle href='#' className='card-drop' tag='i'>
                <i className='mdi mdi-dots-horizontal font-size-18' action={onClickAction} />
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu-end'>
                <DropdownItem onClick={() => handleDeleteProduct(row)} action={onClickAction}>
                  <i className='fas fa-trash-alt text-danger me-1' />
                  {translator('delete')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        },
      ];
    return [...staticProductsColumns];
  }, [isAddRemoveFavouritePermission, isSuperior]);

  const MessagesColumns = [
    {
      dataField: 'start_date',
      text: translator('start date'),
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className='mb-0'>{handleValidDate(_get(row, 'start_date', ''))}</p>
        </>
      ),
    },
    {
      dataField: 'end_date',
      text: translator('end date'),
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className='mb-0'>{handleValidDate(_get(row, 'end_date', ''))}</p>
        </>
      ),
    },
    {
      dataField: 'admin',
      text: translator('sender'),
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className='mb-0'>{_get(row, ['admin', 'name'], '')}</p>
        </>
      ),
    },
    {
      dataField: 'title',
      text: translator('title'),
      sort: true,
    },
    {
      dataField: 'content',
      text: translator('content'),
      formatter: (cellContent, row) => {
        const contentMessage = _get(row, 'content', '');
        return (
          <TooltipComponent
            id={_get(row, 'id', '').toString()}
            tag={translator('content')}
            content={contentMessage}
          >
            <EllipseSpan>{contentMessage}</EllipseSpan>
          </TooltipComponent>
        );
      },
    },
    {
      dataField: 'receiver',
      text: translator('receiver'),
      sort: true,
      formatter: (cellContent, row) => {
        const userReceivers = _get(row, 'user_ids', []);
        return <p className='mb-0'>{userReceivers.length}</p>;
      },
    },
  ];

  const NotificationsColumns = [
    {
      dataField: 'created_at',
      text: translator('date'),
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className='mb-0'>{handleValidDate(_get(row, 'created_at', ''))}</p>
        </>
      ),
    },
    {
      dataField: 'admin',
      text: translator('sender'),
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className='mb-0'>{_get(row, ['admin', 'name'], '')}</p>
        </>
      ),
    },
    {
      dataField: 'title',
      text: translator('title'),
      sort: true,
    },
    {
      dataField: 'content',
      text: translator('content'),
      formatter: (cellContent, row) => {
        const contentMessage = _get(row, 'content', '');
        return (
          <TooltipComponent
            id={_get(row, 'id', '').toString()}
            tag={translator('content')}
            content={contentMessage}
          >
            <EllipseSpan>{contentMessage}</EllipseSpan>
          </TooltipComponent>
        );
      },
    },
    {
      dataField: 'receiver',
      text: translator('receiver'),
      sort: true,
      formatter: (cellContent, row) => {
        const userReceivers = _get(row, 'user_ids', []);
        return <p className='mb-0'>{userReceivers.length}</p>;
      },
    },
  ];

  const [pageOptionsOrders, setPageOptionsOrders] = useState(
    {
      sizePerPage: SIZEPERPAGE,
      totalSize: orders !== undefined ? orders.length : 0,
      hideSizePerPage: true,
    },
    [orders]
  );

  useEffect(() => {
    if (!isEmpty(orders))
      setPageOptionsOrders({
        sizePerPage: SIZEPERPAGE,
        hideSizePerPage: true,
      });
  }, [orders]);

  const [pageOptionsProducts, setPageOptionsProducts] = useState({
    sizePerPage: SIZEPERPAGE,
    totalSize: orders !== undefined ? orders.length : 0,
    hideSizePerPage: true,
  });

  useEffect(() => {
    if (!isEmpty(products))
      setPageOptionsProducts({
        sizePerPage: SIZEPERPAGE,
        hideSizePerPage: true,
      });
  }, [products]);

  const [pageOptionsMessages, setPageOptionsMessages] = useState({
    sizePerPage: SIZEPERPAGE,
    totalSize: !isEmpty(messages) ? messages.length : 0,
    hideSizePerPage: true,
  });

  useEffect(() => {
    if (!isEmpty(messages))
      setPageOptionsMessages({
        sizePerPage: SIZEPERPAGE,
        hideSizePerPage: true,
      });
  }, [messages]);

  const [pageOptionsNotifications, setPageOptionsNotifications] = useState({
    sizePerPage: SIZEPERPAGE,
    totalSize: !isEmpty(notifications) ? notifications.length : 0,
    hideSizePerPage: true,
  });

  useEffect(() => {
    if (!isEmpty(notifications))
      setPageOptionsNotifications({
        sizePerPage: SIZEPERPAGE,
        hideSizePerPage: true,
      });
  }, [notifications]);

  const tableRowEvents = {
    onClick: (e, row) => {
      const action = e.target.getAttribute('action');
      if (action !== onClickAction) {
        history.push(`/order/${row.id}`);
      }
    },
  };

  const handleHigherActions = selectedAction => {
    switch (selectedAction.value) {
      case ORDER_HIGHER_ACTION_VALUE.ADD_NEW_ORDER:
        toggleAddOrder();
        break;
      case ORDER_HIGHER_ACTION_VALUE.ADD_NEW_PRODUCT:
        toggleAddProduct();
        break;
      case ORDER_HIGHER_ACTION_VALUE.EXPORT_CSV:
        toggleExportCsv();
        break;
      default:
        return;
    }
  };

  const handleAddProduct = product => {
    dispatch(
      addUserFavoriteProduct({
        userId: _get(userDetail, 'id', ''),
        productId: _get(product, 'productId', ''),
      })
    );
    toggleAddProduct();
  };

  const handleExportCSV = (range) => {
    dispatch(exportUserCsv({
      range,
      user: userDetail
    }));
    setIsExportingCsv(false);
  };

  const handleDeleteProduct = product => {
    dispatch(
      addUserFavoriteProduct({
        userId,
        productId: _get(product, 'id', ''),
      })
    );
  };

  useEffect(() => {
    if (!isEmpty(orderError)) showNotification(orderError);
    if (orderSuccess) showNotification('');
    dispatch(resetOrderErrorStatus());
  }, [orderError, orderSuccess, productError, productSuccess]);

  useEffect(() => {
    if (!isEmpty(productError)) showNotification(productError);
    if (productSuccess) showNotification('');
    dispatch(resetProductErrorStatus());
  }, [productError, productSuccess]);

  return (
    <React.Fragment>
      <div className='page-content'>
        <MetaTags>
          <title> {translator('customerdetail')} | Le diamant du terroir</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={translator('customers')} breadcrumbItem='customerdetail' />
          <Modal scrollable={true} isOpen={isAddingNewOrder} toggle={toggleAddOrder}>
            <ModalHeader toggle={toggleAddOrder} tag='h4'>
              {translator('new order')}
            </ModalHeader>
            <ModalBody>
              <OrderForm
                userId={_get(userDetail, 'id', '').toString()}
                handleSubmit={handleAddOrder}
                isNotificationEnable={isAddingNewOrder}
              />
            </ModalBody>
          </Modal>
          <Modal scrollable={true} isOpen={isAddingNewProduct} toggle={toggleAddProduct}>
            <ModalHeader toggle={toggleAddProduct} tag='h4'>
              {translator('new favorite product')}
            </ModalHeader>
            <ModalBody>
              <AddProductForm
                handleSubmit={values => {
                  handleAddProduct(values);
                }}
                userId={parseInt(_get(userDetail, 'id', ''))}
                isAddFavProduct={isAddingNewProduct}
              />
            </ModalBody>
          </Modal>
          <Modal scrollable={true} isOpen={isExportingCsv} toggle={toggleExportCsv}>
            <ModalHeader toggle={toggleExportCsv} tag='h4'>
              {translator('order creation date')}
            </ModalHeader>
            <ModalBody>
              <ExportCSVForm
                handleSubmit={values => {
                  handleExportCSV(values);
                }}
                userId={parseInt(_get(userDetail, 'id', ''))}
              />
            </ModalBody>
          </Modal>
          <Row>
            <Col xl='4'>
              <Card>
                <CardBody>
                  <CardTitle className='mb-3'>{translator('customer information')}</CardTitle>
                  <div className='table-responsive'>
                    <Table className='table mb-0'>
                      <tbody>
                        <tr>
                          <td>{translator('code')}: </td>
                          <td>{_get(userDetail, 'code', '')}</td>
                        </tr>
                        <tr>
                          <td>{translator('name')}: </td>
                          <td>{_get(userDetail, 'user_name', '')}</td>
                        </tr>
                        <tr>
                          <td>{translator('brand name')}: </td>
                          <td>{_get(userDetail, 'brand_name', '')}</td>
                        </tr>
                        <tr>
                          <td>{translator('address')}:</td>
                          <td>{_get(userDetail, 'address', '')}</td>
                        </tr>
                        <tr>
                          <td>{translator('email address')}: </td>
                          <td>{_get(userDetail, 'email', '')}</td>
                        </tr>
                        <tr>
                          <td>{translator('phone')}: </td>
                          <td>{_get(userDetail, 'phone_number', '')}</td>
                        </tr>
                        <tr>
                          <td>{translator('groups')}: </td>
                          <td>
                            {groups
                              .reduce((accumulator, currentValue) => {
                                return accumulator + ', ' + currentValue.name;
                              }, '')
                              .substring(1)}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lx='8'>
              {(isSuperior || isAddRemoveFavouritePermission || isAddOrderPermission) && (
                <Row className='text-sm-start mb-2'>
                  <Dropdown isOpen={isActionDropdownOpen} toggle={toggleActionDropdown}>
                    <DropdownToggle outline color='success' caret>
                      {translator('actions')} <Icon className='fa fa-angle-down'></Icon>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>{translator('action')}</DropdownItem>
                      {ORDER_HIGHER_ACTION.map((act, index) => {
                        if (
                          _get(act, 'value') === 'ADD_NEW_PRODUCT' &&
                          !isAddRemoveFavouritePermission &&
                          !isSuperior
                        )
                          return;
                        if (
                          _get(act, 'value') === 'ADD_NEW_ORDER' &&
                          !isAddOrderPermission &&
                          !isSuperior
                        )
                          return;
                        if (
                          _get(act, 'value') === 'EXPORT_CSV' &&
                          !isExportCsvPermission &&
                          !isSuperior
                        )
                          return;
                        return (
                          <DropdownItem
                            key={`act-${index}`}
                            onClick={() => handleHigherActions(act)}
                          >
                            {translator(act.label)}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </Row>
              )}

              {!isEmpty(orders) && (
                <Card>
                  <CardBody>
                    <CardTitle tag='h5'>{translator('orders')}</CardTitle>
                    <div className='table-responsive'>
                      <BootstrapTable
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        classes={'table align-middle table-nowrap table-check'}
                        headerWrapperClasses={'table-light'}
                        keyField='id'
                        pagination={paginationFactory(pageOptionsOrders)}
                        data={orderReverse || []}
                        columns={ordersColumns}
                        rowEvents={tableRowEvents}
                      />
                    </div>
                    <Modal scrollable={true} isOpen={isOpenUpdateModal} toggle={toggle}>
                      <ModalHeader toggle={toggle} tag='h4'>
                        {translator('edit')}
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={handleUpdateOrder}>
                          <Row form>
                            <Col className='col-14'>
                              <div className='mb-3'>
                                <AvField
                                  type='select'
                                  name='stat'
                                  label={translator('orders status')}
                                  helpMessage={translator('select a status')}
                                  errorMessage={translator('invalid product')}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  value={editingOrder.status || ''}
                                >
                                  <option value={SELECTIVE_TAB_VALUE[SELECTIVE_TAB.NEW_ORDER]}>
                                    {translator(SELECTIVE_TAB.NEW_ORDER)}
                                  </option>
                                  <option value={SELECTIVE_TAB_VALUE[SELECTIVE_TAB.PREPARATION]}>
                                    {translator(SELECTIVE_TAB.PREPARATION)}
                                  </option>
                                  <option value={SELECTIVE_TAB_VALUE[SELECTIVE_TAB.DELAYED]}>
                                    {translator(SELECTIVE_TAB.DELAYED)}
                                  </option>
                                  <option value={SELECTIVE_TAB_VALUE[SELECTIVE_TAB.PROCESSED]}>
                                    {translator(SELECTIVE_TAB.PROCESSED)}
                                  </option>
                                </AvField>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className='text-end'>
                                <button type='submit' className='btn btn-success save-customer'>
                                  {translator('update')}
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              )}
              {!isEmpty(products) && (
                <Card>
                  <CardBody>
                    <CardTitle tag='h5'>{translator('favorites')}</CardTitle>
                    <div className='table-responsive'>
                      <BootstrapTable
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        classes={'table align-middle table-nowrap table-check'}
                        headerWrapperClasses={'table-light'}
                        keyField='id'
                        pagination={paginationFactory(pageOptionsProducts)}
                        data={products}
                        columns={ProductsColumns}
                      />
                    </div>
                  </CardBody>
                </Card>
              )}
              {!isEmpty(messages) && (
                <Card>
                  <CardBody>
                    <CardTitle tag='h5'>{translator('messages')}</CardTitle>
                    <div className='table-responsive'>
                      <BootstrapTable
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        classes={'table align-middle table-nowrap table-check'}
                        headerWrapperClasses={'table-light'}
                        keyField='id'
                        pagination={paginationFactory(pageOptionsMessages)}
                        data={messageReverse}
                        columns={MessagesColumns}
                      />
                    </div>
                  </CardBody>
                </Card>
              )}
              {!isEmpty(notifications) && (
                <Card>
                  <CardBody>
                    <CardTitle tag='h5'>{translator('notifications')}</CardTitle>
                    <div className='table-responsive'>
                      <BootstrapTable
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        classes={'table align-middle table-nowrap table-check'}
                        headerWrapperClasses={'table-light'}
                        keyField='id'
                        pagination={paginationFactory(pageOptionsNotifications)}
                        data={notificationReverse}
                        columns={NotificationsColumns}
                      />
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

UserDetail.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UserDetail));
