export const PRODUCT_ACTIONS = {
  UPDATE: 'UPDATE_PRODUCT',
  CREATE: 'CREATE_PRODUCT',
  SELECT_CATEGORIES: 'SELECT_CATEGORIES',
};

export const ACTION_LABELS = {
  [PRODUCT_ACTIONS.UPDATE]: 'update',
  [PRODUCT_ACTIONS.CREATE]: 'create',
};

export const PRODUCT_OPTION_LABELS = {
  genCode: 'PRODUCT_GEN_CODE',
  packaging: 'PRODUCT_PACKAGING',
  unitWeight: 'PRODUCT_UNIT_WEIGHT',
};

export const PRODUCT_OPTION_PROP_KEY = {
  [PRODUCT_OPTION_LABELS.genCode]: 'gen_code',
  [PRODUCT_OPTION_LABELS.packaging]: 'packaging',
  [PRODUCT_OPTION_LABELS.unitWeight]: 'unit_weight',
};
