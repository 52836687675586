import { get as _get } from 'lodash';
import { call, put, takeEvery } from 'redux-saga/effects';
import { permissionsService } from 'services';
import { catchError } from 'store/errors/actions';
import { getMyPermissionsFail, getMyPermissionsSuccess, getPermissionsFail, getPermissionsSuccess } from './actions';
import { GET_MY_PERMISSIONS, GET_PERMISSIONS } from './actionTypes';

function* fetchPermissionsFromBE() {
  try {
    const res = yield call(permissionsService.list);
    yield put(getPermissionsSuccess(_get(res, 'results', [])));
  } catch (error) {
    yield put(catchError(error));
    yield put(getPermissionsFail(error));
  }
}

function* fetchMyPermissionsFromBE() {
  try {
    const res = yield call(permissionsService.getMyPermissions);
    yield put(getMyPermissionsSuccess(_get(res, 'results', [])));
  } catch (error) {
    yield put(catchError(error));
    yield put(getMyPermissionsFail(error));
  }
}

function* permissionsSaga() {
  yield takeEvery(GET_PERMISSIONS, fetchPermissionsFromBE);
  yield takeEvery(GET_MY_PERMISSIONS, fetchMyPermissionsFromBE);
}

export default permissionsSaga;
