export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_BY_ID = 'GET_PRODUCTS_BY_ID';
export const GET_PRODUCTS_BY_ID_FAIL = 'GET_PRODUCTS_BY_ID_FAIL';
export const GET_PRODUCTS_BY_ID_SUCCESS = 'GET_PRODUCTS_BY_ID_SUCCESS';
export const ADD_NEW_PRODUCT = 'ADD_NEW_PRODUCT';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAIL = 'ADD_PRODUCT_FAIL';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL';
export const UPDATE_CATEGORIES_FOR_PRODUCT = 'UPDATE_CATEGORIES_FOR_PRODUCT';
export const UPDATE_CATEGORIES_FOR_PRODUCT_SUCCESS = 'UPDATE_CATEGORIES_FOR_PRODUCT_SUCCESS';
export const UPDATE_CATEGORIES_FOR_PRODUCT_FAIL = 'UPDATE_CATEGORIES_FOR_PRODUCT_FAIL';
export const RESET_ERROR_STATUS = 'RESET_ERROR_STATUS';
export const GET_FILTERED_PRODUCTS_BY_USER_ID = 'GET_FILTERED_PRODUCTS_BY_USER_ID';
export const GET_FILTERED_PRODUCTS_BY_USER_ID_SUCCESS = 'GET_FILTERED_PRODUCTS_BY_USER_ID_SUCCESS';
export const GET_FILTERED_PRODUCTS_BY_USER_ID_FAIL = 'GET_FILTERED_PRODUCTS_BY_USER_ID_FAIL';
export const GET_FILTERED_PRODUCTS = 'GET_FILTERED_PRODUCTS';
export const GET_FILTERED_PRODUCTS_SUCCESS = 'GET_FILTERED_PRODUCTS_SUCCESS';
export const GET_FILTERED_PRODUCTS_FAIL = 'GET_FILTERED_PRODUCTS_FAIL';
