import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Col, Row, Input, Label } from 'reactstrap';
import Select from 'react-select';
import { isEmpty, get as _get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getFilteredProductByUserId } from 'store/products/actions';
import { PERMISSIONS } from 'utils/permissions';
import { SUPERIOR_ADMIN_ROLE } from 'constants/index';
import { withTranslation } from 'react-i18next';
import { customStyleSelect } from '../../../constants/styles';

const Form = props => {
  const dispatch = useDispatch();
  const { handleSubmit, userId, isAddFavProduct, isNotificationEnable, t: translator } = props;
  const { products, myPermissions, role } = useSelector(state => {
    return {
      products: _get(state, ['products', 'filteredProductById'], []),
      myPermissions: _get(state, ['permissions', 'myPermissions'], []),
      role: _get(state, ['Profile', 'profile', 'role'], undefined)
    };
  });
  // eslint-disable-next-line no-unused-vars
  const [selectedProductOption, setSelectedProductOption] = useState({});
  const [isSuperior, setIsSuperior] = useState(false);
  useEffect(() => {
    setIsSuperior(role === SUPERIOR_ADMIN_ROLE);
  }, [role]);

  const [isMessagesNotificationPermission, setIsMessagesNotificationPermission] = useState(false);

  useEffect(() => {
    if (isEmpty(myPermissions)) return;
    const arr = [];
    myPermissions.forEach(permission => {
      const permissionName = _get(permission, 'name', '');
      arr.push(permissionName);
    });
    setIsMessagesNotificationPermission(arr.includes(PERMISSIONS.MESSAGES_NOTIFICATIONS));
  }, [myPermissions]);

  const [selectedProduct, setSelectedProduct] = useState({});
  const [isPushNotification, setIsPushNotification] = useState(false);
  useEffect(() => {
    if (isEmpty(products)) dispatch(getFilteredProductByUserId(userId));
  }, []);
  const handleValidSubmit = (e, value) => {
    const infoID = _get(
      selectedProductOption,
      [parseInt(_get(selectedProduct, 'value', '')), 'value', 'id'],
      ''
    );

    handleSubmit({
      productId: _get(selectedProduct, 'value', ''),
      infoId: infoID,
      ...value,
      isPushNotification
    });
    setIsPushNotification(false);
  };
  const handleClickPush = () => {
    setIsPushNotification(!isPushNotification);
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 1,
      position: 'relative'
    }),
    option: (provided, state) => ({
        ...provided,
        paddingLeft: state.data.isSub ? 30 : 15,
    }),
  };
  
  const options = products.map(product => ({
    label: _get(product, 'name', ''),
    options: _get(product, 'products', []).map(option => ({
      label: _get(option, 'title', ''),
      value: _get(option, 'id', ''),
      info: _get(option, 'info', [])
    }))
  }));

  return (
    <AvForm onValidSubmit={handleValidSubmit}>
      <Row form>
        <Col className='col-14'>
          <div className='mb-3'>
            <label className='control-label'>{translator('product')}</label>
            <Select
              styles={customStyles}
              value={selectedProduct}
              onChange={values => {
                setSelectedProduct(values);
              }}
              options={options}
              classNamePrefix='select2-selection'
              placeholder={translator('select')}
            />
          </div>
          {!isAddFavProduct && (
            <>
              <div className='mb-3'>
                <AvField
                  name='amount'
                  label={translator('quantity')}
                  type='number'
                  errorMessage={translator('invalid quantity')}
                  validate={{
                    required: { value: true }
                  }}
                  min='1'
                />
              </div>
              <label className='control-label'>{translator('option')}</label>
              <Select
                styles={customStyleSelect}
                value={_get(selectedProductOption, _get(selectedProduct, 'value', ''), '')}
                onChange={values => {
                  const result = {
                    ...selectedProductOption,
                    [_get(values, ['value', 'prodId'], '')]: values
                  };
                  setSelectedProductOption(result);
                }}
                options={_get(selectedProduct, 'info', []).map(prodInfo => ({
                  value: {
                    id: _get(prodInfo, 'id', ''),
                    prodId: _get(selectedProduct, 'value', '')
                  },
                  label: _get(prodInfo, 'unit_weight', '')
                }))}
                classNamePrefix='select2-selection'
                isMulti={false}
                placeholder={translator('select')}
              />
            </>
          )}
          {isNotificationEnable && (isSuperior || isMessagesNotificationPermission) && (
            <>
              <br />
              <Input
                type='checkbox'
                onChange={() => handleClickPush()}
                title={translator('push notification')}
                value={isPushNotification}
              />
              <Label className='pushNoti_checkbox'>{translator('push notification')} </Label>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='text-end'>
            <button type='submit' className='btn btn-success save-customer'>
              {translator('submit')}
            </button>
          </div>
        </Col>
      </Row>
    </AvForm>
  );
};

export const AddProductForm = withTranslation()(Form);

Form.propTypes = {
  handleSubmit: PropTypes.func,
  userId: PropTypes.number,
  isAddFavProduct: PropTypes.bool,
  isNotificationEnable: PropTypes.bool,
  t: PropTypes.any
};
