import { post, get, del } from '../helpers/apiHelper';
import { API_URL } from '../constants';
import { getFormData } from 'utils/api';

const list = async () => {
  return await get({
    url: API_URL.product.list
  });
};

const listProductsByUserId = async data => {
  return await post({
    url: API_URL.product.listProductsByUserId,
    data
  });
};

const listByUserId = async userId => {
  return await get({
    url: API_URL.product.listByUserId(userId)
  });
};

const update = async ({ id, product }) => {
  return await post({
    url: `${API_URL.product.update}${id}`,
    data: getFormData(product),
    config: {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  });
};

const create = async product => {
  return await post({
    url: API_URL.product.create,
    data: getFormData(product),
    config: {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  });
};

const archive = async product => {
  return await del({
    url: `${API_URL.product.archive}${product.id}`
  });
};

const updateCategories = async ({ productId, categoryIds }) => {
  return await post({
    url: `${API_URL.product.updateCategories}`,
    data: {
      product_id: productId,
      category_ids: categoryIds
    }
  });
};

const getFilterProducts = async ({ query, categoryId }) => {
  return await post({
    url: API_URL.product.filterProducts,
    data: {
      query,
      category_id: categoryId
    }
  });
};

export const productService = {
  list,
  update,
  create,
  archive,
  updateCategories,
  listByUserId,
  getFilterProducts,
  listProductsByUserId
};
