/* eslint-disable react/display-name */
import { AdminForm } from 'components/Common/AdminForm';
import { ADMIN_ACTIONS } from 'components/Common/AdminForm/constants';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { EllipseSpan } from 'components/Common/StyledComponents/Span';
import { TooltipComponent } from 'components/Common/Tooltip';
import { showNotification } from 'constants/messages';
import { get as _get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown
} from 'reactstrap';
import {
  addNewAdmin,
  deleteAdmin,
  getAdmins,
  resetErrorStatus,
  updateAdmin
} from 'store/admins/actions';
import { getStaffGroups } from 'store/staffGroups/actions';
import EmpyDataForm from '../../components/Common/EmptyDataForm';
import { Spin } from 'antd';
import { withTranslation } from 'react-i18next';
import {  ACTIVE, INACTIVE } from 'constants/index';
import { SIZEPERPAGE } from 'constants/utils';

const AdminList = (props) => {
  const dispatch = useDispatch();

  const { staffGroups, admins, errors, isSuccess, isLoadingAdmins } = useSelector(state => {
    return {
      isLoadingAdmins: _get(state, ['admins', 'isLoadingAdmins'], false),
      admins: _get(state, ['admins', 'admins'], []),
      staffGroups: _get(state, ['staffGroups', 'staffGroups'], []),
      errors: _get(state, ['admins', 'errors', 'errors'], ''),
      isSuccess: _get(state, ['admins', 'isSuccess'], '')
    };
  });

  const [confirm_both, setconfirm_both] = useState(false);
  const [adminDelete, setAdminDelete] = useState([]);
  const [edittingAdmin, setEdittingAdmin] = useState({});
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [pageOptions, setPageOptions] = useState({
    sizePerPage: SIZEPERPAGE,
    totalSize: admins.length,
    hideSizePerPage: true
  });

  useEffect(() => {
    if (!isEmpty(admins))
      setPageOptions({
        sizePerPage: SIZEPERPAGE,
        hideSizePerPage: true
      });
  }, [admins]);

const AdminsColumns = [
    {
      dataField: 'name',
      text: props.t('name'),
      sort: true
    },
    {
      dataField: 'first_name',
      text: props.t('first name'),
      sort: true
    },
    {
      dataField: 'last_name',
      text: props.t('last name'),
      sort: true
    },
    {
      dataField: 'status',
      text: props.t('status'),
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className='mb-0'>{row.status == 'active' ? props.t(ACTIVE) : props.t(INACTIVE) }</p>
        </>
      )
    },
    {
      dataField: 'phone_number',
      text: props.t('phone'),
      sort: true
    },
    {
      dataField: 'email',
      text: props.t('email'),
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className='mb-0'>{row.email}</p>
        </>
      )
    },
    {
      dataField: 'teams',
      text: props.t('user groups'),
      formatter: (cellContent, row) => {
        const teams = _get(row, 'teams', []);
        const teamsName = teams.reduce((accumulator, cur) => {
          return accumulator + ', ' + cur.name;
        }, '');

        const tooltipContent = (
          <ul>
            {teams.map(per => (
              <li key={`admin-team-${_get(per, 'id', '')}`}>{_get(per, 'name', '')}</li>
            ))}
          </ul>
        );
        return (
          <TooltipComponent
            id={_get(row, 'id', '').toString()}
            content={tooltipContent}
          >
            <EllipseSpan>{teamsName.substring(1)}</EllipseSpan>
          </TooltipComponent>
        );
      }
    },
    {
      dataField: 'menu',
      isDummyField: true,
      text: props.t('action'),
      formatter: (cellContent, admin) => (
        <UncontrolledDropdown direction='left'>
          <DropdownToggle href='#' className='card-drop' tag='i'>
            <i className='mdi mdi-dots-horizontal font-size-18' />
          </DropdownToggle>
          <DropdownMenu className='dropdown-menu-end'>
            <DropdownItem onClick={() => handleEditAdmin(admin)}>
              <i className='fas fa-pencil-alt text-success me-1' />
              {props.t('edit')}
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                setconfirm_both(true);
                setAdminDelete(admin);
              }}
            >
              <i className='fas fa-trash-alt text-danger me-1' />
              {props.t('delete')}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    }
  ];

  const toggle = () => {
    setModal(!modal);
  };

  const handleEditAdmin = admin => {
    setEdittingAdmin({
      id: admin.id,
      name: admin.name,
      email: admin.email,
      password: admin.password,
      firstName: admin.first_name,
      lastName: admin.last_name,
      phoneNumber: admin.phone_number,
      status: admin.status,
      teams: admin.teams
    });
    setIsEdit(true);
    toggle();
  };

  const handleDeleteAdmin = admin => {
    dispatch(deleteAdmin(admin));
  };

  const handleValidAdminSubmit = (e, values) => {
    const newAdmin = {
      id: edittingAdmin.id,
      name: values.name,
      password: values.password,
      email: values.email,
      firstName: values.first_name,
      lastName: values.last_name,
      phoneNumber: values.phone_number,
      teamIds: values.teamIds,
      status: isEmpty(values.status) ? 'active' : values.status
    };
    isEdit ? dispatch(updateAdmin(newAdmin)) : dispatch(addNewAdmin(newAdmin));
    toggle();
  };

  const onSubmit = (e, values) => {
    handleValidAdminSubmit(e, values);
  };

  useEffect(() => {
    if (!isEmpty(errors)) showNotification(errors);
    if (isSuccess) showNotification('');
    dispatch(resetErrorStatus());
  }, [errors, isSuccess]);

  useEffect(() => {
    dispatch(getAdmins());
    dispatch(getStaffGroups());
  }, []);

  const handleAddNewAdmin = () => {
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc'
    }
  ];
  
  return (
    <Spin tip={props.t('loading')+'...'} spinning={isLoadingAdmins}>
    <React.Fragment>
      <div className='page-content'>
        <MetaTags>
          <title>{props.t('users')} | Le diamant du terroir</title>
        </MetaTags>
        {confirm_both && (
          <SweetAlert
            title={props.t('are you sure?')}
            warning
            showCancel
            confirmBtnBsStyle='success'
            cancelBtnBsStyle='danger'
            onConfirm={() => {
              handleDeleteAdmin(adminDelete);
              setconfirm_both(false);
            }}
            onCancel={() => {
              setconfirm_both(false);
            }}
          />
        )}
        <Container fluid>
          <Breadcrumbs title={props.t('manage')} breadcrumbItem={props.t('users')} />
          <Row>
            <Col xs='12'>
              <Card>
                <CardBody>
                  <React.Fragment>
                    <Row className='mb-2'>
                      <Col sm='4'>
                        <div className='search-box ms-2 mb-2 d-inline-block'>
                          <div className='position-relative'></div>
                        </div>
                      </Col>
                      <Col sm='8'>
                        <div className='text-sm-end'>
                          <Button
                            type='button'
                            color='success'
                            className='btn-rounded  mb-2 me-2'
                            onClick={handleAddNewAdmin}
                          >
                            <i className='mdi mdi-plus me-1' />
                            {props.t('new user')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    {!isEmpty(admins) ? (
                      <Row>
                        <Col xl='12'>
                          <div className='table-responsive'>
                            <BootstrapTable
                              keyField='id'
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              classes={'table align-middle table-nowrap table-check'}
                              headerWrapperClasses={'table-light'}
                              pagination={paginationFactory(pageOptions)}
                              data={admins}
                              columns={AdminsColumns}
                            />
                          </div>
                          <Modal isOpen={modal} toggle={toggle} scrollable={true}>
                            <ModalHeader toggle={toggle} tag='h4'>
                              {isEdit ? props.t('edit') : props.t('add')}
                            </ModalHeader>
                            <ModalBody>
                              <AdminForm
                                admin={isEdit ? edittingAdmin : {}}
                                action={isEdit ? ADMIN_ACTIONS.UPDATE : ADMIN_ACTIONS.CREATE}
                                staffGroups={staffGroups}
                                handleSubmit={onSubmit}
                              />
                            </ModalBody>
                          </Modal>
                        </Col>
                      </Row>
                    ) : (
                      <EmpyDataForm/>
                    )}
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
    </Spin>
  );
};

AdminList.propTypes = {
  Admins: PropTypes.array,
  onGetAdmins: PropTypes.func,
  onAddNewAdmin: PropTypes.func,
  onDeleteAdmin: PropTypes.func,
  onUpdateAdmin: PropTypes.func,
  t: PropTypes.any
};

export default withTranslation()(AdminList);
