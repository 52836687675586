import PropTypes from 'prop-types';
import React from 'react';

import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';

// Import Routes all
import {
  authProtectedRoutes,
  publicRoutes,
  superAdminProtectedRoutes,
  excludedRoutes,
} from './routes';

// Import all middleware
import {
  Authmiddleware,
  ExcludedMiddleware,
  SuperAdminMiddleware,
  PublicMiddleware,
} from './routes/route';

// layouts Format
import VerticalLayout from './components/VerticalLayout/';
import NonAuthLayout from './components/NonAuthLayout';

import { OrderProvider } from 'contexts/orderContext';

// Import scss
import './assets/scss/theme.scss';

const App = props => {
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();
  return (
    <React.Fragment>
      <OrderProvider>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <PublicMiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}
            {authProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}

            {superAdminProtectedRoutes.map((route, idx) => (
              <SuperAdminMiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}

            {excludedRoutes.map((route, idx) => (
              <ExcludedMiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                exact
              />
            ))}
          </Switch>
        </Router>
      </OrderProvider>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
