import { API_URL } from '../constants';
import { get } from '../helpers/apiHelper';

const list = async () => {
  return await get({
    url: API_URL.permissions.list,
  });
};

const getMyPermissions = async () => {
  return await get({
    url: API_URL.permissions.myPermissions,
  });
};

export const permissionsService = {
  list,
  getMyPermissions
};
