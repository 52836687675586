import {
    PUSH_MESSAGE,
    PUSH_MESSAGE_SUCCESS,
    PUSH_MESSAGE_FAILURE,
    GET_MESSAGE_HISTORY,
    GET_MESSAGE_HISTORY_SUCCESS,
    GET_MESSAGE_HISTORY_FAILURE,
    GET_USER_FOR_MESSAGE,
    GET_USER_FOR_MESSAGE_FAILURE,
    UPDATE_MESSAGE,
    UPDATE_MESSAGE_SUCCESS,
    UPDATE_MESSAGE_FAILURE,
    GET_USER_FOR_MESSAGE_SUCCESS,
  } from './actionTypes';
  
  export const pushMessage = message => ({
    type: PUSH_MESSAGE,
    payload: message,
  });
  
  export const pushMessageSuccess = message => ({
    type: PUSH_MESSAGE_SUCCESS,
    payload: message,
  });
  
  export const pushMessageFailure = errors => ({
    type: PUSH_MESSAGE_FAILURE,
    payload: errors,
  });
  
  export const getMessageHistory = () => ({
    type: GET_MESSAGE_HISTORY
  });
  
  export const getMessageHistorySuccess = history => ({
    type: GET_MESSAGE_HISTORY_SUCCESS,
    payload: history,
  });
  
  export const getMessageHistoryFailure = error => ({
    type: GET_MESSAGE_HISTORY_FAILURE,
    payload: error,
  });
  
  export const getUserForMessage = id => ({
    type: GET_USER_FOR_MESSAGE,
    payload: id
  });

  export const getUserForMessageSuccess = users => ({
    type: GET_USER_FOR_MESSAGE_SUCCESS,
    payload: users,
  });
  
  export const getUserForMessageFailure = error => ({
    type: GET_USER_FOR_MESSAGE_FAILURE,
    payload: error,
  });

  export const updateMessage = payload => ({
    type: UPDATE_MESSAGE,
    payload
  });

  export const updateMessageSuccess = message => ({
    type: UPDATE_MESSAGE_SUCCESS,
    payload: message,
  });
  
  export const updateMessageFailure = error => ({
    type: UPDATE_MESSAGE_FAILURE,
    payload: error,
  });
