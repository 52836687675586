import { all, fork } from 'redux-saga/effects';

//public
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import ProfileSaga from './auth/profile/saga';
import LayoutSaga from './layout/saga';
import dashboardSaga from './dashboard/saga';
import adminsSaga from './admins/saga';
import groupsSaga from './groups/saga';
import usersSaga from './users/saga';
import categoriesSaga from './categories/saga';
import ordersSaga from './orders/saga';
import productsSaga from './products/saga';
import notificationsSaga from './pushNotifications/saga';
import staffGroupsSaga from './staffGroups/saga';
import permissionsSaga from './permission/saga';
import messageSaga from './pushMessages/saga';

export default function* rootSaga() {
  yield all([
    //public
    fork(ordersSaga),
    fork(adminsSaga),
    fork(groupsSaga),
    fork(usersSaga),
    fork(categoriesSaga),
    fork(productsSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(notificationsSaga),
    fork(staffGroupsSaga),
    fork(permissionsSaga),
    fork(messageSaga)
  ]);
}
