/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { get as _get } from 'lodash';
import { Table } from 'antd';
import { withTranslation } from 'react-i18next';

const ExpandableRow = props => {
  // eslint-disable-next-line react/prop-types
  const { handleEditSubGroup, handleDeleteSubGroup, rowDatas = {}, isPermission } = props;
  // eslint-disable-next-line react/prop-types
  const { sub: subGroups = {}, id } = rowDatas;

  const { t: translator } = props;

  const subGroupsData = useMemo(() => Object.values(subGroups), [subGroups]);

  const SubGroupsColumnsNoAction = [
    {
      dataIndex: 'name',
      title: translator('name'),
      width: '30%',
      render: (cellContent, row) => <div className='column-content'>{row.name}</div>,
    },
    {
      dataIndex: 'total_user',
      title: translator('numbers of user'),
      className: 'group-column group-user-numbers',
      width: '30%',
    },
    {
      dataIndex: 'categories',
      title: translator('categories'),
      className: 'group-column group-user-numbers',
      width: '30%',
      render: (cellContent, row) => <p>{_get(row, 'category', []).join(', ')}</p>
    }
  ];

  const SubGroupsColumns = isPermission
    ? [
        ...SubGroupsColumnsNoAction,
        {
          dataIndex: 'menu',
          title: translator('action'),
          width: '10%',
          render: (cellContent, group) => (
            <UncontrolledDropdown direction='left'>
              <DropdownToggle href='#' className='card-drop' tag='i'>
                <i className='mdi mdi-dots-horizontal font-size-18' />
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu-end'>
                <DropdownItem onClick={() => handleEditSubGroup(group, id)}>
                  <i className='fas fa-pencil-alt text-success me-1' />
                  {translator('edit')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    handleDeleteSubGroup(group, id);
                  }}
                >
                  <i className='fas fa-trash-alt text-danger me-1' />
                  {translator('delete')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        },
      ]
    : SubGroupsColumnsNoAction;

  return (
    <Table
      showHeader={false}
      rowKey='id'
      dataSource={subGroupsData}
      columns={SubGroupsColumns}
      pagination={false}
    ></Table>
  );
};

ExpandableRow.propTypes = {
  handleEditSubGroup: PropTypes.func,
  handleDeleteSubGroup: PropTypes.func,
  rowDatas: PropTypes.object,
  t: PropTypes.any
};

export const GroupsExpandableRow = withTranslation()(ExpandableRow);