import {
  GET_STAFF_GROUPS,
  GET_STAFF_GROUPS_FAIL,
  GET_STAFF_GROUPS_SUCCESS,
  ADD_NEW_STAFF_GROUP,
  ADD_STAFF_GROUP_FAIL,
  ADD_STAFF_GROUP_SUCCESS,
  UPDATE_STAFF_GROUP,
  UPDATE_STAFF_GROUP_FAIL,
  UPDATE_STAFF_GROUP_SUCCESS,
  DELETE_STAFF_GROUP,
  DELETE_STAFF_GROUP_FAIL,
  DELETE_STAFF_GROUP_SUCCESS,
  RESET_ERROR_STATUS,
} from './actionTypes';

export const getStaffGroups = () => ({
  type: GET_STAFF_GROUPS,
});

export const getStaffGroupsSuccess = staffGroups => ({
  type: GET_STAFF_GROUPS_SUCCESS,
  payload: staffGroups,
});

export const getStaffGroupsFail = error => ({
  type: GET_STAFF_GROUPS_FAIL,
  payload: error,
});

export const addNewStaffGroup = staffGroup => ({
  type: ADD_NEW_STAFF_GROUP,
  payload: staffGroup,
});

export const addStaffGroupSuccess = staffGroup => ({
  type: ADD_STAFF_GROUP_SUCCESS,
  payload: staffGroup,
});

export const addStaffGroupFail = error => ({
  type: ADD_STAFF_GROUP_FAIL,
  payload: error,
});

export const updateStaffGroup = staffGroup => ({
  type: UPDATE_STAFF_GROUP,
  payload: staffGroup,
});

export const updateStaffGroupSuccess = staffGroup => ({
  type: UPDATE_STAFF_GROUP_SUCCESS,
  payload: staffGroup,
});

export const updateStaffGroupFail = error => ({
  type: UPDATE_STAFF_GROUP_FAIL,
  payload: error,
});


export const deleteStaffGroup = staffGroup => {
  return {
    type: DELETE_STAFF_GROUP,
    payload: staffGroup,
  };
};

export const deleteStaffGroupSuccess = staffGroup => ({
  type: DELETE_STAFF_GROUP_SUCCESS,
  payload: staffGroup,
});

export const deleteStaffGroupFail = error => ({
  type: DELETE_STAFF_GROUP_FAIL,
  payload: error,
});

export const resetErrorStatus = () => ({
  type: RESET_ERROR_STATUS,
});