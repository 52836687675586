import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get as _get, isEmpty } from 'lodash';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Col, Row, Input, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getFilteredProductByUserId } from 'store/products/actions';
import { capitalize } from 'utils/string';
import Select from 'react-select';
import { SUPERIOR_ADMIN_ROLE } from 'constants/index';
import { PERMISSIONS } from 'utils/permissions';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { customStyleSelect } from '../../../constants/styles';

const CustomAVField = styled(AvField)`
  display: none;
`;

const staticProperty = ['dateOfDelivery', 'product-validator'];

const Form = props => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const { handleSubmit, userId, isNotificationEnable, t: translator } = props;
  const [selectedProduct, setSelectedProduct] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedProductOption, setSelectedProductOption] = useState({});
  const [isPushNotification, setIsPushNotification] = useState(false);
  const { products, myPermissions, role } = useSelector(state => {
    return {
      products: _get(state, ['products', 'filteredProductById'], []),
      myPermissions: _get(state, ['permissions', 'myPermissions'], []),
      role: _get(state, ['Profile', 'profile', 'role'], undefined),
    };
  });

  const [isSuperior, setIsSuperior] = useState(false);
  useEffect(() => {
    setIsSuperior(role === SUPERIOR_ADMIN_ROLE);
  }, [role]);

  const [isMessagesNotificationPermission, setIsMessagesNotificationPermission] = useState(false);

  useEffect(() => {
    if (isEmpty(myPermissions)) return;
    const arr = [];
    myPermissions.forEach(permission => {
      const permissionName = _get(permission, 'name', '');
      arr.push(permissionName);
    });
    setIsMessagesNotificationPermission(arr.includes(PERMISSIONS.MESSAGES_NOTIFICATIONS));
  }, [myPermissions]);

  useEffect(() => {
    if (isEmpty(products)) dispatch(getFilteredProductByUserId(userId));
  }, []);

  const handleValidSubmit = (e, values) => {
    const formFields = { ...values };
    let newOrder = {};

    staticProperty.forEach(property => {
      newOrder[property] = _get(formFields, property, '');
      delete formFields[property];
    });

    newOrder = {
      ...newOrder,
      products: Object.entries(formFields).map(val => {
        const [productId, quantity] = val;
        return [
          parseInt(productId),
          parseInt(quantity),
          _get(selectedProductOption, [parseInt(productId), 'value', 'id'], ''),
        ];
      }),
      isPushNotification,
    };

    handleSubmit(newOrder);
  };

  const handleClickPush = () => {
    setIsPushNotification(!isPushNotification);
  };

  const productsValidator = (value, ctx, input, cb) => {
    cb(!isEmpty(selectedProduct));
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 1,
      position: 'relative'
    }),
    option: (provided, state) => ({
        ...provided,
        paddingLeft: state.data.isSub ? 30 : 15,
    }),
  };

  const options = products.map(product => ({
    label: _get(product, 'name', ''),
    options: _get(product, 'products', []).map(option => ({
      label: _get(option, 'title', ''),
      value: _get(option, 'id', ''),
      info: _get(option, 'info', [])
    }))
  }));

  return (
    <AvForm onValidSubmit={handleValidSubmit}>
      <Row form>
        <Col className='col-14'>
          <div className='mb-3 order-form__product-select'>
            <label className='control-label'>{translator('product')}</label>
            <Select
              name='products'
              styles={customStyles}
              value={selectedProduct}
              onChange={values => {
                setSelectedProduct(values);
              }}
              options={options}
              classNamePrefix='select2-selection'
              isMulti={true}
              placeholder={translator('select')}
            />
            <CustomAVField
              className='hidden-validator'
              name='product-validator'
              type='text'
              errorMessage={translator('invalid product')}
              validate={{ async: productsValidator }}
            />
          </div>
          <div className='mb-3'>
            <AvField
              name='dateOfDelivery'
              label={translator('date of delivery')}
              type='date'
              errorMessage={translator('invalid date of delivery')}
              validate={{
                required: { value: true },
              }}
            />
          </div>
          {selectedProduct.map(product => (
            <div key={`product-${product.value}`} className='mb-3'>
              <label className='control-label'>{capitalize(product.label)}</label>
              <Row>
                <AvField
                  name={`${product.value}`}
                  label={translator('quantity')}
                  type='number'
                  errorMessage={translator('invalid quantity')}
                  min='1'
                  validate={{
                    required: { value: true },
                  }}
                />
              </Row>
              <div className='mb-3 order-form__product-select'>
                <label className='control-label'>{translator('option')}</label>
                <Select
                  styles={customStyleSelect}
                  value={_get(selectedProductOption, _get(product, 'value', ''), '')}
                  onChange={values => {
                    const result = {
                      ...selectedProductOption,
                      [_get(values, ['value', 'prodId'], '')]: values,
                    };
                    setSelectedProductOption(result);
                  }}
                  options={_get(product, 'info', []).map(prodInfo => ({
                    value: {
                      id: _get(prodInfo, 'id', ''),
                      prodId: _get(product, 'value', ''),
                    },
                    label: _get(prodInfo, 'unit_weight', ''),
                  }))}
                  classNamePrefix='select2-selection'
                  placeholder={translator('select')}
                />
              </div>
            </div>
          ))}
          {isNotificationEnable && (isSuperior || isMessagesNotificationPermission) && (
            <>
              <div className='mb-3'>
                <Input
                  type='checkbox'
                  onChange={() => handleClickPush()}
                  title={translator('push notification')}
                  value={isPushNotification}
                />
                <Label className='pushNoti_checkbox'>{translator('push notification')} </Label>
              </div>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='text-end'>
            <button type='submit' className='btn btn-success save-customer'>
              {translator('submit')}
            </button>
          </div>
        </Col>
      </Row>
    </AvForm>
  );
};

export const OrderForm = withTranslation()(Form);

Form.propTypes = {
  deliveryStaffs: PropTypes.array,
  handleSubmit: PropTypes.func,
  userId: PropTypes.string,
  isNotificationEnable: PropTypes.bool,
  t: PropTypes.any,
};
