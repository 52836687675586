import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import PropTypes from 'prop-types';

const ActionsPopUp = props => {
  const { actionLabel, actions = [] } = props;
  return (
    <UncontrolledDropdown direction='left'>
      <DropdownToggle className='card-drop' tag='i'>
        <i className='mdi mdi-dots-horizontal font-size-18' action={actionLabel} />
      </DropdownToggle>
      <DropdownMenu className='dropdown-menu-end'>
        {actions.map(action => (
          <DropdownItem key={`item-${action.label}`} onClick={action.onClick} action={actionLabel}>
            <i
              className={
                action.type === 'success'
                  ? 'fas fa-pencil-alt text-success me-1'
                  : 'fas fa-trash-alt text-danger me-1'
              }
            />
            {action.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

ActionsPopUp.propTypes = {
  actionLabel: PropTypes.string,
  actions: PropTypes.array,
};

export default ActionsPopUp;
