/* STAFF_GROUPS */
export const GET_STAFF_GROUPS = 'GET_STAFF_GROUPS';
export const GET_STAFF_GROUPS_SUCCESS = 'GET_STAFF_GROUPS_SUCCESS';
export const GET_STAFF_GROUPS_FAIL = 'GET_STAFF_GROUPS_FAIL';

/**
 * add STAFF_GROUP
 */
export const ADD_NEW_STAFF_GROUP = 'ADD_NEW_STAFF_GROUP';
export const ADD_STAFF_GROUP_SUCCESS = 'ADD_STAFF_GROUP_SUCCESS';
export const ADD_STAFF_GROUP_FAIL = 'ADD_STAFF_GROUP_FAIL';

/**
 * EditSTAFF_GROUP
 */
export const UPDATE_STAFF_GROUP = 'UPDATE_STAFF_GROUP';
export const UPDATE_STAFF_GROUP_SUCCESS = 'UPDATE_STAFF_GROUP_SUCCESS';
export const UPDATE_STAFF_GROUP_FAIL = 'UPDATE_STAFF_GROUP_FAIL';

/**
 * Delete STAFF_GROUP
 */
export const DELETE_STAFF_GROUP = 'DELETE_STAFF_GROUP';
export const DELETE_STAFF_GROUP_SUCCESS = 'DELETE_STAFF_GROUP_SUCCESS';
export const DELETE_STAFF_GROUP_FAIL = 'DELETE_STAFF_GROUP_FAIL';

 /**
 * RESET ERROR STATUS
 */
export const RESET_ERROR_STATUS = 'RESET_ERROR_STATUS';