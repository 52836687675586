import {
  PUSH_NOTIFICATION,
  PUSH_NOTIFICATION_SUCCESS,
  PUSH_NOTIFICATION_FAILURE,
  GET_NOTIFICATION_HISTORY,
  GET_NOTIFICATION_HISTORY_SUCCESS,
  GET_NOTIFICATION_HISTORY_FAILURE
} from './actionTypes';
import { get as _get } from 'lodash';

const INIT_STATE = {
  errors: '',
  success: ''
};

const NotificationsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PUSH_NOTIFICATION:
    case GET_NOTIFICATION_HISTORY:
      return {
        ...state,
        isProcessing: true
      };
    case PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        notificationHistory: [
          _get(action, 'payload', {}),
          ..._get(state, 'notificationHistory', [])
        ]
      };
    case PUSH_NOTIFICATION_FAILURE:
      return {
        ...state,
        isProcessing: false,
        errors: _get(action, 'payload', {})
      };
    case GET_NOTIFICATION_HISTORY_SUCCESS:
      return {
        isProcessing: false,
        notificationHistory: _get(action, 'payload', [])
      };
    case GET_NOTIFICATION_HISTORY_FAILURE:
      return {
        isProcessing: false,
        notificationHistory: [],
        errors: _get(action, 'payload', {})
      };
    default:
      return state;
  }
};

export default NotificationsReducer;
