import { post, get } from '../helpers/apiHelper';
import { API_URL } from '../constants';
import { get as _get, isEmpty } from 'lodash';

const list = async () => {
  return await get({
    url: API_URL.notifications.list,
  });
};

const create = async notification => {
  const groupId = _get(notification,'groupId',[]);
  const userId = _get(notification,'userId','').toString();
  const productId = _get(notification,'productId','');
  const categoryId = _get(notification,'categoryId','');
  let data = {
    title: _get(notification,'title',''),
    body: _get(notification,'content',''),
  };
  data = isEmpty(userId) ? data : {
    ...data,
    user_id: userId,
  };
  data = isEmpty(groupId) ? data : {
    ...data,
    group_id: groupId,
  };
  data = productId === '' ? data : {
    ...data,
    product_id: productId,
  };
  data = categoryId === '' ? data : {
    ...data,
    category_id: categoryId,
  };
  return await post({
    url: API_URL.notifications.create,
    data,
  });
};

export const notificationService = {
  list,
  create,
};
