import { get as _get } from 'lodash';
import {
  ADD_GROUP_CATEGORY_DEPENDENCIES,
  ADD_GROUP_CATEGORY_DEPENDENCIES_FAIL,
  ADD_GROUP_CATEGORY_DEPENDENCIES_SUCCESS,
  ADD_GROUP_FAIL,
  ADD_GROUP_SUCCESS,
  ADD_NEW_GROUP,
  ADD_SUB_GROUP_SUCCESS,
  ADD_USER_GROUP_SUCCESS,
  DELETE_GROUP,
  DELETE_GROUP_FAIL,
  DELETE_GROUP_SUCCESS,
  DELETE_SUB_GROUP_SUCCESS,
  DELETE_USERS_GROUP_SUCCRESS,
  GET_GROUPS,
  GET_GROUPS_FAIL,
  GET_GROUPS_SUCCESS,
  GET_ALL_GROUPS,
  GET_ALL_GROUPS_FAIL,
  GET_ALL_GROUPS_SUCCESS,
  GET_SUB_GROUPS,
  GET_SUB_GROUPS_FAIL,
  GET_SUB_GROUPS_SUCCESS,
  RESET_ERROR_STATUS,
  UPDATE_GROUP,
  UPDATE_GROUP_FAIL,
  UPDATE_GROUP_SUCCESS,
  UPDATE_SUB_GROUP_SUCCESS
} from './actionTypes';
import {
  handleDeleteSubGroupState,
  handleUpdateSubGroupState,
  handleUpdateTotalUserGroup,
  handleUpdateUserGroup
} from './utils';

const INIT_STATE = {
  error: '',
  success: ''
};

const GroupsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_NEW_GROUP:
    case UPDATE_GROUP:
    case GET_GROUPS:
    case GET_ALL_GROUPS:
    case DELETE_GROUP:
    case GET_SUB_GROUPS:
    case ADD_GROUP_CATEGORY_DEPENDENCIES:
      return {
        ...state,
        isLoadingGroups: true,
        isSuccess: false,
        errors: ''
      };
    case ADD_GROUP_CATEGORY_DEPENDENCIES_SUCCESS: {
      let result = {
        ...state,
        isLoadingGroups: false,
        isSuccess: true
      };
      result = action.payload.isSubGroupRequest
        ? {
            ...result,
            subGroups: state.subGroups.map(subGroup =>
              subGroup.id.toString() === action.payload.id.toString()
                ? { subGroup, ...action.payload }
                : subGroup
            )
          }
        : {
            ...result,
            groups: state.groups.map(group =>
              group.id.toString() === action.payload.id.toString()
                ? { group, ...action.payload }
                : group
            )
          };
      return result;
    }

    case ADD_GROUP_CATEGORY_DEPENDENCIES_FAIL:
      return {
        ...state,
        isLoadingGroups: false,
        errors: action.payload,
        isSuccess: false
      };
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        isLoadingGroups: false,
        groups: action.payload
      };
    case GET_GROUPS_FAIL:
      return {
        ...state,
        isLoadingGroups: false,
        errors: action.payload
      };
    case GET_ALL_GROUPS_SUCCESS:
      return {
        ...state,
        isLoadingGroups: false,
        groups: action.payload
      };
    case GET_ALL_GROUPS_FAIL:
      return {
        ...state,
        isLoadingGroups: false,
        errors: action.payload
      };
    case ADD_GROUP_SUCCESS:
      return {
        ...state,
        isLoadingGroups: false,
        groups: [action.payload, ...state.groups],
        isSuccess: true
      };
    case ADD_GROUP_FAIL:
      return {
        ...state,
        isLoadingGroups: false,
        errors: action.payload,
        isSuccess: false
      };
    case UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        isLoadingGroups: false,
        groups: state.groups.map(group =>
          group.id.toString() === action.payload.id.toString()
            ? { group, ...action.payload }
            : group
        ),
        isSuccess: true
      };
    case UPDATE_GROUP_FAIL:
      return {
        ...state,
        isLoadingGroups: false,
        errors: action.payload,
        isSuccess: false
      };
    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        isLoadingGroups: false,
        groups: state.groups.filter(group => group.id.toString() !== action.payload.toString()),
        isSuccess: true
      };
    case DELETE_GROUP_FAIL:
      return {
        ...state,
        isLoadingGroups: false,
        errors: action.payload,
        isSuccess: false
      };
    case GET_SUB_GROUPS_SUCCESS:
      return {
        ...state,
        isLoadingGroups: false,
        subGroups: action.payload
      };
    case GET_SUB_GROUPS_FAIL:
      return {
        ...state,
        isLoadingGroups: false,
        errors: action.payload
      };
    case ADD_SUB_GROUP_SUCCESS: {
      const groups = _get(state, 'groups', []);
      return {
        ...state,
        isLoadingGroups: false,
        groups: groups.map(group => {
          if (group.id.toString() !== action.payload.type.toString()) return group;
          const subGrs = Object.values(_get(group, 'sub', {}));
          const newSubGrs = [action.payload, ...subGrs];
          return {
            ...group,
            sub: newSubGrs
          };
        }),
        isSuccess: true
      };
    }
    case UPDATE_SUB_GROUP_SUCCESS:
      return {
        ...state,
        ...handleUpdateSubGroupState(state, action),
        isLoadingGroups: false,
        isSuccess: true
      };
    case DELETE_SUB_GROUP_SUCCESS:
      return {
        ...state,
        ...handleDeleteSubGroupState(state, action),
        isLoadingGroups: false,
        isSuccess: true
      };
    case RESET_ERROR_STATUS:
      return {
        ...state,
        errors: undefined,
        isSuccess: false
      };
    case ADD_USER_GROUP_SUCCESS: {
      const parentGroupId = _get(action, ['payload', 'parentGroupId'], undefined);
      const groupId = _get(action, ['payload', 'groupId'], '');
      const groupsState = _get(state, 'groups', []);
      if (parentGroupId)
        return {
          ...state,
          groups: handleUpdateUserGroup(groupsState, parentGroupId, groupId, 1)
        };
      return {
        ...state,
        groups: handleUpdateTotalUserGroup(groupsState, groupId, 1)
      };
    }
    case DELETE_USERS_GROUP_SUCCRESS: {
      const parentGroupId = _get(action, ['payload', 'parentGroupId'], undefined);
      const groupId = _get(action, ['payload', 'groupId'], '');
      const groupsState = _get(state, 'groups', []);
      const totalUserDeleted = _get(action, ['payload', 'totalUserDeleted'], 0);

      if (parentGroupId)
        return {
          ...state,
          groups: handleUpdateUserGroup(groupsState, parentGroupId, groupId, -totalUserDeleted)
        };
      return {
        ...state,
        groups: handleUpdateTotalUserGroup(groupsState, groupId, -totalUserDeleted)
      };
    }
    default:
      return state;
  }
};

export default GroupsReducer;
